import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_TWO_FACTOR,
  LOGOUT,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_USER_PICTURE,
  ACCOUNT_REACTIVATION,
} from "action-types/auth"

const initialState = {
  data: null,
  status: "loginRequested",
  statusMessage: null,
  email: null,
  userPicture: localStorage.getItem("userPicture"),
}

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        data: null,
        status: "loginRequested",
        statusMessage: null,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        userPicture:
          action.payload.picture || localStorage.getItem("userPicture"),
        status: "loginSucceeded",
        statusMessage: null,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        data: action.payload,
        email: action.email,
        status: "loginFailed",
        statusMessage: null,
      }
    case LOGIN_TWO_FACTOR:
      return {
        ...state,
        status: "loginTwoFactor",
        statusMessage: null,
      }
    case LOGOUT:
      return {
        ...state,
        data: null,
        status: "logoutSuceeded",
        statusMessage: null,
      }
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        data: null,
        status: "changePasswordRequested",
        statusMessage: null,
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        data: null,
        status: "changePasswordSucceeded",
        statusMessage: null,
      }
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        data: null,
        status: "changePasswordFailed",
        statusMessage: action.payload,
      }
    case GET_USER_PICTURE:
      return {
        ...state,
        userPicture: action.payload,
      }
    case ACCOUNT_REACTIVATION:
      return {
        ...state,
        status: "accountReactivation",
        statusMessage: null,
      }

    default:
      return state
  }
}

export default auth
