import React, { Component } from "react"
import PropTypes from "prop-types"
import { historyPropTypes, locationPropTypes } from "consts/prop-types"
import { withRouter, Switch, Route, Redirect } from "react-router-dom"
import queryString from "query-string"
import isRequired from "validators/isRequired"
import isEmail from "validators/isEmail"
import isPassword from "validators/isPassword"
import { isMatchPassword } from "validators/isMatchPassword"
import { isMaxBriefText } from "validators/isMaxBriefText"
import FormFormik from "components/FormFormik"
import Spinner from "components/Spinner"
import { colors } from "utils/colorPalette"
import SignupFirstStep from "./SignupFirstStep"
import SignupSecondStep from "./SignupSecondStep"
import { industryOptions } from "../constants"

class SignupForm extends Component {
  state = {
    currentStep: 1,
  }

  preFillIndustryId = () => {
    const { location } = this.props
    const params = queryString.parse(location.search)
    if (params.industry) {
      const industry = industryOptions.find(
        (option) => option.value === Number(params.industry),
      )

      return industry ? industry.value : ""
    }

    return ""
  }

  handleSubmit = (values, form) => {
    const { currentStep } = this.state
    const { history, onSubmit } = this.props
    if (currentStep === 1) {
      form.resetForm(values)

      this.setState(
        {
          currentStep: 2,
        },
        () => history.push("/signup/step-2"),
      )

      return
    }

    localStorage.setItem("track.registration", false)
    onSubmit(values)
  }

  handleBackButton = (form) => {
    const { onClearSubmit } = this.props
    const { values, resetForm } = form

    this.setState({
      currentStep: 1,
    })

    onClearSubmit()
    resetForm(values)
  }

  render() {
    const { currentStep } = this.state
    const { submitting, success } = this.props

    return (
      <FormFormik
        ref={(form) => {
          this.form = form
        }}
        initialValues={{
          email: localStorage.getItem("email_from_site") || "",
          password: "",
          confirmPassword: "",
          firstName: "",
          lastName: "",
          organization: "",
          industry: this.preFillIndustryId(),
          useCase: "",
        }}
        validations={{
          email: currentStep === 1 ? [isRequired, isEmail] : [],
          password: currentStep === 1 ? [isRequired, isPassword] : [],
          confirmPassword:
            currentStep === 1
              ? [
                  isRequired,
                  (confirmPassword, values) =>
                    isMatchPassword(confirmPassword, values.password),
                ]
              : [],
          firstName: currentStep === 2 ? [isRequired] : [],
          lastName: currentStep === 2 ? [isRequired] : [],
          organization: currentStep === 2 ? [isRequired] : [],
          industry: currentStep === 2 ? [isRequired] : [],
          useCase:
            currentStep === 2
              ? [(useCase) => useCase && isMaxBriefText(useCase)]
              : [],
        }}
        onSubmit={(values, form) => this.handleSubmit(values, form)}
      >
        {(form) => (
          <div>
            <Switch>
              <Route
                exact
                path="/signup"
                render={() => (
                  <SignupFirstStep
                    form={form}
                    submitting={submitting}
                    success={success}
                  />
                )}
              />
              <Route
                path="/signup/step-2"
                render={() =>
                  currentStep === 2 ? (
                    <SignupSecondStep
                      form={form}
                      submitting={submitting}
                      success={success}
                    />
                  ) : (
                    <Redirect from="/signup/step-2" to="/signup" />
                  )
                }
              />
            </Switch>
            <div
              style={{
                marginTop: -5,
                marginBottom: 5,
              }}
            >
              <small
                style={{
                  color: colors.crimson,
                }}
              >
                * required fields
              </small>
            </div>
            <button
              className="btn btn-login btn-block"
              data-test-id="signup"
              type="submit"
              disabled={submitting || success}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  marginRight: 5,
                }}
              >
                {currentStep === 1 ? "Continue" : "Register Account"}{" "}
              </span>
              {submitting && <Spinner small />}
            </button>
            {currentStep === 2 && (
              <div
                style={{
                  textAlign: "right",
                  marginTop: 10,
                }}
              >
                <small>
                  <button
                    disabled={submitting || success}
                    className="back-button"
                    type="button"
                    onClick={() => this.handleBackButton(form)}
                  >
                    Back
                  </button>
                </small>
              </div>
            )}
          </div>
        )}
      </FormFormik>
    )
  }
}

SignupForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClearSubmit: PropTypes.func.isRequired,
  history: historyPropTypes.isRequired,
  location: locationPropTypes.isRequired,
}

SignupForm.defaultProps = {
  success: false,
}

export default withRouter(SignupForm)
