export function AccessControl(props) {
  if (props.env === "prod" && process.env.NODE_ENV !== "production") {
    return null
  }

  if (
    props.env === "dev" &&
    !process.env.REACT_APP_API_URL.includes("dev-api")
  ) {
    return null
  }

  return props.children
}
