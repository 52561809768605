import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Callout = (props) => (
  <div
    className={classNames(
      "bp3-callout",
      `bp3-intent-${props.intent}`,
      props.icon && `bp3-icon-${props.icon}`,
    )}
    style={{
      marginBottom: props.marginBottom,
      textAlign: props.textAlign,
      ...props.style,
    }}
  >
    {props.title && (
      <h5
        style={{
          fontSize: 17,
          fontWeight: 600,
        }}
      >
        {props.title}
      </h5>
    )}
    {props.children}
  </div>
)

Callout.propTypes = {
  intent: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.node,
  marginBottom: PropTypes.number,
  textAlign: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}

export default Callout
