import React, { Component, useEffect, useContext } from "react"
import httpClient from "_v2/Core/Utils/httpClient"
import { useFlags } from "launchdarkly-react-client-sdk"
import moment from "moment"

import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { locationPropTypes, organizationPropTypes } from "consts/prop-types"
import get from "lodash/get"
import createUrl from "utils/createUrl"
import { hidePartnerProgramPage, hideAppAwarePage } from "utils/auth"

import { useResource } from "_Infinity/Core/Hooks/useResource"

import Spinner from "components/Spinner"
import RocketIcon from "components/SvgIcons/RocketIcon"

import { UPDATE_SUBSCRIPTIONS } from "action-types/subscriptions"
import { SessionContext } from "_v2/Modules/Auth"

import { useDistributorPlatform } from "_Infinity/DistributorPlatform/Hooks/useDistributorPlatform"
import { isDataExportVisible } from "utils/dataExport"
import { isUniversalListsVisible } from "utils/universalLists"
import { usePartnerProgram } from "components/PartnerProgramContext"
import { isDistributorSubscriptionPageVisible } from "utils/distributor"

const PartnerProgramLink = (props) => {
  const partnerProgram = usePartnerProgram()

  return (
    (props.showPartnerProgramPage || partnerProgram.showPartnerProgram) && (
      <div>
        <Link
          to={createUrl(`/settings/billing-info/partner-program`)}
          style={{
            margin: "20px 10px",
            padding: 10,
            border: "1px dashed #0498f3",
            color: "#fff",
            display: "flex",
          }}
        >
          <RocketIcon
            style={{
              marginRight: "10px",
            }}
          />
          Become a MSP Partner
        </Link>
      </div>
    )
  )
}

class Navigation extends Component {
  state = {
    expandedMenu: null,
  }

  getMenuItems() {
    const { currentOrg, session, mainOrganization, location, flags } =
      this.props
    const hideLegacyReports = !!currentOrg?.feature_flags?.includes(
      "disable_legacy_reports",
    )
    const showDataExport = isDataExportVisible(
      session,
      location?.pathname,
      flags,
    )
    const showUniversalLists = isUniversalListsVisible(
      session,
      location?.pathname,
    )
    const showSubscriptionsPage = isDistributorSubscriptionPageVisible(
      session,
      location?.pathname,
      flags,
    )

    return [
      {
        key: "overview",
        label: "Overview",
        path: createUrl(`/overview/general#${btoa(Math.random())}`),
        icon: "site-menu-icon fa fa-globe",
      },
      ...(!localStorage.getItem("owned_msp_id")
        ? [
            {
              key: "policies",
              label: "Policies",
              path: createUrl("/policies/filtering"),
              icon: "site-menu-icon fa fa-user",
              children: [
                {
                  key: "filtering",
                  label: "Filtering",
                  path: createUrl("/policies/filtering"),
                },
                {
                  key: "filtering-schedules",
                  label: "Filtering Schedules",
                  path: createUrl("/policies/schedules"),
                },
                {
                  key: "universal-lists",
                  label: "Universal Lists",
                  path: createUrl("/policies/universal-lists"),
                  hidden: !showUniversalLists,
                },
                {
                  key: "block-pages",
                  label: "Block Pages",
                  path: createUrl("/policies/block-pages"),
                },
              ],
            },
          ]
        : [
            {
              key: "global-policies",
              label: "Global Policies",
              path: createUrl("/global-policies/filtering"),
              icon: "site-menu-icon fa fa-gavel",
              children: [
                {
                  key: "global-filtering",
                  label: "Filtering",
                  path: createUrl("/global-policies/filtering"),
                },
                {
                  key: "global-universal-lists",
                  label: "Universal Lists",
                  path: createUrl("/global-policies/universal-lists"),
                  hidden: !showUniversalLists,
                },
                {
                  key: "global-block-pages",
                  label: "Block Pages",
                  path: createUrl("/global-policies/block-pages"),
                },
              ],
            },
          ]),

      ...(!localStorage.getItem("owned_msp_id")
        ? [
            {
              key: "deployments",
              label: "Deployments",
              path: createUrl("/deployments/sites"),
              icon: "site-menu-icon fa fa-sitemap",
              children: [
                {
                  key: "sites",
                  label: "Sites",
                  path: createUrl("/deployments/sites"),
                },
                {
                  key: "user-agents",
                  label: "Roaming Clients",
                  path: createUrl("/deployments/user-agents"),
                },
                {
                  key: "collections",
                  label: "Collections",
                  path: createUrl("/deployments/collections"),
                  hidden:
                    !this.props.showActiveDirectory ||
                    localStorage.getItem("gdpr") ||
                    (localStorage.getItem("organization.isMSPSubAccount") &&
                      session?.user?.acl.deploymentsCollections === false),
                },
                {
                  key: "rc-users",
                  label: "Users",
                  path: createUrl("/deployments/users"),
                  hidden: localStorage.getItem("gdpr"),
                },
                {
                  key: "sync-tools",
                  label: "Sync Tools",
                  path: createUrl("/deployments/sync-tools"),
                  hidden:
                    !this.props.showActiveDirectory ||
                    localStorage.getItem("gdpr") ||
                    (localStorage.getItem("organization.isMSPSubAccount") &&
                      session?.user?.acl.deploymentsSincTools === false),
                },
                {
                  key: "lan-proxies",
                  label: "Relays",
                  path: createUrl("/deployments/lan-proxy"),
                  hidden:
                    localStorage.getItem("organization.isMSPSubAccount") &&
                    session?.user?.acl.deploymentsRelays === false,
                },
              ],
            },
          ]
        : []),
      {
        key: "reporting",
        label: "Reporting",
        path: createUrl("/reporting/insights"),
        icon: "site-menu-icon fa fa-bar-chart",
        children: [
          {
            key: "insights",
            label: "Insights",
            path: createUrl("/reporting/insights"),
          },
          {
            key: "appaware",
            label: "AppAware",
            path: createUrl("/reporting/appaware"),
            hidden: this.props.hideAppAware,
          },
          {
            key: "scheduled-reports",
            label: "Scheduled Reports",
            path: createUrl("/reporting/scheduled-reports"),
            hidden:
              /read_only|policies_only/.test(
                session.user?.rolesByOrganization[currentOrg.id],
              ) || localStorage.getItem("organization.isMSPSubAccount"),
          },
          {
            key: "data-explorer",
            label: "Data Explorer",
            path: createUrl("/reporting/data-explorer"),
            hidden: hideLegacyReports,
          },
          {
            key: "total-request",
            label: "Total Requests",
            path: createUrl("/reporting/total-requests"),
            hidden: hideLegacyReports,
          },
          {
            key: "queries-per-second",
            label: "Queries Per Second",
            path: createUrl("/reporting/queries-per-second"),
            hidden: hideLegacyReports,
          },
          ...(!localStorage.getItem("owned_msp_id")
            ? [
                {
                  key: "most-active-sites",
                  label: "Most Active Sites",
                  path: createUrl("/reporting/most-active-sites"),
                  hidden: hideLegacyReports,
                },
              ]
            : [
                {
                  key: "most-active-organizations",
                  label: "Most Active Orgs",
                  path: createUrl("/reporting/most-active-organizations"),
                  hidden: hideLegacyReports,
                },
              ]),
          {
            key: "top-requests",
            label: "Top Requests",
            path: createUrl("/reporting/top-requests"),
            hidden: hideLegacyReports,
          },
          {
            key: "threats",
            label: "Threats",
            path: createUrl("/reporting/threats"),
            hidden: hideLegacyReports,
          },
          {
            key: "alerts",
            label: "Alerts",
            path: createUrl("/reporting/alerts"),
            hidden:
              hideLegacyReports || mainOrganization.type === "suborganization",
          },
        ],
      },
      {
        key: "settings",
        label: localStorage.getItem("owned_msp_id") ? "MSP" : "Organization",
        path: createUrl("/settings/profile"),
        icon: "site-menu-icon fa fa-building",
        children: [
          {
            key: "profile",
            label: "Profile",
            path: createUrl("/settings/profile"),
          },
          {
            key: "users",
            label: "Users",
            path: createUrl("/settings/users"),
          },
          {
            key: "whitelabel",
            label: "Whitelabel",
            path: createUrl("/settings/whitelabel"),
            hidden: !localStorage.getItem("organization.isMSP"),
          },
          {
            key: "subscriptions",
            label: "Subscriptions",
            path: createUrl("/settings/subscriptions"),
            hidden: !showSubscriptionsPage,
          },
          {
            key: "billing-info",
            label: "Billing",
            path: createUrl("/settings/billing-info"),
            hidden:
              !localStorage.getItem("organization.showBilling") ||
              localStorage.getItem("organization.isMSPSubAccount") ||
              localStorage.getItem("organization.readOnly"),
          },
          {
            key: "settings",
            label: "Settings",
            path: createUrl("/settings/settings"),
          },
        ],
      },
      {
        key: "tools",
        label: "Tools",
        path: createUrl("/tools/ssl-certificate"),
        icon: "site-menu-icon fa fa-wrench",
        children: [
          {
            key: "ssl-certificate",
            label: "SSL Certificate",
            path: createUrl("/tools/ssl-certificate"),
          },
          ...(!localStorage.getItem("owned_msp_id")
            ? [
                {
                  key: "query-log",
                  label: "Query Log",
                  path: createUrl("/tools/query-log"),
                },
                {
                  key: "policy-audit-log",
                  label: "Policy Audit Log",
                  path: createUrl("/tools/policy-audit-log"),
                  hidden: session.user.plan === "basic",
                },
              ]
            : []),
          ...(showDataExport
            ? [
                {
                  key: "data-export",
                  label: "Data Export",
                  path: createUrl("/tools/data-export"),
                },
              ]
            : []),
          {
            key: "domain-lookup",
            label: "Domain Report",
            path: createUrl("/tools/domain-lookup"),
          },
          {
            key: "Color palette",
            label: "Color Palette",
            path: createUrl("/tools/color-palette"),
            hidden: process.env.NODE_ENV === "production",
          },
        ],
      },
      {
        key: "integrations",
        label: "Integrations",
        path: createUrl("/integrations"),
        icon: "site-menu-icon fa fa-cogs",
        hidden:
          !session.user.rolesByOrganization[mainOrganization.id].match(
            /owner|administrator/,
          ) ||
          (localStorage.getItem("whitelabel.name") &&
            mainOrganization.type === "suborganization"),
      },
    ]
  }

  componentDidMount() {
    let menuItems = this.getMenuItems()

    const sections = this.props.location.pathname.split("/")

    const currentMenu = menuItems.find((item) =>
      item.path.includes(sections[3]),
    )

    this.setState({
      expandedMenu: currentMenu ? currentMenu.key : null,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const menuItems = this.getMenuItems()
    const nextSections = nextProps.location.pathname.split("/")
    const sections = this.props.location.pathname.split("/")
    if (nextSections[1] !== sections[1]) {
      const currentMenu = menuItems.find((item) =>
        item.path.includes(nextSections[1]),
      )

      this.setState({
        expandedMenu: currentMenu ? currentMenu.key : null,
      })
    }
  }

  getItemClassName(path) {
    const { location } = this.props

    if (location.pathname.includes(path)) {
      return "site-menu-item active open"
    }

    return "site-menu-item"
  }

  onSubOrgBillingInfoClick = () => {
    const { currentOrg } = this.props
    localStorage.setItem("owned_msp_id", currentOrg.owned_msp_id)
    localStorage.setItem("orgId", currentOrg.id)
  }

  showTrialMessage = ({ currentOrg, isLeft = true }) => {
    if (localStorage.getItem("orgChanged")) {
      return ""
    }

    if (!isLeft) {
      return ""
    }

    let message = (
      <span>
        {currentOrg.trial_days} {currentOrg.trial_days === 1 ? "day" : "days"}{" "}
        left in trial
      </span>
    )

    let distributorLenovo = currentOrg?.stated_distributor === "lenovo" ?? false

    if (distributorLenovo && this.props.subscriptions.data?.data.length) {
      message = (
        <span>
          Your subscription starts in {currentOrg.trial_days}{" "}
          {currentOrg.trial_days === 1 ? "day" : "days"}
        </span>
      )
    }

    return (
      <li
        style={{
          backgroundColor: "#f36098",
          color: "#fff",
        }}
      >
        <div
          style={{
            backgroundColor: "#A04B71",
            color: "#fff",
            marginLeft: 10,
            padding: "10px 15px",
            textAlign: "left",
          }}
        >
          {message}
        </div>
      </li>
    )
  }

  render() {
    const { currentOrg, subscriptions } = this.props

    let distributorLenovo = currentOrg?.stated_distributor === "lenovo" ?? false

    const statusInTrial =
      subscriptions?.data?.data[0]?.attributes?.status === "in_trial"

    const cancelDateHasExpired =
      moment(currentOrg.will_cancel_at).diff(moment(), "days") > 0

    return (
      <div className="hidden-print">
        <div className="site-menubar">
          <div className="site-menubar-body scrollable scrollable-inverse is-enabled scrollable-vertical">
            <div className="scrollable-container">
              <div className="scrollable-content">
                <ul
                  className="site-menu"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  {this.getMenuItems()
                    .filter((item) => !item.hidden)
                    .map((item) => (
                      <li
                        key={item.key}
                        className={this.getItemClassName(item.key)}
                      >
                        <Link
                          to={{
                            pathname: item.path,
                            search: item.path.includes("reporting")
                              ? this.props.location.search
                              : null,
                          }}
                          data-test-id={`site-menu-${item.key}`}
                          onClick={() =>
                            this.setState({
                              expandedMenu: item.key,
                            })
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.icon && <span className={item.icon} />}
                          <span className="site-menu-title">{item.label}</span>
                        </Link>
                        {item.children && (
                          <ul
                            className="site-menu site-menu-sub animated"
                            style={{
                              height:
                                this.state.expandedMenu === item.key
                                  ? 30 *
                                    item.children.filter(
                                      (child) => !child.hidden,
                                    ).length
                                  : 0,
                            }}
                          >
                            {item.children.map((child) =>
                              child.hidden ? null : (
                                <li
                                  key={child.path}
                                  className={`site-menu-item ${
                                    this.props.location.pathname.includes(
                                      child.path,
                                    )
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  {currentOrg.owned_msp_id &&
                                  child.path.includes("organization") &&
                                  child.key === "billing-info" ? (
                                    <a
                                      data-test-id={`site-menu-subitem-${child.key}`}
                                      href={`/msp/${currentOrg.owned_msp_id}/settings/billing-info`}
                                      onClick={this.onSubOrgBillingInfoClick}
                                    >
                                      {child.label}
                                    </a>
                                  ) : (
                                    <Link
                                      data-test-id={`site-menu-subitem-${child.key}`}
                                      to={{
                                        pathname: child.path,
                                        search: item.path.includes("reporting")
                                          ? this.props.location.search
                                          : null,
                                      }}
                                    >
                                      {child.label}
                                    </Link>
                                  )}
                                </li>
                              ),
                            )}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
                {!!currentOrg &&
                  !localStorage.getItem("organization.isMSPSubAccount") &&
                  localStorage.getItem("organization.showBilling") &&
                  !!currentOrg.trial_days && (
                    <div>
                      <ul>
                        <li key={currentOrg.id}>
                          {!!currentOrg.trial_days && (
                            <div>
                              {subscriptions?.fetching && <Spinner />}
                              {subscriptions?.success && (
                                <p className="text-center">
                                  {!localStorage.getItem("user.isMSP") &&
                                  !localStorage.getItem(
                                    "organization.isMSPSubAccount",
                                  ) &&
                                  subscriptions?.data?.data[0]?.attributes
                                    ?.status === "active"
                                    ? this.showTrialMessage({
                                        currentOrg,
                                        isLeft: false,
                                      })
                                    : localStorage.getItem("user.isMSP") &&
                                      !localStorage.getItem(
                                        "organization.isMSPSubAccount",
                                      ) &&
                                      !statusInTrial &&
                                      !cancelDateHasExpired
                                    ? this.showTrialMessage({
                                        currentOrg,
                                        isLeft: false,
                                      })
                                    : this.showTrialMessage({
                                        currentOrg,
                                      })}
                                </p>
                              )}
                              {subscriptions?.failure &&
                                this.showTrialMessage({
                                  currentOrg,
                                })}
                            </div>
                          )}
                          <PartnerProgramLink
                            showPartnerProgramPage={
                              this.props.showPartnerProgramPage
                            }
                          />
                          {!localStorage.getItem("user.isMSP") &&
                            !localStorage.getItem(
                              "organization.isMSPSubAccount",
                            ) &&
                            currentOrg.update_payment &&
                            !subscriptions?.fetching &&
                            subscriptions?.data?.data[0]?.attributes?.status !==
                              "active" &&
                            !distributorLenovo && (
                              <div
                                style={{
                                  padding: "0 10px 20px 10px",
                                  color: "#fff",
                                }}
                              >
                                Please{" "}
                                <Link
                                  to={createUrl(
                                    "/settings/billing-info/subscription",
                                  )}
                                >
                                  {" "}
                                  select a subscription
                                </Link>{" "}
                                to avoid interruption of service.
                              </div>
                            )}
                        </li>
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function NavigationContainer(props) {
  const platform = useDistributorPlatform()

  const session = useContext(SessionContext)
  const mainOrganization = session.getMainOrganization()

  const flags = useFlags()

  const hideAppAware = hideAppAwarePage({
    showAppAwarePlatform: platform.showAppAware,
    session,
  })

  let subscriptionRes = useResource({
    initialParams: {},
    async fetch() {
      try {
        let res = await httpClient.get("/subscriptions", {
          params: {
            organization_id: localStorage.getItem("orgId"),
          },
        })

        return {
          data: res.data,
          meta: {},
        }
      } catch (error) {
        throw error.response
      }
    },
  })

  const { subscriptions } = props

  useEffect(() => {
    if (subscriptions.type === UPDATE_SUBSCRIPTIONS) {
      subscriptionRes.fetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions])

  if (!platform.showNavigation) {
    return null
  }

  return (
    <Navigation
      {...props}
      subscriptions={subscriptionRes}
      showActiveDirectory={mainOrganization?.showActiveDirectory ?? false}
      session={session}
      mainOrganization={mainOrganization}
      hideAppAware={hideAppAware}
      flags={flags}
    />
  )
}

const mapStateToProps = ({ auth, organizations, subscriptions }) => ({
  currentOrg: get(organizations, "data", []).find(
    (org) => Number(org.id) === Number(localStorage.getItem("orgId")),
  ),
  subscriptions,
  showPartnerProgramPage: !hidePartnerProgramPage({
    auth,
  }),
})

Navigation.propTypes = {
  location: locationPropTypes.isRequired,
  currentOrg: organizationPropTypes.isRequired,
}

export default withRouter(connect(mapStateToProps, null)(NavigationContainer))
