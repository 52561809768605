import { isPageMspDashboard } from "./isPageMspDashboard"

export const isDataExportVisible = (session, pathname, flags) => {
  if (session.activeOrganization.isDistributor) {
    return !!session.activeOrganization.distributorSkus?.includes("data-export")
  }

  const showDataExportToAdmins = flags ? flags["showDataExportToAdmins"] : false

  const mainOrg = session?.getMainOrganization()
  const rolesByOrg = session?.user?.rolesByOrganization || {}

  const orgType = mainOrg?.type
  const role = rolesByOrg[session.activeOrganization.id]
  const isMspDashboard = isPageMspDashboard(mainOrg, pathname)

  // useful for debugging purposes
  // console.log({
  //   orgType,
  //   isMspDashboard,
  //   role,
  //   orgRole: `${orgType}-${role}`,
  //   show:
  //     !isMspDashboard &&
  //     [
  //       "msp-super_administrator",
  //       "msp-administrator",
  //       "msp-owner",
  //       "organization-super_administrator",
  //       "organization-administrator",
  //       "organization-owner",
  //     ].includes(`${orgType}-${role}`),
  // })

  return (
    !isMspDashboard &&
    [
      "msp-super_administrator",
      "msp-administrator",
      "msp-owner",
      "organization-super_administrator",
      "organization-administrator",
      "organization-owner",
      showDataExportToAdmins && "suborganization-administrator",
    ].includes(`${orgType}-${role}`)
  )
}

export const hasDataExportAddOn = (session) => {
  const subscription =
    session?.activeOrganization.type === "suborganization"
      ? session?.subscriptionByBeneficiary
      : session?.activeSubscription

  const addons = subscription?.addons ?? []
  const isActiveOrganizationMsp = session?.activeOrganization.type === "msp"

  return (
    !!addons?.includes(
      `data-export-${subscription?.planFeatureAddOn}-monthly`,
    ) ||
    !!addons?.includes(
      `data-export-${subscription?.planFeatureAddOn}-annual`,
    ) ||
    (isActiveOrganizationMsp &&
      !!addons?.find((addon) => addon.startsWith(`data-export`)))
  )
}
