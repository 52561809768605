import get from "lodash/get"
import sortBy from "lodash/sortBy"
import {
  LOAD_LAN_PROXIES,
  TOGGLE_EDIT_MULTIPLE_LAN_PROXIES_FORM,
  CHANGE_LAN_PROXIES_SEARCH_TEXT_FILTER,
  CHANGE_LAN_PROXIES_STATUS_FILTER,
  CHANGE_ALL_LAN_PROXIES,
  SELECT_PROXY,
  CHANGE_LAN_PROXY_PAGE,
  CHANGE_LAN_PROXY,
  CHANGE_MULTIPLE_LAN_PROXIES,
  UPDATE_MULTIPLE_LAN_PROXIES_REQUEST,
  LOAD_PROXIES_STATUS,
  REMOVE_LAN_PROXY,
  DELETE_LAN_PROXY_REQUEST,
  REMOVE_MULTIPLE_PROXIES,
  SHOW_CONFIRM_DELETE_RELAY_MODAL,
  HIDE_CONFIRM_DELETE_RELAY_MODAL,
  UNSELECT_ALL_LAN_PROXIES,
  UPDATE_SELECTED_TAGS,
} from "action-types/lanProxies"

const initialState = {
  data: [],
  requestStatus: null,
  searchText: "",
  status: "all",
  selectedLanProxiesIds: [],
  allLanProxiesSelected: false,
  currentPage: 0,
  numberOfPages: 0,
  editMultipleLanProxies: false,
  editMultipleLanProxiesStatus: null,
  showConfirmDeleteModal: false,
  idToBeDeleted: null,
  deleteLanProxyStatus: null,
  activeProxiesIds: [],
  activeProxiesStatus: null,
  selectedTags: [],
}

const proxiesState = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_TAGS:
      return {
        ...state,
        selectedTags: action.payload,
      }
    case LOAD_LAN_PROXIES:
      return {
        ...state,
        requestStatus: action.payload.status,
        data: action.payload.data || state.data,
        numberOfPages: action.payload.numberOfPages,
      }
    case CHANGE_LAN_PROXIES_SEARCH_TEXT_FILTER:
      return {
        ...state,
        searchText: action.payload,
      }
    case CHANGE_LAN_PROXIES_STATUS_FILTER:
      return {
        ...state,
        status: action.payload,
      }
    case CHANGE_ALL_LAN_PROXIES:
      return {
        ...state,
        allLanProxiesSelected: action.payload.allChecked,
        selectedLanProxiesIds: action.payload.allChecked
          ? action.payload.visibleIds
          : [],
      }
    case SELECT_PROXY:
      const selectedUserAgent = state.selectedLanProxiesIds.find(
        (agentId) => agentId === action.payload,
      )
      const newSelectedUserAgents = selectedUserAgent
        ? state.selectedLanProxiesIds.filter(
            (agentId) => agentId !== action.payload,
          )
        : [...state.selectedLanProxiesIds, action.payload]

      return {
        ...state,
        selectedLanProxiesIds: newSelectedUserAgents,
        allLanProxiesSelected:
          newSelectedUserAgents.length === state.data.length,
      }
    case CHANGE_LAN_PROXY_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case CHANGE_LAN_PROXY:
      return {
        ...state,
        data: state.data.map((proxy) =>
          proxy.id === action.payload.id ? action.payload : proxy,
        ),
      }
    case CHANGE_MULTIPLE_LAN_PROXIES:
      return {
        ...state,
        data: state.data.map((proxy) => {
          const updatedAgent = action.payload.find(
            (userAgent) => userAgent.id === proxy.id,
          )

          return updatedAgent || proxy
        }),
      }
    case TOGGLE_EDIT_MULTIPLE_LAN_PROXIES_FORM:
      return {
        ...state,
        editMultipleLanProxies: action.payload,
        editMultipleLanProxiesStatus: null,
      }
    case UPDATE_MULTIPLE_LAN_PROXIES_REQUEST:
      return {
        ...state,
        editMultipleLanProxiesStatus: action.payload,
        selectedLanProxiesIds:
          action.payload === "success" ? [] : state.selectedLanProxiesIds,
        allLanProxiesSelected:
          action.payload === "success" ? false : state.allLanProxiesSelected,
      }
    case REMOVE_LAN_PROXY:
      return {
        ...state,
        data: state.data.filter((proxy) => proxy.id !== action.payload),
        selectedLanProxiesIds: state.selectedLanProxiesIds.filter(
          (agentId) => agentId !== action.payload,
        ),
        idToBeDeleted: null,
      }
    case REMOVE_MULTIPLE_PROXIES:
      const filteredData = state.data.filter(
        (proxy) => action.payload.indexOf(proxy.id) === -1,
      )

      return {
        ...state,
        data: filteredData,
        allLanProxiesSelected:
          filteredData.length === 0 ? false : state.allLanProxiesSelected,
        selectedLanProxiesIds: state.selectedLanProxiesIds.filter(
          (agentId) => action.payload.indexOf(agentId) === -1,
        ),
        idToBeDeleted: null,
      }
    case SHOW_CONFIRM_DELETE_RELAY_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: true,
        idToBeDeleted: action.payload,
      }
    case HIDE_CONFIRM_DELETE_RELAY_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: false,
        idToBeDeleted: null,
      }
    case DELETE_LAN_PROXY_REQUEST:
      return {
        ...state,
        deleteLanProxyStatus: action.payload.status,
        idToBeDeleted: action.payload.agentId,
      }
    case LOAD_PROXIES_STATUS:
      return {
        ...state,
        activeProxiesIds:
          action.payload.activeProxiesIds || state.activeProxiesIds,
        activeProxiesStatus: action.payload.status,
      }
    case UNSELECT_ALL_LAN_PROXIES:
      return {
        ...state,
        selectedLanProxiesIds: [],
      }
    default:
      return state
  }
}

// REFACTOR METHOD BELOW, USER AGENTS USING THE SAME METHOD

export const selectVisibleProxies = (state) => {
  const {
    data,
    searchText,
    status,
    selectedLanProxiesIds,
    activeProxiesIds,
    selectedTags,
  } = state.lanProxies
  const lowerCaseSearchText = searchText.toLowerCase()
  let regex
  let lanProxies = [...data]

  if (selectedTags.length) {
    regex = "^("

    selectedTags.forEach((tag, i, array) => {
      if (array.length - 1 === i) {
        regex += `${tag.value})$`

        return
      }

      regex += `${tag.value}|`
    })

    lanProxies = sortBy(lanProxies, (proxy) => !proxy.tags)
  }

  return lanProxies
    .map((proxy) => ({
      ...proxy,
      selected: selectedLanProxiesIds.includes(proxy.id),
      site: proxy.network_id
        ? state.networks.data.find(
            (network) => Number(network.id) === Number(proxy.network_id),
          )
        : null,
      policy: state.policies.data.find(
        (policy) => policy.id === proxy.policy_id,
      ),
      filteringSchedule: state.filteringSchedules.data.find(
        (filteringSchedule) =>
          filteringSchedule.id === proxy.scheduled_policy_id,
      ),
      blockPage: state.blockPages.data.find(
        (blockPage) => blockPage.id === proxy.block_page_id,
      ),
    }))
    .filter((proxy) => {
      let textMatches = !searchText
      let statusMatches = false

      if (
        (proxy.friendly_name || proxy.hostname || "")
          .toLowerCase()
          .includes(lowerCaseSearchText)
      ) {
        textMatches = true
      } else if (
        get(proxy, "site.name", "").toLowerCase().includes(lowerCaseSearchText)
      ) {
        textMatches = true
      }

      if (status === "all") {
        statusMatches = true
      } else if (status === "active") {
        statusMatches =
          activeProxiesIds.includes(proxy.id) && proxy.status === "active"
      } else if (status === "inactive") {
        statusMatches =
          !activeProxiesIds.includes(proxy.id) && proxy.status === "active"
      } else if (status === "uninstalled") {
        statusMatches = proxy.status.includes("uninstalled")
      }

      return textMatches && statusMatches
    })
    .filter((proxy) => {
      const tags = proxy.tags ? proxy.tags.trim().split(",") : []

      if (!tags.length && regex) {
        return false
      }

      return !tags.length || tags.some((tag) => new RegExp(regex).test(tag))
    })
}

export const selectVisibleLanProxiesIds = (state) =>
  selectVisibleProxies(state)
    .filter(
      (proxy) =>
        state.lanProxies.allLanProxiesSelected ||
        state.lanProxies.selectedLanProxiesIds.includes(proxy.id),
    )
    .map((proxy) => proxy.id)

export const selectTotalActiveProxies = (state) =>
  state.lanProxies.data.filter(
    (proxy) =>
      proxy.status === "active" &&
      state.lanProxies.activeProxiesIds.indexOf(proxy.id) !== -1,
  ).length

export const selectTotalInactiveProxies = (state) =>
  state.lanProxies.data.filter((proxy) => proxy.status !== "uninstalled").length

export const selectTotalUninstalledProxies = (state) =>
  state.lanProxies.data.filter((proxy) => proxy.status === "uninstalled").length

export default proxiesState
