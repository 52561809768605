import React from "react"
import { useLocalStorageItem } from "_Infinity/Core/Hooks/useLocalStorageItem"
import { BulkNetworksContext } from "_Infinity/BulkNetworks/Contexts/BulkNetworksContext"

export function BulkNetworksProvider(props) {
  const [createdIds, setCreatedIds] = useLocalStorageItem(
    "bulk-created-networks-values",
    "",
  )
  const [updatedIds, setUpdatedIds] = useLocalStorageItem(
    "bulk-updated-networks-values",
    "",
  )
  const [destroyedIds, setDestroyedIds] = useLocalStorageItem(
    "bulk-destroyed-networks-values",
    "",
  )

  // Created Ids
  const setStringifyCreatedIds = (createdIds) =>
    setCreatedIds(JSON.stringify(createdIds))
  const getParseCreatedIds = () => (createdIds ? JSON.parse(createdIds) : [])

  const addCreatedId = (id) => {
    let allIds = getParseCreatedIds()
    allIds.push(id)
    setStringifyCreatedIds(allIds)
  }

  const removeCreatedId = (id) => {
    const allIds = getParseCreatedIds()
    setStringifyCreatedIds(allIds.filter((idBulk) => idBulk !== id))
  }

  // Updated Ids
  const setStringifyUpdatedIds = (updatedIds) =>
    setUpdatedIds(JSON.stringify(updatedIds))
  const getParseUpdatedIds = () => (updatedIds ? JSON.parse(updatedIds) : [])

  const addUpdatedId = (id) => {
    let allIds = getParseUpdatedIds()
    allIds.push(id)
    setStringifyUpdatedIds(allIds)
  }

  const removeUpdatedId = (id) => {
    const allIds = getParseUpdatedIds()
    setStringifyUpdatedIds(allIds.filter((idBulk) => idBulk !== id))
  }

  // Destroyed Ids
  const setStringifyDestroyedIds = (destroyedIds) =>
    setDestroyedIds(JSON.stringify(destroyedIds))
  const getParseDestroyedIds = () =>
    destroyedIds ? JSON.parse(destroyedIds) : []

  const addDestroyedId = (id) => {
    let allIds = getParseDestroyedIds()
    allIds.push(id)
    setStringifyDestroyedIds(allIds)
  }

  const removeDestroyedId = (id) => {
    const allIds = getParseDestroyedIds()
    setStringifyDestroyedIds(allIds.filter((idBulk) => idBulk !== id))
  }

  return (
    <BulkNetworksContext.Provider
      value={{
        createdIds: getParseCreatedIds(),
        addCreatedId,
        removeCreatedId,
        updatedIds: getParseUpdatedIds(),
        addUpdatedId,
        removeUpdatedId,
        destroyedIds: getParseDestroyedIds(),
        addDestroyedId,
        removeDestroyedId,
      }}
    >
      {props.children}
    </BulkNetworksContext.Provider>
  )
}
