import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import get from "lodash/get"
import NotificationListContainer from "components/NotificationListContainer"
import Spinner from "components/Spinner"
import HeaderContainer from "views/app/HeaderContainer"
import Page from "views/app/Page"
import Navigation from "components/Navigation"
import { trackPage } from "utils/analytics"
import jwtDecode from "jwt-decode"
import BulkNetworksNotification from "_Infinity/BulkNetworks/Components/BulkNetworksNotification"
import { showDNSResolvers } from "utils/dnsResolvers"

import { useDistributorPlatform } from "_Infinity/DistributorPlatform/Hooks/useDistributorPlatform"

import { DISTPLATFORM_TEMPLATES } from "_Infinity/DistributorPlatform/constants"

import {
  SubscriptionIssuesAlert,
  BillingDetailsAlert,
} from "_v2/Modules/Organization/Billing"
import initNewPageViewedEvent from "utils/initNewPageViewedEvent"

// This is a very ugly workaround.
// For some reason <App /> will mount twice, then handleRouteChange() will also
// fire twice causing issues.
// We need to track the pathname globally so we can avoid firing handleRouteChange()
// twice when the app is initially loaded.
let previousPathname = null

class App extends Component {
  analyticsTimeout = null

  componentDidMount() {
    if (this.props.location.pathname !== previousPathname) {
      this.handleRouteChange(this.props.location)
      previousPathname = this.props.location.pathname
    }
  }

  UNSAFE_componentWillReceiveProps({ location, isAuthorized }) {
    if (this.props.location.pathname !== location.pathname) {
      this.handleRouteChange(
        location,
        isAuthorized,
        this.props.location.pathname,
      )
    }
  }

  trackNewPageViewEvent = () => {
    try {
      initNewPageViewedEvent({
        getUserId: function () {
          return localStorage.getItem("userId")
        },
        getUserEmail: function () {
          try {
            return jwtDecode(localStorage.getItem("access_token"))[
              "https://dnsfilter.com/email"
            ]
          } catch {
            return null
          }
        },
        getOrganizationId: function () {
          return localStorage.getItem("orgId")
        },
        getOrganizationName: function () {
          return localStorage.getItem("orgName")
        },
        shouldRegisterAppParams: true,
      })

      // Unfortunately there's no easy way to tell `AppContainer` that this
      // code ran, so we need to use a ugly hack here until we refactor the whole Analytics workflow.
      window.__trackNewPageViewEvent__ = true
    } catch (error) {
      console.error("Failed to dispatch page viewed event", error)
    }
  }

  handleRouteChange = (location, isAuthorized, previousLocation) => {
    if (window.inline_manual_player) {
      window.inline_manual_player.update()
    }

    this.trackNewPageViewEvent()

    clearTimeout(this.analyticsTimeout)

    this.analyticsTimeout = setTimeout(() => {
      if (process.env.REACT_APP_SEGMENT_ID) {
        trackPage(location.pathname)
      }

      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({
        event: "Pageview",
        url: location.pathname,
        userName: get(this.props, "auth.data.name"),
        email: get(this.props, "auth.data.email"),
        orgId: get(this.props, "auth.data.orgId"),
        organizationName: get(this.props, "auth.data.organizationName"),
        organizationType: get(this.props, "auth.data.organizationType"),
        startDate: get(this.props, "auth.data.startDate"),
        industry: get(this.props, "auth.data.industry"),
        revenue: get(this.props, "auth.data.revenue"),
      })

      const platform =
        DISTPLATFORM_TEMPLATES[localStorage.getItem("distributor")] ??
        DISTPLATFORM_TEMPLATES.default

      const iframe = window.parent !== window

      const currentOrgId = get(this.props, "auth.data.orgId")
      const allOrgs = get(this.props, "organizations.data", [])
      const stated_distributor = allOrgs.find(
        (org) => String(org.id) === String(currentOrgId),
      )?.stated_distributor

      if (
        !stated_distributor &&
        !platform.shouldBypassBuiltInSupportBot &&
        !iframe &&
        window.zE &&
        window.zE.identify &&
        get(this.props, "auth.data.email") &&
        !localStorage.getItem("hideChatSupport") &&
        !localStorage.getItem("zendeskEnabled")
      ) {
        if (!localStorage.getItem("whitelabel.domain")) {
          window.zE("webWidget", "show")
        } else if (
          localStorage.getItem("organization.isMSP") &&
          localStorage.getItem("user.isMSP")
        ) {
          window.zE("webWidget", "show")
        }

        window.zE.identify({
          name: get(this.props, "auth.data.name"),
          email: get(this.props, "auth.data.email"),
          organization: get(this.props, "auth.data.organizationName"),
        })
      }

      // Not sure if we have to track registrations when signed in, but doing it
      // this way anyway
      if (get(this.props, "auth.data.email")) {
        const regEvent = localStorage.getItem("track.registration")
        if (regEvent !== null) {
          window.dataLayer.push({
            event: "registration",
          })

          if (regEvent === true) {
            window.dataLayer.push({
              event: "paymentAdded",
            })
          }

          localStorage.removeItem("track.registration")
        }
      }

      if (
        window.userflow &&
        get(this.props, "auth.data.userId") &&
        this.props.platform.loadUserflow
      ) {
        window.userflow.identify(String(get(this.props, "auth.data.userId")), {
          name: get(this.props, "auth.data.name"),
          email: get(this.props, "auth.data.email"),
          role: get(this.props, "auth.data.role"),
          organization_id: get(this.props, "auth.data.organizationId"),
          organization_name: get(this.props, "auth.data.organizationName"),
        })
      }
    }, 300)
  }

  render() {
    const {
      isLoading,
      isAuthorized,
      shouldShowDNSServers,
      children,
      isTwoFAEnforcement,
    } = this.props
    if (isLoading) {
      return (
        <h1
          className="text-center"
          style={{
            marginTop: 20,
          }}
        >
          <Spinner small={false} />
        </h1>
      )
    }

    if (!isAuthorized) {
      return (
        <div>
          <NotificationListContainer />
          {children}
        </div>
      )
    }

    const hideHeaderAndNavigation =
      this.props.location.pathname.includes("invoice/") || isTwoFAEnforcement

    return (
      <div
        style={
          isTwoFAEnforcement
            ? {
                width: "100wh",
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }
            : null
        }
      >
        <BulkNetworksNotification />
        <NotificationListContainer />
        {!hideHeaderAndNavigation && <HeaderContainer />}
        {!hideHeaderAndNavigation && <Navigation />}
        <Page isInvoice={hideHeaderAndNavigation}>
          {!isTwoFAEnforcement && <SubscriptionIssuesAlert />}
          {!isTwoFAEnforcement && <BillingDetailsAlert />}

          {children}
          {shouldShowDNSServers && (
            <div
              className="hidden-print text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 40,
              }}
            >
              <small>
                {shouldShowDNSServers ? (
                  <>
                    {localStorage.getItem("whitelabel.name") || "DNSFilter"}{" "}
                    Resolvers:
                    <br />
                    {showDNSResolvers({ asSentence: false })}
                  </>
                ) : (
                  <>
                    See <Link to={`/policies/filtering`}>policy page</Link> for{" "}
                    {localStorage.getItem("whitelabel.name") || "DNSFilter"}{" "}
                    resolver details.
                  </>
                )}
              </small>
            </div>
          )}
        </Page>
      </div>
    )
  }
}

function AppContainer(props) {
  const platform = useDistributorPlatform()

  return <App {...props} platform={platform} />
}

export default withRouter(AppContainer)
