export const colors = {
  white: "#fff",
  navyBlue: "#000066",
  azure: "#0498F3",
  primary: "#3ab8ff",
  success: "#b3e496",
  warning: "#F0C427",
  danger: "#ff9c90",
  default: "#ccc",
  alabaster: "#f9f9f9",
  emerald: "#46be8a",
  carnation: "#f96868",
  crimson: "#ED1A12",
  semiGray: "rgb(92, 112, 128)",
  tabasco: "#9e2b0e",
  persianRed: "#c23030",
  limedSpruce: "#37474F",
  slateGray: "#76838f",
  malachite: "#3cda3c",
  prussianBlue: "#002841",
  mystic: "#e4eaec",
  regentBlue: "#A8CEE8",
  punch: "#db3737",
  gallery: "#eee",
  silver: "#c3c3c3",
  easternBlue: "#1791C4",
  downy: "#69c5a8",
  cerulean: "#00B0D6",
  dodgerBlue: "#33ABFC",
}

// FIX: Required to refactor lists below, after components that are using them will be refactored as well

export const hexColorsList = [
  "#d47985",
  "#C86F7D",
  "#C8758F",
  "#C37DA1",
  "#BA87B1",
  "#AE91BF",
  "#9D9CCA",
  "#8AA7D1",
  "#76B1D4",
  "#62BBD2",
  "#52C3CC",
  "#4BCBC3",
  "#51D1B6",
  "#62D7A8",
  "#78DB98",
  "#91DE88",
  "#ABE079",
  "#C7E16E",
  "#E3E066",
  "#fffd8a",
]

export const bluePalette = [
  "#30B7C4",
  "#47C0C4",
  "#0270A9",
  "#10C6FA",
  "#00B0D6",
  "#2E495D",
  "#A8CEE8",
  "#72AED8",
  "#005591",
  "#002841",
]

export const toggleBgColors = {
  success: "#DCEDC8",
  warning: "#FFECB3",
  error: "#FFCDD2",
}

export const blockedCategoriesColors = [
  "#FF4301",
  "#FF6802",
  "#FF8F02",
  "#FFB903",
  "#FF0001",
]
