import React from "react"
import ViewportCentered from "components/ViewportCentered"
import Logo from "components/Logo"
import Card from "components/Card"
import Fetch from "components/Fetch"
import FormFormik from "components/FormFormik"
import isRequired from "validators/isRequired"
import isEmail from "validators/isEmail"
import FormGroup from "components/FormGroup"
import InputGroup from "components/InputGroup"
import Button from "components/Button"
import TextCenter from "components/TextCenter"
import Footer from "components/Footer"
import { Link } from "react-router-dom"
import HCaptcha from "@hcaptcha/react-hcaptcha"

const ForgotPasswordScene = ({ history }) => {
  const [token, setToken] = React.useState(null)
  const [errorHcaptcha, setErrorHcaptcha] = React.useState(false)
  const captchaRef = React.useRef(null)

  const onVerifyHcaptcha = (value) => {
    setToken(value)
    if (value) {
      setErrorHcaptcha(false)
    }
  }

  return (
    <ViewportCentered padding={30}>
      <div
        style={{
          maxWidth: 330,
        }}
      >
        <Logo marginBottom={30} />
      </div>
      <Card maxWidth={500} fullWidth>
        <Fetch
          method="post"
          url="/users/reset_password"
          headers={{
            "Accept": "application/json",
            "Content-Type": "application/json",
          }}
          lazy
          showAlertOnSuccess
          successMessage={
            <div>
              <strong>Request processed!</strong>
              <br />
              If we find your account we'll send you an email with next steps.
              If you haven't received an email within 10 minutes please{" "}
              {localStorage.getItem("whitelabel.domain") ? (
                "contact support"
              ) : (
                <a
                  href="mailto:support@dnsfilter.com?subject=Password Reset Issue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact support
                </a>
              )}
              .
            </div>
          }
          onConfirm={() => {
            history.push("/")
          }}
        >
          {(fetch) => (
            <FormFormik
              initialValues={{
                email: "",
              }}
              onSubmit={(values) => {
                if (!token && process.env.REACT_APP_HCAPTCHA_SITE_KEY) {
                  setErrorHcaptcha(true)
                  return
                }

                fetch.dispatch({
                  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                  connection: "Username-Password-Authentication",
                  email: values.email,
                })
              }}
              validations={{
                email: [isRequired, isEmail],
              }}
            >
              {(formProps) => (
                <div>
                  <FormGroup
                    error={formProps.isSubmitted && formProps.getError("email")}
                  >
                    <InputGroup
                      data-test-id="email"
                      leftIcon="envelope"
                      value={formProps.getValue("email")}
                      placeholder="E-Mail Address"
                      autoFocus
                      onChange={(e) => {
                        const value = e.target.value
                          ? e.target.value.toLowerCase()
                          : ""
                        formProps.setValue("email", value)
                      }}
                    />
                  </FormGroup>
                  {process.env.REACT_APP_HCAPTCHA_SITE_KEY && (
                    <FormGroup
                      error={errorHcaptcha && "Please select the captcha."}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <HCaptcha
                          sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                          onVerify={onVerifyHcaptcha}
                          ref={captchaRef}
                        />
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Button
                      type="submit"
                      intent="primary"
                      fullWidth
                      data-test-id="forgot-password"
                    >
                      Request New Password
                    </Button>
                  </FormGroup>
                </div>
              )}
            </FormFormik>
          )}
        </Fetch>
        <TextCenter>
          <Link to="/">
            <small>Meant to login?</small>
          </Link>
        </TextCenter>
      </Card>
      <Footer />
    </ViewportCentered>
  )
}

export default ForgotPasswordScene
