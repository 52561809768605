import React from "react"
import { Classes, Dialog as BaseDialog } from "@blueprintjs/core"

export function Dialog(props) {
  return (
    <BaseDialog
      icon={props.icon}
      title={props.title}
      isOpen={props.open}
      isCloseButtonShown={!props.hideCloseButton}
      onOpened={props.onOpened}
      onClose={props.onClose}
      onClosed={props.onClosed}
      canOutsideClickClose={props.canOutsideClickClose}
    >
      <div className={Classes.DIALOG_BODY}>{props.children}</div>
      {props.actions && (
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>{props.actions}</div>
        </div>
      )}
    </BaseDialog>
  )
}
