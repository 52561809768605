import React, { lazy } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useFlags } from "launchdarkly-react-client-sdk"
import retry from "utils/retry"
import LoginContainer from "views/auth/LoginContainer"
import SignupSceneContainer from "views/signup/SignupSceneContainer"
import ForgotPasswordScene from "views/forgot-password/ForgotPasswordScene"
import NotFound from "views/app/NotFound"
import IntegrationsScreen from "views/integrations/IntegrationsScreen"
import { useLDClient } from "launchdarkly-react-client-sdk"
import * as authUtils from "utils/auth"
import { SessionContext } from "_v2/Modules/Auth"
import {
  isForcePayment,
  isTwoFAEnforcement,
  isOwnerOrSuperAdmin,
} from "utils/auth"
import createUrl from "utils/createUrl"
import { hasAddOrgRole } from "utils/roles"
import { OnboardingModal } from "components/OnboardingModal"

let OverviewScene = lazy(() =>
  retry(() => import("views/overview/OverviewScene")),
)

let DeploymentsScene = lazy(() => retry(() => import("views/deployments")))

let AddNetworkScene = lazy(() =>
  retry(() => import("views/deployments/networks/AddNetworkScene")),
)

let UpdateNetworkScene = lazy(() =>
  retry(() => import("views/deployments/networks/UpdateNetworkScene")),
)

let NetworkListIpAddressesContainer = lazy(() =>
  retry(() =>
    import("views/deployments/networks/NetworkListIpAddressesContainer"),
  ),
)

let AddIpAddressScene = lazy(() =>
  retry(() => import("views/deployments/networks/AddIpAddressScene")),
)

let PoliciesSceneContainer = lazy(() =>
  retry(() => import("views/policies/filtering/PoliciesSceneContainer")),
)

let AddPolicyScene = lazy(() =>
  retry(() => import("views/policies/filtering/AddPolicyScene")),
)

let UpdatePolicyScene = lazy(() =>
  retry(() => import("views/policies/filtering/UpdatePolicyScene")),
)

let GlobalPoliciesSceneContainer = lazy(() =>
  retry(() =>
    import("views/global-policies/filtering/GlobalPoliciesSceneContainer"),
  ),
)

let AddGlobalPolicyScene = lazy(() =>
  retry(() => import("views/global-policies/filtering/AddGlobalPolicyScene")),
)

let UpdateGlobalPolicyScene = lazy(() =>
  retry(() =>
    import("views/global-policies/filtering/UpdateGlobalPolicyScene"),
  ),
)

let ReportingScene = lazy(() =>
  retry(() => import("views/reporting/ReportingScene")),
)

let AddBlockPageContainer = lazy(() =>
  retry(() => import("views/policies/block-pages/AddBlockPageContainer")),
)

let EditBlockPageContainer = lazy(() =>
  retry(() => import("views/policies/block-pages/EditBlockPageContainer")),
)

let AddGlobalBlockPageContainer = lazy(() =>
  retry(() =>
    import("views/global-policies/block-pages/AddGlobalBlockPageContainer"),
  ),
)

let EditGlobalBlockPageContainer = lazy(() =>
  retry(() =>
    import("views/global-policies/block-pages/EditGlobalBlockPageContainer"),
  ),
)

let ProfileScene = lazy(() => retry(() => import("views/account/ProfileScene")))

let AccountSettingsScene = lazy(() =>
  retry(() => import("views/account/AccountSettingsScene")),
)

let AccountSettingsPasswordContainer = lazy(() =>
  retry(() => import("views/account/AccountSettingsPasswordContainer")),
)

let SecurityScene = lazy(() =>
  retry(() => import("views/account/SecurityScene")),
)

let TwoFAScene = lazy(() =>
  retry(() => import("views/account/TwoFA/TwoFAScene")),
)

let Invoice = lazy(() => retry(() => import("views/organization/Invoice")))

let LoginTwoFactorContainer = lazy(() =>
  retry(() => import("views/auth/LoginTwoFactorContainer")),
)

let LoginTwoFactorRecoveryContainer = lazy(() =>
  retry(() => import("views/auth/LoginTwoFactorRecoveryContainer")),
)

let PasswordChangedContainer = lazy(() =>
  retry(() => import("views/auth/PasswordChangedContainer")),
)

let EmailConfirmedContainer = lazy(() =>
  retry(() => import("views/auth/EmailConfirmedContainer")),
)

let ToolsScene = lazy(() => retry(() => import("views/tools/ToolsScene")))

let UpdateScheduleContainer = lazy(() =>
  retry(() =>
    import("views/policies/filtering-schedules/UpdateScheduleContainer"),
  ),
)

let OrganizationSettingsScene = lazy(() =>
  retry(() => import("views/organization/OrganizationSettingsScene")),
)

let AddOrganizationScene = lazy(() =>
  retry(() => import("views/organization/AddOrganizationScene")),
)

let SingleSignOnScene = lazy(() =>
  retry(() => import("views/organization/SingleSignOn")),
)

let RegistrationDebug = lazy(() =>
  retry(() => import("views/signup/RegistrationDebug")),
)

let AccountReactivationScene = lazy(() =>
  retry(() =>
    import("_v3/AccountReactivation/Components/AccountReactivationScene"),
  ),
)

let ReactivationInvoice = lazy(() =>
  retry(() => import("_v3/AccountReactivation/Components/ReactivationInvoice")),
)

let ProductRoadmap = lazy(() =>
  retry(() => import("components/ProductRoadmap")),
)

// Created so we can use SessionContext
const AppRoutes = (props) => {
  const session = React.useContext(SessionContext)
  const { user, activeOrganization, msp } = session

  const ldClient = useLDClient()
  if (user && activeOrganization.id && ldClient?.identify) {
    ldClient.identify({
      name: user.fullName,
      key: `user-${user.id}`,
      email: user.email,
      custom: {
        currentOrganization: activeOrganization.id,
      },
    })
  }

  const flags = useFlags()

  const roleUser = user?.rolesByOrganization[activeOrganization.id]

  const showSingleSignOnRoute = isOwnerOrSuperAdmin({
    user,
    activeOrganization,
  })

  return props.auth.status === "loginSucceeded" ? (
    isForcePayment(props) && props.initBilling?.status === "success" ? (
      <Switch>
        <Route
          path="/(organizations|msp)/:orgId/settings/billing-info/:id?"
          component={(props) => (
            <OrganizationSettingsScene
              {...props}
              forcePayment
              activeOrganization={activeOrganization}
            />
          )}
          exact
        />

        <Route
          render={({ location }) => {
            let redirectTo = `/settings/billing-info/subscription${location.search}`

            if (location.pathname === "/settings/billing-info/manage") {
              redirectTo = location.pathname + location.search
            }

            return <Redirect to={createUrl(redirectTo)} />
          }}
        />
      </Switch>
    ) : isTwoFAEnforcement(props) ? (
      <Switch>
        <Route
          path="/account/two_factor_authentication/:page"
          component={() => <TwoFAScene enforcement />}
          exact
        />
        <Redirect to="/account/two_factor_authentication/intro" />
      </Switch>
    ) : (
      <>
        <OnboardingModal />
        <Switch>
          <Redirect from="/login" to={createUrl("/overview/general")} />
          <Redirect from="/signup" to={createUrl("/overview/general")} />
          <Redirect from="/two-factor" to={createUrl("/overview/general")} />
          <Redirect exact from="/" to={createUrl("/overview/general")} />
          <Redirect
            from="/(organizations|msp)/:orgId/account-reactivation"
            to={createUrl("/overview/general")}
          />
          <Route
            exact
            path="/(organizations|msp)/:orgId/overview"
            render={() => <Redirect to={createUrl("/overview/general")} />}
          />
          <Route
            path="/(organizations|msp)/:orgId/overview"
            component={OverviewScene}
          />
          <Redirect from="/networks" to={createUrl("/deployments/sites")} />
          <Route
            path="/organizations/:orgId/deployments/sites/add"
            component={AddNetworkScene}
            exact
          />
          <Route
            path="/organizations/:orgId/deployments/sites/:id/edit"
            component={UpdateNetworkScene}
            exact
          />
          <Route
            path="/organizations/:orgId/deployments/sites/:networkId/ip_addresses"
            component={NetworkListIpAddressesContainer}
            exact
          />
          <Route
            path="/organizations/:orgId/deployments/sites/:networkId/ip_addresses/add"
            component={AddIpAddressScene}
            exact
          />
          <Route
            path="/organizations/:orgId/deployments"
            component={DeploymentsScene}
          />
          <Redirect
            from="/msp/:orgId/deployments/"
            to={createUrl("/overview/general")}
          />
          <Route
            path="/organizations/:orgId/policies/filtering/add"
            component={AddPolicyScene}
          />
          <Route
            path="/organizations/:orgId/policies/filtering/:id/edit"
            component={UpdatePolicyScene}
          />
          <Route
            path="/organizations/:orgId/policies/schedules/:id/edit"
            component={UpdateScheduleContainer}
          />
          <Route
            path="/organizations/:orgId/policies/block-pages/add"
            component={() => (
              <AddBlockPageContainer platform={props.platform} />
            )}
          />
          <Route
            path="/organizations/:orgId/policies/block-pages/:id/edit"
            component={() => (
              <EditBlockPageContainer platform={props.platform} />
            )}
          />
          <Route
            path="/msp/:orgId/global-policies/block-pages/add"
            component={() => (
              <AddGlobalBlockPageContainer platform={props.platform} />
            )}
          />
          <Route
            path="/msp/:orgId/global-policies/block-pages/:id/edit"
            component={() => (
              <EditGlobalBlockPageContainer platform={props.platform} />
            )}
          />
          <Route
            path="/organizations/:orgId/policies"
            component={PoliciesSceneContainer}
          />
          <Route
            path="/msp/:orgId/global-policies/filtering/add"
            component={AddGlobalPolicyScene}
          />
          <Route
            path="/msp/:orgId/global-policies/filtering/:id/edit"
            component={UpdateGlobalPolicyScene}
          />
          <Route
            path="/msp/:orgId/global-policies"
            component={GlobalPoliciesSceneContainer}
          />
          <Redirect
            from="/organizations/:orgId/global-policies/"
            to={createUrl("/overview/general")}
          />

          <Redirect
            from="/msp/:orgId/policies/"
            to={createUrl("/overview/general")}
          />
          <Redirect
            from="/(organizations|msp)/:orgId/reporting"
            to={createUrl("/reporting/data-explorer")}
            exact
          />
          <Route
            path="/(organizations|msp)/:orgId/reporting/alerts/"
            component={ReportingScene}
          />
          <Route
            path="/(organizations|msp)/:orgId/reporting/:report"
            component={ReportingScene}
            exact
          />
          <Route
            exact
            path="/reporting/threats"
            render={({ location }) => (
              <Redirect to={createUrl(location.pathname + location.search)} />
            )}
          />

          {flags.showNewAccountSettingsPage ? (
            <Redirect from="/account" to="/account/settings" exact />
          ) : (
            <Redirect from="/account" to="/account/profile" exact />
          )}

          {flags.showNewAccountSettingsPage ? (
            <Redirect from="/account/profile" to="/account/settings" exact />
          ) : (
            <Redirect from="/account/settings" to="/account/profile" exact />
          )}

          {flags.showNewAccountSettingsPage ? (
            <Route
              path="/account/settings"
              component={AccountSettingsScene}
              exact
            />
          ) : (
            <Route path="/account/profile" component={ProfileScene} exact />
          )}

          {flags.showNewAccountSettingsPage && (
            <Redirect from="/account/security" to="/account/profile" exact />
          )}

          <Redirect
            from="/account/billing"
            to={createUrl("/settings/billing-info/invoices")}
            exact
          />
          {(!activeOrganization.ssoEnabled || roleUser === "owner") &&
            !authUtils.isSocialSignIn() && (
              <Route
                path="/account/password"
                component={AccountSettingsPasswordContainer}
                exact
              />
            )}
          {!activeOrganization.ssoEnabled && !authUtils.isSocialSignIn() && (
            <Route path="/account/security" component={SecurityScene} exact />
          )}
          <Route
            path="/account/two_factor_authentication/:page"
            component={TwoFAScene}
            exact
          />
          {localStorage.getItem("organization.showBilling") && (
            <Route path="/invoice/:id" component={Invoice} exact />
          )}
          {showSingleSignOnRoute && (
            <Route
              path="/(organizations|msp)/:orgId/settings/settings/single-sign-on"
              component={SingleSignOnScene}
              exact
            />
          )}
          <Route
            path="/(organizations|msp)/:orgId/settings/:tab/:id?"
            component={OrganizationSettingsScene}
            exact
          />
          {!msp?.prevent_sub_org_creation && hasAddOrgRole(session) && (
            <Route
              path="/(organizations|msp)/:orgId/add-organization"
              component={AddOrganizationScene}
              exact
            />
          )}
          <Route
            exact
            path="/(organizations|msp)/:orgId/integrations"
            component={IntegrationsScreen}
          />
          <Route
            exact
            path="/(organizations|msp)/:orgId/tools"
            render={() => <Redirect to={createUrl("/tools/ssl-certificate")} />}
          />
          <Route
            path="/(organizations|msp)/:orgId/tools/:tab/:id?"
            component={ToolsScene}
            exact
          />
          <Route
            path="/(organizations|msp)/:orgId/feature-request"
            component={ProductRoadmap}
            exact
          />
          <Route
            exact
            path="/(organizations|msp)/:orgId/settings"
            render={() => <Redirect to={createUrl("/settings/profile")} />}
          />
          <Route path="/(organizations|msp)/:id" component={NotFound} />
          <Route
            render={({ location }) => (
              <Redirect to={createUrl(location.pathname + location.search)} />
            )}
          />
        </Switch>
      </>
    )
  ) : (
    <Switch>
      <Route path="/registration-debug" component={RegistrationDebug} exact />
      <Route path="/signup" component={SignupSceneContainer} />
      <Route path="/forgot-password" component={ForgotPasswordScene} exact />
      <Route
        path="/password-changed"
        component={PasswordChangedContainer}
        exact
      />
      <Route
        path="/email-confirmed"
        component={EmailConfirmedContainer}
        exact
      />
      <Route
        path="/account-reactivation"
        exact
        component={AccountReactivationScene}
      />
      <Route
        path="/reactivation-invoice/:orgId/:id"
        component={ReactivationInvoice}
        exact
      />
      <Route path="/login" exact component={LoginContainer} />
      <Route path="/two-factor" exact component={LoginTwoFactorContainer} />
      <Route
        path="/two-factor/recovery"
        exact
        component={LoginTwoFactorRecoveryContainer}
      />
      <Redirect from="/" to="/login" />
    </Switch>
  )
}

export default AppRoutes
