import jwtDecode from "jwt-decode"
import queryString from "query-string"
import callAPI from "callAPI"
import cookieStorage from "utils/cookieStorage"
import createUrl from "./createUrl"

export const load = () => {
  // TODO: Looks like analytics script was upgraded and `load()` is not available anymore
  window.analytics.load?.(process.env.REACT_APP_SEGMENT_ID)
}

export const trackPage = (pathname) => {
  const url = createUrl()
  let pageName

  // Remove orgId from the url, to have a default page name on Segment
  if (pathname.indexOf(url) > -1) {
    pageName = pathname.substring(url.length, pathname.length)
  }

  window.analytics.page(pageName || pathname)
}

export const trackLogin = () => {
  window.trackAnalyticsEvent("Login")
}

export const trackLogout = () => {
  window.trackAnalyticsEvent("Logout")
}

export function sendUTMParamsToAPI() {
  // fetchingLogin is to prevent the /login endpoint being called more than once
  if (localStorage.getItem("fetchingLogin")) {
    return
  }

  if (!localStorage.getItem("didLogin")) {
    return
  }

  // In theory `didLogin` is enough, but an additional safe check doesn't hurt
  if (!localStorage.getItem("access_token")) {
    return
  }

  if (!window.analytics) {
    return
  }

  if (!window.ga) {
    return
  }

  if (!window.mixpanel) {
    return
  }

  // This will be true only when we finish Mixpanel setup
  if (!window.__trackNewPageViewEvent__) {
    return
  }

  let decodedToken = {
    "https://dnsfilter.com/email": "not set",
  }

  try {
    decodedToken = jwtDecode(localStorage.getItem("access_token"))
  } catch {
    // do nothing
  }

  function getLocalStorageProp(propName) {
    return localStorage.getItem(propName) || "not set"
  }

  function getMixPanelProp(propName) {
    return window.mixpanel.get_property(propName) || "not set"
  }

  let utmParams = {}
  try {
    utmParams = queryString.parse(cookieStorage.getItem("dnsfilterUTMParams"))
  } catch {}

  function getUTMParam(paramName) {
    return (
      utmParams[paramName.replace("utm_", "")] ||
      window.mixpanel.get_property(paramName) ||
      "not set"
    )
  }

  localStorage.setItem("fetchingLogin", true)

  window.ga((tracker) => {
    callAPI({
      method: "POST",
      path: "/login",
      body: {
        id: tracker.get("clientId"),
        user_id: getLocalStorageProp("userId"),
        email: decodedToken["https://dnsfilter.com/email"] || "not set",
        phone_number: getLocalStorageProp("phone"),
        organization_name: getLocalStorageProp("orgName"),
        organization_id: getLocalStorageProp("orgId"),
        initial_referring_domain: getMixPanelProp("$initial_referring_domain"),
        initial_referrer_hostname: getMixPanelProp("initial referrer hostname"),
        utm_source: getUTMParam("utm_source"),
        utm_medium: getUTMParam("utm_medium"),
        utm_campaign: getUTMParam("utm_campaign"),
        utm_content: getUTMParam("utm_content"),
        utm_term: getUTMParam("utm_term"),
        utm_source_last_touch: getUTMParam("utm_source last touch"),
        utm_medium_last_touch: getUTMParam("utm_medium last touch"),
        utm_campaign_last_touch: getUTMParam("utm_campaign last touch"),
        utm_content_last_touch: getUTMParam("utm_content last touch"),
        utm_term_last_touch: getUTMParam("utm_term last touch"),
      },
    })
      .then(() => {
        localStorage.removeItem("fetchingLogin")
        localStorage.removeItem("didLogin")
      })
      .catch((error) => {
        localStorage.removeItem("fetchingLogin")
        console.error("Failed to dispatch call to /login", error)
      })
  })
}
