import React from "react"
import PropTypes from "prop-types"
import { InputGroup, Intent } from "@blueprintjs/core"

const NewInputGroup = (props) => (
  <InputGroup
    disabled={props.disabled}
    intent={props.intent && Intent[props.intent.toUpperCase()]}
    leftIcon={props.leftIcon}
    onChange={(e) => props.onChange(e.target.value)}
    placeholder={props.placeholder}
    rightElement={props.rightElement}
    type={props.type}
    value={props.value}
  />
)

NewInputGroup.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["text", "password", "number"]),
  intent: PropTypes.oneOf(["primary", "success", "warning", "danger"]),
  leftIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rightElement: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

NewInputGroup.defaultProps = {
  disabled: false,
  type: "text",
  intent: "primary",
  leftIcon: null,
  placeholder: null,
  rightElement: null,
  value: null,
}

export default NewInputGroup
