export const DISTPLATFORM_STORAGE_KEYS = [
  "distributor",
  "distributor-policies",
  "avoid-ready-event",
]

export const DISTPLATFORM_TEMPLATES = {
  default: {
    showNavbar: true,
    showNavigation: true,
    showBilling: true,
    showCsvExportButton: true,
    showOutdatedPolicyModal: true,
    hideChatSupport: false,
    showShareDataExplorer: true,
    showMessageNoFilteringSchedule: true,
    showAppAware: true,
    hideTabSettings: false,
    redirectToBlockPageList: true,
    showLearnMore: true,
    loadUserflow: true,
    shouldBypassBuiltInSupportBot: false,
    showAppAwareUpsellBanner: true,
    enableUserPremiumFeature: true,
  },
  solarwinds: {
    loginUrl: "https://www.n-able.com/products/n-central",
    showNavbar: false,
    showNavigation: false,
    showBilling: false,
    showCsvExportButton: false,
    showOutdatedPolicyModal: false,
    hideChatSupport: true,
    showShareDataExplorer: false,
    showMessageNoFilteringSchedule: false,
    showAppAware: true,
    hideTabSettings: true,
    redirectToBlockPageList: false,
    showLearnMore: false,
    loadUserflow: false,
    shouldBypassBuiltInSupportBot: true,
    showAppAwareUpsellBanner: false,
    enableUserPremiumFeature: false,
  },
}
