import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Select from "views/app/Select"
import { Switch } from "@blueprintjs/core"

const Input = ({
  className,
  type,
  label,
  rows,
  options,
  multiple,
  min,
  max,
  step,
  value,
  placeholder,
  autoFocus,
  onChange,
  onFocus,
  onBlur,
  disabledPlaceholder,
  disabled,
  style,
  fill,
  ...rest
}) => {
  if (type === "textarea") {
    return (
      <textarea
        className={classNames("bp3-input", "bp3-fill", className)}
        rows={rows}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    )
  }

  if (type === "select" && multiple) {
    return (
      <Select
        options={options}
        multi
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={onChange}
        disabled={disabled}
        style={style}
      />
    )
  }

  if (type === "select") {
    return (
      <div className={classNames("bp3-select", "bp3-fill", className)}>
        <select
          value={value}
          autoFocus={autoFocus}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          style={style}
        >
          <option value="" disabled={disabledPlaceholder}>
            {placeholder || "Choose an item..."}
          </option>
          {options &&
            options.map(({ label, value }) => (
              <option key={`${label}_${value}`} value={value}>
                {label}
              </option>
            ))}
        </select>
      </div>
    )
  }

  if (type === "switch") {
    return (
      <Switch
        label={label}
        checked={Boolean(value)}
        onChange={(event) => onChange(event.target.checked)}
        disabled={disabled}
      />
    )
  }

  return (
    <input
      className={classNames("bp3-input", fill ? "bp3-fill" : "", className)}
      type={type}
      min={min}
      max={max}
      step={step}
      value={value}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      style={style}
      {...rest}
    />
  )
}

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
  multiple: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]).isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
}

Input.defaultProps = {
  type: "text",
  disabled: false,
  fill: true,
  onFocus: () => {},
}

export default Input
