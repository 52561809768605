import createEntitySchema from "../Utils/createEntitySchema"

let userSchema = createEntitySchema(
  "users",
  {},
  {
    idAttribute: "id",
    processStrategy: (e) => ({
      id: e.id,
      firstName: e.first_name,
      lastName: e.last_name,
      fullName: `${e.first_name} ${e.last_name}`,

      // `phone` might return an empty string, we'll use `null` instead
      phone: e.phone || null,

      email: e.email,
      emailVerified: e.email_verified,
      pin: e.pin,
    }),
  },
)

export default userSchema
