export const hasAddOrgRole = (session) => {
  const mainOrganization = session.getMainOrganization()

  return (
    mainOrganization.type === "msp" &&
    ["owner", "administrator", "super_administrator"].includes(
      session.user.rolesByOrganization[mainOrganization?.id],
    )
  )
}
