import callAPI from "callAPI"

export default ({
  id,
  organization: organization_id,
  name,
  categoryIds: blacklist_categories,
  whitelist,
  blacklist,
  policy_ip_id,
  googleSearch,
  bingSearch,
  yandexSearch,
  duckDuckGoSearch,
  interstitial,
  youtube_restricted,
  youtube_restricted_level,
  block_unknown_domains,
  lockVersion,
  is_global_policy,
  appendDomains,
  allow_applications,
  block_applications,
}) => {
  let policy = {
    organization_id,
    name,
    blacklist_categories,

    whitelist_domains: whitelist,
    blacklist_domains: blacklist,
    policy_ip_id,
    google_safesearch: googleSearch,
    bing_safe_search: bingSearch,
    yandex_safe_search: yandexSearch,
    duck_duck_go_safe_search: duckDuckGoSearch,
    interstitial,
    youtube_restricted,
    youtube_restricted_level,
    allow_unknown_domains: !block_unknown_domains,
    lock_version: lockVersion,
    allow_applications,
    block_applications,
  }

  if (localStorage.getItem("organization.isMSP")) {
    policy = {
      ...policy,
      is_global_policy: String(is_global_policy),
    }
  }

  return callAPI({
    method: "PUT",
    path: `/policies/${id}`,
    body: {
      append_domains: !!appendDomains,
      policy,
    },
  })
}
