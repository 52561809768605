import callAPI from "callAPI"
import sortBlockPages from "api/block-pages/transform/sortBlockPages"

export default async () => {
  let blockPages = await callAPI({
    path: "/block_pages",
    query: {
      organization_id: localStorage.getItem("orgId"),
    },
  })

  return sortBlockPages(
    blockPages.data.map((datum) => ({
      ...datum.attributes,
      networkCount: datum.relationships.networks.data.length,
      agentCount: datum.relationships.user_agents.data.length,
      agentLocalUsers: datum.relationships.agent_local_users.data.length,
      collectionCount: datum.relationships.collections.data.length,
      id: Number(datum.id),
    })),
  )
}
