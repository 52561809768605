import callAPI from "callAPI"

export default ({
  showIndividualNetworks,
  organizationIds = localStorage.getItem("orgId"),
  from,
  to,
  type,
}) =>
  callAPI({
    path: "/traffic_reports/qps_active_agents",
    query: {
      organization_ids: organizationIds,
      type: type,
      from,
      to,
    },
  })
