import React from "react"
import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from "action-types/notifications"
import { Toaster, Intent, Position } from "@blueprintjs/core"

export const showNotification = ({ type, title, message, icon }) => ({
  type: SHOW_NOTIFICATION,
  payload: {
    id: new Date().getTime(),
    type,
    title,
    message,
    icon,
  },
})

export const hideNotification = (id) => ({
  type: HIDE_NOTIFICATION,
  payload: id,
})

const appToaster = Toaster.create({
  position: Position.TOP_RIGHT,
})

export const showNotificationWithTTL =
  (notification, ttl = 4) =>
  (dispatch) => {
    appToaster.show({
      message: (
        <span data-test-id="toaster-message">
          {notification.icon && (
            <span>
              <i
                className={`fa fa-${notification.icon}`}
                style={{
                  marginRight: ".5rem",
                }}
              />
            </span>
          )}
          {notification.title && (
            <span>
              <strong>{notification.title}</strong>
              <br />
            </span>
          )}
          {notification.message}
        </span>
      ),
      intent:
        notification.type === "danger"
          ? Intent.DANGER
          : notification.type === "success"
          ? Intent.SUCCESS
          : Intent.PRIMARY,
      timeout: ttl * 1000,
    })
  }
