import callAPI from "callAPI"

export default (networkId, block_page_id) =>
  callAPI({
    method: "PUT",
    path: `/networks/${networkId}`,
    body: {
      network: {
        block_page_id,
      },
    },
  })
