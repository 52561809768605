import { isPageMspDashboard } from "./isPageMspDashboard"

export function filterDistributorPolicies({ data, key }) {
  if (localStorage.getItem("distributor-policies") && key) {
    const distributorPolicies = JSON.parse(
      localStorage.getItem("distributor-policies"),
    )

    if (!Array.isArray(distributorPolicies)) {
      return data
    }

    return data.filter((policy) =>
      distributorPolicies.find(
        (policyId) => Number(policyId) === Number(policy.id),
      ),
    )
  }

  return data
}

export const isDistributorSubscriptionPageVisible = (
  session,
  path,
  LDFlags,
) => {
  const mainOrganization = session.getMainOrganization()

  const isMspDashboard = isPageMspDashboard(mainOrganization, path)

  // hide Distributor Subscription Page on single orgs
  if (!isMspDashboard) {
    return false
  }

  return LDFlags.showSubscriptionsPage && mainOrganization.isDistributor
}
