import React from "react"
import { Grid } from "_Infinity/Core/Components/Grid"
import { Heading } from "_Infinity/Core/Components/Heading"
import { Text } from "_Infinity/Core/Components/Text"
import { Switch } from "_Infinity/Core/Components/Switch"
import { Button } from "_Infinity/Core/Components/Button"
import { Tooltip } from "_Infinity/Core/Components/Tooltip"
import { useKnobs } from "_Infinity/Knobs/Hooks/useKnobs"

export function KnobsConfig() {
  let knobs = useKnobs()
  let allKnobs = knobs.getAll()

  if (allKnobs.length === 0) {
    return <Text>No knobs found</Text>
  }

  return (
    <Grid gap={6}>
      {allKnobs.map((knob, i) => (
        <Grid key={knob.id} gap={4}>
          {knob.group !== allKnobs[i - 1]?.group && (
            <Heading size={1}>{knob.group}</Heading>
          )}
          <Grid templateColumns="1fr auto" gap={4} alignItems="center">
            <Switch on={knob.value} onChange={knobs.setValue(knob.id)}>
              {knob.label}
            </Switch>
            {knob.pristine ? (
              <Button icon="blank" size={1} minimal readOnly />
            ) : (
              <Tooltip content="Knob changed">
                <Button
                  icon="dot"
                  size={1}
                  minimal
                  onClick={knobs.resetValue(knob.id)}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
