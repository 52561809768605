import React from "react"
import PropTypes from "prop-types"
import ViewportCentered from "components/ViewportCentered"
import Logo from "components/Logo"
import Card from "components/Card"
import Callout from "components/Callout"
import Spinner from "components/Spinner"
import { Row, Col } from "jsxstyle"
import { Link } from "react-router-dom"
import Footer from "components/Footer"
import SignupForm from "./SignupForm/SignupForm"

const SignupScene = ({
  onSubmit,
  onClearSubmit,
  success,
  error,
  isSubmitting,
}) => (
  <ViewportCentered padding={30}>
    <div
      style={{
        maxWidth: 330,
      }}
    >
      <Logo marginBottom={30} />
    </div>
    <Card maxWidth={550} fullWidth>
      <Row>
        <Col flexGrow={1}>
          <h4>Create Account</h4>
        </Col>
        <Col flexGrow={1} textAlign="right">
          <Link to="/">
            <small>Meant to login?</small>
          </Link>
        </Col>
      </Row>
      <SignupForm
        onSubmit={onSubmit}
        submitting={isSubmitting}
        success={success}
        onClearSubmit={onClearSubmit}
      />
      {!!(success || error) && (
        <Callout
          style={{
            marginTop: 15,
            marginBottom: 0,
            paddingBottom: success ? 1 : 5,
          }}
          intent={success ? "success" : "danger"}
          icon={success ? "tick" : "error"}
        >
          {success ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: -2,
              }}
            >
              <span
                style={{
                  marginRight: 5,
                }}
              >
                Thanks for signing up! Redirecting to the application...
              </span>
              <Spinner small />
            </div>
          ) : (
            error
          )}
        </Callout>
      )}
    </Card>
    <Footer />
  </ViewportCentered>
)

SignupScene.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClearSubmit: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

export default SignupScene
