import callAPI from "callAPI"

export default (organization_id) =>
  callAPI({
    method: "POST",
    path: "/policies",
    body: {
      policy: {
        organization_id,
        name: "Block Adult Content",
        blacklist_categories: [2],
      },
    },
  })
