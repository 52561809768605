import React, { useContext } from "react"

import Link from "components/Link"

import createUrl from "utils/createUrl"

import { useLocation } from "react-router-dom"

import { Icon } from "@blueprintjs/core"

import { SessionContext } from "_v2/Modules/Auth"

import "./LegacyEnterpriseAlert.css"

function LegacyEnterpriseAlert() {
  let location = useLocation()
  let session = useContext(SessionContext)
  let mainOrganization = session.getMainOrganization()

  if (!session.user?.acl?.isLegacyEnterprise) {
    return null
  }

  if (location.pathname.endsWith("/settings/billing-info/subscription")) {
    return null
  }

  if (session.loading) {
    return null
  }

  if (session.legacyEnterpriseAlertDismissed) {
    return null
  }

  if (!mainOrganization.showBilling) {
    return null
  }

  if (
    !session.user.rolesByOrganization[mainOrganization.id].match(
      /owner|administrator/,
    )
  ) {
    return null
  }

  return (
    <div className="LegacyEnterpriseAlert">
      <p>
        Usage based pricing is transitioning to user-based pricing on 1 June
        2021.  Read more on our 
        <a
          href="https://help.dnsfilter.com/hc/en-us/articles/1500012185521-Legacy-Usage-Conversion-FAQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQ Page
        </a>
        . |  
        <Link
          intent="success"
          to={createUrl("/settings/billing-info/subscription")}
          style={{
            textDecoration: "none",
          }}
        >
          Visit the Billing Portal to update or verify your user counts
        </Link>
        .
      </p>
      <a
        href="#close"
        onClick={(e) => {
          e.preventDefault()
          session.sendDismissLegacyEnterpriseAlert()
        }}
      >
        <Icon icon="cross" />
      </a>
    </div>
  )
}

export default LegacyEnterpriseAlert
