import get from "lodash/get"
import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  BILLING_DATA_REQUEST,
  BILLING_DATA_SUCCESS,
  BILLING_DATA_FAILURE,
  LOAD_ORG_BILLING_DATA_REQUEST,
  LOAD_ORG_BILLING_DATA_SUCCESS,
  LOAD_ORG_BILLING_DATA_FAILURE,
  UPDATE_BILLING_TOKEN_REQUEST,
  UPDATE_BILLING_TOKEN_FAILURE,
  LOAD_INIT_BILLING_DATA_REQUEST,
  LOAD_INIT_BILLING_DATA_SUCCESS,
  LOAD_INIT_BILLING_DATA_FAILURE,
} from "action-types/billing"
import callAPI from "callAPI"
import loadOrgBillingCall from "api/billing/loadBillingCall"
import updateBillingTokenCall from "api/billing/updateBillingTokenCall"
import { removeUpdatePaymentMessage } from "actions/organization"
import { getUserAndOrgData } from "./auth"

export const userDataRequest = () => ({
  type: USER_REQUEST,
})

export const userDataSuccess = (data) => ({
  type: USER_SUCCESS,
  payload: data,
})

export const userDataFailure = (error) => ({
  type: USER_FAILURE,
  payload: error,
})

export const billingDataRequest = () => ({
  type: BILLING_DATA_REQUEST,
})

export const billingDataSuccess = (data) => ({
  type: BILLING_DATA_SUCCESS,
  payload: data,
})

export const billingDataFailure = (error) => ({
  type: BILLING_DATA_FAILURE,
  payload: error,
})

export const getOrgBillingDataRequest = () => ({
  type: LOAD_ORG_BILLING_DATA_REQUEST,
})

export const getOrgBillingDataSuccess = (data) => ({
  type: LOAD_ORG_BILLING_DATA_SUCCESS,
  payload: data,
})

export const getOrgBillingDataFailure = () => ({
  type: LOAD_ORG_BILLING_DATA_FAILURE,
})

export const updateBillingTokenRequest = () => ({
  type: UPDATE_BILLING_TOKEN_REQUEST,
})

export const updateBillingTokenFailure = (token) => ({
  type: UPDATE_BILLING_TOKEN_FAILURE,
  payload: token,
})

export const getInitBillingDataRequest = () => ({
  type: LOAD_INIT_BILLING_DATA_REQUEST,
})

export const getInitBillingDataSuccess = (data) => ({
  type: LOAD_INIT_BILLING_DATA_SUCCESS,
  payload: data,
})

export const getInitBillingDataFailure = () => ({
  type: LOAD_INIT_BILLING_DATA_FAILURE,
})

export const userCall = () => getUserAndOrgData()

export const billingCall = () =>
  callAPI({
    path: "/invoices/current",
    query: {
      organization_id: localStorage.getItem("orgId"),
    },
  })

export const getUserInfo = () => (dispatch) => {
  dispatch(userDataRequest())

  return userCall()
    .then((data) => dispatch(userDataSuccess(data)))
    .catch((err) => dispatch(userDataFailure(err)))
}

export const getBillingData = () => (dispatch) => {
  dispatch(
    billingDataRequest({
      loadingOrgData: false,
    }),
  )

  return billingCall()
    .then((data) => dispatch(billingDataSuccess(data)))
    .catch((err) => dispatch(billingDataFailure(err)))
}

export const loadOrgBilling = () => (dispatch, getState) => {
  dispatch(getOrgBillingDataRequest())

  return loadOrgBillingCall()
    .then((data) => {
      const orgs = getState().organizations.data
      const updatePayment = get(
        orgs.find(
          (org) => Number(org.id) === Number(localStorage.getItem("orgId")),
        ),
        "attributes.update_payment",
      )
      dispatch(getOrgBillingDataSuccess(data))

      if (Object.keys(data).length && updatePayment) {
        dispatch(removeUpdatePaymentMessage())
      }

      return data
    })
    .catch(() => {
      dispatch(getOrgBillingDataFailure())
    })
}

export const loadInitBilling = () => (dispatch) => {
  dispatch(getInitBillingDataRequest())

  return loadOrgBillingCall()
    .then((data) => {
      dispatch(getInitBillingDataSuccess(data))
    })
    .catch(() => {
      dispatch(getInitBillingDataFailure())
    })
}

export const updateBillingToken = (token) => (dispatch) =>
  updateBillingTokenCall(token).then(() => dispatch(loadOrgBilling()))
