import React from "react"
import { useQuery } from "react-query"
import { useFlags } from "launchdarkly-react-client-sdk"
import httpClient from "_v2/Core/Utils/httpClient"
import { Drawer } from "components/Drawer"
import { SessionContext } from "_v2/Modules/Auth"

import styles from "./SupportDistributors.module.css"

export const SupportDistributorsDrawer = ({ isOpen, handleClose, data }) => (
  <Drawer isOpen={isOpen} onClose={handleClose}>
    <div className={styles["support-distributors-body"]}>
      <div className={styles["support-distributors-title"]}>
        Contact support through {data?.name || ""}
      </div>
      {data?.support_description && (
        <div className={styles["support-distributors-description"]}>
          {data.support_description}
        </div>
      )}
      {data?.support_phone && (
        <div>
          <div className={styles["support-distributors-label"]}>Phone</div>
          <div className={styles["support-distributors-value"]}>
            {data.support_phone}
          </div>
        </div>
      )}
      {data?.support_email && (
        <div>
          <div className={styles["support-distributors-label"]}>E-Mail</div>
          <div className={styles["support-distributors-value"]}>
            <a href={`mailto:${data.support_email}`}>{data.support_email}</a>
          </div>
        </div>
      )}
      {data?.support_url && (
        <div>
          <div className={styles["support-distributors-label"]}>
            Open a ticket
          </div>
          <div className={styles["support-distributors-value"]}>
            <a
              href={data.support_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {data.support_url}
            </a>
          </div>
        </div>
      )}
    </div>
  </Drawer>
)

export const useSupportDistributors = () => {
  const ldFlags = useFlags()
  const session = React.useContext(SessionContext)

  const [isOpen, setIsOpen] = React.useState(false)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const distributorsQuery = useQuery(
    ["distributorsQuery"],
    () =>
      httpClient
        .get(`distributors`)
        .then((res) => res.data?.data?.attributes || []),
    {
      refetchOnWindowFocus: false,
      enabled:
        !!session.activeOrganization?.isDistributor &&
        ldFlags.showSupportButtonForDistributors,
    },
  )

  const { data } = distributorsQuery

  const showSupportInfo = distributorsQuery.isLoading
    ? false
    : data?.show_support_info || false

  return {
    isOpen,
    showSupportInfo,
    openSupportDistributors: handleOpen,
    closeSupportDistributors: handleClose,
    data,
  }
}
