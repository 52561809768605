export const SELECT_USER_AGENT = "SELECT_USER_AGENT"
export const SELECT_NETWORK = "SELECT_NETWORK"
export const SELECT_NETWORK_FILTER = "SELECT_NETWORK_FILTER"
export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION"
export const SELECT_LAN_PROXY = "SELECT_LAN_PROXY"
export const LOAD_DEPLOYMENT_SELECTOR_USER_AGENTS =
  "LOAD_DEPLOYMENT_SELECTOR_USER_AGENTS"
export const SELECT_TRAFFIC_TYPE = "SELECT_TRAFFIC_TYPE"
export const SELECT_QUERY_TYPE = "SELECT_QUERY_TYPE"
export const CHANGE_SEARCH_TEXT = "CHANGE_SEARCH_TEXT"
export const CHANGE_PERIOD = "CHANGE_PERIOD"
export const SELECT_QUERY_LOG_DEPLOYMENT = "SELECT_QUERY_LOG_DEPLOYMENT"
