import React from "react"
import cx from "classnames"
import { Row } from "_Infinity/Core/Components/Row"
import { Grid } from "_Infinity/Core/Components/Grid"
import "./CardActions.css"

export function CardActions(props) {
  return (
    <div className={cx("CardActions")}>
      <Row justifyContent={props.left ? "flex-start" : "flex-end"}>
        <Grid autoFlow="column" gap={2}>
          {props.children}
        </Grid>
      </Row>
    </div>
  )
}
