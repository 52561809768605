import React from "react"
import { Link } from "react-router-dom"

export default function NotFound() {
  return (
    <div className="page-content vertical-align-middle text-center">
      <header>
        <h1 className="animation-slide-top">404</h1>
        <p>Page Not Found !</p>
      </header>
      <Link className="btn btn-primary btn-round" to="/">
        GO TO HOME PAGE
      </Link>
    </div>
  )
}
