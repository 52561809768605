export const LOAD_USER_AGENTS_LOCAL = "LOAD_USER_AGENTS_LOCAL"
export const SELECT_AGENT_LOCAL = "SELECT_AGENT_LOCAL"
export const CHANGE_ALL_USER_AGENTS_LOCAL = "CHANGE_ALL_USER_AGENTS_LOCAL"
export const TOGGLE_EDIT_MULTIPLE_USER_AGENTS_LOCAL_FORM =
  "TOGGLE_EDIT_MULTIPLE_USER_AGENTS_LOCAL_FORM"
export const UPDATE_MULTIPLE_USER_AGENTS_LOCAL_REQUEST =
  "UPDATE_MULTIPLE_USER_AGENTS_LOCAL_REQUEST"
export const CHANGE_MULTIPLE_USER_AGENTS_LOCAL =
  "CHANGE_MULTIPLE_USER_AGENTS_LOCAL"
export const CHANGE_USER_AGENT_LOCAL = "CHANGE_USER_AGENT_LOCAL"
export const CHANGE_USER_AGENTS_LOCAL_SEARCH_TEXT_FILTER =
  "CHANGE_USER_AGENTS_LOCAL_SEARCH_TEXT_FILTER"
export const SORT_USER_AGENTS_LOCAL = "SORT_USER_AGENTS_LOCAL"
