import { useMachine as baseUseMachine } from "@xstate/react"

function useMachine(machine, config) {
  let [state, send] = baseUseMachine(machine, config)

  let meta = Object.values(state.meta).reduce(
    (acc, v) => ({
      ...acc,
      ...v,
    }),
    {},
  )

  return {
    state,
    context: state.context,
    meta,
    send: (type, payload) =>
      send({
        type,
        payload,
      }),
  }
}

export default useMachine
