import { connect } from "react-redux"
import { hideNotification } from "actions/notifications"
import NotificationList from "../components/NotificationList"

const mapStateToProps = ({ notifications }) => ({
  notifications,
})

const mapDispatchToProps = {
  hideNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)
