import httpClient from "_v2/Core/Utils/httpClient"

export const deleteNetworksBulk = (request) =>
  httpClient.delete(`networks/bulk_destroy`, request)

export const updateNetworksBulk = (request) =>
  httpClient.post(`/networks/bulk_update`, request)

export const createNetworksBulk = (request) =>
  httpClient.post(`/networks/bulk_create`, request)
