import moment from "moment"

export default (period) => {
  const format = (date) => date.format()

  return {
    lastMinute: [format(moment().subtract(1, "minute")), format(moment())],
    last5Minutes: [format(moment().subtract(5, "minute")), format(moment())],
    last10Minutes: [format(moment().subtract(10, "minute")), format(moment())],
    last15Minutes: [format(moment().subtract(15, "minute")), format(moment())],
    last30Minutes: [format(moment().subtract(30, "minute")), format(moment())],
    lastHour: [
      format(moment().subtract(1, "hour").startOf("minute")),
      format(moment().subtract(1, "minute").endOf("minute")),
    ],
    last2Hours: [format(moment().subtract(2, "hour")), format(moment())],
    last4Hours: [format(moment().subtract(4, "hour")), format(moment())],
    last8Hours: [format(moment().subtract(8, "hour")), format(moment())],
    last12Hours: [format(moment().subtract(12, "hour")), format(moment())],
    last24Hours: [format(moment().subtract(24, "hour")), format(moment())],
    last48Hours: [format(moment().subtract(48, "hour")), format(moment())],
    last72Hours: [format(moment().subtract(72, "hour")), format(moment())],
    last144Hours: [format(moment().subtract(144, "hour")), format(moment())],
    last216Hours: [format(moment().subtract(216, "hour")), format(moment())],
    last2160Hours: [format(moment().subtract(2160, "hour")), format(moment())],
    today: [format(moment().startOf("day")), format(moment())],
    yesterday: [
      format(moment().subtract(1, "day").startOf("day")),
      format(moment().subtract(1, "day").endOf("day")),
    ],
    dayBeforeYesterday: [
      format(moment().subtract(2, "day").startOf("day")),
      format(moment().subtract(2, "day").endOf("day")),
    ],
    lastWeek: [
      format(moment().subtract(1, "week").startOf("day")),
      format(moment().endOf("day")),
    ],
    last30days: [
      format(moment().subtract(30, "days").startOf("day")),
      format(moment().endOf("day")),
    ],
    last60days: [
      format(moment().subtract(60, "days").startOf("day")),
      format(moment().endOf("day")),
    ],
  }[period]
}
