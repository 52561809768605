import qs from "qs"
import validateTokenExpDate from "utils/validateTokenExpDate"

export default function callAPI({
  method = "GET",
  path,
  query,
  headers,
  body,
  ...other
}) {
  const url =
    process.env.REACT_APP_API_URL.replace(/\/$/, "") +
    path +
    (query ? `?${qs.stringify(query)}` : "")
  const access_token = localStorage.getItem("access_token")

  return fetch(url, {
    ...other,
    method,
    headers: {
      ...headers,
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": access_token ? `Bearer ${access_token}` : null,
    },
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      if (!validateTokenExpDate(response)) return
      try {
        const data = await response.json()

        return Promise.reject(data)
      } catch (err) {
        return Promise.reject(response)
      }
    }

    return response.json().catch(() => ({}))
  })
}

export function callAPIWithResponse({
  method = "GET",
  path,
  query,
  headers,
  body,
  ...other
}) {
  const url =
    process.env.REACT_APP_API_URL.replace(/\/$/, "") +
    path +
    (query ? `?${qs.stringify(query)}` : "")
  const access_token = localStorage.getItem("access_token")

  return fetch(url, {
    ...other,
    method,
    headers: {
      ...headers,
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": access_token ? `Bearer ${access_token}` : null,
    },
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      if (!validateTokenExpDate(response)) return
      const status = response.status
      try {
        const data = await response.json()
        return Promise.reject({ status, data })
      } catch (err) {
        return Promise.reject({ response })
      }
    }

    return response.json().catch(() => ({}))
  })
}
