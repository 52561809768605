import moment from "moment"
import get from "lodash/get"
import totalRequestsCall from "api/reports/totalRequestsCall"

export default () =>
  totalRequestsCall({
    showIndividualNetworks: false,
    from: moment().subtract(1, "day").startOf("day").format(),
    to: moment().format(),
  })
    .then((data) => {
      const total = get(data, "data.values", []).reduce(
        (acc, value) => acc + value.total,
        0,
      )

      return total > 0
    })
    .catch(() => {})
