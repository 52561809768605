import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { useFlags } from "launchdarkly-react-client-sdk"
import {
  historyPropTypes,
  locationPropTypes,
  organizationPropTypes,
} from "consts/prop-types"
import Link from "components/Link"
import Button from "components/Button"
import Popover from "components/Popover"
import Menu from "components/Menu"
import Avatar from "components/Avatar"
import PhoneInput from "components/PhoneInput"
import OrganizationPopover from "components/OrganizationPopover"
import getLogo, { getLogoAlt } from "utils/getLogo"
import createUrl from "utils/createUrl"

import { useResource } from "_Infinity/Core/Hooks/useResource"
import { Popover as InfinityPopover } from "_Infinity/Core/Components/Popover"
import { AccessControl } from "_Infinity/Auth/Components/AccessControl"
import { KnobsButton } from "_Infinity/Knobs/Components/KnobsButton"
import { KnobsConfig } from "_Infinity/Knobs/Components/KnobsConfig"
import { Toast } from "_Infinity/Core/Components/Toast"
import { Dialog } from "_Infinity/Core/Components/Dialog"
import { Form } from "_Infinity/Core/Components/Form"
import { FormField } from "_Infinity/Core/Components/FormField"
import { TextInput } from "_Infinity/Core/Components/TextInput"
import { TextArea } from "_Infinity/Core/Components/TextArea"
import { CardContent } from "_Infinity/Core/Components/CardContent"
import { CardActions } from "_Infinity/Core/Components/CardActions"

import { SessionContext } from "_v2/Modules/Auth"

import { fetchSupport } from "api/support/fetchSupport"

import isEmail from "validators/isEmail"

import { showSupportButton } from "utils/navbar"

const NavbarDesktop = (props) => {
  const session = useContext(SessionContext)
  const mainOrganization = session.getMainOrganization()
  let [openSupport, setOpenSupport] = useState(false)
  let supportResource = useResource({
    fetch: (params) => fetchSupport(params),
    onSuccess: () => setOpenSupport(false),
  })
  const ldFlags = useFlags()

  useEffect(() => {
    if (window.Canny && process.env.REACT_APP_CANNY_ID) {
      window.Canny("initChangelog", {
        appID: process.env.REACT_APP_CANNY_ID,
        position: "bottom",
        align: "right",
      })
    }
  }, [])

  return (
    <div className="bp3-navbar bp3-navbar-desktop bp3-fixed-top">
      <div className="bp3-navbar-group bp3-align-left">
        <div
          className="bp3-navbar-heading"
          style={{
            position: "relative",
          }}
        >
          <Link data-test-id="logo" to="/">
            <img src={getLogo()} alt={getLogoAlt()} />
          </Link>
          {localStorage.getItem("owned_msp_id") && (
            <span
              className="bp3-tag bp3-round"
              style={{
                background: "#9AF5CC",
                color: "#F96197",
                fontSize: 10,
                fontWeight: "bold",
                lineHeight: "8px",
                position: "absolute",
                bottom: 5,
                right: 0,
                padding: "4px 5px",
              }}
            >
              MSP
            </span>
          )}
          {process.env.NODE_ENV !== "production" && (
            <span
              className="bp3-tag bp3-intent-warning bp3-round"
              style={{
                fontSize: 6,
                lineHeight: "8px",
                position: "absolute",
                top: 5,
                right: 0,
                padding: "2px 5px",
              }}
            >
              TEST MODE
            </span>
          )}
        </div>
        {props.organizations && (
          <OrganizationPopover
            currentOrganization={props.currentOrganization}
            organizations={props.organizations}
            location={props.location}
            history={props.history}
            onOrganizationClick={props.onOrganizationClick}
          />
        )}
      </div>
      {props.showMenu && (
        <div className="bp3-navbar-group bp3-align-right">
          <AccessControl env="dev">
            <InfinityPopover
              content={<KnobsConfig />}
              interactionKind="hover"
              position="bottom-right"
              contentSizing
            >
              <KnobsButton tooltipPosition="left" />
            </InfinityPopover>
          </AccessControl>

          <Popover
            interactionKind="hover"
            position="bottom-right"
            content={
              <div>
                <Menu
                  items={[
                    (!localStorage.getItem("organization.isMSPSubAccount") ||
                      localStorage.getItem("user.isMSP")) && {
                      icon: "search-text",
                      text: "Documentation",
                      onClick: () => {
                        window.trackAnalyticsEvent(
                          "Support Menu Click - Documentation",
                          {
                            user_id: localStorage.getItem("userId"),
                            organization_id: localStorage.getItem("orgId"),
                          },
                        )
                        window.open(
                          "https://help.dnsfilter.com/hc/en-us",
                          "_blank",
                        )
                      },
                    },
                    (!localStorage.getItem("organization.isMSPSubAccount") ||
                      localStorage.getItem("user.isMSP")) &&
                      !localStorage.getItem("whitelabel.domain") && {
                        icon: "presentation",
                        text: "View Tutorial",
                        onClick: () => {
                          window.trackAnalyticsEvent(
                            "Support Menu Click - View Tutorial",
                            {
                              user_id: localStorage.getItem("userId"),
                              organization_id: localStorage.getItem("orgId"),
                            },
                          )
                        },
                      },
                    showSupportButton({
                      session,
                      ldFlags,
                      showSupportDistributorsInfo:
                        props.showSupportDistributorsInfo,
                    }) && {
                      icon: "label",
                      text: localStorage.getItem("organization.isMSPSubAccount")
                        ? "Contact Support"
                        : "Open a ticket",
                      onClick: session.activeOrganization?.isDistributor
                        ? (...args) => {
                            localStorage.getItem("organization.isMSPSubAccount")
                              ? window.trackAnalyticsEvent(
                                  "Support Menu Click - Contact Support",
                                  {
                                    user_id: localStorage.getItem("userId"),
                                    organization_id:
                                      localStorage.getItem("orgId"),
                                  },
                                )
                              : window.trackAnalyticsEvent(
                                  "Support Menu Click - Open a Ticket",
                                  {
                                    user_id: localStorage.getItem("userId"),
                                    organization_id:
                                      localStorage.getItem("orgId"),
                                  },
                                )
                            props.openSupportDistributors(...args)
                          }
                        : (...args) => {
                            localStorage.getItem("organization.isMSPSubAccount")
                              ? window.trackAnalyticsEvent(
                                  "Support Menu Click - Contact Support",
                                  {
                                    user_id: localStorage.getItem("userId"),
                                    organization_id:
                                      localStorage.getItem("orgId"),
                                  },
                                )
                              : window.trackAnalyticsEvent(
                                  "Support Menu Click - Open a Ticket",
                                  {
                                    user_id: localStorage.getItem("userId"),
                                    organization_id:
                                      localStorage.getItem("orgId"),
                                  },
                                )
                            props.onSupportClick(...args)
                          },
                    },
                    (!localStorage.getItem("organization.isMSPSubAccount") ||
                      localStorage.getItem("user.isMSP")) && {
                      icon: "pulse",
                      text: "System Status",
                      onClick: (...args) => {
                        window.trackAnalyticsEvent(
                          "Support Menu Click - System Status",
                          {
                            user_id: localStorage.getItem("userId"),
                            organization_id: localStorage.getItem("orgId"),
                          },
                        )
                        props.onSystemStatusClick(...args)
                      },
                    },
                    {
                      icon: "geosearch",
                      text: "Domain Report",
                      onClick: (...args) => {
                        window.trackAnalyticsEvent(
                          "Support Menu Click - Domain Report",
                          {
                            user_id: localStorage.getItem("userId"),
                            organization_id: localStorage.getItem("orgId"),
                          },
                        )
                        props.onDomainLookupClick(...args)
                      },
                    },
                    (!localStorage.getItem("organization.isMSPSubAccount") ||
                      localStorage.getItem("user.isMSP")) && {
                      icon: "code",
                      text: "Feature Request",
                      onClick: () => {
                        window.trackAnalyticsEvent(
                          "Support Menu Click - Feature Request",
                          {
                            user_id: localStorage.getItem("userId"),
                            organization_id: localStorage.getItem("orgId"),
                          },
                        )
                        props.history.push(createUrl("/feature-request"))
                      },
                    },
                    (!localStorage.getItem("organization.isMSPSubAccount") ||
                      localStorage.getItem("user.isMSP")) && {
                      icon: "form",
                      text: "View Debug Page",
                      onClick: () => {
                        window.trackAnalyticsEvent(
                          "Support Menu Click - View Debug Page",
                          {
                            user_id: localStorage.getItem("userId"),
                            organization_id: localStorage.getItem("orgId"),
                          },
                        )
                        window.open("https://debug.dnsfilter.com/", "_blank")
                      },
                    },
                    session?.user?.acl?.supportRequestCallback === true &&
                      !localStorage.getItem("organization.isMSPSubAccount") && {
                        icon: "phone",
                        text: "Request a Callback",
                        onClick: () => {
                          window.trackAnalyticsEvent(
                            "Support Menu Click - Request a Callback",
                            {
                              user_id: localStorage.getItem("userId"),
                              organization_id: localStorage.getItem("orgId"),
                            },
                          )
                          setOpenSupport(true)
                        },
                      },
                  ].filter((item) => item)}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "20px 0",
                  }}
                >
                  <small>
                    {session?.user?.acl?.supportPhone === true &&
                      !localStorage.getItem("organization.isMSPSubAccount") && (
                        <>
                          24/7 Enterprise Support
                          <br />
                          <b>+1 877 835 2882</b>
                          <br />
                          <br />
                        </>
                      )}
                    {mainOrganization.type === "msp"
                      ? "MSP Org ID"
                      : "Org ID"}:{" "}
                    <strong>
                      {mainOrganization.type === "msp"
                        ? mainOrganization.mspId
                        : mainOrganization.id}
                    </strong>
                    <br />
                    User PIN: <strong>{session.user.pin}</strong>
                  </small>
                </div>
              </div>
            }
          >
            <Button leftIcon="help" minimal data-test-id="support">
              Support
            </Button>
          </Popover>
          <span className="bp3-navbar-divider" />
          <Popover
            interactionKind="hover"
            position="bottom-right"
            content={
              <Menu
                items={[
                  {
                    icon: "user",
                    text: "Account",
                    onClick: props.onAccountClick,
                  },
                  props.onSwitchAccountClick && {
                    icon: "refresh",
                    text: "Switch Account",
                    onClick: props.onSwitchAccountClick,
                  },
                  {
                    icon: "log-out",
                    text: "Logout",
                    onClick: props.onLogoutClick,
                  },
                ].filter(Boolean)}
              />
            }
          >
            <Button minimal data-test-id="avatar">
              <Avatar size={26} userPicture={props.userPicture} />
            </Button>
          </Popover>
          {!localStorage.getItem("whitelabel.name") && (
            <div
              style={{
                position: "relative",
              }}
            >
              <Button
                minimal
                data-test-id="badge"
                data-canny-changelog
                onClick={() => {
                  window.trackAnalyticsEvent("Latest Updates Click", {
                    user_id: localStorage.getItem("userId"),
                    organization_id: localStorage.getItem("orgId"),
                  })
                }}
              >
                <span
                  className="bp3-icon-standard bp3-icon-notifications"
                  style={{
                    marginRight: 0,
                  }}
                />
              </Button>
            </div>
          )}
        </div>
      )}
      <Dialog
        title="Request a Callback"
        open={openSupport}
        onClose={() => setOpenSupport(false)}
      >
        <Form
          initialValues={{
            name: session?.user?.fullName ?? "",
            email: session?.user?.email ?? "",
            phone: session?.user?.phone ?? "",
            note: "",
          }}
          disabled={supportResource.fetching}
          validate={(values) => {
            let errors = {}

            if (!values.name) {
              errors.name = "This field is required"
            }

            if (!values.phone) {
              errors.phone = "This field is required"
            }

            if (!values.email || isEmail(values.email)) {
              errors.email = "Invalid e-mail"
            }

            if (Object.keys(errors).length === 0) {
              return null
            }

            return errors
          }}
          onValidate={({ name, phone, email, note }) => {
            supportResource.fetch({
              body_text: `The ${localStorage.getItem(
                "orgName",
              )} with the user email ${email} and name ${name} have requested a call back, please call ${phone} when available. ${
                note && `See the client description below: ${note}`
              }`,
              subject: `${localStorage.getItem(
                "orgName",
              )} requested a call back`,
              support: "true",
            })
          }}
        >
          <CardContent>
            <FormField label="Name" name="name">
              <TextInput autoFocus />
            </FormField>
          </CardContent>
          <CardContent>
            <FormField label="E-mail" name="email">
              <TextInput />
            </FormField>
          </CardContent>
          <CardContent>
            <FormField label="Phone" name="phone">
              <PhoneInput />
            </FormField>
          </CardContent>
          <CardContent>
            <FormField label="Note" name="note">
              <TextArea />
            </FormField>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => setOpenSupport(false)}
              disabled={supportResource.fetching}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              intent="primary"
              loading={supportResource.fetching}
            >
              Send
            </Button>
          </CardActions>
        </Form>
      </Dialog>
      <Toast intent="success" open={supportResource.success}>
        Thanks for requesting a call from support. We've passed this onto our
        support team, and they'll give you a call as soon as they can.
      </Toast>
      <Toast intent="danger" open={supportResource.failure}>
        There was an error sending the message. Please try again.
      </Toast>
    </div>
  )
}

NavbarDesktop.defaultProps = {
  showMenu: true,
}

NavbarDesktop.propTypes = {
  currentOrganization: organizationPropTypes.isRequired,
  organizations: PropTypes.arrayOf(organizationPropTypes),
  userPicture: PropTypes.string.isRequired,
  history: historyPropTypes.isRequired,
  location: locationPropTypes.isRequired,
  onOrganizationClick: PropTypes.func.isRequired,
  onAccountClick: PropTypes.func.isRequired,
  onSwitchAccountClick: PropTypes.func,
  onDomainLookupClick: PropTypes.func.isRequired,
  onSystemStatusClick: PropTypes.func.isRequired,
  onSupportClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
}

export default withRouter(NavbarDesktop)
