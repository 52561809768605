import React from "react"
import { Classes, Popover as BasePopover } from "@blueprintjs/core"

export function Popover(props) {
  if (props.content === null) {
    return props.children
  }

  return (
    <BasePopover
      popoverClassName={
        props.contentSizing ? Classes.POPOVER_CONTENT_SIZING : undefined
      }
      content={props.content}
      interactionKind={props.interactionKind}
      position={props.position}
      minimal={props.minimal}
      fill={props.fill}
      isOpen={props.open}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      onInteraction={props.onInteraction}
      onOpened={props.onOpened}
      onClose={props.onClose}
      onClosed={props.onClosed}
    >
      {props.children}
    </BasePopover>
  )
}
