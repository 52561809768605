export function parseLocation(location, history) {
  if (location === "../") {
    return history.location.pathname.split("/").slice(0, -1).join("/")
  }

  let nextLocation = location.replace(
    "$",
    history.location.pathname === "/" ? "" : history.location.pathname,
  )

  return nextLocation
}
