import React from "react"
import { Classes, Text as BaseText } from "@blueprintjs/core"
import cx from "classnames"
import "./Text.css"

export function Text(props) {
  return (
    <BaseText
      className={cx({
        "Text": true,
        "Text--normal": props.normal,
        "text-center": props.align === "center",
        [Classes.INTENT_DANGER]: props.intent === "danger",
        [Classes.TEXT_MUTED]: props.muted,
        [Classes.TEXT_LARGE]: props.size === 3,
        [Classes.TEXT_SMALL]: props.size === 1,
        [Classes.TEXT_DISABLED]: props.disabled,
      })}
      tagName={props.inline ? "span" : "p"}
      ellipsize={props.ellipsize}
    >
      {props.children}
    </BaseText>
  )
}

Text.Separator = function TextSeparator() {
  return <>&nbsp;&bull;&nbsp;</>
}
