import callAPI from "callAPI"

export default async (values, id) => {
  let block_page = {
    organization_id: localStorage.getItem("orgId"),
    name: values.name,
    block_bypass: values.block_bypass,
    block_bypass_password: values.block_bypass_password,
    block_org_name: values.organizationName,
    block_email_addr: values.noticeEmail,
    block_logo_uuid: values.logo,
    block_redirect_url: values.block_redirect_url,
  }

  if (
    !localStorage.getItem("organization.isMSPSubAccount") &&
    !localStorage.getItem("organization.isStandardOrg")
  ) {
    block_page = {
      ...block_page,
      is_global: values.is_global,
    }
  }

  return await callAPI({
    path: `/block_pages/${id}`,
    method: "PUT",
    body: {
      block_page,
    },
  })
}
