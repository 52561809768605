import get from "lodash/get"
import sortBy from "lodash/sortBy"
import {
  LOAD_USER_AGENTS,
  CHANGE_USER_AGENTS_SEARCH_TEXT_FILTER,
  CHANGE_USER_AGENTS_STATUS_FILTER,
  CHANGE_ALL_USER_AGENTS,
  SELECT_AGENT,
  CHANGE_USER_AGENT_PAGE,
  CHANGE_USER_AGENT,
  CHANGE_MULTIPLE_USER_AGENTS,
  TOGGLE_EDIT_MULTIPLE_USER_AGENTS_FORM,
  UPDATE_MULTIPLE_USER_AGENTS_REQUEST,
  REMOVE_USER_AGENT,
  REMOVE_MULTIPLE_AGENTS,
  SHOW_CONFIRM_DELETE_CLIENT_MODAL,
  HIDE_CONFIRM_DELETE_CLIENT_MODAL,
  DELETE_USER_AGENT_REQUEST,
  LOAD_AGENTS_STATUS,
  UNSELECT_ALL_USER_AGENTS,
  UPDATE_SELECTED_TAGS,
  SORT_USER_AGENTS,
} from "action-types/userAgents"

const initialState = {
  data: [],
  requestStatus: null,
  searchText: "",
  searchTag: "",
  searchStatus: "",
  status: "all",
  selectedUserAgentsIds: [],
  allUserAgentsSelected: false,
  currentPage: 1,
  totalPages: 0,
  size: 100,
  editMultipleUserAgents: false,
  editMultipleUserAgentsStatus: null,
  showConfirmDeleteModal: false,
  idToBeDeleted: null,
  deleteUserAgentStatus: null,
  activeAgentsIds: [],
  activeAgentsStatus: null,
  selectedTags: [],
  sortedBy: {
    type: "",
    direction: "",
  },
}

const agentsState = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_TAGS:
      return {
        ...state,
        selectedTags: action.payload,
      }
    case LOAD_USER_AGENTS:
      return {
        ...state,
        requestStatus: action.payload.status,
        data: action.payload.data || state.data,
        totalPages: action.payload.totalPages || state.totalPages,
        searchText: action.payload.searchText || "",
        searchTag: action.payload.searchTag || "",
        searchStatus: action.payload.searchStatus || "",
        currentPage: action.payload.currentPage || state.currentPage,
        fetching: action.payload.fetching || false,
      }
    case CHANGE_USER_AGENTS_SEARCH_TEXT_FILTER:
      return {
        ...state,
        searchText: action.payload,
      }
    case CHANGE_USER_AGENTS_STATUS_FILTER:
      return {
        ...state,
        status: action.payload,
      }
    case CHANGE_ALL_USER_AGENTS:
      return {
        ...state,
        allUserAgentsSelected: action.payload.allChecked,
        selectedUserAgentsIds: action.payload.allChecked
          ? action.payload.visibleIds
          : [],
      }
    case SELECT_AGENT:
      const selectedUserAgent = state.selectedUserAgentsIds.find(
        (agentId) => agentId === action.payload,
      )
      const newSelectedUserAgents = selectedUserAgent
        ? state.selectedUserAgentsIds.filter(
            (agentId) => agentId !== action.payload,
          )
        : [...state.selectedUserAgentsIds, action.payload]

      return {
        ...state,
        selectedUserAgentsIds: newSelectedUserAgents,
        allUserAgentsSelected:
          newSelectedUserAgents.length === state.data.length,
      }
    case CHANGE_USER_AGENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case CHANGE_USER_AGENT:
      return {
        ...state,
        data: state.data.map((agent) =>
          agent.id === action.payload.id ? action.payload : agent,
        ),
      }
    case CHANGE_MULTIPLE_USER_AGENTS:
      return {
        ...state,
        data: state.data.map((agent) => {
          const updatedAgent = action.payload.find(
            (userAgent) => userAgent.id === agent.id,
          )

          return updatedAgent || agent
        }),
      }
    case TOGGLE_EDIT_MULTIPLE_USER_AGENTS_FORM:
      return {
        ...state,
        editMultipleUserAgents: action.payload,
        editMultipleUserAgentsStatus: null,
      }
    case UPDATE_MULTIPLE_USER_AGENTS_REQUEST:
      return {
        ...state,
        editMultipleUserAgentsStatus: action.payload,
        selectedUserAgentsIds:
          action.payload === "success" ? [] : state.selectedUserAgentsIds,
        allUserAgentsSelected:
          action.payload === "success" ? false : state.allUserAgentsSelected,
      }
    case REMOVE_USER_AGENT:
      return {
        ...state,
        data: state.data.filter((agent) => agent.id !== action.payload),
        selectedUserAgentsIds: state.selectedUserAgentsIds.filter(
          (agentId) => agentId !== action.payload,
        ),
        idToBeDeleted: null,
      }
    case REMOVE_MULTIPLE_AGENTS:
      const filteredData = state.data.filter(
        (agent) => action.payload.indexOf(agent.id) === -1,
      )

      return {
        ...state,
        data: filteredData,
        allUserAgentsSelected:
          filteredData.length === 0 ? false : state.allUserAgentsSelected,
        selectedUserAgentsIds: state.selectedUserAgentsIds.filter(
          (agentId) => action.payload.indexOf(agentId) === -1,
        ),
        idToBeDeleted: null,
      }
    case SHOW_CONFIRM_DELETE_CLIENT_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: true,
        idToBeDeleted: action.payload,
      }
    case HIDE_CONFIRM_DELETE_CLIENT_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: false,
        idToBeDeleted: null,
      }
    case DELETE_USER_AGENT_REQUEST:
      return {
        ...state,
        deleteUserAgentStatus: action.payload.status,
        idToBeDeleted: action.payload.agentId,
      }
    case LOAD_AGENTS_STATUS:
      return {
        ...state,
        activeAgentsIds:
          action.payload.activeAgentsIds || state.activeAgentsIds,
        activeAgentsStatus: action.payload.status,
      }
    case UNSELECT_ALL_USER_AGENTS:
      return {
        ...state,
        selectedUserAgentsIds: [],
      }
    case SORT_USER_AGENTS:
      return {
        ...state,
        sortedBy: action.payload,
      }
    default:
      return state
  }
}

export const selectFilteredAgents = (state) => {
  const { data, searchText } = state.userAgents
  const lowerCaseSearchText = searchText.toLowerCase()

  return data
    .map((agent) => ({
      ...agent,
      site: agent.network_id
        ? state.networks.data.find((network) => network.id === agent.network_id)
        : null,
    }))
    .filter(
      (agent) =>
        (agent.friendly_name || agent.hostname || "")
          .toLowerCase()
          .includes(lowerCaseSearchText) ||
        get(agent, "site.name", "").toLowerCase().includes(lowerCaseSearchText),
    )
}

export const selectVisibleAgents = (state) => {
  const {
    data,
    searchText,
    status,
    selectedUserAgentsIds,
    activeAgentsIds,
    selectedTags,
  } = state.userAgents
  const lowerCaseSearchText = searchText.toLowerCase()
  let regex
  let userAgents = [...data]

  if (selectedTags.length) {
    regex = "^("

    selectedTags.forEach((tag, i, array) => {
      if (array.length - 1 === i) {
        regex += `${tag.value})$`

        return
      }

      regex += `${tag.value}|`
    })

    userAgents = sortBy(userAgents, (agent) => !agent.tags)
  }

  return userAgents
    .map((agent) => ({
      ...agent,
      selected: selectedUserAgentsIds.includes(agent.id),
      site: agent.network_id
        ? state.networks.data.find(
            (network) => Number(network.id) === Number(agent.network_id),
          )
        : null,
      policy: state.policies.data.find(
        (policy) => policy.id === agent.policy_id,
      ),
      filteringSchedule: state.filteringSchedules.data.find(
        (filteringSchedule) =>
          filteringSchedule.id === agent.scheduled_policy_id,
      ),
      blockPage: state.blockPages.data.find(
        (blockPage) => blockPage.id === agent.block_page_id,
      ),
    }))
    .filter((agent) => {
      let textMatches = !searchText
      let statusMatches = false

      if (
        (agent.friendly_name || agent.hostname || "")
          .toLowerCase()
          .includes(lowerCaseSearchText)
      ) {
        textMatches = true
      } else if (
        get(agent, "site.name", "").toLowerCase().includes(lowerCaseSearchText)
      ) {
        textMatches = true
      }

      if (status === "all") {
        statusMatches = true
      } else if (status === "active") {
        statusMatches =
          activeAgentsIds.includes(agent.id) && agent.status === "active"
      } else if (status === "inactive") {
        statusMatches =
          !activeAgentsIds.includes(agent.id) && agent.status === "active"
      } else if (status === "uninstalled") {
        statusMatches = agent.status.includes("uninstalled")
      }

      return textMatches && statusMatches
    })
    .filter((agent) => {
      const tags = agent.tags ? agent.tags.trim().split(",") : []

      return !tags.length || tags.some((tag) => new RegExp(regex).test(tag))
    })
}

export const selectVisibleUserAgentsIds = (state) =>
  selectVisibleAgents(state)
    .filter(
      (agent) =>
        state.userAgents.allUserAgentsSelected ||
        state.userAgents.selectedUserAgentsIds.includes(agent.id),
    )
    .map((agent) => agent.id)

export const selectTotalActiveAgents = (state) =>
  state.userAgents.data.filter(
    (agent) =>
      agent.status.indexOf("active") > -1 &&
      state.userAgents.activeAgentsIds.indexOf(agent.id) !== -1,
  ).length

export const selectTotalInactiveAgents = (state) =>
  state.userAgents.data.filter(
    (agent) =>
      state.userAgents.activeAgentsIds.indexOf(agent.id) === -1 &&
      agent.status.indexOf("uninstalled") === -1,
  ).length

export const selectTotalUninstalledAgents = (state) =>
  state.userAgents.data.filter(
    (agent) => agent.status.indexOf("uninstalled") > -1,
  ).length

export default agentsState
