import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export default function AppTitle(props) {
  const location = useLocation()

  useEffect(() => {
    let title = null

    if (location.pathname.includes("/overview")) {
      title = "Overview"
    }

    // Policies
    if (location.pathname.includes("/policies")) {
      title = "Policies"
    }

    if (location.pathname.includes("/global-policies")) {
      title = "Global Policies"
    }

    if (
      location.pathname.includes("/policies/filtering") ||
      location.pathname.includes("/global-policies/filtering")
    ) {
      title = "Filtering"
    }

    if (location.pathname.includes("/policies/schedules")) {
      title = "Filtering Schedules"
    }

    if (location.pathname.includes("/policies/block-pages")) {
      title = "Block Pages"
    }

    if (
      location.pathname.includes("/policies/universal-lists") ||
      location.pathname.includes("/global-policies/universal-lists")
    ) {
      title = "Universal Lists"
    }

    // Deployments
    if (location.pathname.includes("/deployments")) {
      title = "Deployments"
    }

    if (location.pathname.includes("/deployments/sites")) {
      title = "Sites"
    }

    if (location.pathname.includes("/deployments/user-agents")) {
      title = "Roaming Clients"
    }

    if (location.pathname.includes("/deployments/collections")) {
      title = "Collections"
    }

    if (location.pathname.includes("/deployments/users")) {
      title = "Users"
    }

    if (location.pathname.includes("/deployments/sync-tools")) {
      title = "Sync Tools"
    }

    if (location.pathname.includes("/deployments/lan-proxy")) {
      title = "Relays"
    }

    // Reporting
    if (location.pathname.includes("/reporting")) {
      title = "Reporting"
    }

    if (location.pathname.includes("/single-sign-on")) {
      title = "Single Sign-On"
    }

    if (location.pathname.includes("/reporting/insights")) {
      title = "Insights Reporting"
    }

    if (location.pathname.includes("/reporting/appaware")) {
      title = "Reporting AppAware"
    }

    if (location.pathname.includes("/reporting/data-explorer")) {
      title = "Data Explorer"
    }

    if (location.pathname.includes("/reporting/total-requests")) {
      title = "Total Requests"
    }

    if (location.pathname.includes("/reporting/queries-per-second")) {
      title = "Queries Per Second"
    }

    if (location.pathname.includes("/reporting/most-active-sites")) {
      title = "Most Active Sites"
    }

    if (location.pathname.includes("/reporting/most-active-organizations")) {
      title = "Most Active Organizations"
    }

    if (location.pathname.includes("/reporting/top-requests")) {
      title = "Top Requests"
    }

    if (location.pathname.includes("/reporting/threats")) {
      title = "Threats"
    }

    if (location.pathname.includes("/reporting/alerts")) {
      title = "Alerts"
    }

    if (location.pathname.includes("/account")) {
      title = "Account"
    }

    // MSP || Organization
    if (location.pathname.includes("/settings")) {
      if (localStorage.getItem("owned_msp_id")) {
        title = "MSP"
      } else {
        title = "Organization"
      }
    }

    if (location.pathname.includes("/settings/profile")) {
      title = "Profile"
    }

    if (location.pathname.includes("/settings/users")) {
      title = "Users"
    }

    if (location.pathname.includes("/settings/whitelabel")) {
      title = "Whitelabel"
    }

    if (location.pathname.includes("/settings/subscriptions")) {
      title = "Subscriptions"
    }

    if (location.pathname.includes("/settings/billing-info")) {
      title = "Billing"
    }

    if (location.pathname.includes("/settings/settings")) {
      title = "Settings"
    }

    if (location.pathname.includes("/integrations")) {
      title = "Integrations"
    }

    if (location.pathname.includes("/tools")) {
      title = "Tools"
    }

    if (location.pathname.includes("/tools/ssl-certificate")) {
      title = "SSL Certificate"
    }

    if (location.pathname.includes("/tools/query-log")) {
      title = "Query Log"
    }

    if (location.pathname.includes("/tools/policy-audit-log")) {
      title = "Policy Audit Log"
    }

    if (location.pathname.includes("/tools/data-export")) {
      title = "Data Export"
    }

    if (location.pathname.includes("/tools/domain-lookup")) {
      title = "Domain Report"
    }

    if (location.pathname.includes("/tools/color-palette")) {
      title = "Color Palette"
    }

    if (title) {
      document.title = `${title} | ${
        localStorage.getItem("whitelabel.name") || "DNSFilter"
      }`
    } else {
      document.title = `${
        localStorage.getItem("whitelabel.name") || "DNSFilter"
      }`
    }
  }, [location.pathname])

  return props.children
}
