import {
  UPDATE_FILTERING_SCHEDULE_SEARCH_TEXT,
  UPDATE_FILTERING_SCHEDULE_STATUS_FILTER,
} from "action-types/filteringSchedules"

export const filteringScheduleFilters = (
  state = {
    text: "",
    status: "all",
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_FILTERING_SCHEDULE_SEARCH_TEXT:
      return {
        ...state,
        text: action.payload,
      }
    case UPDATE_FILTERING_SCHEDULE_STATUS_FILTER:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}

export default filteringScheduleFilters
