import React from "react"
import PropTypes from "prop-types"
import { Button as BlueprintButton, Intent } from "@blueprintjs/core"
import classNames from "classnames"

const Button = ({
  type,
  intent,
  leftIcon,
  minimal,
  fullWidth,
  style,
  onClick,
  small,
  large,
  disabled,
  children,
  className,
  ...rest
}) => (
  <BlueprintButton
    className={classNames(
      "bp3-button",
      minimal && "bp3-minimal",
      fullWidth && "bp3-fill",
      small && "bp3-small",
      large && "bp3-large",
      className,
    )}
    type={type}
    intent={intent && Intent[intent.toUpperCase()]}
    icon={leftIcon}
    style={{
      ...style,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {children}
  </BlueprintButton>
)

Button.propTypes = {
  type: PropTypes.oneOf(["submit", "button"]).isRequired,
  intent: PropTypes.oneOf(["none", "primary", "success", "warning", "danger"]),
  leftIcon: PropTypes.string,
  minimal: PropTypes.bool,
  fullWidth: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
}

Button.defaultProps = {
  type: "button",
  small: false,
  large: false,
  disabled: false,
  children: null,
  className: null,
}

export default Button
