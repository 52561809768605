import React from "react"
import { createSelector } from "reselect"
import sortBy from "lodash/sortBy"
import orderBy from "lodash/orderBy"

import {
  LOAD_ORGANIZATIONS,
  LOAD_MSP_OWNER_REQUEST,
  LOAD_MSP_OWNER_SUCCESS,
  LOAD_MSP_OWNER_FAILURE,
  ORGANIZATION_REMOVE_UPDATE_PAYMENT_MESSAGE,
  UPDATE_ORGANIZATION,
} from "action-types/organization"

export const organizations = (
  state = {
    data: [],
    status: null,
    mspStatus: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_ORGANIZATIONS: {
      const { data, status } = action.payload

      return {
        ...state,
        data: data
          .map((organization) => ({
            ...organization,
            isMSP: organization.feature_flags.indexOf("msp") !== -1,
            isMSPCustomer: !!organization.managed_by_msp_id,
          }))
          .filter((organziation) => !organziation.canceled),
        status,
      }
    }

    case UPDATE_ORGANIZATION:
      return {
        ...state,
        ...action.payload,
      }
    case LOAD_MSP_OWNER_REQUEST:
      return {
        ...state,
        mspStatus: "request",
      }
    case LOAD_MSP_OWNER_SUCCESS:
      return {
        ...state,
        mspStatus: "success",
      }
    case LOAD_MSP_OWNER_FAILURE:
      return {
        ...state,
        fetchingMspInfo: false,
        mspStatus: "failure",
      }
    case ORGANIZATION_REMOVE_UPDATE_PAYMENT_MESSAGE:
      return {
        ...state,
        data: state.data.map((organization) => ({
          ...organization,
          update_payment:
            Number(organization.id) === Number(action.payload.organizationId)
              ? false
              : organization.update_payment,
        })),
      }
    default:
      return state
  }
}

export const getParentOrganizationId = (state) => {
  const subOrg = state.organizations.data.find(
    (org) => Number(org.id) === Number(localStorage.getItem("orgId")),
  )
  const parent = state.organizations.data.find(
    (org) => Number(org.owned_msp_id) === Number(subOrg.managed_by_msp_id),
  )

  return parent ? parent.id : null
}

export const getMSPCustomerOrganizationNames = (state) =>
  state.organizations.data
    .filter(
      ({ managed_by_msp_id, feature_flags }) =>
        !!managed_by_msp_id && !feature_flags.includes("MSP"),
    )
    .map(({ name }) => name)

export const getMspOrganizations = (state) =>
  sortBy(state.organizations.data, (org) => org.name + org.id)
    .filter(
      ({ managed_by_msp_id, feature_flags }) =>
        !!managed_by_msp_id && !feature_flags.includes("MSP"),
    )
    .map((org) => org)

export const getMspOrganizationsWithMsp = (state) =>
  sortBy(state.organizations.data, (org) => org.name + org.id).map((org) => org)

export const getOrganizationsMenu = (orgs, onOrganizationClick) => {
  const createOrganizationMenuItem = (
    { id, name, owned_msp_id, globalMSP },
    menu,
  ) => {
    const isActive = globalMSP
      ? localStorage.getItem("owned_msp_id") === String(owned_msp_id)
      : !localStorage.getItem("owned_msp_id") &&
        String(id) === localStorage.getItem("orgId")

    return {
      id: `${menu}.${id}`,
      intent: isActive ? "primary" : null,
      text: name,
      name,
      label: isActive ? <span className="bp3-icon bp3-icon-tick" /> : null,
      onClick: () =>
        !isActive && onOrganizationClick(id, owned_msp_id, globalMSP),
      icon: globalMSP ? "globe-network" : "office",
    }
  }

  const activeOrganizations = orderBy(
    orgs,
    [(org) => org.name.toLowerCase()],
    ["asc"],
  ).filter((org) => !org.canceled)
  const hasAtleastOneMSP = activeOrganizations.find(
    ({ owned_msp_id }) => owned_msp_id,
  )
  if (hasAtleastOneMSP) {
    const hasAtLeastOneStandaloneOrg = activeOrganizations.some(
      ({ owned_msp_id, managed_by_msp_id }) =>
        !owned_msp_id && !managed_by_msp_id,
    )
    const filteredMspOrgs = activeOrganizations.filter(
      ({ owned_msp_id }) => owned_msp_id,
    )
    const filteredMspCustomers = activeOrganizations.filter(
      ({ owned_msp_id, managed_by_msp_id }) =>
        !owned_msp_id && managed_by_msp_id,
    )
    const filteredStandaloneOrgs = activeOrganizations.filter(
      ({ owned_msp_id, managed_by_msp_id }) =>
        !owned_msp_id && !managed_by_msp_id,
    )

    return [
      {
        title: "MSP Dashboard",
      },
      ...(filteredMspOrgs.length > 0
        ? filteredMspOrgs.map((org) =>
            createOrganizationMenuItem(
              {
                ...org,
                globalMSP: true,
              },
              "msp-dashboard",
            ),
          )
        : [
            {
              text: "Nothing matches your criteria.",
            },
          ]),
      {
        title: "MSP Organizations",
      },
      ...([...filteredMspOrgs, ...filteredMspCustomers].length > 0
        ? [...filteredMspOrgs, ...filteredMspCustomers].map((org) =>
            createOrganizationMenuItem(org, "msp-orgs"),
          )
        : [
            {
              text: "Nothing matches your criteria.",
            },
          ]),
      ...(hasAtLeastOneStandaloneOrg
        ? [
            {
              title: "Organizations",
            },
            ...(filteredStandaloneOrgs.length > 0
              ? filteredStandaloneOrgs.map((org) =>
                  createOrganizationMenuItem(org, "orgs"),
                )
              : [
                  {
                    text: "Nothing matches your criteria.",
                  },
                ]),
          ]
        : []),
    ]
  }

  return [
    {
      title: "Organization",
    },
    ...(orgs.length
      ? orgs.map((org) => createOrganizationMenuItem(org, "org"))
      : [
          {
            text: "Nothing matches your criteria.",
          },
        ]),
  ]
}

export const organizationMenuItems = (state, props) =>
  createSelector(
    () => state.organizations.data,
    (orgs) => getOrganizationsMenu(orgs, props),
  )

export default organizations
