import callAPI from "callAPI"
import sortCategories from "utils/sortCategories"

export default () =>
  callAPI({
    path: "/categories/all",
  }).then((data) =>
    sortCategories(
      data.data.map((value) => ({
        id: Number(value.id),
        name: value.attributes.name,
        description: value.attributes.description,
        security: value.attributes.security,
      })),
    ),
  )
