import { LOAD_BLOCK_PAGES } from "action-types/blockPages"

export const blockPages = (
  state = {
    data: [],
    status: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_BLOCK_PAGES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const getBlockPageById = (blockPages, blockPageId) =>
  blockPages.find(({ id }) => Number(blockPageId) === Number(id))

export const getFilteredBlockPages = (state) => {
  if (!state.blockPagesFilters.text) {
    return state.blockPages.data
  }

  return state.blockPages.data.filter(
    ({ name }) =>
      name.toLowerCase().indexOf(state.blockPagesFilters.text.toLowerCase()) >
      -1,
  )
}

export default blockPages
