import { storageKeysToClean } from "../consts/auth"
import { DISTPLATFORM_STORAGE_KEYS } from "../_Infinity/DistributorPlatform/constants"

export function cleanupSessionKeys({ ignore = [] } = {}) {
  storageKeysToClean
    .filter((key) => !ignore.includes(key))
    .forEach((key) => {
      // If our app is being rendered within an iframe we should not clean up
      // the distributor params
      const iframe = window.parent !== window
      if (iframe && DISTPLATFORM_STORAGE_KEYS.includes(key)) {
        return
      }

      localStorage.removeItem(key)
    })
}
