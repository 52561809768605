import parseUrl from "parse-url"

export const getFqdn = (domain) => {
  const parsedUrl = parseUrl(
    domain.match(/^(http(s?)):\/\//) ? domain : `http://${domain}`,
  )

  return parsedUrl.resource
}

export default getFqdn
