import React from "react"
import { render } from "react-dom"
import Provider from "./provider"
import MaintenancePage from "views/MaintenancePage"
import { FocusStyleManager } from "@blueprintjs/core"

// TODO: move to index.css
import "normalize.css"
import "@blueprintjs/core/lib/css/blueprint.css"
import "@blueprintjs/icons/lib/css/blueprint-icons.css"
import "@blueprintjs/select/lib/css/blueprint-select.css"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css"
import "./css/bootstrap.css"
import "./css/bootstrap-extend.css"
import "./css/site.css"
import "./css/utils.css"
import "./index.css"
import "./_Infinity/index.css"
import "./views/policies/filtering/CategoryButton.css"
import "./components/Switch.css"
import "./components/Radio.css"
import moment from "moment"
import "moment/min/locales.min.js"
import { datadogRum } from "@datadog/browser-rum"
import { sendRequest } from "_Infinity/Core/Utils/sendRequest"

window.trackAnalyticsEvent = (event, properties = {}) => {
  window.analytics?.track(event, properties)

  sendRequest({
    method: "post",
    path: "/segment",
    payload: {
      event,
      properties: {
        ...properties,
        organization_id:
          properties.organization_id || localStorage.getItem("orgId"),
      },
    },
  })
}

if (process.env.REACT_APP_DATADOG_RUM_APP_ID) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    env: process.env.REACT_APP_DATADOG_RUM_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  })
  datadogRum.startSessionReplayRecording()
}

var locale = window.navigator.userLanguage || window.navigator.language
moment.locale(locale)
FocusStyleManager.onlyShowFocusOnTabs()

const rootElement =
  process.env.REACT_APP_MAINTENANCE === "true" ? (
    <MaintenancePage />
  ) : (
    <Provider />
  )

render(rootElement, document.getElementById("root"))
