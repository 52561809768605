import {
  UPDATE_NETWORK_SEARCH_TEXT,
  UPDATE_NETWORK_STATUS_FILTER,
} from "action-types/networks"

export const networkFilters = (
  state = {
    text: "",
    status: "all",
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_NETWORK_SEARCH_TEXT:
      return {
        ...state,
        text: action.payload,
      }
    case UPDATE_NETWORK_STATUS_FILTER:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}

export default networkFilters
