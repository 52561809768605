import { LOAD_ALL_CATEGORIES } from "action-types/categories"

export const allCategories = (
  state = {
    data: [],
    status: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_ALL_CATEGORIES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default allCategories
