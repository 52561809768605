import React from "react"
import PropTypes from "prop-types"
import { Dialog, Classes } from "@blueprintjs/core"

const Modal = (props) => (
  <Dialog
    icon={props.icon}
    title={props.title}
    isOpen={props.isOpen}
    canEscapeKeyClose={props.canEscapeKeyClose}
    canOutsideClickClose={props.canOutsideClickClose}
    style={props.style}
    onClose={props.onClose}
    noBody={props.noBody}
  >
    <div className={props.noBody ? null : Classes.DIALOG_BODY}>
      {props.children}
    </div>
  </Dialog>
)

Modal.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.node,
  isOpen: PropTypes.bool,
  canEscapeKeyClose: PropTypes.bool,
  canOutsideClickClose: PropTypes.bool,
  style: PropTypes.object,
  onClose: PropTypes.func,
  noBody: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Modal.defaultProps = {
  canEscapeKeyClose: true,
  canOutsideClickClose: true,
}

export default Modal
