import React, { useContext } from "react"
import { connect } from "react-redux"
import { openChargebee } from "_Infinity/Core/Components/ChargebeePortal"
import { Icon } from "@blueprintjs/core"
import { SessionContext } from "_v2/Modules/Auth"
import "./BillingDetailsAlert.css"

import { loadOrgBilling } from "actions/billing"

function BillingDetailsAlert({ orgBillingData, loadOrgBilling }) {
  const loadOrgBillingRef = React.useRef(loadOrgBilling)

  React.useEffect(() => {
    loadOrgBillingRef.current = loadOrgBilling
  })

  React.useEffect(() => {
    loadOrgBillingRef.current()
  }, [])

  const session = useContext(SessionContext)
  const mainOrganization = session.getMainOrganization()

  const hasCard = !!orgBillingData?.last4
  const hasBillingAddress = !!mainOrganization.billingAddress

  if (
    !hasCard ||
    hasBillingAddress ||
    session.billingDetailsAlertDismissed ||
    localStorage.getItem("organization.isMSPSubAccount") ||
    session.activeOrganization?.isDistributor
  ) {
    return null
  }

  const updateClick = () =>
    openChargebee({
      sectionType: window.Chargebee.getPortalSections().ADDRESS,
      onClose: () => {
        session.sendDismissBillingDetailsAlert()
      },
    })

  const closeClick = (e) => {
    e.preventDefault()
    session.sendDismissBillingDetailsAlert()
  }

  return (
    <div className="BillingDetailsAlert">
      <p>
        Please{" "}
        <a href="#billing" onClick={updateClick}>
          update your billing details
        </a>{" "}
        to avoid an interruption in service.
      </p>
      <a href="#close" onClick={closeClick}>
        <Icon icon="cross" />
      </a>
    </div>
  )
}

const mapStateToProps = ({ billing }) => ({
  orgBillingData: billing.orgBillingData,
})

const mapDispatchToProps = (dispatch) => ({
  loadOrgBilling: () => dispatch(loadOrgBilling()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetailsAlert)
