import React from "react"
import { Switch as BaseSwitch } from "@blueprintjs/core"

export function Switch(props) {
  return (
    <BaseSwitch
      defaultChecked={props.defaultChecked}
      inline={props.inline}
      large={props.size === 3}
      checked={props.on}
      disabled={props.disabled}
      onChange={(e) => {
        props.onChange(e.target.checked)
      }}
    >
      <span
        style={{
          fontSize: 14,
        }}
      >
        {props.children}
      </span>
    </BaseSwitch>
  )
}
