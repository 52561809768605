import React from "react"
import PropTypes from "prop-types"
import { Block } from "jsxstyle"
import getLogo, { getLogoAlt } from "utils/getLogo"

const Logo = ({ maxWidth, marginBottom }) => (
  <Block
    className="img-responsive"
    component="img"
    props={{
      src: getLogo(),
      alt: getLogoAlt(),
    }}
    maxWidth={maxWidth}
    marginBottom={marginBottom}
    marginLeft="auto"
    marginRight="auto"
  />
)

Logo.propTypes = {
  maxWidth: PropTypes.number,
  marginBottom: PropTypes.number,
}

export default Logo
