import React from "react"
import PropTypes from "prop-types"
import { Intent, Toast } from "@blueprintjs/core"

const NotificationList = ({ notifications, hideNotification }) => (
  <div className="growl">
    {notifications.map(({ id, type, title, message, icon }) => (
      <Toast
        intent={
          type === "danger"
            ? Intent.DANGER
            : type === "success"
            ? Intent.SUCCESS
            : Intent.PRIMARY
        }
        message={
          <span>
            {icon && (
              <span>
                <i
                  className={`fa fa-${icon}`}
                  style={{
                    marginRight: ".5rem",
                  }}
                />
              </span>
            )}
            {title && (
              <span>
                <strong>{title}</strong>
                <br />
              </span>
            )}
            {message}
          </span>
        }
        onDismiss={() => hideNotification(id)}
      />
    ))}
  </div>
)

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  hideNotification: PropTypes.func.isRequired,
}

export default NotificationList
