import callAPI from "callAPI"
import get from "lodash/get"

export default ({
  id: networkId,
  organization: organization_id,
  name,
  address,
  allowAllPolicies,
  policyIds,
  block_page_id,
  scheduled_policy_id,
  policies,
  ipAddresses,
  localDomains,
  localResolvers,
  isLegacyVpnActive: is_legacy_vpn_active,
  externalId,
}) => {
  let policy_ids

  // TODO refactor policy ids value when updating networks
  if (allowAllPolicies || scheduled_policy_id) {
    policy_ids = []
  } else if (policyIds) {
    policy_ids = policyIds.split(",")
  } else if (policies) {
    policy_ids = policies.map((policy) => policy.id)
  } else {
    policy_ids = []
  }

  let local_domains = localDomains

  if (localDomains?.length === 1 && localDomains[0] === "") {
    local_domains = []
  }

  let local_resolvers = localResolvers

  if (localResolvers?.length === 1 && localResolvers[0] === "") {
    local_resolvers = []
  }

  return callAPI({
    method: "PUT",
    path: `/networks/${networkId}`,
    body: {
      network: {
        organization_id,
        name,
        physical_address: get(address, "address", address) || "",
        allow_all_policies: allowAllPolicies,
        policy_ids: policy_ids,
        block_page_id,
        scheduled_policy_id: policy_ids.length
          ? null
          : scheduled_policy_id
          ? Number(scheduled_policy_id)
          : null,
        ip_addresses_attributes: ipAddresses,
        local_domains,
        local_resolvers,
        is_legacy_vpn_active,
        external_id: externalId,
      },
    },
  })
}
