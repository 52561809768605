import { PasswordPolicy } from "password-sheriff"
import { charsets } from "password-sheriff/lib/rules/containsAtLeast"

const passwordPolicy = new PasswordPolicy({
  length: {
    minLength: 8,
  },
  containsAtLeast: {
    atLeast: 3,
    expressions: [
      charsets.lowerCase,
      charsets.upperCase,
      charsets.numbers,
      charsets.specialCharacters,
    ],
  },
})

const isPassword = (value) => {
  if (!value) {
    return null
  }

  if (value.length < 8) {
    return "Should contain at least 8 characters in length"
  }

  if (!passwordPolicy.check(value)) {
    return "Should contain at least 3 of the following 4 types of characters: lower case letters (a-z), upper case letters (A-Z), numbers (i.e. 0-9), special characters (e.g. !@#$%^&*)"
  }

  return null
}

export default isPassword
