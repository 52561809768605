import React, { cloneElement } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Row, Col } from "jsxstyle"

const renderFooter = (help, error) => {
  if (help && error) {
    return (
      <div className="bp3-form-helper-text">
        <Row>
          <Col flexGrow={1}>{error}</Col>
          <Col flexGrow={1} textAlign="right">
            {help}
          </Col>
        </Row>
      </div>
    )
  }

  if (error || help) {
    return <div className="bp3-form-helper-text">{error || help}</div>
  }

  return null
}

const FormGroup = ({
  label,
  help,
  error,
  marginBottom,
  children,
  className,
  isRequired,
}) => (
  <div
    className={classNames("bp3-form-group", error && "bp3-intent-danger")}
    style={{
      marginBottom,
    }}
  >
    {label && (
      <label className="bp3-label-custom">
        {label}
        {isRequired && (
          <span
            style={{
              color: "red",
              marginLeft: 3,
            }}
          >
            *
          </span>
        )}
      </label>
    )}
    <div className="bp3-form-content">
      {cloneElement(children, {
        className: className || classNames(error && "bp3-intent-danger"),
      })}
      {renderFooter(help, error)}
    </div>
  </div>
)

FormGroup.propTypes = {
  label: PropTypes.node,
  help: PropTypes.node,
  error: PropTypes.node,
  marginBottom: PropTypes.number.isRequired,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
}

FormGroup.defaultProps = {
  marginBottom: 15,
  isRequired: false,
}

export default FormGroup
