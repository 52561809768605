import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  BILLING_DATA_REQUEST,
  BILLING_DATA_SUCCESS,
  BILLING_DATA_FAILURE,
  LOAD_ORG_BILLING_DATA_REQUEST,
  LOAD_ORG_BILLING_DATA_SUCCESS,
  LOAD_ORG_BILLING_DATA_FAILURE,
  UPDATE_BILLING_TOKEN_REQUEST,
  UPDATE_BILLING_TOKEN_FAILURE,
} from "action-types/billing"

const initialState = {
  user: null,
  data: null,
  error: "",
  isLoading: false,
  isLoadingUser: false,
  isInTrial: false,
  statedNetworkCount: 0,
  isEducation: false,
  orgBillingData: null,
  loadingOrgBilling: false,
  orgBillingError: false,
  updateTokenError: false,
  billingToken: null,
}

export const billing = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        isLoadingUser: true,
      }
    case USER_SUCCESS: {
      const currentOrg = action.payload.organizations.find(
        (org) => Number(org.id) === Number(localStorage.getItem("orgId")),
      )

      return {
        ...state,
        isLoadingUser: false,
        user: action.payload,
        isInTrial: currentOrg.trial_days > 0,
        statedNetworkCount: currentOrg.stated_network_count,
        isEducation: currentOrg.industry === "17",
      }
    }

    case USER_FAILURE:
      return {
        ...state,
        isLoadingUser: false,
        error: action.payload,
      }
    case BILLING_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case BILLING_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case BILLING_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case LOAD_ORG_BILLING_DATA_REQUEST:
      return {
        ...state,
        loadingOrgBilling: true,
        orgBillingError: false,
      }
    case LOAD_ORG_BILLING_DATA_SUCCESS:
      return {
        ...state,
        loadingOrgBilling: false,
        orgBillingData: action.payload,
      }
    case LOAD_ORG_BILLING_DATA_FAILURE:
      return {
        ...state,
        loadingOrgBilling: false,
        orgBillingError: true,
      }
    case UPDATE_BILLING_TOKEN_REQUEST:
      return {
        ...state,
        loadingOrgBilling: true,
        updateTokenError: false,
        billingToken: null,
      }
    case UPDATE_BILLING_TOKEN_FAILURE:
      return {
        loadingOrgBilling: false,
        updateTokenError: true,
        billingToken: action.payload,
      }
    default:
      return state
  }
}

export default billing
