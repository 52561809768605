import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { historyPropTypes, authPropTypes } from "consts/prop-types"
import Navbar from "components/Navbar"
import { withRouter } from "react-router-dom"
import DomainLookupModal from "views/tools/domain-lookup/DomainLookupModal"
import createUrl from "utils/createUrl"
import { sendRequest } from "_Infinity/Core/Utils/sendRequest"

class Header extends Component {
  state = {
    showDomainLookup: false,
    shouldShowSwitchAccountOption: false,
  }

  componentDidMount() {
    sendRequest({
      method: "post",
      path: `/authenticate?limit_organizations=1&skip_user_checks=false`,
    }).catch((error) => {
      if (Array.isArray(error.data)) {
        this.setState({ shouldShowSwitchAccountOption: true })
      }
    })
  }

  getOrganizations = () =>
    this.props.organizations &&
    (this.props.organizations.length > 1 ||
      this.props.organizations.find(({ isMSP }) => isMSP))
      ? this.props.organizations
      : null

  getCurrentOrganization() {
    if (!this.props.organizations) {
      return null
    }

    return this.props.organizations.find((organization) =>
      localStorage.getItem("owned_msp_id")
        ? String(organization.owned_msp_id) ===
          localStorage.getItem("owned_msp_id")
        : String(organization.id) === localStorage.getItem("orgId"),
    )
  }

  handleSelectOrganization = (organizationId, owned_msp_id, globalMSP) => {
    if (globalMSP) {
      localStorage.setItem("owned_msp_id", owned_msp_id)
    } else {
      localStorage.removeItem("owned_msp_id")
    }

    localStorage.setItem("orgId", organizationId)
    localStorage.setItem("orgChanged", true)

    const newPath = createUrl(
      `/${window.location.pathname.split("/").slice(3).join("/")}`,
    )

    this.props.history.push(
      newPath.indexOf("/invoice") > -1
        ? "/"
        : parseInt(window.location.pathname.split("/")[2], 10)
        ? newPath
        : window.location.pathname,
    )
    window.location.reload()
  }

  toggleDomainLookup = () => {
    this.setState((prevState) => ({
      showDomainLookup: !prevState.showDomainLookup,
    }))
  }

  handleAccountClick = () => this.props.history.push("/account")

  handleSwitchAccountClick = () => (window.location.href = "/select-account")

  handleDomainLookupClick = () => this.toggleDomainLookup()

  handleSystemStatusClick = () => window.open("https://status.dnsfilter.com")

  handleProductRoadmapClick = () =>
    window.open("http://feedback.dnsfilter.com/")

  handleSupportClick = () => {
    try {
      if (
        localStorage.getItem("whitelabel.email") &&
        !localStorage.getItem("organization.isMSP")
      ) {
        window.location.href = `mailto:${localStorage.getItem(
          "whitelabel.email",
        )}`

        return
      }

      if (
        localStorage.getItem("whitelabel.domain") &&
        localStorage.getItem("organization.isMSP")
      ) {
        window.location.href = `mailto:support@dnsfilter.com`

        return
      }

      if (window.zE) {
        window.zE("webWidget", "show")
        window.zE("webWidget", "open")
      }
    } catch (err) {
      window.location.href = "mailto:support@dnsfilter.com"
    }
  }

  handleLogoutClick = () => this.props.logout()

  render() {
    const { auth } = this.props

    return (
      <div>
        <Navbar
          user={auth.data}
          userPicture={auth.userPicture}
          currentOrganization={this.getCurrentOrganization()}
          organizations={this.getOrganizations()}
          onOrganizationClick={this.handleSelectOrganization}
          onAccountClick={this.handleAccountClick}
          onSwitchAccountClick={
            this.state.shouldShowSwitchAccountOption
              ? this.handleSwitchAccountClick
              : undefined
          }
          onDomainLookupClick={this.handleDomainLookupClick}
          onSystemStatusClick={this.handleSystemStatusClick}
          onProductRoadmapClick={this.handleProductRoadmapClick}
          onSupportClick={this.handleSupportClick}
          onLogoutClick={this.handleLogoutClick}
          history={this.props.history}
        />
        <DomainLookupModal
          isOpen={this.state.showDomainLookup}
          onClose={this.toggleDomainLookup}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ organizations }) => ({
  organizations: organizations.data,
})

Header.propTypes = {
  auth: authPropTypes.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: historyPropTypes.isRequired,
  logout: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, null)(Header))
