import callAPI from "callAPI"

export default (userAgent, type) => {
  const tags = !userAgent.tags
    ? []
    : typeof userAgent.tags === "string"
    ? userAgent.tags.split(",").map((tag) => tag.trim())
    : userAgent.tags

  return callAPI({
    method: "PATCH",
    path: `/user_agents/${userAgent.id}`,
    type: type || "agent",
    body: {
      user_agent: {
        friendly_name: userAgent.friendly_name,
        status: userAgent.status,
        policy_id: userAgent.policy_id,
        scheduled_policy_id: userAgent.scheduled_policy_id,
        block_page_id: userAgent.block_page_id,
        network_id: userAgent.network_id,
        tags,
      },
    },
  })
}
