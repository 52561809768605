import React from "react"
import Media from "react-media"
import { withRouter } from "react-router-dom"
import NavbarMobile from "./NavbarMobile"
import NavbarDesktop from "./NavbarDesktop"
import {
  useSupportDistributors,
  SupportDistributorsDrawer,
} from "./SupportDistributors"

import { useDistributorPlatform } from "_Infinity/DistributorPlatform/Hooks/useDistributorPlatform"

const Navbar = (props) => {
  const {
    isOpen,
    openSupportDistributors,
    closeSupportDistributors,
    showSupportInfo,
    data,
  } = useSupportDistributors()

  const platform = useDistributorPlatform()
  if (!platform.showNavbar) {
    return null
  }

  return (
    <>
      <Media query="(max-width: 799px)">
        {(matches) =>
          matches ? (
            <NavbarMobile
              {...props}
              showSupportDistributorsInfo={showSupportInfo}
              openSupportDistributors={openSupportDistributors}
            />
          ) : (
            <NavbarDesktop
              {...props}
              showSupportDistributorsInfo={showSupportInfo}
              openSupportDistributors={openSupportDistributors}
            />
          )
        }
      </Media>
      {showSupportInfo && (
        <SupportDistributorsDrawer
          isOpen={isOpen}
          handleClose={closeSupportDistributors}
          data={data}
        />
      )}
    </>
  )
}

export default withRouter(Navbar)
