import React from "react"
import queryString from "query-string"
import { DistributorPlatformContext } from "_Infinity/DistributorPlatform/Contexts/DistributorPlatformContext"
import Spinner from "_v2/Core/Components/Spinner"
import { getUserAndOrgData, __logout } from "actions/auth"
import {
  DISTPLATFORM_STORAGE_KEYS,
  DISTPLATFORM_TEMPLATES,
} from "_Infinity/DistributorPlatform/constants"

async function handleMspOrOrgChangeFromUrl() {
  const loggedAsMSP = !!localStorage.getItem("owned_msp_id")
  const didIChangeMyUrlFromMspToOrg =
    loggedAsMSP && window.location.pathname.includes("/organizations/")
  const didIChangeMyUrlFromOrgToMsp =
    !loggedAsMSP && window.location.pathname.includes("/msp/")
  const orgId = window.location.pathname.split("/")[2]

  if (didIChangeMyUrlFromMspToOrg) {
    await changeFromMspToOrg()
  }

  if (didIChangeMyUrlFromOrgToMsp) {
    await changeFromOrgToMsp()
  }

  async function changeFromMspToOrg() {
    if (!orgId) {
      return
    }

    localStorage.removeItem("owned_msp_id")
    localStorage.setItem("orgId", orgId)
  }

  async function changeFromOrgToMsp() {
    if (!orgId) {
      return
    }

    // In case of a MSP the orgId is actually the ownedMspId
    const ownedMspId = orgId

    // Now we need to find the actual orgId
    let actualOrgId = null

    try {
      const auth = {
        data: await getUserAndOrgData(),
      }

      actualOrgId = auth.data.organizations.find(
        (org) =>
          Number(org.owned_msp_id) === Number(ownedMspId) &&
          !org.managed_by_msp_id,
      )?.id
    } catch {
      // if something goes wrong we're not going to make any changes
    }

    if (ownedMspId && actualOrgId) {
      localStorage.setItem("owned_msp_id", ownedMspId)
      localStorage.setItem("orgId", actualOrgId)
    }
  }
}

function DistributorPlatformProvider(props) {
  const [ready, setReady] = React.useState(window.parent === window)

  React.useEffect(() => {
    if (window.parent === window) {
      // If we have distributor policies and we're not in an iframe it means
      // the user opened a new tab and we need to redirect them to the
      // distributor login page
      if (localStorage.getItem("distributor-policies")) {
        window.location.href =
          DISTPLATFORM_TEMPLATES[localStorage.getItem("distributor")].loginUrl
        return
      }

      DISTPLATFORM_STORAGE_KEYS.forEach((key) => localStorage.removeItem(key))

      return
    }

    const searchParams = queryString.parse(window.location.search)

    if (searchParams.distplatform) {
      localStorage.setItem("distributor", searchParams.distplatform)
    }

    function notifyReady() {
      if (localStorage.getItem("avoid-ready-event")) {
        localStorage.removeItem("avoid-ready-event")

        window.parent.postMessage(
          {
            ecosystem: {
              type: "getThirdPartyProfileIds",
              id: Date.now(),
            },
          },
          "*",
        )

        return
      }

      window.parent.postMessage(
        {
          ecosystem: {
            type: "readyToAuthenticate",
            id: Date.now(),
          },
        },
        "*",
      )
    }

    notifyReady()

    async function handleMessage(e) {
      const type = e.data?.ecosystem?.type
      const action = e.data?.ecosystem?.data?.action

      switch (type) {
        case "action":
          if (action !== "signout") {
            return
          }

          __logout()

          window.location.href = `/?distplatform=${localStorage.getItem(
            "distributor",
          )}`

          return

        case "getThirdPartyProfileIdsResult":
          let policies = e.data?.ecosystem?.data?.thirdPartyProfileIds

          // Policies received but user is not authenticated yet
          if (!localStorage.getItem("access_token")) {
            return
          }

          localStorage.setItem("distributor-policies", JSON.stringify(policies))

          await handleMspOrOrgChangeFromUrl()

          setReady(true)

          return

        case "authToken":
          const token = e.data?.ecosystem?.data?.authToken

          if (!token) {
            return
          }

          // If there's an existing session we need to sign out the user
          // and reload the app
          if (localStorage.getItem("access_token")) {
            setReady(false)

            __logout()
            localStorage.setItem("access_token", token)

            // New session started, reloading page
            localStorage.setItem("avoid-ready-event", "true")
            window.location.reload()

            return
          }

          localStorage.setItem("access_token", token)

          window.parent.postMessage(
            {
              ecosystem: {
                type: "getThirdPartyProfileIds",
                id: Date.now(),
              },
            },
            "*",
          )

          return

        default:
          return
      }
    }

    window.addEventListener("message", handleMessage, false)

    return () => window.removeEventListener("message", handleMessage, false)
  }, [])

  if (!ready) {
    return (
      <div
        style={{
          padding: 20,
        }}
      >
        <Spinner size={6} />
      </div>
    )
  }

  return (
    <DistributorPlatformContext.Provider
      value={
        DISTPLATFORM_TEMPLATES[localStorage.getItem("distributor")] ??
        DISTPLATFORM_TEMPLATES.default
      }
    >
      {props.children}
    </DistributorPlatformContext.Provider>
  )
}

export default DistributorPlatformProvider
