import { LOAD_AGENT_LOCAL_USERS } from "action-types/agentLocalUsers"

const initialState = {
  data: [],
  status: "",
}

const agentLocalUsers = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AGENT_LOCAL_USERS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default agentLocalUsers
