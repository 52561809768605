import React, { Component } from "react"
import queryString from "query-string"
import Fetch from "components/Fetch"
import ViewportCentered from "components/ViewportCentered"
import { Provider } from "react-redux"
import store from "./store"
import AppContainer from "views/app/AppContainer"
import Rollbar from "rollbar"
import IEBlockedPage from "views/IEBlockedPage"
import DistributorPlatformProvider from "_Infinity/DistributorPlatform/Providers/DistributorPlatformProvider"
import Spinner from "_v2/Core/Components/Spinner"
import cookieStorage from "utils/cookieStorage"

const searchParams = queryString.parse(window.location.search)
const domain =
  searchParams.domain ||
  (!window.location.host.match(
    /localhost|\.dnsfilter\.(com|app|cloud)|\.vix.docker/,
  )
    ? window.location.host
    : null)

const applyWhitelabel = (data) => {
  localStorage.setItem("whitelabel.name", data.name)
  localStorage.setItem("whitelabel.domain", domain)
  localStorage.setItem("whitelabel.customDomain", data.custom_domain)
  localStorage.setItem("whitelabel.logo", data.custom_logo)
  localStorage.setItem("whitelabel.email", data.customer_email)

  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link")
  link.type = "image/x-icon"
  link.rel = "shortcut icon"
  link.href = data.custom_favicon
  document.getElementsByTagName("head")[0].appendChild(link)
}

const resetWhitelabel = () => {
  localStorage.removeItem("whitelabel.name")
  localStorage.removeItem("whitelabel.domain")
  localStorage.removeItem("whitelabel.customDomain")
  localStorage.removeItem("whitelabel.logo")
  localStorage.removeItem("whitelabel.email")
}

/* eslint no-useless-escape: 0 */
const initThirdPartyScripts = () => {
  if (process.env.NODE_ENV !== "development") {
    const rollbarEnv = process.env.NODE_ENV || "unknown"

    window.rollbar = new Rollbar({
      accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
      verbose: false,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: rollbarEnv,
        client: {
          javascript: {
            code_version: process.env.REACT_APP_COMMIT_REF
              ? process.env.REACT_APP_COMMIT_REF
              : false,
            source_map_enabled: true,
            guess_uncaught_frames: true,
          },
        },
      },
    })
  }
}

function getIEVersion() {
  var sAgent = window.navigator.userAgent
  var Idx = sAgent.indexOf("MSIE")
  // If IE, return version number.
  if (Idx > 0)
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)), 10)
  if (!!navigator.userAgent.match(/Trident\/7\./))
    // If IE 11 then look for Updated user agent string.
    return 11

  return 0
}

class AppProvider extends Component {
  componentDidMount() {
    this.handleUTMParams()
    this.handlePlanParam()
    this.handleUnbounceParam()
  }

  handleUTMParams() {
    const utmParams = [
      "utm_source",
      "utm_campaign",
      "utm_medium",
      "utm_term",
      "utm_content",
      "dtm_source",
      "dtm_campaign",
      "dtm_medium",
      "dtm_term",
      "dtm_content",
    ]
    const query = queryString.parse(window.location.search)
    const shouldSkip = !utmParams
      .map((param) => query[param])
      .filter((value) => value).length
    if (shouldSkip) {
      return
    }

    const parsedUtmParams = utmParams.reduce(
      (result, param) =>
        !!query[param]
          ? {
              ...result,
              [param.replace("utm_", "").replace("dtm_", "")]: query[param],
            }
          : result,
      {},
    )

    cookieStorage.setItem(
      "dnsfilterUTMParams",
      String(queryString.stringify(parsedUtmParams).substr(0, 255)),
    )
  }

  handlePlanParam() {
    const query = queryString.parse(window.location.search)
    if (query.plan) {
      cookieStorage.setItem("plan", query.plan)
    }
  }

  handleUnbounceParam() {
    const query = queryString.parse(window.location.search)
    if (query.ub) {
      cookieStorage.setItem("ub", query.ub)
    }
  }

  render() {
    return (
      <DistributorPlatformProvider>
        <Fetch
          method="get"
          url="/site"
          params={{
            domain,
          }}
          noHeaders
          showAlertOnError={false}
          onData={(data) => {
            if (domain) {
              return applyWhitelabel(data)
            }

            resetWhitelabel()
            initThirdPartyScripts()
          }}
          onError={() => {
            resetWhitelabel()
            initThirdPartyScripts()
          }}
        >
          {({ isFetching, error }) => {
            if (isFetching) {
              return (
                <div
                  style={{
                    padding: 20,
                  }}
                >
                  <Spinner size={6} />
                </div>
              )
            }

            if (getIEVersion()) {
              return <IEBlockedPage />
            }

            if (domain && error) {
              return (
                <ViewportCentered padding={30}>
                  <p>
                    <strong>This site is not currently configured.</strong>{" "}
                    Contact your system administrator.
                  </p>
                </ViewportCentered>
              )
            }

            return (
              <Provider store={store}>
                <AppContainer />
              </Provider>
            )
          }}
        </Fetch>
      </DistributorPlatformProvider>
    )
  }
}

export default AppProvider
