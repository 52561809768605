export const LOAD_NETWORKS = "LOAD_NETWORKS"
export const LOAD_NETWORK_BY_ID = "LOAD_NETWORK_BY_ID"
export const LOAD_ACTIVE_NETWORKS = "LOAD_ACTIVE_NETWORKS"
export const UPDATE_NETWORK_SEARCH_TEXT = "UPDATE_NETWORK_SEARCH_TEXT"
export const UPDATE_NETWORK_STATUS_FILTER = "UPDATE_NETWORK_STATUS_FILTER"
export const CHANGE_NETWORK = "CHANGE_NETWORK"
export const LOAD_NETWORK_STATUS = "LOAD_NETWORK_STATUS"
export const UPDATE_SECRET_KEY = "UPDATE_SECRET_KEY"
export const SUBMITTING_NETWORK_FORM = "SUBMITTING_NETWORK_FORM"
export const TOGGLE_EDIT_MULTIPLE_NETWORKS_FORM =
  "TOGGLE_EDIT_MULTIPLE_NETWORKS_FORM"
export const CHANGE_ALL_NETWORKS = "CHANGE_ALL_NETWORKS"
export const SELECT_NETWORK = "SELECT_NETWORK"
export const UPDATE_MULTIPLE_NETWORKS_REQUEST =
  "UPDATE_MULTIPLE_NETWORKS_REQUEST"
export const CHANGE_MULTIPLE_NETWORKS = "CHANGE_MULTIPLE_NETWORKS"
export const SHOW_CONFIRM_NETWORK_DELETE_MODAL =
  "SHOW_CONFIRM_NETWORK_DELETE_MODAL"
export const HIDE_CONFIRM_NETWORK_DELETE_MODAL =
  "HIDE_CONFIRM_NETWORK_DELETE_MODAL"
export const CLEAR_SELECTED_NETWORKS = "CLEAR_SELECTED_NETWORKS"
export const TOGGLE_ADD_FORM = "TOGGLE_ADD_FORM"
export const ENABLE_USER_AGENT_AUTO_REGISTER = "ENABLE_USER_AGENT_AUTO_REGISTER"
export const UPDATE_NETWORK_IP = "UPDATE_NETWORK_IP"
