import React from "react"
import { NewAppContainer } from "components/NewAppContainer"
import { useQuery } from "react-query"
import { sendRequest } from "_Infinity/Core/Utils/sendRequest"
import jwtDecode from "jwt-decode"
import { usePartnerProgram } from "components/PartnerProgramContext"

// TODO: extract as an util
const getUserEmail = () => {
  try {
    return jwtDecode(localStorage.getItem("access_token"))[
      "https://dnsfilter.com/email"
    ]
  } catch {
    return null
  }
}

export const OnboardingModal = () => {
  const partnerProgram = usePartnerProgram()

  const [open, setOpen] = React.useState(false)

  useQuery(
    ["authenticate"],
    () =>
      sendRequest({
        method: "post",
        path: "/authenticate?limit_organizations=1&skip_user_checks=false",
      }).then((res) => res.data),
    {
      onSuccess: (data) => {
        if (
          !data.organizations[0].fetched_onboard_details &&
          !data.organizations[0].stated_distributor &&
          !data.organizations[0].canceled_at &&
          !data.organizations[0].will_cancel_at
        )
          setOpen(true)
      },
    },
  )

  if (open) {
    return (
      <NewAppContainer
        title="Onboarding"
        path="/onboarding"
        asModal
        onMessage={(type, event) => {
          switch (type) {
            case "START_GUIDED_TOUR": {
              window.trackAnalyticsEvent("Setup v2 Guided Tour", {
                user_id: localStorage.getItem("userId"),
                organization_id: localStorage.getItem("orgId"),
              })
              window.userflow?.start(
                process.env.REACT_APP_USERFLOW_GUIDED_TOUR_ID,
              )
              setOpen(false)
              break
            }

            case "CLOSE_ONBOARDING": {
              window.trackAnalyticsEvent("Setup v2 Skipped Tour", {
                user_id: localStorage.getItem("userId"),
                organization_id: localStorage.getItem("orgId"),
              })
              setOpen(false)
              break
            }

            case "ONBOARDING_STEP_CHANGED": {
              switch (event.state.step) {
                case 2: {
                  window.trackAnalyticsEvent("Setup v2 Get Started", {
                    user_id: localStorage.getItem("userId"),
                    organization_id: localStorage.getItem("orgId"),
                  })
                  break
                }

                case 3: {
                  window.trackAnalyticsEvent("Setup v2 Industry", {
                    user_id: localStorage.getItem("userId"),
                    organization_id: localStorage.getItem("orgId"),
                    stated_industry: event.industries.find(
                      (industry) => industry.id === event.state.industryId,
                    ).label,
                  })
                  break
                }

                case 4: {
                  window.trackAnalyticsEvent("Setup v2 Org Name", {
                    user_id: localStorage.getItem("userId"),
                    organization_id: localStorage.getItem("orgId"),
                    organization_name: event.state.organizationName,
                  })
                  break
                }

                case 5: {
                  window.trackAnalyticsEvent("Setup v2 User Count", {
                    user_id: localStorage.getItem("userId"),
                    organization_id: localStorage.getItem("orgId"),
                    signup_employee_count: event.state.employeeCount,
                  })
                  break
                }

                case 6: {
                  partnerProgram.setShowPartnerProgram(
                    event.state.industryId === "7" /* ISP */ ||
                      event.state.industryId === "18" /* MSP */,
                  )
                  window.trackAnalyticsEvent("Setup v2 Use Case", {
                    user_id: localStorage.getItem("userId"),
                    organization_id: localStorage.getItem("orgId"),
                    use_case: event.state.useCase,
                  })
                  window.analytics?.identify(localStorage.getItem("userId"), {
                    email: getUserEmail(),
                    organization_id: localStorage.getItem("orgId"),
                    organization_name: event.state.organizationName,
                    stated_industry: event.industries.find(
                      (industry) => industry.id === event.state.industryId,
                    ).label,
                    user_count: event.state.employeeCount,
                    use_case: event.state.useCase,
                  })
                  break
                }

                default: {
                  break
                }
              }
              break
            }

            default: {
              break
            }
          }
        }}
      />
    )
  }

  return null
}
