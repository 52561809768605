import { FETCHING, SUCCEEDED, FAILED } from "action-types/status"

const actionCreator = (type, payload = {}, status) => ({
  type,
  payload: {
    ...payload,
    status,
  },
})

export const actionCreatorFetch = (type) => (payload) =>
  actionCreator(type, payload, FETCHING)
export const actionCreatorSuccess = (type) => (payload) =>
  actionCreator(type, payload, SUCCEEDED)
export const actionCreatorFailure = (type) => (payload) =>
  actionCreator(type, payload, FAILED)
