import { DISTPLATFORM_STORAGE_KEYS } from "../_Infinity/DistributorPlatform/constants"

export const storageKeysToClean = [
  "orgId",
  "orgName",
  "userId",
  "organization.firstTrafficSent",
  "org_ids",
  "organization.isMSP",
  "organization.allowCreateNetwork",
  "organization.showBilling",
  "organization.readOnly",
  "owned_msp_id",
  "mfa_token",
  "access_token",
  "refresh_token",
  "associate.authenticator_type",
  "associate.barcode_uri",
  "associate.secret",
  "associate.recovery_codes",
  "use_mfa",
  "reactivation.token",
  "reactivation.orgId",
  "zendeskEnabled",
  "impersonation",
  "fetchingLogin",
  "selected_account",
  ...DISTPLATFORM_STORAGE_KEYS,
]

export const cancelReasons = [
  "Time: Didn't have time to trial",
  "Testing: Just testing / not ready",
  "Pricing: Too high",
  "Competition: Went with another solution (please specify)",
  "Issues: Performance / reliability related issues",
  "Issues: False positive / blocked too much",
  "Issues: Roaming Client issues",
  "Reporting: Need better reporting",
  "Integrations: Need more integrations",
  "Support: Support problems",
  "Features: Missing Active Directory integration",
  "Features: Other missing features (please specify)",
  "Distributor: Purchased through DNSF Distributor",
  "Other (please specify)",
]

export const cancelReasonsAndSubReasons = [
  {
    id: "business changed",
    display:
      "Business Changed (e.g. sold business, business acquired, closed business)",
    specify: true,
  },
  {
    id: "didn't have enough time with the product",
    display: "Didn't Have Enough Time with the Product",
    specify: true,
  },
  {
    id: "missing product feature",
    display: "Missing Product Feature",
    specify: true,
  },
  {
    id: "moved to a different solution",
    display: "Moved to a Different Solution",
    subReasons: [
      { id: "cisco umbrella", display: "Cisco Umbrella (formerly OpenDNS)" },
      { id: "bitdefender", display: "Bitdefender" },
      { id: "cloudflare", display: "Cloudflare" },
      { id: "fortinet", display: "Fortinet" },
      { id: "infoblox", display: "Infoblox" },
      { id: "microsoft defender", display: "Microsoft Defender" },
      { id: "nextdns", display: "NextDNS" },
      { id: "todyl", display: "Todyl" },
      { id: "webroot", display: "Webroot" },
      { id: "webtitan", display: "WebTitan" },
      { id: "zscaler", display: "Zscaler" },
      { id: "zorus", display: "Zorus" },
      { id: "other", display: "Other", specify: true },
    ],
  },
  {
    id: "no relationship established with dnsfilter",
    display:
      "No Relationship Established with DNSFilter (e.g. never heard from DNSFilter account representative)",
    specify: true,
  },
  {
    id: "not ready to purchase",
    display: "Not Ready to Purchase",
    specify: true,
  },
  {
    id: "pricing",
    display: "Pricing",
    subReasons: [
      { id: "not seeing the value", display: "Not Seeing the Value" },
      { id: "plans are unclear", display: "Plans are Unclear" },
      { id: "too difficult", display: "Too Difficult" },
      { id: "too expensive", display: "Too Expensive" },
    ],
  },
  {
    id: "product issues",
    display: "Product Issues",
    subReasons: [
      { id: "blocking incorrectly", display: "Blocking Incorrectly" },
      { id: "performance issues", display: "Performance Issues" },
      { id: "roaming clients", display: "Roaming Clients" },
      { id: "other", display: "Other", specify: true },
    ],
  },
  {
    id: "still using dnsfilter, but with another supplier/company",
    display:
      "Still Using DNSFilter, but with Another Supplier/Company (e.g. Pax8)",
    subReasons: [
      { id: "pax8", display: "Pax8" },
      { id: "n-able", display: "N-able" },
      { id: "other", display: "Other", specify: true },
    ],
  },
  { id: "support issues", display: "Support Issues", specify: true },
  { id: "other", display: "Other", specify: true },
]
