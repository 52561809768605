import callAPI from "callAPI"

export default (token) =>
  callAPI({
    method: "POST",
    path: "/billing",
    body: {
      organization_id: localStorage.getItem("orgId"),
      payment_token: token.id,
    },
  })
