import React from "react"
import PropTypes from "prop-types"
import { Col } from "jsxstyle"

const ViewportCentered = ({ padding, children }) => (
  <Col
    width="100wh"
    minHeight="100vh"
    padding={padding}
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Col>
)

ViewportCentered.propTypes = {
  padding: PropTypes.number,
}

export default ViewportCentered
