export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGIN_TWO_FACTOR = "LOGIN_TWO_FACTOR"
export const LOGOUT = "LOGOUT"
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"
export const GET_USER_PICTURE = "GET_USER_PICTURE"
export const ACCOUNT_REACTIVATION = "ACCOUNT_REACTIVATION"
