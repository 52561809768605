import { combineReducers } from "redux"
import auth from "reducers/auth"
import signup from "reducers/signup"
import billing from "reducers/billing"
import initBilling from "reducers/initBilling"
import invoices from "reducers/invoices"
import notifications from "reducers/notifications"
import categories from "reducers/categories"
import allCategories from "reducers/allCategories"
import networks from "reducers/networks"
import network from "reducers/network"
import networkFilters from "reducers/networkFilters"
import ipAddresses from "reducers/ipAddresses"
import activeNetworks from "reducers/activeNetworks"
import filters from "reducers/filters"
import organizations from "reducers/organizations"
import msp from "reducers/msp"
import userAgents from "reducers/userAgents"
import lanProxies from "reducers/lanProxies"
import lanSubnets from "reducers/lanSubnets"
import subscriptions from "reducers/subscriptions"
import overview from "reducers/overview"
import policies from "reducers/filteringPolicies"
import policyFilters from "reducers/filteringPolicyFilters"
import policyFormCategoriesSearchText from "reducers/filteringPolicyFormCategoriesSearchText"
import filteringSchedules from "reducers/filteringSchedules"
import filteringScheduleFilters from "reducers/filteringScheduleFilters"
import blockPages from "reducers/blockPages"
import blockPagesFilters from "reducers/blockPagesFilters"
import queryLogs from "reducers/queryLogs"
import agentLocalUsers from "reducers/agentLocalUsers"
import userAgentsLocal from "reducers/userAgentsLocal"
import policyAuditLogs from "reducers/policyAuditLogs"
import users from "reducers/users"

const rootReducer = combineReducers({
  auth,
  signup,
  notifications,
  categories,
  allCategories,
  network,
  networks,
  networkFilters,
  ipAddresses,
  userAgents,
  lanProxies,
  lanSubnets,
  agentLocalUsers,
  userAgentsLocal,
  activeNetworks,
  organizations,
  msp,
  policies,
  policyFilters,
  blockPagesFilters,
  blockPages,
  policyFormCategoriesSearchText,
  filteringSchedules,
  filteringScheduleFilters,
  queryLogs,
  policyAuditLogs,
  filters,
  overview,
  users,
  billing,
  initBilling,
  subscriptions,
  invoices,
})

export default (state, action) => {
  const appState =
    action.type === "LOGOUT"
      ? undefined
      : {
          ...state,
        }

  return rootReducer(appState, action)
}
