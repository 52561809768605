import {
  LOAD_POLICY_AUDIT_LOGS,
  LOAD_ALL_POLICIES,
  CHANGE_POLICY_AUDIT_LOG_PAGE,
  CHANGE_SELECTED_POLICY_ID,
} from "action-types/policyAuditLog"
import { FETCHING } from "action-types/status"
import get from "lodash/get"
import queryString from "query-string"

const initialState = {
  status: FETCHING,
  allPoliciesStatus: null,
  page: 1,
  size: 100,
  totalPages: null,
  data: [],
  allPolicies: [],
  policyId: null,
  policyLabel: null,
  included: [],
}

export const queryLogs = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_POLICY_AUDIT_LOGS:
      const links = action.payload.links
      let totalPages = state.totalPages

      if (links) {
        const last = queryString.parse(links.last)

        totalPages = Number(last["page[number]"] ?? 1)
      }

      return {
        ...state,
        status: action.payload.status,
        data: get(action.payload, "data", state.data),
        totalPages,
        included: action.payload.included,
      }
    case LOAD_ALL_POLICIES:
      return {
        ...state,
        allPoliciesStatus: action.payload.status,
        allPolicies: action.payload.data,
      }
    case CHANGE_SELECTED_POLICY_ID:
      return {
        ...state,
        policyId: action.payload.policyId,
        policyLabel: action.payload.policyLabel,
      }
    case CHANGE_POLICY_AUDIT_LOG_PAGE:
      return {
        ...state,
        page: action.payload.page,
      }
    default:
      return state
  }
}

export default queryLogs
