import { createStore, compose, applyMiddleware } from "redux"
import thunkMiddleware from "redux-thunk"
import reducers from "reducers"

const composeWithDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
)

export default store
