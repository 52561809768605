import React from "react"
import { Classes, H1 } from "@blueprintjs/core"
import cx from "classnames"
import "./Heading.css"

export function Heading(props) {
  return (
    <H1
      className={cx(
        {
          Heading: true,
          [Classes.TEXT_MUTED]: props.muted,
        },
        props.className,
      )}
      style={{
        fontSize: `var(--font-size--${(props.size ?? 2) + 1})`,
      }}
    >
      {props.children}
    </H1>
  )
}
