import {
  UPDATE_SECOND_TO_UPDATE,
  RESET_UPDATE_COUNT,
  INCREASE_UPDATE_COUNT,
} from "action-types/overview"

const UPDATE_INTERVAL = 60

export const overview = (
  state = {
    updateCount: 0,
    secondsBeforeNextUpdate: UPDATE_INTERVAL,
  },
  action,
) => {
  switch (action.type) {
    case INCREASE_UPDATE_COUNT:
      return {
        ...state,
        updateCount: state.updateCount + 1,
        secondsBeforeNextUpdate: UPDATE_INTERVAL,
      }
    case UPDATE_SECOND_TO_UPDATE:
      return {
        ...state,
        secondsBeforeNextUpdate: state.secondsBeforeNextUpdate - 1,
      }
    case RESET_UPDATE_COUNT:
      return {
        updateCount: 0,
        secondsBeforeNextUpdate: UPDATE_INTERVAL,
      }
    default:
      return state
  }
}

export default overview
