import { LOAD_CATEGORIES } from "action-types/categories"

export const categories = (
  state = {
    data: [],
    status: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_CATEGORIES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default categories
