import { LOAD_NETWORK_STATUS } from "action-types/networks"

export const activeNetworks = (
  state = {
    activeNetworkIds: [],
    status: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_NETWORK_STATUS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default activeNetworks
