import { LOAD_USERS } from "action-types/users"
import get from "lodash/get"

const initialState = {
  status: null,
  data: [],
}

export const queryLogs = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return {
        ...state,
        status: action.payload.status,
        data: get(action.payload, "data", state.data),
      }
    default:
      return state
  }
}

export default queryLogs
