export const LOAD_LAN_SUBNETS = "LOAD_LAN_SUBNETS"
export const CHANGE_LAN_SUBNETS_SEARCH_TEXT_FILTER =
  "CHANGE_LAN_SUBNETS_SEARCH_TEXT_FILTER"
export const CHANGE_LAN_SUBNET = "CHANGE_LAN_SUBNET"
export const CHANGE_LAN_SUBNET_PAGE = "CHANGE_LAN_SUBNET_PAGE"
export const ADD_LAN_SUBNET = "ADD_LAN_SUBNET"
export const REMOVE_LAN_SUBNET = "REMOVE_LAN_SUBNET"
export const SHOW_CONFIRM_DELETE_SUBNET_MODAL =
  "SHOW_CONFIRM_DELETE_SUBNET_MODAL"
export const HIDE_CONFIRM_DELETE_SUBNET_MODAL =
  "HIDE_CONFIRM_DELETE_SUBNET_MODAL"
export const DELETE_LAN_SUBNET_REQUEST = "DELETE_LAN_SUBNET_REQUEST"
export const TOGGLE_ADD_FORM = "TOGGLE_ADD_FORM"
