import { LOAD_NETWORK_BY_ID } from "action-types/networks"

export const network = (
  state = {
    data: [],
  },
  action,
) => {
  switch (action.type) {
    case LOAD_NETWORK_BY_ID:
      return {
        ...state,
        data: action.payload.data,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default network
