export const LOAD_USER_AGENTS = "LOAD_USER_AGENTS"
export const CHANGE_USER_AGENTS_SEARCH_TEXT_FILTER =
  "CHANGE_USER_AGENTS_SEARCH_TEXT_FILTER"
export const CHANGE_USER_AGENTS_STATUS_FILTER =
  "CHANGE_USER_AGENTS_STATUS_FILTER"
export const CHANGE_ALL_USER_AGENTS = "CHANGE_ALL_USER_AGENTS"
export const SELECT_AGENT = "SELECT_AGENT"
export const CHANGE_USER_AGENT = "CHANGE_USER_AGENT"
export const CHANGE_MULTIPLE_USER_AGENTS = "CHANGE_MULTIPLE_USER_AGENTS"
export const CHANGE_USER_AGENT_PAGE = "CHANGE_USER_AGENT_PAGE"
export const TOGGLE_EDIT_MULTIPLE_USER_AGENTS_FORM =
  "TOGGLE_EDIT_MULTIPLE_USER_AGENTS_FORM"
export const UPDATE_MULTIPLE_USER_AGENTS_REQUEST =
  "UPDATE_MULTIPLE_USER_AGENTS_REQUEST"
export const LOAD_AGENTS_STATUS = "LOAD_AGENTS_STATUS"
export const REMOVE_USER_AGENT = "REMOVE_USER_AGENT"
export const REMOVE_MULTIPLE_AGENTS = "REMOVE_MULTIPLE_AGENTS"
export const SHOW_CONFIRM_DELETE_CLIENT_MODAL =
  "SHOW_CONFIRM_DELETE_CLIENT_MODAL"
export const HIDE_CONFIRM_DELETE_CLIENT_MODAL =
  "HIDE_CONFIRM_DELETE_CLIENT_MODAL"
export const DELETE_USER_AGENT_REQUEST = "DELETE_USER_AGENT_REQUEST"
export const UNSELECT_ALL_USER_AGENTS = "UNSELECT_ALL_USER_AGENTS"
export const UPDATE_SELECTED_TAGS = "UPDATE_SELECTED_TAGS"
export const SORT_USER_AGENTS = "SORT_USER_AGENTS"
