import httpClient from "_v2/Core/Utils/httpClient"

export default () =>
  httpClient
    .get("/networks", {
      params: {
        "organization_id": localStorage.getItem("orgId"),
        "page[number]": 1,
        "page[size]": 1,
      },
    })
    .then((response) => response.data.data.length > 0)
