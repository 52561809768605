import { useMutation, useQueries } from "react-query"
import {
  fetchDataNetworksBulkUpdate,
  fetchDataNetworksBulkDestroy,
  fetchDataNetworksBulkCreate,
} from "./queries"
import {
  createNetworksBulk,
  updateNetworksBulk,
  deleteNetworksBulk,
} from "./mutations"

export const useDataNetworksBulkUpdateQueries = (bulkIds, options) =>
  useQueries(
    bulkIds.map((bulkId) => ({
      queryKey: ["useDataNetworksBulkUpdate", bulkId],
      queryFn: () => fetchDataNetworksBulkUpdate(bulkId),
      ...options,
    })),
  )

export const useDataNetworksBulkDestroyQueries = (bulkIds, options) =>
  useQueries(
    bulkIds.map((bulkId) => ({
      queryKey: ["useDataNetworksBulkDestroyQueries", bulkId],
      queryFn: () => fetchDataNetworksBulkDestroy(bulkId),
      ...options,
    })),
  )

export const useDataNetworksBulkCreateQueries = (bulkIds, options) =>
  useQueries(
    bulkIds.map((bulkId) => ({
      queryKey: ["useDataNetworksBulkCreateQueries", bulkId],
      queryFn: () => fetchDataNetworksBulkCreate(bulkId),
      ...options,
    })),
  )

export const useUpdateNetworksBulkMutation = (options) =>
  useMutation((requestBody) => updateNetworksBulk(requestBody), options)

export const useDeleteNetworksBulkMutation = (options) =>
  useMutation((requestBody) => deleteNetworksBulk(requestBody), options)

export const useCreateNetworksBulkMutation = (options) =>
  useMutation((requestBody) => createNetworksBulk(requestBody), options)
