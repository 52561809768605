import callAPI from "callAPI"

export default (org) =>
  callAPI({
    method: "PUT",
    path: `/organizations/${org.id}`,
    body: {
      organization: {
        gdpr: org.gdpr,
      },
    },
  })
