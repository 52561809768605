import React from "react"
import { TextArea as BaseTextArea } from "@blueprintjs/core"

export function TextArea(props) {
  return (
    <BaseTextArea
      intent={props.intent}
      rows={props.rows}
      value={props.value}
      placeholder={props.placeholder}
      large={props.size === 3}
      small={props.size === 1}
      fill={props.fill ?? true}
      growVertically={props.growVertically}
      readOnly={props.readOnly}
      disabled={props.disabled}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
      maxLength={props.maxLength}
    />
  )
}
