import Cookies from "js-cookie"

const cookies = Cookies.withAttributes({
  path: "/",
  domain:
    typeof window === "undefined" || window.location.hostname === "localhost"
      ? undefined
      : ".dnsfilter.com",
  sameSite: "Lax",
  secure: true,
})

const cookieStorage = {
  getItem(key) {
    return cookies.get(key)
  },
  setItem(key, value, expires /* session cookie by default */) {
    cookies.set(key, value, {
      expires,
    })
  },
  removeItem(key) {
    cookies.remove(key)
  },
}

export default cookieStorage
