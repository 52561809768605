import React from "react"

const PartnerProgramContext = React.createContext()

export const usePartnerProgram = () => React.useContext(PartnerProgramContext)

export const PartnerProgramProvider = (props) => {
  const [showPartnerProgram, setShowPartnerProgram] = React.useState(false)

  const value = React.useMemo(
    () => ({ showPartnerProgram, setShowPartnerProgram }),
    [showPartnerProgram, setShowPartnerProgram],
  )

  return (
    <PartnerProgramContext.Provider value={value}>
      {props.children}
    </PartnerProgramContext.Provider>
  )
}
