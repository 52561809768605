import React from "react"
import {
  Drawer as DrawerDefault,
  DrawerSize,
  Icon,
  IconSize,
} from "@blueprintjs/core"

import styles from "./Drawer.module.css"

export const Drawer = ({ isOpen, onClose, children }) => (
  <DrawerDefault onClose={onClose} isOpen={isOpen} size={DrawerSize.SMALL}>
    <div className={styles["drawer-close"]} onClick={onClose}>
      <Icon
        icon="cross"
        className={styles["drawer-close-icon"]}
        color="#FFFFFF"
        size={IconSize.LARGE}
      />
    </div>
    {children}
  </DrawerDefault>
)
