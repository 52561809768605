import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from "action-types/notifications"

export const notifications = (state = [], action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return [...state, action.payload]
    case HIDE_NOTIFICATION:
      return [...state].filter(({ id }) => id !== action.payload)
    default:
      return state
  }
}

export default notifications
