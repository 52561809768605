import { LOAD_USERS } from "action-types/users"
import loadUsersCall from "api/msp/loadUsersCall"
import { showNotificationWithTTL } from "actions/notifications"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"

export const loadUsersRequest = actionCreatorFetch(LOAD_USERS)

export const loadUsersSuccess = actionCreatorSuccess(LOAD_USERS)

export const loadUsersFailure = actionCreatorFailure(LOAD_USERS)

export const loadUsers = () => (dispatch) => {
  dispatch(loadUsersRequest())

  return loadUsersCall()
    .then(({ users }) => {
      dispatch(
        loadUsersSuccess({
          data: users,
        }),
      )
    })
    .catch(() => {
      dispatch(loadUsersFailure())

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "The was a problem while loading the users",
          message: "",
          icon: "exclamation-circle",
        }),
      )
    })
}
