import { connect } from "react-redux"
import Header from "views/app/Header"
import { logout } from "actions/auth"

function mapStateToProps({ auth }) {
  return {
    auth,
  }
}

const mapDispatchToProps = {
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
