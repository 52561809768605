import $ from "jquery"
import { toggleBgColors } from "utils/colorPalette"

export default (element, type) => {
  $(element).css("transition", "all 0.3s ease")
  $(element).css("background-color", toggleBgColors[type])

  setTimeout(() => {
    $(element).css("background-color", "")
    $(element).css("transition", "")
  }, 600)
}
