import React from "react"
import createUrl from "utils/createUrl"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"

import { loadNetworks, clearSelectedNetworks } from "actions/networks"
import { useBulkNetworks } from "_Infinity/BulkNetworks/Hooks/useBulkNetworks"
import {
  useDataNetworksBulkUpdateQueries,
  useDataNetworksBulkDestroyQueries,
  useDataNetworksBulkCreateQueries,
} from "views/deployments/networks/queries/hooks"

import { Toast } from "_Infinity/Core/Components/Toast"
import { Icon } from "@blueprintjs/core"
import Button from "components/Button"
import { Dialog } from "_Infinity/Core/Components/Dialog"

const ToastSuccessful = ({ label, removeId, data }) => (
  <Toast
    intent="success"
    open={true}
    icon={<Icon icon="tick" intent="primary" />}
    onClose={() => {
      removeId(data?.id)
    }}
  >
    <div>
      <strong>Bulk {label} completed</strong>
    </div>
    {data?.data?.successful}/{data?.data?.total} sites successfully {label}d.
  </Toast>
)

const ToastFailed = ({ label, removeId, data }) => {
  const location = useLocation()

  return (
    <Toast
      intent="danger"
      open={true}
      icon={<Icon icon="info-sign" intent="primary" />}
      onClose={() => {
        removeId(data.id)
        data?.data?.failed_ids &&
          console.error(
            `List of sites ids that failed to ${label}: `,
            data?.data?.failed_ids,
          )
        data?.data?.skipped_ids &&
          console.error(
            `List of sites ids that skipped to ${label}: `,
            data?.data?.skipped_ids,
          )
      }}
    >
      <div>
        <strong>Bulk {label} completed with errors</strong>
      </div>
      {Number(data?.data?.failed) + Number(data?.data?.skipped)}/
      {data?.data?.total} sites failed to {label}.
      {data?.data?.error_messages && (
        <div style={{ margin: "10px 0" }}>
          {data?.data?.error_messages.split(",").map((message) => (
            <>
              {message}
              <br />
            </>
          ))}
        </div>
      )}
      {!location.pathname.includes("/deployments/sites") && (
        <>
          <a href={createUrl("/deployments/sites")}>Click here</a> to see which
          sites failed.
        </>
      )}
    </Toast>
  )
}

function BulkNetworksNotification(props) {
  const [openBulkOperationDialog, setOpenBulkOperationDialog] =
    React.useState(false)
  const bulkNetworks = useBulkNetworks()

  const dataNetworksBulkUpdateQueries = useDataNetworksBulkUpdateQueries(
    bulkNetworks.updatedIds,
    {
      refetchInterval: 30_000,
      enabled: !!bulkNetworks.updatedIds,
      onSuccess: (data) => {
        if (data?.data?.total && !data?.data?.done) {
          setOpenBulkOperationDialog(true)
        }

        if (data?.id && !data?.data?.total && !data?.data?.done) {
          bulkNetworks.removeUpdatedId(data?.id)
        }

        if (data?.id && Number(data?.data?.total) === 0 && data?.data?.done) {
          bulkNetworks.removeUpdatedId(data?.id)
        }

        props.loadNetworks()
      },
    },
  )

  const dataNetworksBulkDestroyQueries = useDataNetworksBulkDestroyQueries(
    bulkNetworks.destroyedIds,
    {
      refetchInterval: 30_000,
      enabled: !!bulkNetworks.destroyedIds,
      onSuccess: (data) => {
        if (data?.data?.total && !data?.data?.done) {
          setOpenBulkOperationDialog(true)
        }

        if (data?.id && !data?.data?.total && !data?.data?.done) {
          bulkNetworks.removeDestroyedId(data?.id)
        }

        if (data?.id && Number(data?.data?.total) === 0 && data?.data?.done) {
          bulkNetworks.removeDestroyedId(data?.id)
        }

        if (data?.data?.done) {
          props.clearSelectedNetworks()
        }

        props.loadNetworks()
      },
    },
  )

  const dataNetworksBulkCreateQueries = useDataNetworksBulkCreateQueries(
    bulkNetworks.createdIds,
    {
      refetchInterval: 30_000,
      enabled: !!bulkNetworks.createdIds,
      onSuccess: (data) => {
        if (data?.data?.total && !data?.data?.done) {
          setOpenBulkOperationDialog(true)
        }

        if (data?.id && !data?.data?.total && !data?.data?.done) {
          bulkNetworks.removeCreatedId(data?.id)
        }

        if (data?.id && Number(data?.data?.total) === 0 && data?.data?.done) {
          bulkNetworks.removeCreatedId(data?.id)
        }

        props.loadNetworks()
      },
    },
  )

  const removeCreatedId = (id) => {
    props.loadNetworks()
    bulkNetworks.removeCreatedId(id)
    setOpenBulkOperationDialog(false)
  }

  const removeUpdatedId = (id) => {
    props.loadNetworks()
    bulkNetworks.removeUpdatedId(id)
    setOpenBulkOperationDialog(false)
  }

  const removeDestroyedId = (id) => {
    props.loadNetworks()
    bulkNetworks.removeDestroyedId(id)
    setOpenBulkOperationDialog(false)
  }

  const dataBulkSuccessful = (queries) =>
    queries &&
    queries.filter(
      (bulk) =>
        Number(bulk?.data?.data?.successful) > 0 && bulk?.data?.data?.done,
    )

  const dataBulkFailed = (queries) =>
    queries &&
    queries.filter(
      (bulk) =>
        (Number(bulk?.data?.data?.failed) > 0 ||
          Number(bulk?.data?.data?.skipped) > 0) &&
        bulk?.data?.data?.done,
    )

  return (
    <>
      <Dialog
        title="Bulk Operation In Progress"
        open={openBulkOperationDialog}
        onClose={() => {
          setOpenBulkOperationDialog(false)
        }}
        canOutsideClickClose={false}
        hideCloseButton={true}
      >
        Bulk operations may take several minutes to complete. You will receive
        an in app notification when your bulk operation has completed. You can
        safely dismiss this message and continue working.
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Button
            intent="primary"
            onClick={() => {
              setOpenBulkOperationDialog(false)
            }}
          >
            DISMISS
          </Button>
        </div>
      </Dialog>
      {/*--  Bulk Created ---*/}
      {!dataBulkFailed(dataNetworksBulkCreateQueries)?.length &&
        dataBulkSuccessful(dataNetworksBulkCreateQueries)?.map((bulk) => (
          <ToastSuccessful
            key={bulk?.dataUpdatedAt}
            label="create"
            removeId={removeCreatedId}
            data={bulk?.data}
          />
        ))}
      {dataBulkFailed(dataNetworksBulkCreateQueries)?.map((bulk) => (
        <ToastFailed
          key={bulk?.dataUpdatedAt}
          label="create"
          removeId={removeCreatedId}
          data={bulk?.data}
        />
      ))}

      {/*--  Bulk Updated ---*/}
      {!dataBulkFailed(dataNetworksBulkUpdateQueries)?.length &&
        dataBulkSuccessful(dataNetworksBulkUpdateQueries)?.map((bulk) => (
          <ToastSuccessful
            key={bulk?.dataUpdatedAt}
            label="update"
            removeId={removeUpdatedId}
            data={bulk?.data}
          />
        ))}
      {dataBulkFailed(dataNetworksBulkUpdateQueries)?.map((bulk) => (
        <ToastFailed
          key={bulk?.dataUpdatedAt}
          label="update"
          removeId={removeUpdatedId}
          data={bulk?.data}
        />
      ))}

      {/*--  Bulk Deleted ---*/}
      {!dataBulkFailed(dataNetworksBulkDestroyQueries)?.length &&
        dataBulkSuccessful(dataNetworksBulkDestroyQueries)?.map((bulk) => (
          <ToastSuccessful
            key={bulk?.dataUpdatedAt}
            label="delete"
            removeId={removeDestroyedId}
            data={bulk?.data}
          />
        ))}
      {dataBulkFailed(dataNetworksBulkDestroyQueries)?.map((bulk) => (
        <ToastFailed
          key={bulk?.dataUpdatedAt}
          label="delete"
          removeId={removeDestroyedId}
          data={bulk?.data}
        />
      ))}
    </>
  )
}

const mapDispatchToProps = {
  loadNetworks,
  clearSelectedNetworks,
}

export default connect(null, mapDispatchToProps)(BulkNetworksNotification)
