import axios from "axios"

let httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120_000,
})

httpClient.interceptors.request.use((config) => {
  let access_token = localStorage.getItem("access_token")

  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`
  }

  return config
})

export default httpClient
