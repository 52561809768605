import httpClient from "_v2/Core/Utils/httpClient"

export const fetchDataNetworksBulkUpdate = (bulkId) =>
  httpClient.get(`networks/bulk_update/${bulkId}`).then((response) => ({
    id: bulkId,
    data: response?.data,
  }))

export const fetchDataNetworksBulkDestroy = (bulkId) =>
  httpClient.get(`networks/bulk_destroy/${bulkId}`).then((response) => ({
    id: bulkId,
    data: response?.data,
  }))

export const fetchDataNetworksBulkCreate = (bulkId) =>
  httpClient.get(`networks/bulk_create/${bulkId}`).then((response) => ({
    id: bulkId,
    data: response?.data,
  }))
