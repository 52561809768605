import clipUserMetaData from "utils/clipUserMetaData"
import convertToQueryString from "utils/convertToQueryString"
import cookieStorage from "utils/cookieStorage"

export default (values) =>
  fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/signup`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      connection: "Username-Password-Authentication",
      email: values.email,
      password: values.password,
      given_name: values.firstName,
      family_name: values.lastName,
      name: `${values.firstName} ${values.lastName}`,
      user_metadata: clipUserMetaData({
        name: `${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
        organization: values.organization,
        adRef: cookieStorage.getItem("dnsfilterUTMParams") || "source=direct",
        useCase: values.useCase,
        misc: convertToQueryString({
          phone: values.phone,
          industry: values.industry,
          location: values.location,
        }),
        misc1: convertToQueryString({
          plan: cookieStorage.getItem("plan"),
          unbounce_data: cookieStorage.getItem("ub"),
        }),
      }),
    }),
  })
