import React, { useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import {
  historyPropTypes,
  organizationPropTypes,
  organizationsArrayPropTypes,
} from "consts/prop-types"
import { useFlags } from "launchdarkly-react-client-sdk"
import { hasAddOrgRole } from "utils/roles"
import Link from "components/Link"
import Button from "components/Button"
import Popover from "components/Popover"
import Menu from "components/Menu"
import Avatar from "components/Avatar"
import { withRouter } from "react-router-dom"
import getLogo, { getLogoAlt } from "utils/getLogo"
import createUrl from "utils/createUrl"
import { showSupportButton } from "utils/navbar"
import { organizationMenuItems } from "reducers/organizations"
import { SessionContext } from "_v2/Modules/Auth"

import { Tag } from "@blueprintjs/core"

const NavbarMobile = (props) => {
  let session = React.useContext(SessionContext)
  let mainOrganization = session.getMainOrganization()
  const ldFlags = useFlags()

  const hiddenIntegrations =
    !session.user.rolesByOrganization[mainOrganization.id].match(
      /owner|administrator/,
    ) ||
    (localStorage.getItem("whitelabel.name") &&
      mainOrganization.type === "suborganization")

  useEffect(() => {
    if (window.Canny && process.env.REACT_APP_CANNY_ID) {
      window.Canny("initChangelog", {
        appID: process.env.REACT_APP_CANNY_ID,
        position: "bottom",
        align: "right",
      })
    }
  }, [])

  const items = [
    !localStorage.getItem("organization.isMSPSubAccount") && {
      divider: true,
    },
    (!localStorage.getItem("organization.isMSPSubAccount") ||
      localStorage.getItem("user.isMSP")) && {
      icon: "search-text",
      text: "Documentation",
      onClick: () => {
        window.trackAnalyticsEvent("Support Menu Click - Documentation", {
          user_id: localStorage.getItem("userId"),
          organization_id: localStorage.getItem("orgId"),
        })
        window.open("https://help.dnsfilter.com/hc/en-us", "_blank")
      },
    },
    (!localStorage.getItem("organization.isMSPSubAccount") ||
      localStorage.getItem("user.isMSP")) &&
      !localStorage.getItem("whitelabel.domain") && {
        icon: "presentation",
        text: "View Tutorial",
        onClick: () => {
          window.trackAnalyticsEvent("Support Menu Click - View Tutorial", {
            user_id: localStorage.getItem("userId"),
            organization_id: localStorage.getItem("orgId"),
          })
        },
      },
    showSupportButton({
      session,
      ldFlags,
      showSupportDistributorsInfo: props.showSupportDistributorsInfo,
    }) && {
      icon: "label",
      text: "Open a ticket",
      onClick: session.activeOrganization?.isDistributor
        ? (...args) => {
            window.trackAnalyticsEvent("Support Menu Click - Open a Ticket", {
              user_id: localStorage.getItem("userId"),
              organization_id: localStorage.getItem("orgId"),
            })
            props.openSupportDistributors(...args)
          }
        : (...args) => {
            window.trackAnalyticsEvent("Support Menu Click - Open a Ticket", {
              user_id: localStorage.getItem("userId"),
              organization_id: localStorage.getItem("orgId"),
            })
            props.onSupportClick(...args)
          },
    },
    (!localStorage.getItem("organization.isMSPSubAccount") ||
      localStorage.getItem("user.isMSP")) && {
      icon: "pulse",
      text: "System Status",
      onClick: (...args) => {
        window.trackAnalyticsEvent("Support Menu Click - System Status", {
          user_id: localStorage.getItem("userId"),
          organization_id: localStorage.getItem("orgId"),
        })
        props.onSystemStatusClick(...args)
      },
    },
    {
      divider: true,
    },
    {
      icon: "geosearch",
      text: "Domain Report",
      onClick: (...args) => {
        window.trackAnalyticsEvent("Support Menu Click - Domain Report", {
          user_id: localStorage.getItem("userId"),
          organization_id: localStorage.getItem("orgId"),
        })
        props.onDomainLookupClick(...args)
      },
    },
    (!localStorage.getItem("organization.isMSPSubAccount") ||
      localStorage.getItem("user.isMSP")) && {
      icon: "code",
      text: "Feature Request",
      onClick: () => {
        window.trackAnalyticsEvent("Support Menu Click - Feature Request", {
          user_id: localStorage.getItem("userId"),
          organization_id: localStorage.getItem("orgId"),
        })
        props.history.push(createUrl("/feature-request"))
      },
    },
    (!localStorage.getItem("organization.isMSPSubAccount") ||
      localStorage.getItem("user.isMSP")) && {
      icon: "form",
      text: "View Debug Page",
      onClick: () => {
        window.trackAnalyticsEvent("Support Menu Click - View Debug Page", {
          user_id: localStorage.getItem("userId"),
          organization_id: localStorage.getItem("orgId"),
        })
        window.open("https://debug.dnsfilter.com/", "_blank")
      },
    },
    {
      divider: true,
    },
    {
      text: (
        <div key="a" className="avatar-mobile-menu-item">
          <Avatar userPicture={props.userPicture} size={22} />{" "}
          {localStorage.getItem("firstName")}
        </div>
      ),
      onClick: props.onAccountClick,
    },
    props.onSwitchAccountClick && {
      icon: "refresh",
      text: "Switch Account",
      onClick: props.onSwitchAccountClick,
    },
    {
      icon: "log-out",
      text: "Logout",
      onClick: props.onLogoutClick,
    },
  ].filter(Boolean)
  if (props.organizations) {
    items.unshift({
      icon: localStorage.getItem("owned_msp_id") ? "globe-network" : "office",
      text: props.currentOrganization ? props.currentOrganization.name : "",
      subitems: [
        ...props.organizationItems,
        ...(props.currentOrganization &&
        props.currentOrganization.isMSP &&
        hasAddOrgRole(session) &&
        !session.msp?.prevent_sub_org_creation
          ? [
              {
                icon: "add",
                text: "Add Organization",
                onClick: () =>
                  props.history.push(createUrl("/add-organization")),
              },
            ]
          : []),
      ],
    })
  }

  items.unshift({
    icon: "menu",
    text: "Navigation",
    subitems: [
      {
        icon: "globe",
        text: "Overview",
        onClick: () => props.history.push(createUrl("/overview/general")),
      },
      ...(!localStorage.getItem("owned_msp_id")
        ? [
            {
              icon: "user",
              text: "Policies",
              onClick: () => props.history.push(createUrl("/policies")),
            },
          ]
        : []),
      ...(!localStorage.getItem("owned_msp_id")
        ? [
            {
              icon: "diagram-tree",
              text: "Deployments",
              onClick: () =>
                props.history.push(createUrl("/deployments/sites")),
            },
          ]
        : []),
      {
        icon: "timeline-bar-chart",
        text: "Reporting",
        onClick: () =>
          props.history.push(createUrl("/reporting/data-explorer")),
      },
      ...(!localStorage.getItem("owned_msp_id")
        ? [
            {
              icon: "office",
              text: "Organization",
              onClick: () => props.history.push(createUrl("/settings/profile")),
            },
          ]
        : [
            {
              icon: "globe-network",
              text: "MSP",
              onClick: () => props.history.push(createUrl("/settings/profile")),
            },
          ]),
      localStorage.getItem("allowTools") && {
        icon: "wrench",
        text: "Tools",
        onClick: () => props.history.push(createUrl("/tools/ssl-certificate")),
      },
      !hiddenIntegrations && {
        icon: "cog",
        text: (
          <>
            Integrations
            <Tag
              round
              intent="primary"
              style={{
                marginLeft: "5px",
              }}
            >
              beta
            </Tag>
          </>
        ),
        onClick: () => props.history.push(createUrl("/integrations")),
      },
    ],
  })

  return (
    <div
      style={{
        height: 25,
      }}
    >
      <div className="bp3-navbar bp3-navbar-mobile bp3-fixed-top">
        <div className="bp3-navbar-group bp3-align-left">
          <div
            className="bp3-navbar-heading"
            style={{
              position: "relative",
              width: 140,
              marginRight: 5,
            }}
          >
            <Link to="/" data-test-id="logo">
              <img
                src={getLogo()}
                alt={getLogoAlt()}
                style={{
                  height: 40,
                }}
              />
            </Link>
            {localStorage.getItem("owned_msp_id") && (
              <span
                className="bp3-tag bp3-round"
                style={{
                  background: "#9AF5CC",
                  color: "#F96197",
                  fontSize: 10,
                  fontWeight: "bold",
                  lineHeight: "8px",
                  position: "absolute",
                  bottom: -2,
                  right: 0,
                  padding: "4px 5px",
                }}
              >
                MSP
              </span>
            )}
            {process.env.NODE_ENV !== "production" && (
              <span
                className="bp3-tag bp3-intent-warning bp3-round"
                style={{
                  fontSize: 6,
                  lineHeight: "8px",
                  position: "absolute",
                  top: 5,
                  right: 0,
                  padding: "2px 5px",
                }}
              >
                TEST MODE
              </span>
            )}
          </div>
        </div>

        {props.showMenu && (
          <div className="bp3-navbar-group bp3-align-right">
            {!localStorage.getItem("whitelabel.name") && (
              <div
                style={{
                  position: "relative",
                }}
              >
                <Button
                  minimal
                  data-test-id="navbar-mobile"
                  data-canny-changelog
                  onClick={() => {
                    window.trackAnalyticsEvent("Latest Updates Click", {
                      user_id: localStorage.getItem("userId"),
                      organization_id: localStorage.getItem("orgId"),
                    })
                  }}
                >
                  <span
                    className="bp3-icon-standard bp3-icon-notifications"
                    style={{
                      marginRight: 0,
                    }}
                  />
                </Button>
              </div>
            )}
            <Popover
              interactionKind="hover"
              position="bottom-right"
              content={<Menu items={items} isMobileMenu />}
            >
              <Button minimal data-test-id="navbar-minimal">
                <span
                  className="bp3-icon-standard bp3-icon-menu"
                  style={{
                    margin: 0,
                  }}
                />
              </Button>
            </Popover>
          </div>
        )}
      </div>
    </div>
  )
}

NavbarMobile.propTypes = {
  currentOrganization: organizationPropTypes,
  organizations: organizationsArrayPropTypes,
  userPicture: PropTypes.string.isRequired,
  onAccountClick: PropTypes.func.isRequired,
  onSwitchAccountClick: PropTypes.func,
  onDomainLookupClick: PropTypes.func.isRequired,
  onSystemStatusClick: PropTypes.func.isRequired,
  onSupportClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  history: historyPropTypes.isRequired,
}

NavbarMobile.defaultProps = {
  currentOrganization: {},
  organizations: [],
  showMenu: true,
}

const mapStateToProps = (state, props) =>
  createStructuredSelector({
    organizationItems: organizationMenuItems(state, props.onOrganizationClick),
  })

export default withRouter(connect(mapStateToProps, null)(NavbarMobile))
