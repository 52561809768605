import React from "react"
import {
  Menu as BlueprintMenu,
  MenuDivider,
  MenuItem,
  Intent,
} from "@blueprintjs/core"
import PropTypes from "prop-types"

const renderItem = (item, i) =>
  item.title ? (
    <MenuDivider key={item.title} title={item.title} />
  ) : (
    <MenuItem
      key={item.id || item.text || i}
      intent={item.intent && Intent[item.intent.toUpperCase()]}
      icon={item.icon}
      text={item.text}
      label={item.label}
      disabled={item.disabled}
      className={item.className}
      onClick={item.onClick}
    />
  )

const Menu = (props) => (
  <BlueprintMenu className={props.isMobileMenu && "header-mobile-menu"}>
    {props.items.map((item, i) => {
      let key = item.id || item.text || item.title || i

      if (item.title) {
        return <MenuDivider key={key} title={item.title} />
      }

      if (item.subitems) {
        return (
          <MenuItem
            key={key}
            onClick={item.onClick}
            icon={item.icon}
            text={item.text}
            intent={item.intent && Intent[item.intent.toUpperCase()]}
            label={item.label}
            popoverProps={
              props.isMobileMenu
                ? {
                    openOnTargetFocus: false,
                  }
                : undefined
            }
          >
            <div className={props.isMobileMenu && "header-mobile-submenu"}>
              {item.subitems.map(renderItem)}
            </div>
          </MenuItem>
        )
      }

      if (item.divider) {
        return <MenuDivider key={key} />
      }

      return renderItem(item, i)
    })}
  </BlueprintMenu>
)

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      intent: PropTypes.oneOf(["primary", "success", "warning", "danger"]),
      icon: PropTypes.node,
      title: PropTypes.node,
      text: PropTypes.node,
      label: PropTypes.node,
      divider: PropTypes.bool,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  isMobileMenu: PropTypes.bool,
}

Menu.defaultProps = {
  isMobileMenu: false,
}

export default Menu
