import React from "react"
import { Button } from "_Infinity/Core/Components/Button"
import { Tooltip } from "_Infinity/Core/Components/Tooltip"
import { useKnobs } from "_Infinity/Knobs/Hooks/useKnobs"

export function KnobsButton(props) {
  let knobs = useKnobs()
  let allKnobs = knobs.getAll()
  let changeCount = knobs.getChangeCount()

  return (
    <Tooltip
      content={
        allKnobs.length === 0
          ? null
          : changeCount === 1
          ? "1 knob change"
          : `${changeCount} knob changes`
      }
      position={props.tooltipPosition}
    >
      <Button icon="cog" minimal>
        {allKnobs.length === 0 ? null : changeCount}
      </Button>
    </Tooltip>
  )
}
