import PropTypes from "prop-types"

export const locationPropTypes = PropTypes.shape({
  hash: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
})

export const matchPropTypes = PropTypes.shape({
  isExact: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

export const historyPropTypes = PropTypes.shape({
  push: PropTypes.func.isRequired,
})

export const formPropTypes = PropTypes.shape({
  getError: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
  getInitialValues: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isTouched: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  unTouch: PropTypes.func.isRequired,
  // values: PropTypes.instanceOf(Object), FIX IT: Define values object
})

export const policyCategoryPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  security: PropTypes.bool,
})

export const policyCategoriesPropTypes = PropTypes.arrayOf(
  policyCategoryPropTypes,
)

// FIXME: Fill organization shape with all key/value pairs
export const organizationPropTypes = PropTypes.shape({
  canceled: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  isMSP: PropTypes.bool.isRequired,
  isMSPCustomer: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  ownedMSPID: PropTypes.number,
})

export const organizationsArrayPropTypes = PropTypes.arrayOf(
  organizationPropTypes,
)

export const whitelabelFormValuesPropTypes = PropTypes.shape({
  allowSiteCreation: PropTypes.bool,
  customDomainMapping: PropTypes.string,
  customFavicon: PropTypes.string,
  customLogo: PropTypes.string,
  customerContactEmail: PropTypes.string,
  elasticmailKey: PropTypes.string,
  netAlertApp: PropTypes.string,
  siteName: PropTypes.string,
  whitelabelEnabled: PropTypes.bool,
})

export const userPropTypes = PropTypes.shape({
  email: PropTypes.string,
  id: PropTypes.string,
  industry: PropTypes.number,
  name: PropTypes.string,
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  organizationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  organizationName: PropTypes.string,
  organizationType: PropTypes.string,
  revenue: PropTypes.number,
  role: PropTypes.string,
  startDate: PropTypes.string,
})

export const authPropTypes = PropTypes.shape({
  data: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
    industry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    orgId: PropTypes.string,
    organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    organizationName: PropTypes.string,
    organizationType: PropTypes.string,
    revenue: PropTypes.number,
    role: PropTypes.string,
    startDate: PropTypes.string,
  }),
  status: PropTypes.string,
  statusMessage: PropTypes.string,
})

export const blockPageOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
)

export const visibleSubnetsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    site: PropTypes.object,
    blockPage: PropTypes.object,
  }),
)

export const blockPagePropTypes = PropTypes.shape({
  agentCount: PropTypes.number,
  block_bypass: PropTypes.bool,
  block_bypass_password: PropTypes.string,
  block_email_addr: PropTypes.string,
  block_logo_uuid: PropTypes.string,
  block_org_name: PropTypes.string,
  block_redirect_url: PropTypes.string,
  created_at: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  networkCount: PropTypes.number,
  organization_id: PropTypes.number,
  updated_at: PropTypes.string,
})

export const SubscriptionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  orgId: PropTypes.number,
  name: PropTypes.string,
  planId: PropTypes.number,
  plan: PropTypes.string,
  term: PropTypes.string,
  usersAmount: PropTypes.number,
  guestsAmount: PropTypes.number,
  userPrice: PropTypes.number,
  guestPrice: PropTypes.number,
  renewsDate: PropTypes.string,
})

export const networkPropTypes = PropTypes.shape({
  address: PropTypes.string,
  allowAllPolicies: PropTypes.bool,
  block_page_id: PropTypes.number,
  id: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  network_type: PropTypes.string,
  pin: PropTypes.string,
  scheduled_policy_id: PropTypes.number,
  secret_key: PropTypes.string,
  sitekey_auto_register_expires_at: PropTypes.string,
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  ipAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      id: PropTypes.number,
      ip: PropTypes.string,
      version: PropTypes.string,
    }),
  ),
  groupedAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      id: PropTypes.number,
      ip: PropTypes.string,
      version: PropTypes.string,
    }),
  ),
})

export const organizationBillingPropTypes = PropTypes.shape({
  address_city: PropTypes.string,
  address_country: PropTypes.string,
  address_line1: PropTypes.string,
  address_line2: PropTypes.string,
  address_state: PropTypes.string,
  address_zip: PropTypes.string,
  brand: PropTypes.string,
  country: PropTypes.string,
  exp_month: PropTypes.number,
  exp_year: PropTypes.number,
  last4: PropTypes.string,
  object: PropTypes.string,
})

export const beneficiaryPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  plan: PropTypes.string,
  term: PropTypes.string,
  userCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  guestCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minimumPrice: PropTypes.number,
})
