export const showSupportButton = ({
  session,
  ldFlags,
  showSupportDistributorsInfo,
}) => {
  if (
    !localStorage.getItem("organization.isMSPSubAccount") ||
    localStorage.getItem("user.isMSP") ||
    localStorage.getItem("whitelabel.email")
  ) {
    if (session.activeOrganization?.isDistributor) {
      return (
        ldFlags.showSupportButtonForDistributors && showSupportDistributorsInfo
      )
    }

    return true
  }

  return false
}
