import React from "react"
import FormGroup from "components/FormGroup"
import InputGroup from "components/InputGroup"

const SignupFirstStep = ({ form, submitting, success }) => (
  <div>
    <FormGroup
      label="E-Mail Address"
      error={form.isSubmitted && form.getError("email")}
      isRequired
    >
      <InputGroup
        data-test-id="email"
        leftIcon="envelope"
        value={form.getValue("email")}
        onChange={(e) => form.setValue("email", e.target.value)}
        disabled={submitting || success}
      />
    </FormGroup>
    <FormGroup
      label="Password"
      error={form.isSubmitted && form.getError("password")}
      isRequired
    >
      <InputGroup
        leftIcon="lock"
        data-test-id="password"
        type="password"
        value={form.getValue("password")}
        onChange={(e) => form.setValue("password", e.target.value)}
        disabled={submitting || success}
      />
    </FormGroup>
    <FormGroup
      label="Confirm Password"
      error={form.isSubmitted && form.getError("confirmPassword")}
      isRequired
    >
      <InputGroup
        leftIcon="lock"
        data-test-id="confirm-password"
        type="password"
        value={form.getValue("confirmPassword")}
        onChange={(e) => form.setValue("confirmPassword", e.target.value)}
        disabled={submitting || success}
      />
    </FormGroup>
  </div>
)

export default SignupFirstStep
