import React from "react"
import { NewAppContainer } from "components/NewAppContainer"
import { useMetric } from "_v3/Core/useMetric"

export default function NewOverviewScene() {
  useMetric({
    scope: "Integrations",
    title: "Loaded Integrations",
    recordOnLoad: true,
  })

  return <NewAppContainer title="Integrations" path="/integrations" />
}
