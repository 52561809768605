import {
  LOAD_IP_ADDRESSES,
  CHANGE_IP_ADDRESSES_PAGE,
} from "action-types/ipAddresses"
import { FETCHING } from "action-types/status"
import get from "lodash/get"
import queryString from "query-string"

const initialState = {
  status: FETCHING,
  page: 1,
  size: 100,
  totalPages: null,
  data: [],
}

export const ipAddresses = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_IP_ADDRESSES:
      const links = action.payload.links
      let totalPages = state.totalPages

      if (links) {
        const last = queryString.parse(links.last)
        const self = queryString.parse(links.self)

        totalPages =
          Object.keys(last).length > 0 ? Number(last[Object.keys(last)[2]]) : 1

        if (Object.keys(last).length === 0) {
          totalPages =
            Object.keys(self).length > 0
              ? Number(self[Object.keys(self)[2]])
              : 1
        }
      }

      return {
        ...state,
        status: action.payload.status,
        data: get(action.payload, "data", state.data),
        totalPages,
      }
    case CHANGE_IP_ADDRESSES_PAGE:
      return {
        ...state,
        page: action.payload.page,
      }
    default:
      return state
  }
}

export default ipAddresses
