import React from "react"
import { Block } from "jsxstyle"

const Footer = () => (
  <Block textAlign="center">
    <small>
      © {new Date().getFullYear()}{" "}
      {localStorage.getItem("whitelabel.name") || "DNSFilter, Inc"}. All rights
      reserved.
    </small>
  </Block>
)

export default Footer
