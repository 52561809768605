import React from "react"
import cx from "classnames"
import "./CardContent.css"

export function CardContent(props) {
  return (
    <div
      className={cx({
        "CardContent": true,
        "CardContent--padded": props.padded ?? true,
        "CardContent--fullHeight": props.fullHeight,
        "CardContent--centered": props.centered,
        "CardContent--autoOverflowX": props.autoOverflowX,
      })}
    >
      {props.children}
    </div>
  )
}
