import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import FormFormik from "components/FormFormik"
import FormGroup from "components/FormGroup"
import ControlGroup from "components/ControlGroup"
import Input from "components/Input"
import Spinner from "components/Spinner"
import ViewportCentered from "components/ViewportCentered"
import isRequired from "validators/isRequired"
import isEmail from "validators/isEmail"
import getLogo, { getLogoAlt } from "utils/getLogo"

const Login = ({ status, messageError, onSubmit, initialValues }) => (
  <div>
    <FormFormik
      initialValues={initialValues}
      onSubmit={(values) => {
        const { email, password } = values

        onSubmit({
          email,
          password,
        })
      }}
      validations={{
        email: [isRequired, isEmail],
        password: [isRequired],
      }}
    >
      {(form) => (
        <ViewportCentered padding={30}>
          <div
            style={{
              maxWidth: 330,
            }}
          >
            <img
              className="img-responsive center-block"
              src={getLogo()}
              alt={getLogoAlt()}
              style={{
                marginBottom: 20,
              }}
            />
          </div>
          <div
            className="panel panel-default center-block"
            style={{
              maxWidth: 460,
              width: "100%",
            }}
          >
            <div className="panel-body">
              {status === "loginFailed" && messageError && (
                <div className="alert alert-danger text-center">
                  {messageError}
                </div>
              )}
              <FormGroup error={form.isSubmitted && form.getError("email")}>
                <ControlGroup
                  leftButtonIcon="envelope"
                  onLeftButtonClick={null}
                  large
                >
                  <Input
                    value={form.getValue("email")}
                    onChange={(e) => form.setValue("email", e.target.value)}
                    type="email"
                    placeholder="E-Mail Address"
                    disabled={form.isSubmitting}
                    autoFocus
                    fill={false}
                    style={{
                      width: "calc(100% - 40px)",
                      height: 40,
                    }}
                  />
                </ControlGroup>
              </FormGroup>
              <FormGroup error={form.isSubmitted && form.getError("password")}>
                <ControlGroup
                  leftButtonIcon="lock"
                  onLeftButtonClick={null}
                  large
                >
                  <Input
                    value={form.getValue("password")}
                    onChange={(e) => form.setValue("password", e.target.value)}
                    type="password"
                    placeholder="Password"
                    disabled={form.isSubmitting}
                    fill={false}
                    style={{
                      width: "calc(100% - 40px)",
                      height: 40,
                    }}
                  />
                </ControlGroup>
              </FormGroup>
              <button className="btn btn-login btn-block" type="submit">
                {form.isSubmitting ? <Spinner /> : "Login"}
              </button>
              <hr />
              {localStorage.getItem("whitelabel.name") ? (
                <Link
                  className="btn btn-default btn-block"
                  to="/forgot-password"
                >
                  <small>Forgot your password?</small>
                </Link>
              ) : (
                <div className="row">
                  <div className="col-sm-6">
                    <Link
                      data-test-id="forgot-password-link"
                      className="btn btn-default btn-block"
                      to="/forgot-password"
                    >
                      <small>Forgot your password?</small>
                    </Link>
                    <div
                      className="visible-xs-block"
                      style={{
                        height: 20,
                      }}
                    />
                  </div>
                  <div className="col-sm-6">
                    <a
                      data-test-id="signup-link"
                      className="btn btn-default btn-block"
                      href="/signup"
                    >
                      <small>Don’t have an account?</small>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="text-center"
            style={{
              marginBottom: 30,
            }}
          >
            <small>
              {localStorage.getItem("whitelabel.name")
                ? `Service provided by ${localStorage.getItem(
                    "whitelabel.name",
                  )}`
                : `© ${new Date().getFullYear()} DNSFilter, Inc. All rights reserved.`}
            </small>
          </div>
        </ViewportCentered>
      )}
    </FormFormik>
  </div>
)

Login.propTypes = {
  status: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  messageError: PropTypes.string,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
}

Login.defaultProps = {
  messageError: null,
}

export default Login
