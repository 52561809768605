import { UPDATE_BLOCK_PAGES_SEARCH_TEXT } from "action-types/blockPages"

export const blockPagesFilters = (
  state = {
    text: "",
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_BLOCK_PAGES_SEARCH_TEXT:
      return {
        ...state,
        text: action.payload,
      }
    default:
      return state
  }
}

export default blockPagesFilters
