import { useInterval } from "react-use"
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect"
import { createMachine, assign } from "xstate"
import { useMachine } from "@xstate/react"

let resourceMachine = createMachine(
  {
    id: "resource",
    context: {
      initialParams: null,
      params: null,
      data: null,
      meta: null,
      error: null,
      cachePolicy: null,
    },
    initial: "idle",
    states: {
      idle: {
        on: {
          "": {
            target: "prefetching",
            cond: "isEager",
          },
          "FETCH": {
            target: "prefetching",
            actions: "setParams",
          },
        },
      },
      prefetching: {
        on: {
          "": [
            {
              target: "refetching",
              cond: "isCached",
              actions: "notifyData",
            },
            {
              target: "fetching",
            },
          ],
        },
      },
      fetching: {
        entry: ["resetData", "resetMeta", "resetError"],
        invoke: {
          src: "fetch",
          onDone: {
            target: "success",
            actions: ["setData", "setMeta", "updateCache"],
          },
          onError: {
            target: "failure",
            actions: "setError",
          },
        },
      },
      debouncing: {
        after: {
          500: "refetching",
        },
      },
      refetching: {
        invoke: {
          src: "fetch",
          onDone: {
            target: "success",
            actions: ["setData", "setMeta", "updateCache"],
          },
          onError: {
            target: "failure",
            actions: "setError",
          },
        },
      },
      appending: {
        invoke: {
          src: "fetch",
          onDone: {
            target: "success",
            actions: ["appendData", "setMeta"],
          },
          onError: {
            target: "failure",
            actions: "setError",
          },
        },
      },
      success: {
        entry: ["notifyData", "notifySuccess"],
        on: {
          FETCH: {
            target: "prefetching",
            actions: "setParams",
          },
          REFETCH: {
            target: "debouncing",
            actions: "setParams",
          },
          APPEND: {
            target: "appending",
            actions: "setParams",
          },
        },
      },
      failure: {
        entry: "notifyFailure",
        on: {
          FETCH: {
            target: "fetching",
            actions: "setParams",
          },
          RETRY: {
            target: "fetching",
          },
          CANCEL: {
            target: "idle",
            actions: ["resetParams", "resetData", "resetMeta", "resetError"],
          },
        },
      },
    },
    on: {
      REDUCE_DATA: {
        actions: "reduceData",
      },
      RESET: {
        target: "idle",
        actions: [
          "setInitialParams",
          "resetParams",
          "resetData",
          "resetMeta",
          "resetError",
        ],
      },
    },
  },
  {
    guards: {
      isEager: (ctx) => ctx.initialParams !== null,
      isCached: (ctx) => !!ctx.cachePolicy && !!ctx.data,
    },
    actions: {
      setInitialParams: assign({
        initialParams: (_, e) => e.payload,
      }),
      setParams: assign({
        params: (ctx, e) => ({
          ...ctx.params,
          ...e.payload,
        }),
      }),
      setData: assign({
        data: (_, e) => e.data.data,
      }),
      appendData: assign({
        data: (ctx, e) => [...ctx.data, ...e.data.data],
      }),
      setMeta: assign({
        meta: (_, e) => e.data.meta ?? {},
      }),
      setError: assign({
        error: (_, e) => e.data,
      }),
      resetParams: assign({
        params: (ctx) => ctx.initialParams ?? {},
      }),
      resetData: assign({
        data: null,
      }),
      resetMeta: assign({
        meta: {},
      }),
      resetError: assign({
        error: null,
      }),
    },
  },
)

let cache = {}

export function useResource(params) {
  let [state, send] = useMachine(resourceMachine, {
    id: `[Resource] ${params.id}`,
    context: {
      initialParams: params.initialParams ?? null,
      params: params.initialParams ?? {},
      data: cache[params.id]?.data ?? null,
      meta: cache[params.id]?.meta ?? {},
      error: null,
      cachePolicy: params.cachePolicy ?? null,
    },
    actions: {
      reduceData: assign({
        data: (ctx, e) =>
          params.dataReducer?.[e.payload.type]?.(ctx.data, e.payload.payload) ??
          ctx.data,
      }),
      updateCache: (ctx) => {
        if (!params.cachePolicy) {
          return
        }

        cache[params.id] = {
          data: ctx.data,
          meta: ctx.meta,
        }
      },
      notifyData: (ctx) =>
        params.onData?.(
          params.transformData?.(ctx.data) ?? ctx.data,
          params.transformMeta?.(ctx.meta) ?? ctx.meta,
          ctx.params,
        ),
      notifySuccess: (ctx) =>
        params.onSuccess?.(
          params.transformData?.(ctx.data) ?? ctx.data,
          params.transformMeta?.(ctx.meta) ?? ctx.meta,
          ctx.params,
        ),
      notifyFailure: (ctx) => params.onFailure?.(ctx.error, ctx.params),
    },
    services: {
      fetch: (ctx) => params.fetch(ctx.params),
    },
    devTools: params.debug,
  })

  useDeepCompareEffectNoCheck(() => {
    if (!params.initialParams) {
      return
    }

    // useDeepCompareEffect will run on the initial render as well, so
    // it's gonna dispatch an unnecessary RESET event. To prevent that we'll
    // compare the passed initialParams to the current initialParams to make
    // sure the RESET is really necessary.
    if (params.initialParams === state.context.initialParams) {
      return
    }

    send({
      type: "RESET",
      payload: params.initialParams,
    })
  }, [params.initialParams])

  useInterval(() => {
    send({
      type: "REFETCH",
    })
  }, params.pollInterval ?? null)

  return {
    initialParams: state.context.initialParams,
    params: state.context.params,
    data: params.transformData?.(state.context.data) ?? state.context.data,
    meta: params.transformMeta?.(state.context.meta) ?? state.context.meta,
    error: state.context.error,
    idle: state.value === "idle",
    fetching: state.value === "fetching",
    refetching: /debouncing|refetching/.test(state.value),
    appending: state.value === "appending",
    pending: /fetching|debouncing|refetching|appending/.test(state.value),
    success: state.value === "success",
    failure: state.value === "failure",
    fetch: (params) =>
      send({
        type: "FETCH",
        payload: params,
      }),
    refetch: (params) =>
      send({
        type: "REFETCH",
        payload: params,
      }),
    append: (params) =>
      send({
        type: "APPEND",
        payload: params,
      }),
    send: (type, payload) =>
      send({
        type: "REDUCE_DATA",
        payload: {
          type,
          payload,
        },
      }),
    retry: () =>
      send({
        type: "RETRY",
      }),
    cancel: () =>
      send({
        type: "CANCEL",
      }),
  }
}
