import { SUCCEEDED } from "action-types/status"
import {
  CHANGE_ALL_NETWORKS,
  CHANGE_MULTIPLE_NETWORKS,
  LOAD_NETWORKS,
  SUBMITTING_NETWORK_FORM,
  SELECT_NETWORK,
  TOGGLE_EDIT_MULTIPLE_NETWORKS_FORM,
  UPDATE_MULTIPLE_NETWORKS_REQUEST,
  UPDATE_SECRET_KEY,
  SHOW_CONFIRM_NETWORK_DELETE_MODAL,
  HIDE_CONFIRM_NETWORK_DELETE_MODAL,
  CLEAR_SELECTED_NETWORKS,
  TOGGLE_ADD_FORM,
  ENABLE_USER_AGENT_AUTO_REGISTER,
  UPDATE_NETWORK_IP,
} from "action-types/networks"

export const networks = (
  state = {
    data: [],
    status: null,
    hasAtLeastOneSite: null,
    haveTraffic: null,
    isSubmitting: false,
    editMultipleNetworks: false,
    editMultipleNetworksStatus: null,
    selectedNetworksIds: [],
    allNetworksSelected: false,
    activeNetworkIds: [],
    showConfirmDeleteModal: false,
    idToBeDeleted: null,
    showMultipleNetworkForm: false,
    showAddForm: false,
    enableUserAgentAutoRegisterStatus: null,
    isSecretKeyFetching: false,
    secretKeyError: false,
  },
  action,
) => {
  switch (action.type) {
    case SUBMITTING_NETWORK_FORM:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      }
    case LOAD_NETWORKS:
      return {
        ...state,
        isSubmitting:
          action.payload.status === SUCCEEDED ? false : state.isSubmitting,
        ...action.payload,
      }
    case UPDATE_SECRET_KEY:
      return {
        ...state,
        ...action.payload,
      }
    case TOGGLE_EDIT_MULTIPLE_NETWORKS_FORM:
      return {
        ...state,
        showMultipleNetworkForm: action.payload,
        editMultipleNetworks: action.payload,
        editMultipleNetworksStatus: null,
      }
    case CHANGE_ALL_NETWORKS:
      return {
        ...state,
        allNetworksSelected: action.payload.allChecked,
        selectedNetworksIds: action.payload.allChecked
          ? action.payload.visibleIds
          : [],
      }
    case UPDATE_MULTIPLE_NETWORKS_REQUEST:
      return {
        ...state,
        editMultipleNetworksStatus: action.payload,
        selectedNetworksIds:
          action.payload === "success" ? [] : state.selectedNetworksIds,
        allNetworksSelected:
          action.payload === "success" ? false : state.allNetworksSelected,
      }
    case CHANGE_MULTIPLE_NETWORKS:
      return {
        ...state,
        data: state.data.map((network) => {
          const updatedNetwork = action.payload.find(
            (net) => net.id === network.id,
          )

          return updatedNetwork || network
        }),
      }
    case CLEAR_SELECTED_NETWORKS:
      return {
        ...state,
        allNetworksSelected: false,
        selectedNetworksIds: [],
      }
    case SELECT_NETWORK: {
      const selectedNetwork = state.selectedNetworksIds.find(
        (networkId) => networkId === action.payload,
      )
      const newSelectedNetwork = selectedNetwork
        ? state.selectedNetworksIds.filter(
            (networkId) => networkId !== action.payload,
          )
        : [...state.selectedNetworksIds, action.payload]

      return {
        ...state,
        selectedNetworksIds: newSelectedNetwork,
        allNetworksSelected: false,
      }
    }

    case SHOW_CONFIRM_NETWORK_DELETE_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: true,
        idToBeDeleted: action.payload,
      }
    case HIDE_CONFIRM_NETWORK_DELETE_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: false,
        idToBeDeleted: null,
      }
    case TOGGLE_ADD_FORM:
      return {
        ...state,
        showAddForm: action.payload,
      }
    case ENABLE_USER_AGENT_AUTO_REGISTER:
      return {
        ...state,
        enableUserAgentAutoRegisterStatus: action.payload.status,
        data: state.data.map((network) => {
          if (action.payload.status !== SUCCEEDED) {
            return network
          }

          if (Number(action.payload.id) !== Number(network.id)) {
            return network
          }

          return {
            ...network,
            sitekey_auto_register_expires_at: action.payload.attributes
              .sitekey_auto_register_expires_at
              ? new Date(
                  action.payload.attributes.sitekey_auto_register_expires_at,
                )
              : null,
          }
        }),
      }
    case UPDATE_NETWORK_IP:
      const { networkId, id, ip } = action.payload

      const newData = state?.data?.map((network) => {
        if (network.id === networkId) {
          return {
            ...network,
            ipAddresses: network.ipAddresses.map((ipAddress) => {
              if (ipAddress.id === id) {
                return {
                  ...ipAddress,
                  ip,
                }
              }

              return ipAddress
            }),
          }
        }

        return network
      })

      if (newData) {
        return {
          ...state,
          data: newData,
        }
      }

      return state
    default:
      return state
  }
}

export default networks
