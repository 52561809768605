import {
  UPDATE_POLICY_SEARCH_TEXT,
  UPDATE_POLICY_STATUS_FILTER,
} from "action-types/filtering"

export const policyFilters = (
  state = {
    text: "",
    status: "all",
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_POLICY_SEARCH_TEXT:
      return {
        ...state,
        text: action.payload,
      }
    case UPDATE_POLICY_STATUS_FILTER:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}

export default policyFilters
