import { useRef } from "react"
import { useFirstMountState, useMount, useUnmount } from "react-use"
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect"
import * as R from "ramda"
import * as L from "lodash"
import * as D from "date-fns"

export function useMetric(params) {
  let firstMount = useFirstMountState()
  let timestampRef = useRef(null)

  useMount(() => {
    if (!params.recordOnLoad) {
      return
    }

    record()
  })

  useUnmount(() => {
    if (!params.recordOnUnload) {
      return
    }

    record()
  })

  useDeepCompareEffectNoCheck(() => {
    if (firstMount) {
      return
    }

    if (!params.recordOnChange) {
      return
    }

    record()
  }, [R.pick(params.recordOnChange ?? [], params.attrs ?? {})])

  function startTimer() {
    timestampRef.current = Date.now()
  }

  function record(additionalAttrs) {
    window.trackAnalyticsEvent(
      params.title,
      L.mapKeys(
        {
          scope: params.scope,
          ...(timestampRef.current
            ? {
                duration: D.formatDistanceStrict(
                  timestampRef.current,
                  Date.now(),
                ),
              }
            : undefined),
          ...params.attrs,
          ...additionalAttrs,
        },
        R.pipe(R.nthArg(1), L.lowerCase, L.upperFirst),
      ),
    )
  }

  return {
    startTimer,
    record,
  }
}
