import createEntitySchema from "../Utils/createEntitySchema"

let subscriptionSchema = createEntitySchema(
  "subscriptions",
  {},
  {
    idAttribute: "id",
    processStrategy: (e) => ({
      id: Number(e.id),
      organizationId: e.attributes.organization_id,
      status:
        e.attributes.status === "cancelled" ? "canceled" : e.attributes.status,
      plan: e.attributes.plan,
      term: e.attributes?.term,
      startedAt: e.attributes.started_at,
      nextbBillingAt: e.attributes.next_billing_at,
      addons:
        e.attributes?.organization_info?.addons?.map((addon) =>
          addon.replace("legacy", "enterprise"),
        ) ?? [],
      beneficiaries: e.attributes?.beneficiaries
        ? e.attributes.beneficiaries.map((beneficiary) => ({
            id: beneficiary.id,
            userCount: beneficiary.user_count,
            wifiCount: beneficiary.wifi_count,
          }))
        : null,
      // this is the same as `plan` but with more consistent naming
      // so that plan type matches with feature addon type
      planFeatureAddOn: e.attributes?.plan?.replace("legacy_", ""),
    }),
  },
)

export default subscriptionSchema
