import httpClient from "_v2/Core/Utils/httpClient"

window.cbInstance.setPortalSession(function () {
  return httpClient
    .post("/chargebee/portal", {
      organization_id: localStorage.orgId,
    })
    .then((response) => response.data)
})

const cbPortal = window.cbInstance.createChargebeePortal()

export function openChargebee({ sectionType, onClose, onSuccess, onError }) {
  cbPortal.openSection(
    {
      sectionType,
    },
    {
      close: function () {
        onClose && onClose()
      },
      error: function (error) {
        console.error("[Chargebee Portal]", error)
        onError && onError()
      },
      paymentSourceAdd: function (res) {
        if (res === "success") {
          onSuccess && onSuccess()
        }
      },
      paymentSourceUpdate: function (res) {
        if (res === "success") {
          onSuccess && onSuccess()
        }
      },
      paymentSourceRemove: function (res) {
        onSuccess && onSuccess()
      },
    },
  )
}
