import isCidr from "is-cidr"
import IpSubnetCalculator from "ip-subnet-calculator"

export const filterNetworks = (networks, searchText) => {
  if (!searchText) {
    return networks
  }

  function matchName({ name }) {
    return name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  }

  const replaceRegExp = /[^A-Za-z0-9]/g
  const searchAddress = searchText && searchText.replace(replaceRegExp, "")
  function matchIPAddress({ ipAddresses }) {
    if (!searchAddress) {
      return false
    }

    return (
      ipAddresses.filter(({ address, ip }) => {
        const a = address && address.replace(replaceRegExp, "")
        const b = ip && ip.replace(replaceRegExp, "")
        if (!a && !b) {
          return false
        }

        if (!b) {
          return a.indexOf(searchAddress) > -1
        }

        return a.indexOf(searchAddress) > -1 || b.indexOf(searchAddress) > -1
      }).length > 0
    )
  }

  return networks.filter(
    (network) => matchName(network) || matchIPAddress(network),
  )
}

const convertAddressesToCidr = (lowIp, highIp) => {
  const convertedAddresses = []
  const calculated = IpSubnetCalculator.calculate(lowIp, highIp)

  calculated.forEach((el) => {
    const mask = el.invertedMask + 1
    const part = el.ipLowStr.split(".")
    const addresses = []
    for (let i = 0; i < mask; i++) {
      const increase = Number(part[3]) + i
      const addr = `${part[0]}.${part[1]}.${part[2]}.${increase}`
      addresses.push(addr)
    }

    convertedAddresses.push({
      ips: addresses,
      mask: el.prefixSize,
    })
  })

  return convertedAddresses
}

const consecutiveNumbers = (ipsSubnet) => {
  let results = ""

  for (let i = 0; i < ipsSubnet.length; i++) {
    const diff = ipsSubnet[i + 1] - ipsSubnet[i]
    if (diff === 1) {
      results = results.concat(ipsSubnet[i], ",")
    } else {
      results = results.concat(ipsSubnet[i])
      results = results.concat("|")
    }
  }

  return results
}

const sortIpAddresses = (ipAddressArray) =>
  ipAddressArray.sort((firstIp, nextIp) => {
    const partedFirstIp = firstIp.address.split(".")
    const partedNextIp = nextIp.address.split(".")
    for (let i = 0; i < partedFirstIp.length; i++) {
      if (parseInt(partedFirstIp[i], 10) < parseInt(partedNextIp[i], 10))
        return -1
      if (partedFirstIp[i] > partedNextIp[i]) return 1
    }

    return 0
  })

const isValid = (address) => {
  if (isCidr(address)) {
    return true
  }

  const regex =
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/ // guardrails-disable-line

  return regex.test(address)
}

export const groupIpAddressesBySubnet = (ipAddresses) => {
  const subnetGroups = {}
  const regex =
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/ // guardrails-disable-line
  const domains = ipAddresses.filter((ip) => regex.test(ip.address))

  ipAddresses.forEach((ip) => {
    if (isValid(ip.address)) {
      const splitedIp = ip.address.split(".")
      const subnet = [splitedIp[0], splitedIp[1], splitedIp[2]].join(".")
      let subnetIps = ipAddresses.filter((el) => el.address.startsWith(subnet))

      subnetIps = sortIpAddresses(subnetIps)

      const ipsSubnet = subnetIps.map((addressItem) =>
        Number(addressItem.address.split(".")[3]),
      )
      subnetGroups[subnet] = consecutiveNumbers(ipsSubnet)
    }
  })

  Object.keys(subnetGroups).forEach((key) => {
    const splitedRanges = subnetGroups[key]
      .split("|")
      .filter((range) => range !== "")
    const ranges = []

    splitedRanges.forEach((range) => {
      const splitedRange = range.split(",")
      if (splitedRange.length) {
        ranges.push(splitedRange)
      }
    })

    subnetGroups[key] = ranges
  })

  let ipsAndSubnetsToDisplay = []

  Object.keys(subnetGroups).forEach((key) => {
    subnetGroups[key].forEach((ranges) => {
      if (ranges.length < 8) {
        const ips = ranges.map((range) => [key.toString(), range].join("."))
        ipsAndSubnetsToDisplay = [...ipsAndSubnetsToDisplay, ...ips]
      }

      if (ranges.length >= 8) {
        const lowIp = `${key.toString()}.${ranges[0]}`
        const highIp = `${key.toString()}.${ranges[ranges.length - 1]}`
        const calculatedAddresses = convertAddressesToCidr(lowIp, highIp)

        calculatedAddresses.forEach((group) => {
          if (group.mask > 31) {
            group.ips.forEach((ip) => {
              ipsAndSubnetsToDisplay.push(ip)
            })
          } else {
            ipsAndSubnetsToDisplay.push(`${group.ips[0]}/${group.mask}`)
          }
        })
      }
    })
  })

  const ips = ipAddresses.filter((ip) =>
    ipsAndSubnetsToDisplay.includes(ip.address),
  )
  const cidrs = ipsAndSubnetsToDisplay
    .filter((ip) => ip.includes("/"))
    .map((ip) => {
      const subnetAddress = ip.split("/")[0]
      const lowCidrAddress = ipAddresses.find(
        (item) => item.address === subnetAddress,
      )

      return {
        id: lowCidrAddress.id,
        address: ip,
        existsIps: true,
      }
    })

  return [...ips, ...cidrs, ...domains]
}

// Check if ip is inside CIDR range, for validation
const ip4ToInt = (ip) =>
  ip.split(".").reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0) >>> 0

export const isIp4InCidr = (ip) => (cidr) => {
  const [range, bits = 32] = cidr.split("/")
  const mask = ~(2 ** (32 - bits) - 1)

  return (ip4ToInt(ip) & mask) === (ip4ToInt(range) & mask)
}

export const isIp4InCidrs = (ip, cidrs) => cidrs.some(isIp4InCidr(ip))
