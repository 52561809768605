import transparent from "images/transparent.gif"
import dnsfilterLogo from "images/logo.png"

const isLogo = (logo) =>
  Boolean(String(logo).trim()) && logo !== "undefined" && logo !== "null"

const getLogo = () => {
  if (localStorage.getItem("whitelabel.name")) {
    return isLogo(localStorage.getItem("whitelabel.logo"))
      ? localStorage.getItem("whitelabel.logo")
      : transparent
  }

  return dnsfilterLogo
}

export default getLogo

export const getLogoAlt = () =>
  localStorage.getItem("whitelabel.name") || "DNSFilter"
