import React from "react"
import PropTypes from "prop-types"
import { Block } from "jsxstyle"

const TextCenter = ({ children }) => (
  <Block textAlign="center">{children}</Block>
)

TextCenter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TextCenter
