export const USER_REQUEST = "USER_REQUEST"
export const USER_SUCCESS = "USER_SUCCESS"
export const USER_FAILURE = "USER_FAILURE"
export const BILLING_DATA_REQUEST = "BILLING_DATA_REQUEST"
export const BILLING_DATA_SUCCESS = "BILLING_DATA_SUCCESS"
export const BILLING_DATA_FAILURE = "BILLING_DATA_FAILURE"
export const LOAD_ORG_BILLING_DATA = "LOAD_ORG_BILLING_DATA"
export const LOAD_ORG_BILLING_DATA_REQUEST = "LOAD_ORG_BILLING_DATA_REQUEST"
export const LOAD_ORG_BILLING_DATA_SUCCESS = "LOAD_ORG_BILLING_DATA_SUCCESS"
export const LOAD_ORG_BILLING_DATA_FAILURE = "LOAD_ORG_BILLING_DATA_FAILURE"
export const UPDATE_BILLING_TOKEN_REQUEST = "UPDATE_BILLING_TOKEN_REQUEST"
export const UPDATE_BILLING_TOKEN_FAILURE = "UPDATE_BILLING_TOKEN_FAILURE"
export const LOAD_INIT_BILLING_DATA_REQUEST = "LOAD_INIT_BILLING_DATA_REQUEST"
export const LOAD_INIT_BILLING_DATA_SUCCESS = "LOAD_INIT_BILLING_DATA_SUCCESS"
export const LOAD_INIT_BILLING_DATA_FAILURE = "LOAD_INIT_BILLING_DATA_FAILURE"
