import React from "react"
import {
  Tooltip as BlueprintTooltip,
  Position,
  PopoverInteractionKind,
} from "@blueprintjs/core"
import PropTypes from "prop-types"

const Tooltip = (props) => (
  <BlueprintTooltip
    position={Position[props.position.toUpperCase()]}
    content={props.content}
    defaultIsOpen={props.defaultIsOpen}
    inline={props.inline}
    isOpen={props.isOpen}
    tooltipClassName={props.className}
    hoverCloseDelay={props.hoverCloseDelay}
    interactionKind={
      PopoverInteractionKind[props.interactionKind.toUpperCase()]
    }
  >
    <div>{props.children}</div>
  </BlueprintTooltip>
)

Tooltip.propTypes = {
  position: PropTypes.oneOf(
    Object.keys(Position).map((position) =>
      String(position).toLowerCase().replace("_", "-"),
    ),
  ).isRequired,
  interactionKind: PropTypes.oneOf(
    Object.keys(PopoverInteractionKind).map((interactionKind) =>
      String(interactionKind).toLowerCase(),
    ),
  ).isRequired,
  content: PropTypes.node.isRequired,
  defaultIsOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  inline: PropTypes.bool,
  hoverCloseDelay: PropTypes.number,
}

Tooltip.defaultProps = {
  position: "top",
  interactionKind: "hover_target_only",
  isOpen: null,
  inline: true,
}

export default Tooltip
