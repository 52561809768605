import { useMachine } from "_v2/Modules/Core"

import sessionMachine from "../Machines/sessionMachine"

import { isPageMspDashboard } from "utils/isPageMspDashboard"

function useSession() {
  // For now we are cleaning `localStorage.subscriptionIssuesAlertDismissed` up
  // in `login()` and `loginWithToken()` Redux actions. When we move the login
  // logic to `sessionMachine` we can start cleaning that up here.
  let { state, context, send } = useMachine(sessionMachine, {
    context: {
      subscriptionIssuesAlertDismissed: Boolean(
        localStorage.getItem("subscriptionIssuesAlertDismissed"),
      ),
      legacyEnterpriseAlertDismissed: Boolean(
        localStorage.getItem("legacyEnterpriseAlertDismissed"),
      ),
      billingDetailsAlertDismissed: Boolean(
        localStorage.getItem("billingDetailsAlertDismissed"),
      ),
    },
    actions: {
      persist: (ctx) =>
        localStorage.setItem(
          "subscriptionIssuesAlertDismissed",
          ctx.subscriptionIssuesAlertDismissed,
        ),
      persistLegacyEnterprise: (ctx) =>
        localStorage.setItem(
          "legacyEnterpriseAlertDismissed",
          ctx.legacyEnterpriseAlertDismissed,
        ),
      persistBillingDetailsAlert: (ctx) =>
        localStorage.setItem(
          "billingDetailsAlertDismissed",
          ctx.billingDetailsAlertDismissed,
        ),
    },
    devTools: true,
  })

  let {
    user,
    organizations,
    activeOrganization,
    featureFlags,
    subscriptions,
    activeSubscription,
    subscriptionByBeneficiary,
    subscriptionIssuesAlertDismissed,
    legacyEnterpriseAlertDismissed,
    billingDetailsAlertDismissed,
    msp,
  } = context

  let loading = state.matches("awaiting") || state.matches("loading")
  let reloading = state.matches("reloading")
  let failure = state.matches("failure")

  function getSuborganizations() {
    return organizations.filter(
      (organization) => organization.type === "suborganization",
    )
  }

  // 1. Returns itself if the active organization is MSP or a regular
  //    organization
  // 2. Returns the MSP if the active organization is a suborganization and the
  //    logged user is member of a MSP
  // 3. Returns itself if the active organization is a suborganization, but
  //    the logged user is not member of any MSP
  function getMainOrganization() {
    if (!activeOrganization) {
      return null
    }

    if (activeOrganization.type === "suborganization") {
      let msp = organizations.find(
        (organization) =>
          organization.type === "msp" &&
          organization.mspId === activeOrganization.ownedByMspId,
      )

      // If we can find a MSP within the list of organizations, it means that
      // the logged user is member of a MSP
      if (msp) {
        return msp
      }
    }

    return activeOrganization
  }

  function getMainOrganizationSubscriptionIssuesCount() {
    let mainOrganization = getMainOrganization()

    if (!mainOrganization) {
      return null
    }

    switch (mainOrganization.type) {
      case "msp":
        let suborganizations = getSuborganizations()

        return suborganizations.reduce((acc, suborganization) => {
          // Find subscription for current suborganization
          let subscription = subscriptions.find((subscription) =>
            subscription.beneficiaries
              ? subscription.beneficiaries.find(
                  (beneficiary) => beneficiary.id === suborganization.id,
                )
              : null,
          )

          return acc + (subscription && subscription.plan ? 0 : 1)
        }, 0)

      case "suborganization":
        return 0

      case "organization":
        return subscriptions.find((subscription) => subscription.plan) ? 0 : 1

      default:
        throw new Error("Invalid organization type")
    }
  }

  const getTypeActiveOrganization = () => {
    const mainOrganization = getMainOrganization()

    const isMspDashboard = isPageMspDashboard(
      mainOrganization,
      window.location.pathname,
    )

    if (!activeOrganization) {
      return null
    }

    if (activeOrganization.type === "msp" && !isMspDashboard) {
      return "suborganization"
    }

    return activeOrganization.type
  }

  function sendReady(organizationId) {
    send("READY", organizationId)
  }

  function sendReload() {
    send("RELOAD")
  }

  function sendLogout() {
    send("LOGOUT")
  }

  function sendRetry() {
    send("RETRY")
  }

  function sendDismissSubscriptionIssuesAlert() {
    send("DISMISS_SUBSCRIPTION_ISSUES_ALERT")
  }

  function sendDismissLegacyEnterpriseAlert() {
    send("DISMISS_LEGACY_ENTERPRISE_ALERT")
  }

  function sendDismissBillingDetailsAlert() {
    send("DISMISS_BILLING_DETAILS_ALERT")
  }

  const mainOrganization = getMainOrganization()

  const isMspDashboard = isPageMspDashboard(
    mainOrganization,
    window.location.pathname,
  )

  return {
    user,
    organizations,
    activeOrganization,
    featureFlags,
    subscriptions,
    activeSubscription,
    subscriptionByBeneficiary,
    loading,
    reloading,
    failure,
    subscriptionIssuesAlertDismissed,
    legacyEnterpriseAlertDismissed,
    billingDetailsAlertDismissed,
    msp,
    isMspDashboard,
    getSuborganizations,
    getMainOrganization,
    getMainOrganizationSubscriptionIssuesCount,
    getTypeActiveOrganization,
    sendDismissSubscriptionIssuesAlert,
    sendDismissLegacyEnterpriseAlert,
    sendDismissBillingDetailsAlert,
    sendReady,
    sendReload,
    sendLogout,
    sendRetry,
  }
}

export default useSession
