import loadAgentsCall from "api/agents/loadAgentsCall"
import deleteAgentCall from "api/agents/deleteAgentCall"
import loadAgentsStatusCall from "api/agents/loadAgentsStatusCall"
import updateAgentsCall from "api/agents/updateAgentsCall"
import { showNotificationWithTTL } from "actions/notifications"
import { selectVisibleLanProxiesIds } from "reducers/lanProxies"
import { loadFilteringSchedules } from "actions/filteringSchedules"
import { loadPolicies } from "actions/filtering"
import { loadBlockPages } from "actions/blockPages"
import {
  LOAD_LAN_PROXIES,
  TOGGLE_EDIT_MULTIPLE_LAN_PROXIES_FORM,
  CHANGE_LAN_PROXIES_SEARCH_TEXT_FILTER,
  CHANGE_LAN_PROXIES_STATUS_FILTER,
  CHANGE_ALL_LAN_PROXIES,
  SELECT_PROXY,
  CHANGE_LAN_PROXY_PAGE,
  CHANGE_LAN_PROXY,
  CHANGE_MULTIPLE_LAN_PROXIES,
  UPDATE_MULTIPLE_LAN_PROXIES_REQUEST,
  LOAD_PROXIES_STATUS,
  REMOVE_LAN_PROXY,
  DELETE_LAN_PROXY_REQUEST,
  REMOVE_MULTIPLE_PROXIES,
  SHOW_CONFIRM_DELETE_RELAY_MODAL,
  HIDE_CONFIRM_DELETE_RELAY_MODAL,
  UNSELECT_ALL_LAN_PROXIES,
  UPDATE_SELECTED_TAGS,
} from "action-types/lanProxies"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"

export const loadLanProxiesRequest = actionCreatorFetch(LOAD_LAN_PROXIES)

export const loadLanProxiesSuccess = actionCreatorSuccess(LOAD_LAN_PROXIES)

export const loadLanProxiesFailure = actionCreatorFailure(LOAD_LAN_PROXIES)

export const removeLanProxyRequest = actionCreatorFetch(
  DELETE_LAN_PROXY_REQUEST,
)

export const removeLanProxySuccess = actionCreatorSuccess(
  DELETE_LAN_PROXY_REQUEST,
)

export const removeLanProxyFailure = actionCreatorFailure(
  DELETE_LAN_PROXY_REQUEST,
)

export const loadProxiesStatusRequest = actionCreatorFetch(LOAD_PROXIES_STATUS)

export const loadProxiesStatusSuccess =
  actionCreatorSuccess(LOAD_PROXIES_STATUS)

export const loadProxiesStatusFailure =
  actionCreatorFailure(LOAD_PROXIES_STATUS)

export const updateMultipleLanProxiesRequest = actionCreatorFetch(
  UPDATE_MULTIPLE_LAN_PROXIES_REQUEST,
)

export const updateMultipleLanProxiesSuccess = actionCreatorSuccess(
  UPDATE_MULTIPLE_LAN_PROXIES_REQUEST,
)

export const updateMultipleLanProxiesFailure = actionCreatorFailure(
  UPDATE_MULTIPLE_LAN_PROXIES_REQUEST,
)

export const loadLanProxies =
  ({ dispatchRequest = true, text, status, networkId } = {}) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(
        loadLanProxiesRequest({
          data: [],
        }),
      )
    }

    return loadAgentsCall(networkId, text, status, "proxy")
      .then((data) => dispatch(loadLanProxiesSuccess(data)))
      .catch(() => {
        dispatch(loadLanProxiesFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "The was a problem while loading active relays",
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const deleteLanProxy = (lanProxy) => (dispatch, getState) => {
  let idToBeDeleted = getState().lanProxies.idToBeDeleted

  if (!idToBeDeleted && lanProxy.status !== "uninstalled_from_client") {
    dispatch(showConfirmDeleteModal(lanProxy.id))

    return
  }

  idToBeDeleted = idToBeDeleted || lanProxy.id
  dispatch(removeLanProxyRequest(idToBeDeleted))

  return deleteAgentCall(idToBeDeleted, "proxy").then(
    () => {
      dispatch(
        showNotificationWithTTL(
          {
            type: "success",
            title: "Relay deleted!",
            message: null,
            icon: "check",
          },
          6,
        ),
      )

      dispatch(removeLanProxySuccess())
      dispatch(hideConfirmDeleteModal())
      dispatch(removeLanProxy(idToBeDeleted))
    },
    (error) => {
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "Error deleting Relay",
          message: null,
        }),
      )

      throw error
    },
  )
}

export const loadProxiesStatus = () => (dispatch) => {
  dispatch(
    loadProxiesStatusRequest({
      activeProxiesIds: [],
    }),
  )

  return loadAgentsStatusCall("proxy")
    .then((activeProxiesIds) =>
      dispatch(
        loadProxiesStatusSuccess({
          activeProxiesIds,
        }),
      ),
    )
    .catch(() => {
      dispatch(loadProxiesStatusFailure())

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "The was a problem while loading active relays",
          message: "",
          icon: "exclamation-circle",
        }),
      )
    })
}

export const deleteMultipleProxies = () => (dispatch, getState) => {
  dispatch(removeLanProxyRequest())

  const proxyIds = selectVisibleLanProxiesIds(getState())
  const requests = proxyIds.map((proxyId) => deleteAgentCall(proxyId, "proxy"))

  Promise.all(requests)
    .then(() => {
      dispatch(
        showNotificationWithTTL(
          {
            type: "success",
            title: "Relays deleted!",
            message: null,
            icon: "check",
          },
          6,
        ),
      )

      dispatch(loadLanProxies())
      dispatch(removeLanProxySuccess())
      dispatch(hideConfirmDeleteModal())
      dispatch(removeLanProxies(proxyIds))
      dispatch(toggleEditMultipleLanProxiesForm(false))
    })
    .catch((reason) => {
      dispatch(removeLanProxyFailure())

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "Error deleting Relays",
          message: "Failed to delete.",
          icon: "exclamation-circle",
        }),
      )
    })
}

export const updateLanProxy = (lanProxy, afterSaveCallback) => {
  lanProxy.block_page_id = lanProxy.block_page_id || null
  lanProxy.policy_id = lanProxy.policy_id || null
  lanProxy.scheduled_policy_id = lanProxy.scheduled_policy_id || null

  return (dispatch, getState) =>
    updateAgentsCall(lanProxy, "proxy").then(
      () => {
        dispatch(
          showNotificationWithTTL(
            {
              type: "success",
              title: `Relay ${
                lanProxy.friendly_name || lanProxy.hostname
              } updated!`,
              message: null,
              icon: "check",
            },
            6,
          ),
        )

        dispatch(changeLanProxy(lanProxy))
        dispatch(loadLanProxies())

        if (afterSaveCallback) {
          afterSaveCallback()
        }

        dispatch(loadPolicies(false))
        dispatch(loadFilteringSchedules(false))
        dispatch(loadBlockPages(false))
      },
      (error) => {
        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: lanProxy.friendly_name || lanProxy.hostname,
            message: `Failed to update "${
              lanProxy.friendly_name || lanProxy.hostname
            }"`,
          }),
        )

        throw error
      },
    )
}

export const updateMultipleLanProxies =
  (
    {
      selectedLanProxiesIds,
      policy_id,
      scheduled_policy_id,
      block_page_id,
      network_id,
      tags,
    },
    afterSaveCallback,
  ) =>
  (dispatch, getState) => {
    dispatch(updateMultipleLanProxiesRequest())

    const selectedLanProxies = selectedLanProxiesIds.map((proxyId) =>
      getState().lanProxies.data.find((proxy) => proxy.id === proxyId),
    )
    const updatedProxies = selectedLanProxies.map((proxy) => {
      let policyPayload
      let scheduledPolicyPayload
      let blockPagePayload

      if (policy_id === "no-policy" || scheduled_policy_id) {
        policyPayload = null
      } else if (!policy_id) {
        policyPayload = proxy.policy_id ? proxy.policy_id : null
      } else {
        policyPayload = policy_id
      }

      if (policy_id) {
        scheduledPolicyPayload = null
      } else if (scheduled_policy_id) {
        scheduledPolicyPayload = scheduled_policy_id
      } else {
        scheduledPolicyPayload = proxy.scheduled_policy_id
          ? proxy.scheduled_policy_id
          : null
      }

      if (!block_page_id) {
        blockPagePayload = proxy.block_page_id ? proxy.block_page_id : null
      } else if (block_page_id) {
        blockPagePayload =
          block_page_id === "default-appearance" ? null : block_page_id
      }

      return {
        ...proxy,
        tags: tags || proxy.tags,
        policy_id: policyPayload,
        scheduled_policy_id: scheduledPolicyPayload,
        block_page_id: blockPagePayload,
        network_id: network_id || proxy.network_id,
      }
    })
    const requests = updatedProxies.map((proxy) =>
      updateAgentsCall(proxy, "proxy"),
    )

    Promise.all(requests)
      .then(() => {
        dispatch(
          showNotificationWithTTL(
            {
              type: "success",
              title: "Relays updated!",
              message: null,
              icon: "check",
            },
            6,
          ),
        )

        dispatch(updateMultipleLanProxiesSuccess())
        dispatch(toggleEditMultipleLanProxiesForm(false))
        dispatch(changeLanProxies(updatedProxies))

        if (afterSaveCallback) {
          afterSaveCallback()
        }

        dispatch(loadPolicies(false))
        dispatch(loadFilteringSchedules(false))
        dispatch(loadBlockPages(false))
      })
      .catch((reason) => {
        dispatch(updateMultipleLanProxiesFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "Error updating relays.",
            message: "Failed to update.",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export function toggleEditMultipleLanProxiesForm(showForm) {
  return {
    type: TOGGLE_EDIT_MULTIPLE_LAN_PROXIES_FORM,
    payload: showForm,
  }
}

export function showConfirmDeleteModal(proxyId) {
  return {
    type: SHOW_CONFIRM_DELETE_RELAY_MODAL,
    payload: proxyId,
  }
}

export function hideConfirmDeleteModal(proxyId) {
  return {
    type: HIDE_CONFIRM_DELETE_RELAY_MODAL,
    payload: proxyId,
  }
}

export function removeLanProxy(proxyId) {
  return {
    type: REMOVE_LAN_PROXY,
    payload: proxyId,
  }
}

export function removeLanProxies(proxyIds) {
  return {
    type: REMOVE_MULTIPLE_PROXIES,
    payload: proxyIds,
  }
}

export const unselectAllLanProxies = () => ({
  type: UNSELECT_ALL_LAN_PROXIES,
})

export const updateSelectedTag = (payload) => ({
  type: UPDATE_SELECTED_TAGS,
  payload,
})

export const updateSearchText = (searchText) => ({
  type: CHANGE_LAN_PROXIES_SEARCH_TEXT_FILTER,
  payload: searchText,
})

export const updateStatusFilter = (status) => ({
  type: CHANGE_LAN_PROXIES_STATUS_FILTER,
  payload: status,
})

export const updateSelectAllLanProxies = ({ allChecked, visibleIds }) => ({
  type: CHANGE_ALL_LAN_PROXIES,
  payload: {
    allChecked,
    visibleIds,
  },
})

export const selectLanProxy = (proxyId) => ({
  type: SELECT_PROXY,
  payload: proxyId,
})

export const changePage = (page) => ({
  type: CHANGE_LAN_PROXY_PAGE,
  payload: page,
})

export function changeLanProxy(lanProxy) {
  return {
    type: CHANGE_LAN_PROXY,
    payload: lanProxy,
  }
}

export function changeLanProxies(lanProxies) {
  return {
    type: CHANGE_MULTIPLE_LAN_PROXIES,
    payload: lanProxies,
  }
}
