import {
  USER_SIGN_UP_REQUEST,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_FAILURE,
  CLEAR_SIGN_UP,
} from "action-types/signup"
import axios from "axios"
import signupCall from "api/signup/signupCall"
import { login } from "actions/auth"

export const userSignupRequest = () => ({
  type: USER_SIGN_UP_REQUEST,
})

export const userSignupSuccess = () => ({
  type: USER_SIGN_UP_SUCCESS,
})

export const userSignupFailure = (error) => ({
  type: USER_SIGN_UP_FAILURE,
  payload: error,
})

const getUserLocation = () =>
  axios.get(
    `${process.env.REACT_APP_LOCATION_LOOKUP_URL}/check?access_key=${process.env.REACT_APP_IPSTACK_KEY}&format=1`,
  )

const handleSignup = (formValues, dispatch) =>
  signupCall(formValues)
    .then((response) => response.json())
    .then((data) => {
      let error = ""

      if (data.code === "user_exists") {
        error = `${formValues.email} is already registered`

        return dispatch(
          userSignupFailure({
            error,
          }),
        )
      }

      if (data.code) {
        error = `Failed to signup: ${data.message}`

        return dispatch(
          userSignupFailure({
            error,
          }),
        )
      }

      dispatch(userSignupSuccess(data))

      return setTimeout(() => {
        dispatch(login(formValues, false))
      }, 2000)
    })

export const userSignup = (values) => (dispatch) => {
  dispatch(userSignupRequest())

  return getUserLocation()
    .then(({ data }) => {
      const location = `${data.city}, ${data.region_name}, ${data.region_code}, ${data.country_name}`
      const formValues = {
        ...values,
        location,
      }

      return handleSignup(formValues, dispatch)
    })

    .catch(() => handleSignup(values, dispatch))
}

export const clearSignup = () => ({
  type: CLEAR_SIGN_UP,
})
