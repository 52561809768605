import callAPI from "callAPI"

export default ({ id: networkId, localDomains, localResolvers }) => {
  let local_domains = localDomains

  if (localDomains?.length === 1 && localDomains[0] === "") {
    local_domains = []
  }

  let local_resolvers = localResolvers

  if (localResolvers?.length === 1 && localResolvers[0] === "") {
    local_resolvers = []
  }

  return callAPI({
    method: "PUT",
    path: `/networks/${networkId}`,
    body: {
      network: {
        local_domains,
        local_resolvers,
      },
    },
  })
}
