import moment from "moment"
import get from "lodash/get"

export const formatRequestData = (data) =>
  data.map((item) => ({
    ...item.attributes,
    id: item.id,
    clientName: item.attributes.friendly_name || item.attributes.hostname,
    tags: item.attributes.tags
      ? item.attributes.tags.reduce((acc, tag) => `${tag},${acc}`, "")
      : "",
    network_id: Number(get(item, "relationships.network.data.id", null)),
    policy_id: Number(get(item, "relationships.policy.data.id", null)),
    scheduled_policy_id: Number(
      get(item, "relationships.scheduled_policy.data.id", null),
    ),
    block_page_id: Number(get(item, "relationships.block_page.data.id", null)),
    last_sync: item.attributes.last_sync
      ? moment
          .utc(item.attributes.last_sync)
          .local()
          .format("MM/DD/YYYY HH:mm:ss")
      : "",
  }))

export default formatRequestData
