import isDevEnv from "./isDevEnv"
import { isStagingEnv } from "./isStagingEnv"

const DEFAULT_DEV_RESOLVERS = "216.18.215.109"
const DEFAULT_STAGING_RESOLVERS = "172.98.75.82"
const DEFAULT_PRODUCTION_RESOLVERS = "103.247.36.36, 103.247.37.37"

const currentDNSResolverAsString = () => {
  if (
    process.env.QUERY_PROCESSOR_IPS !== undefined &&
    String(process.env.QUERY_PROCESSOR_IPS).length > 0
  ) {
    return String(process.env.QUERY_PROCESSOR_IPS)
  }

  if (isDevEnv()) {
    return DEFAULT_DEV_RESOLVERS
  }

  if (isStagingEnv()) {
    return DEFAULT_STAGING_RESOLVERS
  }

  return DEFAULT_PRODUCTION_RESOLVERS
}

const currentDNSResolvers = () =>
  currentDNSResolverAsString().trim().split(/[, ]+/)

export const primaryDNSResolver = () => currentDNSResolvers()[0]

export const secondaryDNSResolver = () => currentDNSResolvers()[1] || ""

export const showDNSResolvers = ({ asSentence = false }) => {
  const resolvers = currentDNSResolvers()

  if (!asSentence) {
    return resolvers.join(", ")
  }

  switch (resolvers.length) {
    case 1:
      return resolvers[0]
    case 2:
      return resolvers.join(" and ")
    default:
      return (
        resolvers.slice(0, resolvers.length - 1).join(", ") +
        ", and " +
        resolvers[resolvers.length - 1]
      )
  }
}

export default showDNSResolvers
