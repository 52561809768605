import React from "react"

export function Grid(props) {
  return (
    <div
      className="Grid"
      style={{
        display: "grid",
        gridTemplateColumns: props.templateColumns,
        gridTemplateRows: props.templateRows,
        gridAutoFlow: props.autoFlow,
        gap: props.gap ? `var(--spacing--${props.gap})` : undefined,
        justifyItems: props.justifyItems,
        alignItems: props.alignItems,
      }}
    >
      {props.children}
    </div>
  )
}
