import React from "react"
import PropTypes from "prop-types"

const Avatar = (props) => (
  <img
    alt="avatar"
    src={props.userPicture}
    height={props.size}
    width={props.size}
    style={{
      borderRadius: "50%",
    }}
  />
)

Avatar.propTypes = {
  size: PropTypes.number.isRequired,
  userPicture: PropTypes.string.isRequired,
}

export default Avatar
