import React from "react"

import { Spinner as BaseSpinner } from "@blueprintjs/core"

import ICON_SIZES from "_v2/Core/Constants/ICON_SIZES"

function Spinner(props) {
  return <BaseSpinner size={ICON_SIZES[props.size]} />
}

export default Spinner
