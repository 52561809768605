import React, { cloneElement } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
  ControlGroup as BlueprintControlGroup,
  Button,
} from "@blueprintjs/core"

const ControlGroup = (props) => (
  <BlueprintControlGroup
    fill={props.fill}
    vertical={props.vertical}
    className={props.errors ? "bp3-intent-danger" : props.className}
  >
    {(props.leftButtonIcon || props.leftButtonLabel) && (
      <Button
        data-test-id="control-group-left"
        icon={props.leftButtonIcon}
        onClick={props.onLeftButtonClick}
        className={classNames(
          !props.onLeftButtonClick && "read-only",
          props.large && "bp3-large",
        )}
        tabIndex={!props.onLeftButtonClick && -1}
      >
        {props.leftButtonLabel}
      </Button>
    )}
    {cloneElement(props.children, {
      className: classNames(props.className, props.large && "bp3-large"),
    })}
    {(props.rightButtonIcon || props.rightButtonLabel) && (
      <Button
        data-test-id="control-group-right"
        className="bp3-align-right"
        icon={props.rightButtonIcon}
        onClick={props.onRightButtonClick}
      >
        {props.rightButtonLabel}
      </Button>
    )}
  </BlueprintControlGroup>
)

ControlGroup.propTypes = {
  fill: PropTypes.bool,
  vertical: PropTypes.bool,
  leftButtonIcon: PropTypes.string,
  leftButtonLabel: PropTypes.string,
  onLeftButtonClick: PropTypes.func,
  rightButtonIcon: PropTypes.string,
  rightButtonLabel: PropTypes.string,
  onRightButtonClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  error: PropTypes.node,
  help: PropTypes.node,
  className: PropTypes.string,
}

ControlGroup.defaultProps = {
  fill: false,
  vertical: false,
  leftButtonIcon: null,
  leftButtonLabel: null,
  onLeftButtonClick: null,
  rightButtonIcon: null,
  rightButtonLabel: null,
  onRightButtonClick: null,
  error: null,
  help: null,
  className: null,
}

export default ControlGroup
