import React from "react"
import PropTypes from "prop-types"

const Content = (props) =>
  props.minimal ? (
    props.children
  ) : (
    <div className="page-content">{props.children}</div>
  )

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
