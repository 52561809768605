import React from "react"
import PropTypes from "prop-types"
import { Alert as BlueprintAlert, Intent } from "@blueprintjs/core"
import "./Alert.css"

const Alert = ({
  intent,
  icon,
  confirmButtonText,
  cancelButtonText,
  isOpen,
  onConfirm,
  onCancel,
  children,
}) => (
  <BlueprintAlert
    className={`blue-print-alert-${intent}`}
    intent={intent && Intent[intent.toUpperCase()]}
    icon={icon}
    confirmButtonText={confirmButtonText}
    cancelButtonText={cancelButtonText}
    isOpen={isOpen}
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    {children}
  </BlueprintAlert>
)

Alert.propTypes = {
  intent: PropTypes.oneOf(["primary", "success", "warning", "danger"]),
  icon: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  children: PropTypes.node,
}

export default Alert
