import React from "react"
import { Tooltip as BaseTooltip } from "@blueprintjs/core"

export function Tooltip(props) {
  if (props.content === null) {
    return props.children
  }

  return (
    <BaseTooltip
      intent={props.intent}
      content={props.content}
      position={props.position}
      isOpen={props.open}
      disabled={props.disabled}
      onInteraction={props.onInteraction}
      onOpened={props.onOpened}
      onClose={props.onClose}
      onClosed={props.onClosed}
    >
      {props.children}
    </BaseTooltip>
  )
}
