import React from "react"
import { FormGroup as BaseFormGroup } from "@blueprintjs/core"
import { Grid } from "_Infinity/Core/Components/Grid"
import { Heading } from "_Infinity/Core/Components/Heading"
import { Text } from "_Infinity/Core/Components/Text"
import { Row } from "_Infinity/Core/Components/Row"
import "./FormGroup.css"

export function FormGroup(props) {
  return (
    <BaseFormGroup
      className="FormGroup"
      intent={props.intent}
      disabled={props.disabled}
    >
      {props.vertical ? (
        <Grid gap={2}>
          <Grid gap={1}>
            <Heading size={1}>{props.label}</Heading>
            {props.labelInfo && (
              <Text size={1} muted>
                {props.labelInfo}
              </Text>
            )}
          </Grid>
          <Row alignItems="center">{props.children}</Row>
          {props.caption && (
            <Text intent={props.intent} muted>
              {props.caption}
            </Text>
          )}
        </Grid>
      ) : (
        <Grid templateColumns="1fr 3fr" gap={6} alignItems={props.alignItems}>
          <Grid templateRows="repeat(2, max-content)" gap={1}>
            <Heading
              className={
                props.alignItems === "center"
                  ? undefined
                  : "FormGroup__Label--spaced"
              }
              size={1}
            >
              {props.label}
            </Heading>
            {props.labelInfo && (
              <Text size={1} muted>
                {props.labelInfo}
              </Text>
            )}
          </Grid>
          <Grid gap={2}>
            <Row alignItems="center">{props.children}</Row>
            {props.caption && (
              <Text intent={props.intent} muted>
                {props.caption}
              </Text>
            )}
          </Grid>
        </Grid>
      )}
    </BaseFormGroup>
  )
}
