import callAPI from "callAPI"

export default ({
  session,
  showIndividualNetworks,
  organizationIds,
  from,
  to,
  networkIds,
  agentIds,
  lanProxyIds,
  securityReport,
  type,
}) =>
  callAPI({
    path: "/traffic_reports/total_requests",
    query: {
      show_individual_networks: showIndividualNetworks,
      ...(session?.isMspDashboard && session.msp?.id
        ? { msp_id: session.msp?.id }
        : {
            organization_ids: organizationIds || localStorage.getItem("orgId"),
          }),
      from,
      to,
      network_ids: networkIds,
      agent_ids: agentIds,
      lan_proxy_ids: lanProxyIds,
      security_report: securityReport,
      type,
    },
  })
