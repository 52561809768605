import jwtDecode from "jwt-decode"
import moment from "moment"
import { __logout } from "actions/auth"

export default (response) => {
  const access_token = localStorage.getItem("access_token")

  if (!access_token) {
    return true
  }

  if (response?.status !== 401) {
    return true
  }

  try {
    if (moment().isAfter(moment.unix(jwtDecode(access_token).exp))) {
      __logout()

      const iframe = window.parent !== window

      if (iframe) {
        window.location.href = `/?distplatform=${localStorage.getItem(
          "distributor",
        )}`
      } else {
        window.location.reload()
      }

      return false
    }
  } catch {}

  return true
}
