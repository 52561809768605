import {
  LOAD_INIT_BILLING_DATA_REQUEST,
  LOAD_INIT_BILLING_DATA_SUCCESS,
  LOAD_INIT_BILLING_DATA_FAILURE,
} from "action-types/billing"

const initialState = {
  user: null,
  data: null,
  error: "",
  isLoading: false,
  isLoadingUser: false,
  isInTrial: false,
  statedNetworkCount: 0,
  isEducation: false,
  orgBillingData: null,
  loadingOrgBilling: false,
  orgBillingError: false,
  updateTokenError: false,
  billingToken: null,
  status: null,
}

export const initBilling = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INIT_BILLING_DATA_REQUEST:
      return {
        ...state,
        loadingOrgBilling: true,
        orgBillingError: false,
        status: "request",
      }
    case LOAD_INIT_BILLING_DATA_SUCCESS:
      return {
        ...state,
        loadingOrgBilling: false,
        orgBillingData: action.payload,
        status: "success",
      }
    case LOAD_INIT_BILLING_DATA_FAILURE:
      return {
        ...state,
        loadingOrgBilling: false,
        orgBillingError: true,
        status: "failure",
      }

    default:
      return state
  }
}

export default initBilling
