import React from "react"
import PropTypes from "prop-types"
import ReactSelect from "react-select"
import "react-select/dist/react-select.css"

const Select = (props) => (
  <ReactSelect
    {...props}
    value={props.value || ""}
    placeholder={props.placeholder || ""}
    clearable={props.clearable}
    onChange={(value) => {
      if (!props.onChange) {
        return
      }

      if (value && value.length === 0) {
        return props.onChange(null)
      }

      return props.onChange(value)
    }}
    onBlur={() => props.onBlur && props.onBlur(props.value)}
    optionComponent={props.optionComponent}
  />
)

Select.propTypes = {
  clearable: PropTypes.bool,
}

Select.defaultProps = {
  clearable: false,
}

export default Select
