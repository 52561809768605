import React, { Component } from "react"
import PropTypes from "prop-types"
import Spinner from "components/Spinner"
import { Tooltip } from "@blueprintjs/core"
import { colors } from "utils/colorPalette"

class WhitelistBlacklistButton extends Component {
  state = {
    isLoading: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.props.active) {
      this.setState({
        isLoading: false,
      })
    }

    if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const { active, onClick, children } = this.props

    return (
      <Tooltip
        disabled={!this.props.disabled}
        content={this.props.tooltipDisabled}
      >
        <div
          tabIndex={0}
          role="button"
          style={{
            backgroundColor: active ? colors.downy : colors.mystic,
            borderRadius: 3,
            color: active ? colors.white : colors.slateGray,
            cursor:
              this.state.isLoading || this.props.disabled
                ? "not-allowed"
                : "pointer",
            margin: 6,
            padding: "6px 15px",
          }}
          onClick={(event) => {
            event.stopPropagation()

            if (!this.state.isLoading && !this.props.disabled) {
              this.setState({
                isLoading: true,
              })

              onClick()
            }
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            {!this.state.isLoading && active && <i className="fa fa-check" />}
            {this.state.isLoading && <Spinner />}
            <span
              style={{
                marginLeft: ".5rem",
              }}
            >
              {children}
            </span>
          </div>
        </div>
      </Tooltip>
    )
  }
}

WhitelistBlacklistButton.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default WhitelistBlacklistButton
