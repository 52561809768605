export const LOAD_FILTERING_SCHEDULES = "LOAD_FILTERING_SCHEDULES"
export const AFTER_CREATE_FILTERING_SCHEDULE = "AFTER_CREATE_FILTERING_SCHEDULE"
export const AFTER_UPDATE_FILTERING_SCHEDULE = "AFTER_UPDATE_FILTERING_SCHEDULE"
export const AFTER_REMOVE_FILTERING_SCHEDULE = "AFTER_REMOVE_FILTERING_SCHEDULE"
export const SUBMITTING_FILTERING_SCHEDULE = "SUBMITTING_FILTERING_SCHEDULE"
export const UPDATE_FILTERING_SCHEDULE_SEARCH_TEXT =
  "UPDATE_FILTERING_SCHEDULE_SEARCH_TEXT"
export const UPDATE_FILTERING_SCHEDULE_STATUS_FILTER =
  "UPDATE_FILTERING_SCHEDULE_STATUS_FILTER"
export const UPDATE_ADD_NEW_SCHEDULE = "UPDATE_ADD_NEW_SCHEDULE"
export const UPDATE_EDIT_EXISTING_SCHEDULE = "UPDATE_EDIT_EXISTING_SCHEDULE"
export const UPDATE_DELETE_EXISTING_SCHEDULE = "UPDATE_DELETE_EXISTING_SCHEDULE"
export const UPDATE_SELECTED_ID = "UPDATE_SELECTED_ID"
