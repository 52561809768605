import { isPageMspDashboard } from "./isPageMspDashboard"

export const isUniversalListsVisible = (session, path) => {
  const mainOrg = session.getMainOrganization()
  const isPartOfMsp = mainOrg?.mspId || mainOrg?.ownedByMspId

  const isMspDashboard = isPageMspDashboard(mainOrg, path)

  // show Universal Lists on MSP dashboard
  if (isMspDashboard) {
    return true
  }

  // show Universal Lists on single orgs
  if (!isPartOfMsp) {
    return true
  }

  return false
}
