import { schema } from "normalizr"

class EntitySchema extends schema.Entity {
  constructor(...args) {
    super(...args)
    this.process = this._processStrategy
  }
}

function createEntitySchema(...args) {
  return new EntitySchema(...args)
}

export default createEntitySchema
