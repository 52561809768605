import get from "lodash/get"
import {
  LOAD_LAN_SUBNETS,
  CHANGE_LAN_SUBNETS_SEARCH_TEXT_FILTER,
  CHANGE_LAN_SUBNET_PAGE,
  CHANGE_LAN_SUBNET,
  REMOVE_LAN_SUBNET,
  DELETE_LAN_SUBNET_REQUEST,
  SHOW_CONFIRM_DELETE_SUBNET_MODAL,
  HIDE_CONFIRM_DELETE_SUBNET_MODAL,
  ADD_LAN_SUBNET,
  TOGGLE_ADD_FORM,
} from "action-types/lanSubnets"

const initialState = {
  data: [],
  requestStatus: "",
  searchText: "",
  selectedLanSubnetsIds: [],
  allLanSubnetsSelected: false,
  currentPage: 0,
  numberOfPages: 0,
  editMultipleLanSubnets: false,
  editMultipleLanSubnetsStatus: null,
  showConfirmDeleteModal: false,
  showAddSubnetForm: false,
  idToBeDeleted: null,
  relatedNetworkId: null,
  deleteLanSubnetStatus: null,
}

const lanSubnetsState = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LAN_SUBNETS:
      return {
        ...state,
        requestStatus: action.payload.status,
        data: [...action.payload.data],
        numberOfPages: action.payload.numberOfPages,
      }
    case ADD_LAN_SUBNET:
      return {
        ...state,
        data: [...state.data, action.payload],
      }
    case CHANGE_LAN_SUBNETS_SEARCH_TEXT_FILTER:
      return {
        ...state,
        searchText: action.payload,
      }
    case CHANGE_LAN_SUBNET_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case CHANGE_LAN_SUBNET:
      return {
        ...state,
        data: state.data.map((subnet) =>
          subnet.id === action.payload.id ? action.payload : subnet,
        ),
      }
    case REMOVE_LAN_SUBNET:
      return {
        ...state,
        data: state.data.filter((subnet) => subnet.id !== action.payload),
        selectedLanSubnetsIds: state.selectedLanSubnetsIds.filter(
          (agentId) => agentId !== action.payload,
        ),
        idToBeDeleted: null,
        relatedNetworkId: null,
      }
    case SHOW_CONFIRM_DELETE_SUBNET_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: true,
        idToBeDeleted: action.payload.subnetId,
        relatedNetworkId: action.payload.networkId,
      }
    case HIDE_CONFIRM_DELETE_SUBNET_MODAL:
      return {
        ...state,
        showConfirmDeleteModal: false,
        idToBeDeleted: null,
        relatedNetworkId: null,
      }
    case DELETE_LAN_SUBNET_REQUEST:
      return {
        ...state,
        deleteLanSubnetStatus: action.payload.status,
        idToBeDeleted: action.payload.subnetId,
        relatedNetworkId: action.payload.networkId,
      }
    case TOGGLE_ADD_FORM:
      return {
        ...state,
        showAddSubnetForm: action.payload,
      }
    default:
      return state
  }
}

export const selectVisibleSubnets = (state) => {
  const { data, searchText, selectedLanSubnetsIds } = state.lanSubnets
  const lowerCaseSearchText = searchText.toLowerCase()
  const lanSubnets = [...data]

  return lanSubnets
    .map((subnet) => ({
      ...subnet,
      selected: selectedLanSubnetsIds.includes(subnet.id),
      site: subnet.network_id
        ? state.networks.data.find(
            (network) => Number(network.id) === Number(subnet.network_id),
          )
        : null,
      policy: state.policies.data.find(
        (policy) => policy.id === subnet.policy_id,
      ),
      filteringSchedule: state.filteringSchedules.data.find(
        (filteringSchedule) =>
          filteringSchedule.id === subnet.scheduled_policy_id,
      ),
      blockPage: state.blockPages.data.find(
        (blockPage) => blockPage.id === subnet.block_page_id,
      ),
    }))
    .filter((subnet) => {
      let textMatches = !searchText

      if (subnet.name.toLowerCase().includes(lowerCaseSearchText)) {
        textMatches = true
      } else if (
        get(subnet, "site.name", "").toLowerCase().includes(lowerCaseSearchText)
      ) {
        textMatches = true
      }

      return textMatches
    })
    .filter((subnet) => subnet)
}

export const selectVisibleLanSubnetsIds = (state) =>
  selectVisibleSubnets(state)
    .filter(
      (subnet) =>
        state.lanSubnets.allLanSubnetsSelected ||
        state.lanSubnets.selectedLanSubnetsIds.includes(subnet.id),
    )
    .map((subnet) => subnet.id)

export default lanSubnetsState
