export const getActiveOrganization = (organizations) => {
  if (!organizations) {
    return null
  }

  return organizations.find((organization) =>
    localStorage.getItem("owned_msp_id")
      ? String(organization.owned_msp_id) ===
        localStorage.getItem("owned_msp_id")
      : String(organization.id) === localStorage.getItem("orgId"),
  )
}
