import qpsActiveAgentsCall from "api/reports/qpsActiveAgentsCall"
import moment from "moment"

export default async (type) => {
  const queriesPerSecond = await qpsActiveAgentsCall({
    from: moment().subtract(15, "minutes").startOf("minute").format(),
    to: moment().format(),
    type: type || "agent",
  })

  return queriesPerSecond.data.agent_ids
}
