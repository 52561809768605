import { LOAD_CATEGORIES, LOAD_ALL_CATEGORIES } from "action-types/categories"
import loadCategoriesCall from "api/categories/loadCategoriesCall"
import loadAllCategoriesCall from "api/categories/loadAllCategoriesCall"
import { showNotificationWithTTL } from "actions/notifications"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"

export const loadCategoriesRequest = actionCreatorFetch(LOAD_CATEGORIES)

export const loadCategoriesSuccess = actionCreatorSuccess(LOAD_CATEGORIES)

export const loadCategoriesFailure = actionCreatorFailure(LOAD_CATEGORIES)

export const loadAllCategoriesRequest = actionCreatorFetch(LOAD_ALL_CATEGORIES)

export const loadAllCategoriesSuccess =
  actionCreatorSuccess(LOAD_ALL_CATEGORIES)

export const loadAllCategoriesFailure =
  actionCreatorFailure(LOAD_ALL_CATEGORIES)

export const loadCategories =
  (dispatchRequest = true) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(
        loadCategoriesRequest({
          data: [],
        }),
      )
    }

    return loadCategoriesCall()
      .then((data) =>
        dispatch(
          loadCategoriesSuccess({
            data,
          }),
        ),
      )
      .catch(() => {
        dispatch(loadCategoriesFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "The was a problem while loading categories",
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const loadAllCategories =
  (dispatchRequest = true) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(
        loadAllCategoriesRequest({
          data: [],
        }),
      )
    }

    return loadAllCategoriesCall()
      .then((data) =>
        dispatch(
          loadAllCategoriesSuccess({
            data,
          }),
        ),
      )
      .catch(() => {
        dispatch(loadAllCategoriesFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "The was a problem while loading categories",
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }
