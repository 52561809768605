import v4 from "uuid"
import {
  LOAD_POLICIES,
  STORE_POLICY_CHANGES,
  CLEAN_POLICY_CHANGES,
  UPDATE_POLICY,
} from "action-types/filtering"

export const policies = (
  state = {
    data: [],
    status: null,
    showUpdatePolicyModal: false,
    storedPolicyChanges: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_POLICIES:
      return {
        ...state,
        ...action.payload,
      }
    case STORE_POLICY_CHANGES:
      return {
        ...state,
        showUpdatePolicyModal: true,
        storedPolicyChanges: action.payload,
      }

    case CLEAN_POLICY_CHANGES:
      return {
        ...state,
        showUpdatePolicyModal: false,
        storedPolicyChanges: null,
      }

    case UPDATE_POLICY:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          }

          return item
        }),
      }
    default:
      return state
  }
}

export const getFilteredPolicies = (state) => {
  let filteredPolicies

  if (!state.policyFilters.text) {
    filteredPolicies = state.policies.data
  } else {
    filteredPolicies = state.policies.data.filter(
      ({ name }) =>
        name.toLowerCase().indexOf(state.policyFilters.text.toLowerCase()) > -1,
    )
  }

  filteredPolicies = filteredPolicies.map((policy) => {
    const filteringScheduleCount = state.filteringSchedules.data.reduce(
      (acc, schedule) => {
        if (schedule.attributes.policy_ids.includes(policy.id)) {
          return acc + 1
        }

        return acc
      },
      0,
    )

    return {
      ...policy,
      active:
        policy.networkCount ||
        filteringScheduleCount ||
        policy.agentCount ||
        policy.collectionCount ||
        policy.agentLocalUsers,
      filteringScheduleCount,
    }
  })

  if (state.policyFilters.status !== "all") {
    filteredPolicies = filteredPolicies.filter((policy) =>
      state.policyFilters.status === "assigned"
        ? policy.active
        : !policy.active,
    )
  }

  return filteredPolicies
}

export const getPolicyById = (policiesList, policyId) => {
  const policy = policiesList.find(({ id }) => Number(policyId) === Number(id))

  return {
    ...policy,
    whitelistDomains:
      policy && policy.whitelist
        ? policy.whitelist.map((domain) => ({
            domain,
            _isNew: false,
            _id: v4(),
          }))
        : [],
    blacklistDomains:
      policy && policy.blacklist
        ? policy.blacklist.map((domain) => ({
            domain,
            _isNew: false,
            _id: v4(),
          }))
        : [],
  }
}

export default policies
