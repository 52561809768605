function init(config) {
  var SESSION_EXPIRATION_IN_MS = 60 * 1000 * 30 // 30 minutes

  window.analytics.ready(function () {
    // if mixpanel is not available we'll bail out.
    // this is necessary for preventing a crash in the app.
    if (!window.mixpanel) {
      return
    }

    // last activity will be used to allow last touch to be updated after 30 minutes of inactivity (end of user session)
    var lastActivity = window.mixpanel.get_property("last_activity")
    var timeInactive = Date.now() - lastActivity

    var sessionStatus = !lastActivity
      ? "firstVisit"
      : timeInactive < SESSION_EXPIRATION_IN_MS
      ? "active"
      : "inactive"

    registerInitialPage(sessionStatus)
    registerInitialVisitDate(sessionStatus)
    registerReferrer(sessionStatus)
    registerCampaignKeywords(sessionStatus)
    registerLastActivity()
    registerAppParams()
    identitfyUser()
    trackPageView()
  })

  function registerInitialPage(sessionStatus) {
    // let's bail out if this is not the first visit
    if (sessionStatus !== "firstVisit") {
      return
    }

    window.mixpanel.register_once({
      "initial page": window.location.href,
    })
  }

  function registerInitialVisitDate(sessionStatus) {
    // let's bail out if this is not the first visit
    if (sessionStatus !== "firstVisit") {
      return
    }

    window.mixpanel.register_once({
      "initial visit date": new Date().toISOString(),
    })
  }

  function registerReferrer(sessionStatus) {
    // let's bail out if the session is active, i.e. referrer is only registered
    // in the first visit or when session is inactive
    if (sessionStatus === "active") {
      return
    }

    var referrer = "not set"
    var referrerHostname = "not set"
    var referrerPath = "not set"

    if (document.referrer) {
      referrer = document.referrer

      try {
        referrerHostname = new URL(referrer).hostname
        referrerPath = new URL(referrer).pathname
      } catch (error) {
        referrerHostname = parseURL(referrer).hostname
        referrerPath = parseURL(referrer).pathname
      }
    }

    if (sessionStatus === "firstVisit") {
      window.mixpanel.register_once({
        "initial referrer hostname": referrerHostname,
      })
    }

    window.mixpanel.register({
      "referrer": referrer,
      "referrer hostname": referrerHostname,
      "referrer path": referrerPath,
    })
  }

  function registerCampaignKeywords(sessionStatus) {
    var keywords = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
      "gclid",
    ]

    var firstTouchUTMs = {}
    var lastTouchUTMs = {}

    keywords.forEach(function (keyword) {
      var utmValue = getQueryParam(keyword) || "not set"

      switch (sessionStatus) {
        case "firstVisit": {
          // no previous session exist allow first & last touch to be set using new values
          firstTouchUTMs[keyword] = utmValue
          lastTouchUTMs[keyword + " last touch"] = utmValue
          break
        }

        case "active": {
          // no url params need to be set during existing open session
          // but they do need to be captured for Salesforce
          lastTouchUTMs[keyword + " last touch"] = window.mixpanel.get_property(
            keyword + " last touch",
          )
          break
        }

        case "inactive": {
          // the previous session timed out allow last touch to be set using new values and load first touch from MixPanel
          firstTouchUTMs[keyword] = window.mixpanel.get_property(keyword)
          lastTouchUTMs[keyword + " last touch"] = utmValue
          break
        }

        default: {
          break
        }
      }
    })

    // Set first touch UTMs as Super Properties that will live for the life of the cookie
    if (Object.keys(firstTouchUTMs).length) {
      window.mixpanel.register_once(firstTouchUTMs)
    }

    // Set last touch, which will live for duration of the session
    if (Object.keys(lastTouchUTMs).length) {
      window.mixpanel.register(lastTouchUTMs)
    }
  }

  function registerLastActivity() {
    window.mixpanel.register({
      last_activity: Date.now(),
    })
  }

  function registerAppParams() {
    if (!config.shouldRegisterAppParams) {
      return
    }

    // if user_id is already available, it means we already registered
    // so we can bail out
    if (window.mixpanel.get_property("user_id")) {
      return
    }

    window.mixpanel.register_once({
      user_id: config.getUserId(),
      email: config.getUserEmail(),
      organization_id: config.getOrganizationId(),
      organization_name: config.getOrganizationName(),
    })
  }

  function identitfyUser() {
    window.analytics.identify(config.getUserId(), {
      email: config.getUserEmail(),
      organization_id: config.getOrganizationId(),
      organization_name: config.getOrganizationName(),
    })
  }

  function trackPageView() {
    var pathnameSegments = window.location.pathname.split("/")

    const organization_id = config.getOrganizationId()

    ///don't fire the event when the org id is the same as the msp_id of the url
    if (window.location.pathname?.includes(`/msp/${organization_id}/`)) {
      return
    }

    window.analytics.track("page viewed", {
      "title": document.title || "",
      "url": window.location.href,
      "path": window.location.pathname,
      "email": config.getUserEmail(),
      "page path level 1": pathnameSegments[1] || "",
      "page path level 2": pathnameSegments[2] || "",
      "page path level 3": pathnameSegments[3] || "",
      "search": window.location.search.slice(1) || "",
      "page hash": window.location.hash || "",
      "page hostname": window.location.hostname,
      "organization_name": config.getOrganizationName(),
      "organization_id": organization_id,
      "user_id": config.getUserId(),
    })
  }

  function getQueryParam(param) {
    try {
      return new URLSearchParams(window.location.search).get(param)
    } catch (error) {
      // Expects a raw URL
      param = param.replace(/[[]/, "[").replace(/[\]]/, "]")
      var regexS = "[?&]" + param + "=([^&#]*)",
        regex = new RegExp(regexS),
        results = regex.exec(document.URL.toLowerCase())

      if (
        results === null ||
        (results && typeof results[1] !== "string" && results[1].length)
      ) {
        return null
      }

      return decodeURIComponent(results[1])
    }
  }

  function parseURL(url) {
    var a = document.createElement("a")
    a.href = url
    return {
      hostname: a.hostname,
      pathname: a.pathname,
    }
  }
}

export default init
