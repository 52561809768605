import { LOAD_LOGS } from "action-types/queryLog"
import get from "lodash/get"

const initialState = {
  page: 1,
  totalPages: null,
  fetching: false,
  size: 100,
}

export const queryLogs = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LOGS:
      return {
        ...state,
        ...action.payload,
        data: get(action.payload, "data.values", state.data),
        currentPage: get(action.payload, "data.page.self", state.currentPage),
        totalPages: get(action.payload, "data.page.last", state.totalPages),
      }
    default:
      return state
  }
}

export default queryLogs
