import React from "react"
import Media from "react-media"
import PropTypes from "prop-types"
import Modal from "components/Modal"
import DomainLookupForm from "views/tools/domain-lookup/DomainLookupForm"

const DomainLookupModal = ({ isOpen, onClose }) => (
  <Media query="(max-width: 420px)">
    {(matches) => (
      <Modal
        iconName="geosearch"
        title="Domain Report"
        isOpen={isOpen}
        onClose={onClose}
        style={{
          width: matches ? 300 : 400,
        }}
      >
        <DomainLookupForm />
      </Modal>
    )}
  </Media>
)

DomainLookupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DomainLookupModal
