import React, { useContext } from "react"

import { useLocation } from "react-router-dom"

import { Icon } from "@blueprintjs/core"

import { SessionContext } from "_v2/Modules/Auth"

import "./SubscriptionIssuesAlert.css"

function SubscriptionIssuesAlert() {
  let location = useLocation()
  let session = useContext(SessionContext)
  let mainOrganization = session.getMainOrganization()
  let subscriptionIssuesCount =
    session.getMainOrganizationSubscriptionIssuesCount()

  if (localStorage.getItem("organization.isMSPSubAccount")) {
    return null
  }

  if (session.user?.acl?.isLegacyEnterprise) {
    return null
  }

  if (location.pathname.endsWith("/settings/billing-info/subscription")) {
    return null
  }

  if (session.loading) {
    return null
  }

  if (session.subscriptionIssuesAlertDismissed) {
    return null
  }

  if (!mainOrganization.showBilling) {
    return null
  }

  if (subscriptionIssuesCount === 0) {
    return null
  }

  if (
    !session.user.rolesByOrganization[mainOrganization.id].match(
      /owner|administrator/,
    )
  ) {
    return null
  }

  if (mainOrganization.trialDaysLeft > 7) {
    return null
  }

  if (mainOrganization.manualSubscriptions) {
    return null
  }

  return (
    <div className="SubscriptionIssuesAlert">
      <p>
        {mainOrganization.type === "msp" ? (
          <strong>
            You have {subscriptionIssuesCount} organizations with no
            subscription.
          </strong>
        ) : (
          <strong>You have not chosen a subscription yet</strong>
        )}{" "}
        Please{" "}
        <a
          href="#billing"
          onClick={(e) => {
            e.preventDefault()
            localStorage.setItem("orgId", mainOrganization.id)

            if (mainOrganization.type === "msp") {
              localStorage.setItem("owned_msp_id", mainOrganization.mspId)
            } else {
              localStorage.removeItem("owned_msp_id")
            }

            window.location.assign(
              `/${mainOrganization.type === "msp" ? "msp" : "organizations"}/${
                mainOrganization.id
              }/settings/billing-info/subscription`,
            )
          }}
        >
          choose a subscription
        </a>{" "}
        to avoid interruption in service
      </p>
      <a
        href="#close"
        onClick={(e) => {
          e.preventDefault()
          session.sendDismissSubscriptionIssuesAlert()
        }}
      >
        <Icon icon="cross" />
      </a>
    </div>
  )
}

export default SubscriptionIssuesAlert
