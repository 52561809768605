import React, { useState } from "react"
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect"
import { FormContext } from "_Infinity/Core/Contexts/FormContext"

export function Form(props) {
  let [values, setValues] = useState(props.initialValues)
  let [submitted, setSubmitted] = useState(false)
  let finalValues = props.values ?? values ?? {}
  let errors = props.validate?.(finalValues) ?? null

  useDeepCompareEffectNoCheck(() => {
    if (props.initialValues === undefined) {
      return
    }

    setValues(props.initialValues)
  }, [props.initialValues])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        setSubmitted(true)

        if (props.onSubmit !== undefined) {
          props.onSubmit(finalValues)
        }

        if (errors !== null) {
          return
        }

        if (props.onValidate === undefined) {
          return
        }

        props.onValidate(finalValues)
      }}
    >
      <FormContext.Provider
        value={{
          initialValues: props.initialValues,
          values: finalValues,
          errors: submitted ? errors : null,
          submitted,
          readOnly: props.readOnly,
          disabled: props.disabled,
          setValue(name) {
            return (value) => {
              let nextValues = {
                ...finalValues,
                [name]: value,
              }

              setValues(nextValues)

              if (props.onChange === undefined) {
                return
              }

              props.onChange(nextValues)
            }
          },
        }}
      >
        {props.children}
      </FormContext.Provider>
    </form>
  )
}
