import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import get from "lodash/get"

const FormFormik = (props) => (
  <Formik
    initialValues={props.initialValues}
    validateOnBlur={props.validateOnBlur}
    validateOnChange={props.validateOnChange}
    validate={(values) => {
      let errors = {}

      const validationsKeys = Object.keys(props.validations)

      errors = validationsKeys.reduce((acc, element) => {
        const evaluations = props.validations[element]
        if (evaluations) {
          evaluations.forEach((evaluation) => {
            const message = evaluation(values[element], values)
            if (message) {
              acc[element] = message
            }
          })
        }

        return acc
      }, {})

      return errors
    }}
    enableReinitialize={props.enableReinitialize}
    onSubmit={props.onSubmit}
    onReset={props.onReset}
  >
    {(formik) => (
      <Form>
        {props.children({
          getValue: (path) => get(formik.values, path, ""),
          getError: (path) =>
            get(formik.touched, path, false) && get(formik.errors, path, ""),
          getErrors: () => formik.errors,
          isTouched: (path) => get(formik.touched, path, false),
          setTouched: (path, isTouched) => {
            formik.setFieldTouched(path, isTouched)
          },
          setValue: (path, value) => {
            const realValue = get(value, "target.value", value)
            formik.setFieldValue(path, realValue)
          },
          setValues: formik.setValues,
          unTouch: (path) => {
            formik.setFieldTouched(path, false)
          },
          setSubmitting: formik.setSubmitting,
          submitForm: formik.submitForm,
          resetForm: formik.resetForm,
          isSubmitting: formik.isSubmitting,
          isSubmitted: formik.submitCount > 0,
          getInitialValues: () => props.initialValues,
          values: formik.values,
        })}
      </Form>
    )}
  </Formik>
)

FormFormik.propTypes = {
  // FIX: Change instanceOf prop-types
  initialValues: PropTypes.instanceOf(Object),
  validations: PropTypes.instanceOf(Object),
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
}

FormFormik.defaultProps = {
  initialValues: null,
  validations: null,
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: false,
  onReset: () => {},
}

export default FormFormik
