import React from "react"
import * as R from "ramda"
import { useLocalStorageItem } from "_Infinity/Core/Hooks/useLocalStorageItem"
import { KnobsContext } from "_Infinity/Knobs/Contexts/KnobsContext"

export function KnobsProvider(props) {
  let [values, setValues] = useLocalStorageItem("knob-values", {})

  let enhancedKnobs = props.knobs.map((knob) => {
    let value = values[knob.id]

    return {
      ...knob,
      value: value ?? knob.defaultValue,
      pristine: value === undefined || value === knob.defaultValue,
    }
  })

  return (
    <KnobsContext.Provider
      value={{
        getAll() {
          return enhancedKnobs
        },
        getChangeCount() {
          return R.pipe(
            R.filter(R.complement(R.prop("pristine"))),
            R.length,
          )(enhancedKnobs)
        },
        getById(id) {
          return enhancedKnobs.find(R.propEq("id", id)) ?? {}
        },
        setValue(id) {
          return (value) => {
            setValues(
              R.mergeLeft({
                [id]: value,
              }),
            )
          }
        },
        resetValue(id) {
          return () => {
            setValues(
              R.mergeLeft({
                [id]: props.knobs.find(R.propEq("id", id)).defaultValue,
              }),
            )
          }
        },
      }}
    >
      {props.children}
    </KnobsContext.Provider>
  )
}
