import React, { cloneElement, useContext } from "react"
import { FormContext } from "_Infinity/Core/Contexts/FormContext"
import { FormGroup } from "_Infinity/Core/Components/FormGroup"

export function FormField(props) {
  let form = useContext(FormContext)
  let error = form.errors?.[props.name]

  function getInputProps(params = {}) {
    return {
      intent: error ? "danger" : params.intent,
      [params.valueKey ?? "value"]:
        params.value ?? form.values[props.name] ?? "",
      readOnly: params.readOnly ?? form.readOnly,
      disabled: params.disabled ?? form.disabled,
      onChange: params.onChange ?? form.setValue(props.name),
    }
  }

  return (
    <FormGroup
      intent={error ? "danger" : undefined}
      label={props.label}
      caption={error ?? props.caption}
    >
      {typeof props.children === "function"
        ? props.children(getInputProps)
        : cloneElement(props.children, getInputProps(props.children.props))}
    </FormGroup>
  )
}
