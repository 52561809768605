import callAPI from "callAPI"

export default async (allPolicies) => {
  const URL = allPolicies ? "/scheduled_policies/all" : "/scheduled_policies"
  let filteringSchedules = await callAPI({
    path: URL,
    query: {
      organization_id: localStorage.getItem("orgId"),
    },
  })

  return filteringSchedules.data.map((data) => ({
    ...data,
    id: Number(data.id),
    name: data.attributes.name,
    networkCount: data.relationships.networks.data.length,
    agentCount: data.relationships.user_agents.data.length,
    agentLocalUsers: data.relationships.agent_local_users.data.length,
  }))
}
