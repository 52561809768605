import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Input from "components/Input"

const InputGroup = ({ className, leftIcon, rightElement, ...rest }) => (
  <div className={classNames("bp3-input-group", className)}>
    {leftIcon && <span className={`bp3-icon bp3-icon-${leftIcon}`} />}
    <Input {...rest} />
    {rightElement}
  </div>
)

InputGroup.propTypes = {
  leftIcon: PropTypes.string,
  rightElement: PropTypes.node,
  ...Input.propTypes,
}

InputGroup.defaultProps = Input.defaultProps

export default InputGroup
