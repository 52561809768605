import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  organizationPropTypes,
  historyPropTypes,
  locationPropTypes,
} from "consts/prop-types"
import { hasAddOrgRole } from "utils/roles"
import Popover from "components/Popover"
import Menu from "components/Menu"
import Button from "components/Button"
import "./OrganizationPopover.css"
import createUrl from "utils/createUrl"
import { getOrganizationsMenu } from "reducers/organizations"
import { SessionContext } from "_v2/Modules/Auth"

class OrganizationPopover extends Component {
  state = {
    filter: "",
  }

  createOrganizationMenuItem =
    ({ globalMSP } = {}) =>
    ({ id, name, owned_msp_id }) => {
      const isActive = globalMSP
        ? localStorage.getItem("owned_msp_id") === String(owned_msp_id)
        : !localStorage.getItem("owned_msp_id") &&
          this.props.currentOrganization &&
          id === this.props.currentOrganization.id

      return {
        intent: isActive ? "primary" : null,
        text: name,
        label: isActive ? <span className="bp3-icon bp3-icon-tick" /> : null,
        onClick: () =>
          !isActive &&
          this.props.onOrganizationClick(id, owned_msp_id, globalMSP),
      }
    }

  render() {
    const filteredOrganizationItems = this.props.organizationItems.filter(
      ({ name }) =>
        !this.state.filter ||
        !name ||
        name.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1,
    )

    return (
      <Popover
        interactionKind="hover"
        position="bottom"
        content={
          <div>
            <div className="organization-popover">
              <input
                className="form-control search-organization-input"
                type="text"
                value={this.state.filter}
                placeholder="Search organizations"
                onChange={(event) =>
                  this.setState({
                    filter: event.target.value,
                  })
                }
              />
              <Menu items={filteredOrganizationItems} />
            </div>
            {!this.props.session.msp?.prevent_sub_org_creation &&
              this.props.hasAddOrgRole &&
              this.props.currentOrganization &&
              this.props.currentOrganization.isMSP &&
              this.props.location.pathname.indexOf("/add-organization") ===
                -1 && (
                <Menu
                  items={[
                    {
                      divider: true,
                    },
                    {
                      icon: "add",
                      text: "Add Organization",
                      onClick: () =>
                        this.props.history.push(createUrl("/add-organization")),
                    },
                  ]}
                />
              )}
          </div>
        }
      >
        <Button
          leftIcon={
            localStorage.getItem("owned_msp_id") ? "globe-network" : "office"
          }
          minimal
          data-test-id="organization"
        >
          {this.props.currentOrganization &&
            this.props.currentOrganization.name}
          <span
            className="bp3-icon-standard bp3-icon-chevron-down"
            style={{
              marginLeft: 5,
              marginRight: 0,
            }}
          />
        </Button>
      </Popover>
    )
  }
}

const mapStateToProps = ({ organizations }, ownProps) => ({
  organizationItems: getOrganizationsMenu(
    organizations.data,
    ownProps.onOrganizationClick,
  ),
})

OrganizationPopover.propTypes = {
  currentOrganization: organizationPropTypes.isRequired,
  onOrganizationClick: PropTypes.func.isRequired,
  history: historyPropTypes.isRequired,
  location: locationPropTypes.isRequired,
}

const OrganizationPopoverContainer = (props) => {
  const session = React.useContext(SessionContext)

  return (
    <OrganizationPopover
      {...props}
      session={session}
      hasAddOrgRole={hasAddOrgRole(session)}
    />
  )
}

export default connect(mapStateToProps)(OrganizationPopoverContainer)
