import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { login } from "actions/auth"
import { userSignup, clearSignup } from "actions/signup"
import SignupScene from "./SignupScene"

class SignupSceneContainer extends Component {
  componentDidMount() {
    const { history } = this.props

    if (localStorage.getItem("whitelabel.name")) {
      return history.push("/")
    }

    if (process.env.NODE_ENV !== "development") {
      window.location.href = "/signup"
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("email_from_site")
  }

  handleSubmit = (values) => {
    const { registerUser } = this.props
    registerUser(values)
    localStorage.removeItem("email_from_site")
  }

  render() {
    const { error, isSubmitting, success, clearSignupInfo } = this.props

    return (
      <SignupScene
        onSubmit={(values) => this.handleSubmit(values)}
        error={error}
        isSubmitting={isSubmitting}
        success={success}
        onClearSubmit={clearSignupInfo}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(login(credentials, false)),
  registerUser: (values) => dispatch(userSignup(values)),
  clearSignupInfo: () => dispatch(clearSignup()),
})

const mapStateToProps = (state) => ({
  error: state.signup.error,
  isSubmitting: state.signup.isSubmitting,
  success: state.signup.success,
})

SignupSceneContainer.propTypes = {
  error: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  clearSignupInfo: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SignupSceneContainer))
