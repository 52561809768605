import callAPI from "callAPI"
import get from "lodash/get"

export default ({
  organization: organization_id,
  name,
  address,
  ipAddresses,
  policyIds,
  isLegacyVpnActive: is_legacy_vpn_active,
  externalId,
  blockPageId,
}) =>
  callAPI({
    method: "POST",
    path: "/networks",
    body: {
      network: {
        organization_id,
        name,
        ip_addresses_attributes: ipAddresses,
        policy_ids: policyIds,
        physical_address: get(address, "address", address),
        is_legacy_vpn_active,
        external_id: externalId,
        ...(blockPageId
          ? {
              block_page_id:
                blockPageId === "default-appearance" ? null : blockPageId,
            }
          : {}),
      },
    },
  })
