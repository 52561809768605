import {
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICES_SUCCESS,
  LOAD_INVOICES_FAILURE,
  FETCH_INVOICES_PER_PAGE,
  CHANGE_INVOICE_LIST_PAGE,
} from "action-types/invoices"

const initialState = {
  data: [],
  fetching: false,
  error: false,
  page: 1,
  totalPages: 1,
  visibleInvoices: [],
}

const invoices = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INVOICES_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case LOAD_INVOICES_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload.invoices,
        totalPages: action.payload.numberOfPages,
      }
    case LOAD_INVOICES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case FETCH_INVOICES_PER_PAGE:
      return {
        ...state,
        visibleInvoices: action.payload.slice(
          (state.page - 1) * 10,
          (state.page - 1) * 10 + 10,
        ),
      }

    case CHANGE_INVOICE_LIST_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state
  }
}

export default invoices
