export const LOAD_LAN_PROXIES = "LOAD_LAN_PROXIES"
export const TOGGLE_EDIT_MULTIPLE_LAN_PROXIES_FORM =
  "TOGGLE_EDIT_MULTIPLE_LAN_PROXIES_FORM"
export const CHANGE_LAN_PROXIES_SEARCH_TEXT_FILTER =
  "CHANGE_LAN_PROXIES_SEARCH_TEXT_FILTER"
export const CHANGE_LAN_PROXIES_STATUS_FILTER =
  "CHANGE_LAN_PROXIES_STATUS_FILTER"
export const CHANGE_ALL_LAN_PROXIES = "CHANGE_ALL_LAN_PROXIES"
export const SELECT_PROXY = "SELECT_PROXY"
export const CHANGE_LAN_PROXY = "CHANGE_LAN_PROXY"
export const CHANGE_MULTIPLE_LAN_PROXIES = "CHANGE_MULTIPLE_LAN_PROXIES"
export const CHANGE_LAN_PROXY_PAGE = "CHANGE_LAN_PROXY_PAGE"
export const UPDATE_MULTIPLE_LAN_PROXIES_REQUEST =
  "UPDATE_MULTIPLE_LAN_PROXIES_REQUEST"
export const LOAD_PROXIES_STATUS = "LOAD_PROXIES_STATUS"
export const REMOVE_LAN_PROXY = "REMOVE_LAN_PROXY"
export const REMOVE_MULTIPLE_PROXIES = "REMOVE_MULTIPLE_PROXIES"
export const SHOW_CONFIRM_DELETE_RELAY_MODAL = "SHOW_CONFIRM_DELETE_RELAY_MODAL"
export const HIDE_CONFIRM_DELETE_RELAY_MODAL = "HIDE_CONFIRM_DELETE_RELAY_MODAL"
export const DELETE_LAN_PROXY_REQUEST = "DELETE_LAN_PROXY_REQUEST"
export const UNSELECT_ALL_LAN_PROXIES = "UNSELECT_ALL_LAN_PROXIES"
export const UPDATE_SELECTED_TAGS = "UPDATE_SELECTED_TAGS"
