export const industryOptions = [
  {
    label: "Small/Medium Business",
    value: 2,
  },
  {
    label: "Managed Service Provider",
    value: 18,
  },
  {
    label: "Internet Service Provider",
    value: 7,
  },
  {
    label: "Government",
    value: 6,
  },
  {
    label: "Enterprise",
    value: 16,
  },
  {
    label: "Education",
    value: 17,
  },
  {
    label: "Non-Profit Organization",
    value: 19,
  },
  {
    label: "Home User",
    value: 5,
  },
]

export default industryOptions
