import React, { Component, useContext } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import axios from "axios"
import get from "lodash/get"
import { isURL } from "validator"

import callAPI from "callAPI"
import NewInputGroup from "components/NewInputGroup"
import Tooltip from "components/Tooltip"
import Button from "components/Button"
import Input from "components/Input"
import FormGroup from "components/FormGroup"
import { getFqdn } from "utils/formatDomain"
import { updatePolicy } from "actions/filtering"
import v4 from "uuid/v4"
import { colors } from "utils/colorPalette"
import createUrl from "utils/createUrl"
import WhitelistBlacklistButton from "./WhitelistBlacklistButton"
import Fetch from "components/Fetch"
import isEqual from "lodash/isEqual"
import associatedDomains from "../../policies/filtering/associatedDomains"

import DomainLookupCname from "./DomainLookupCname"

import { SessionContext } from "_v2/Modules/Auth"

import { hideAppAwarePage } from "utils/auth"

import { useDistributorPlatform } from "_Infinity/DistributorPlatform/Hooks/useDistributorPlatform"

const renderCategorizationResults = (categories, noResultsText) => {
  if (!categories.length) {
    return (
      <tr>
        <td>{noResultsText}</td>
      </tr>
    )
  }

  return categories.map((category, index) => (
    <tr key={v4()}>
      <td>
        {categories.length > 1 && `${index + 1}.`} {category.name}
      </td>
    </tr>
  ))
}

const renderApllicationResults = (apps, noResultsText) => {
  if (!apps.length) {
    return (
      <tr>
        <td>{noResultsText}</td>
      </tr>
    )
  }

  const categoriesAndApllications = apps.reduce(
    (previousValue, currentValue) => {
      if (!previousValue.categories.includes(currentValue.category)) {
        previousValue.categories.push(currentValue.category)
      }

      if (!previousValue.apllications.includes(currentValue.name)) {
        previousValue.apllications.push(currentValue.name.split("_").join(" "))
      }

      return previousValue
    },
    {
      categories: [],
      apllications: [],
    },
  )

  return (
    <>
      <tr>
        <td>Categories: {categoriesAndApllications.categories.join(", ")}</td>
      </tr>
      <tr>
        <td
          style={{
            textTransform: "capitalize",
          }}
        >
          Applications: {categoriesAndApllications.apllications.join(", ")}
        </td>
      </tr>
    </>
  )
}

class DomainLookupForm extends Component {
  state = {
    domain: "",
    duplicatedDomain: false,
    submitted: false,
    categories: null,
    error: null,
    loading: false,

    showReportThreatForm: false,
    reportThreatDescription: "",
    errorNotes: false,

    showReportInaccurateCategoriesForm: false,
    reportInaccurateCategoriesDescription: "",
    errorNotesInaccurateCategories: false,

    showReportAppAwareForm: false,
    reportAppAwareDescription: "",
    errorNotesAppAware: false,

    ip: null,
    cnames: [],
  }

  componentDidMount() {
    const domainSearchInput = document.querySelector(".bp3-input")
    domainSearchInput.focus()
  }

  componentWillReceiveProps(nextProps) {
    const { duplicatedDomain } = this.state
    if (
      !isEqual(nextProps.policies.data, this.props.policies.data) &&
      duplicatedDomain
    ) {
      this.setState({
        duplicatedDomain: false,
      })
    }
  }

  updateDomain = (value) => {
    this.setState({
      domain: value.toLowerCase(),
      duplicatedDomain: false,
      submitted: false,
      categories: null,
      applications: null,

      errorNotes: false,
      showReportThreatForm: false,
      reportThreatDescription: "",

      showReportInaccurateCategoriesForm: false,
      reportInaccurateCategoriesDescription: "",
      errorNotesInaccurateCategories: false,

      showReportAppAwareForm: false,
      reportAppAwareDescription: "",
      errorNotesAppAware: false,
    })
  }

  hasError = () => {
    const isValidDomain = isURL(this.state.domain, {
      require_valid_protocol: false,
      allow_underscores: true,
    })

    if (this.state.submitted) {
      return !isValidDomain
    }

    return this.state.domain && !isValidDomain
  }

  getApplications = (data) => {
    if (!data || !get(data, "relationships.applications.data")) {
      return []
    }

    return get(data, "relationships.applications.data")
  }

  getCategories = (data) => {
    if (!data || !get(data, "relationships.categories.data")) {
      return []
    }

    const categories = this.props.categories.data

    return data.relationships.categories.data
      .filter((category) => Number(category.id) > 0)
      .map((category) =>
        categories.find(
          (_category) => Number(_category.id) === Number(category.id),
        ),
      )
      .filter((category) => !!category)
  }

  getPolicies = (policies) => {
    if (!policies) {
      return {
        whitelistPolicies: [],
        blacklistPolicies: [],
      }
    }

    const evaluateDomain = (domain, whitelistOrBlacklist) => {
      const splittedDomain = domain.split(".")
      const splittedDomainLength = splittedDomain.length

      return splittedDomain.some((element, index) => {
        let composedDomain = ""

        if (index === 0) {
          composedDomain = splittedDomain.join(".")
        }

        if (index > 0 && index < splittedDomainLength - 1) {
          composedDomain = splittedDomain
            .slice(index, splittedDomainLength)
            .join(".")
        }

        return whitelistOrBlacklist.some((item) => item === composedDomain)
      })
    }

    return policies.reduce(
      (acc, policy) => {
        const isBlackListed =
          get(policy, "blacklist.length", 0) &&
          evaluateDomain(this.state.domain, policy.blacklist)

        const isWhiteListed =
          get(policy, "whitelist.length", 0) &&
          evaluateDomain(this.state.domain, policy.whitelist)

        return {
          ...acc,
          blacklistPolicies: isBlackListed
            ? [...acc.blacklistPolicies, policy]
            : acc.blacklistPolicies,
          whitelistPolicies: isWhiteListed
            ? [...acc.whitelistPolicies, policy]
            : acc.whitelistPolicies,
        }
      },
      {
        blacklistPolicies: [],
        whitelistPolicies: [],
      },
    )
  }

  handleToggleReportThreatForm = () => {
    this.setState((prevState) => ({
      showReportThreatForm: !prevState.showReportThreatForm,
      reportThreatDescription: "",
      errorNotes: false,
    }))
  }

  handleToggleReportInaccurateCategoriesForm = () => {
    this.setState((prevState) => ({
      showReportInaccurateCategoriesForm:
        !prevState.showReportInaccurateCategoriesForm,
      reportInaccurateCategoriesDescription: "",
      errorNotesInaccurateCategories: false,
    }))
  }

  handleToggleAppAwareForm = () => {
    this.setState((prevState) => ({
      showReportAppAwareForm: !prevState.showReportAppAwareForm,
      reportAppAwareDescription: "",
      errorNotesAppAware: false,
    }))
  }

  getCNAMES = async (domain) => {
    let ip = this.state.ip

    const dnsResolveUrl =
      process.env.REACT_APP_DNS_RESOLVE_URL || "https://dns.google.com/resolve"
    if (!ip) {
      const ipResolveUrl =
        process.env.REACT_APP_IP_RESOLVE_URL ||
        "https://api.ipify.org?format=json"

      ip = await axios.get(ipResolveUrl).then((result) => result.data.ip)

      if (ip) {
        ip = ip.substring(0, ip.lastIndexOf("."))

        this.setState({
          ip,
        })
      }
    }

    const result = await axios.get(
      `${dnsResolveUrl}?name=${domain}${
        ip ? `&edns_client_subnet=${ip}.0/24` : ""
      }`,
    )
    let cnames = []
    let IP_TYPE = 5

    if (result.data.Answer) {
      const foundItems = result.data.Answer.filter(
        (item) => item.type === IP_TYPE,
      )

      cnames = foundItems.map((item) =>
        item.data.substring(0, item.data.length - 1),
      )
    }

    const associatedDomainsFound = associatedDomains[domain]

    if (associatedDomainsFound) {
      cnames = [...cnames, ...associatedDomainsFound]
    }

    return cnames
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.hasError()) {
      this.setState({
        submitted: true,
      })

      return
    }

    this.setState(
      (currentState) => ({
        submitted: true,
        loading: true,
        error: null,
        domain: getFqdn(currentState.domain),
      }),
      async () => {
        const { domain } = this.state
        try {
          const categoriesResponse = await callAPI({
            path: "/domains/user_lookup",
            query: {
              fqdn: domain,
            },
          })

          const cnames = await this.getCNAMES(domain)

          this.setState({
            loading: false,
            categories: this.getCategories(categoriesResponse.data),
            policies: this.getPolicies(this.props.policies.data),
            applications: this.getApplications(categoriesResponse.data),
            cnames,
          })
        } catch (error) {
          this.setState({
            error: "Failed to lookup domain. Please try again.",
            loading: false,
          })
        }
      },
    )
  }

  handleUpdatePolicy = (type, action, policy) => {
    if (!policy) return
    const isWhitelist = type === "whitelist"
    let newList = {}

    if (action === "add") {
      const entireList = [...policy.whitelist, ...policy.blacklist]
      const isDuplicated = entireList.some((item) => item === this.state.domain)
      if (isDuplicated) {
        this.setState({
          duplicatedDomain: true,
        })

        return
      }

      newList = {
        ...policy,
        whitelist: isWhitelist
          ? [...(policy.whitelist || []), this.state.domain]
          : [...(policy.whitelist || [])],
        blacklist: !isWhitelist
          ? [...(policy.blacklist || []), this.state.domain]
          : [...(policy.blacklist || [])],
      }
    } else {
      newList = {
        ...policy,
        whitelist: isWhitelist
          ? policy.whitelist.filter((wp) => wp !== this.state.domain)
          : [...(policy.whitelist || [])],
        blacklist: !isWhitelist
          ? policy.blacklist.filter((wp) => wp !== this.state.domain)
          : [...(policy.blacklist || [])],
      }
    }

    this.props.updatePolicy(true, newList)
  }

  defineEmptyCategoriesText = () => {
    const { categories } = this.state
    if (categories && !!categories.length) {
      const filteredCategories = categories.filter(
        (category) => !category.security,
      )
      const securedCategories = categories.filter(
        (category) => category.security,
      )

      return !filteredCategories.length && !!securedCategories.length
        ? "None"
        : "Uncategorized"
    }

    return "Uncategorized"
  }

  render() {
    const { policies, session } = this.props
    const { categories, cnames, applications } = this.state
    const { blacklistPolicies, whitelistPolicies } = this.getPolicies(
      policies.data,
    )
    const isReadOnly = localStorage.getItem("organization.readOnly")
    const isPoliciesOnly = localStorage.getItem("organization.policiesOnly")

    return (
      <div>
        <p
          style={{
            marginBottom: 15,
          }}
        >
          Please enter a domain below to view pertinent information, report
          misclassified domains, or report new threats
        </p>
        {this.state.error && (
          <div className="alert alert-danger text-center">
            {this.state.error}
          </div>
        )}
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              maxWidth: 350,
            }}
          >
            <NewInputGroup
              placeholder="www.example.com"
              onChange={this.updateDomain}
              value={this.state.domain}
              leftIconName="pt-icon-geosearch"
              intent={
                this.hasError() || this.state.duplicatedDomain
                  ? "danger"
                  : "primary"
              }
              disabled={this.state.loading}
              rightElement={
                <Tooltip content="Search">
                  <Button
                    minimal
                    leftIcon="arrow-right"
                    onClick={this.handleSubmit}
                    data-test-id="next-input"
                  />
                </Tooltip>
              }
            />
          </div>
          {this.state.submitted && this.hasError() && (
            <div
              style={{
                color: colors.punch,
                marginTop: 10,
              }}
            >
              Invalid Domain
            </div>
          )}
          {this.state.duplicatedDomain && (
            <div
              style={{
                color: colors.punch,
                marginTop: 10,
              }}
            >
              Domain already added
            </div>
          )}
        </form>
        {this.state.loading ? (
          <i className="fa fa-spinner fa-spin" />
        ) : (
          <div>
            {categories && (
              <div>
                {/* Categories */}
                <table
                  className="table-bordered table-striped table"
                  style={{
                    marginTop: 15,
                    marginBottom: 10,
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: colors.gallery,
                        }}
                      >
                        Categories
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCategorizationResults(
                      categories.filter((category) => !category.security),
                      this.defineEmptyCategoriesText(),
                    )}
                  </tbody>
                </table>
                <Fetch
                  method="post"
                  url={`${process.env.REACT_APP_API_URL}/support/email`}
                  body={{
                    organization_id: localStorage.getItem("orgId"),
                    body_text: `A customer has reported a domain as being miscategorized. Details:<br /><br />
                    domain:    ${this.state.domain}<br /><br />
                    user:      ${session?.user?.fullName ?? " - "}<br />
                    email:     ${session?.user?.email ?? " - "}<br />
                    org name:  ${localStorage.getItem("orgName")}<br />
                    org ID:    ${localStorage.getItem("orgId")}<br /><br />
                    Notes:<br />${
                      this.state.reportInaccurateCategoriesDescription
                    }<br /><br />`,
                    subject: `Domain miscategorized: ${this.state.domain}`,
                    support: "false",
                  }}
                  headers={{
                    Authorization: `Bearer ${localStorage.access_token}`,
                  }}
                  lazy
                  onResponse={(_, data) => {
                    let { success } = data

                    if (success) {
                      window.trackAnalyticsEvent("Miscategorization Reports", {
                        report_type: "miscategorization",
                        domain: this.state.domain,
                        categories: categories
                          .filter((category) => !category.security)
                          .map((category) => ({
                            id: category.id,
                            name: category.name,
                          })),
                        note:
                          this.state.reportInaccurateCategoriesDescription ??
                          "",
                      })
                    }
                  }}
                >
                  {({ isFetching, data, error, dispatch }) => {
                    if (isFetching) {
                      return <i className="fa fa-spinner fa-spin" />
                    }

                    if (data) {
                      return (
                        <small
                          style={{
                            color: colors.silver,
                          }}
                        >
                          Thank you for your submission, we will review{" "}
                          {this.state.domain} and inform you of our findings.
                        </small>
                      )
                    }

                    if (error) {
                      return (
                        <small
                          style={{
                            color: colors.silver,
                          }}
                        >
                          Sorry, submission failed. Please{" "}
                          <a
                            href="#try-again"
                            onClick={(event) => {
                              event.preventDefault()
                              dispatch()
                            }}
                          >
                            try again
                          </a>{" "}
                          or contact support.
                        </small>
                      )
                    }

                    if (this.state.showReportInaccurateCategoriesForm) {
                      return (
                        <div>
                          <FormGroup
                            error={
                              this.state.errorNotesInaccurateCategories &&
                              "Notes are required."
                            }
                          >
                            <Input
                              type="textarea"
                              data-test-id="descriptionInaccurateCategories"
                              rows={3}
                              value={
                                this.state.reportInaccurateCategoriesDescription
                              }
                              onChange={(e) =>
                                this.setState({
                                  reportInaccurateCategoriesDescription:
                                    e.target.value,
                                  errorNotesInaccurateCategories: false,
                                })
                              }
                              placeholder="Add any additional context or notes to this report"
                              autoFocus
                            />
                          </FormGroup>
                          <div
                            style={{
                              marginTop: -5,
                            }}
                          >
                            <Button
                              intent="primary"
                              data-test-id="domain-lookup"
                              leftIcon="tick"
                              small
                              onClick={() => {
                                if (
                                  !this.state
                                    .reportInaccurateCategoriesDescription
                                ) {
                                  this.setState({
                                    errorNotesInaccurateCategories: true,
                                  })

                                  return
                                }

                                dispatch()
                              }}
                            >
                              Submit
                            </Button>{" "}
                            <Button
                              data-test-id="domain-lookup-cancel-Inaccurate-Categories"
                              leftIcon="cross"
                              onClick={
                                this.handleToggleReportInaccurateCategoriesForm
                              }
                              small
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )
                    }

                    if (!this.state.showReportInaccurateCategoriesForm) {
                      return (
                        <small
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          <a
                            href="#try-again"
                            style={{
                              color: colors.easternBlue,
                            }}
                            onClick={(event) => {
                              event.preventDefault()
                              this.handleToggleReportInaccurateCategoriesForm()
                            }}
                          >
                            <i
                              className="fa fa-bullhorn"
                              style={{
                                color: "red",
                                marginRight: 5,
                              }}
                            />
                            Report Inaccurate Categories
                          </a>
                        </small>
                      )
                    }
                  }}
                </Fetch>
                <hr
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                {!this.props.hideAppAware && (
                  <>
                    {/* AppAware */}
                    <table
                      className="table-bordered table-striped table"
                      style={{
                        marginTop: 15,
                        marginBottom: 10,
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: colors.gallery,
                            }}
                          >
                            AppAware
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderApllicationResults(applications, "None")}
                      </tbody>
                    </table>
                    <Fetch
                      method="post"
                      url={`${process.env.REACT_APP_API_URL}/support/email`}
                      body={{
                        organization_id: localStorage.getItem("orgId"),
                        body_text: `A customer has reported Inaccurate Domain Association with AppAware. Details:<br /><br />
                    domain:    ${this.state.domain}<br /><br />
                    user:      ${session?.user?.fullName ?? " - "}<br />
                    email:     ${session?.user?.email ?? " - "}<br />
                    org name:  ${localStorage.getItem("orgName")}<br />
                    org ID:    ${localStorage.getItem("orgId")}<br /><br />
                    Notes:<br />${
                      this.state.reportAppAwareDescription
                    }<br /><br />`,
                        subject: `Inaccurate Domain Association with AppAware: ${this.state.domain}`,
                        support: "false",
                      }}
                      headers={{
                        Authorization: `Bearer ${localStorage.access_token}`,
                      }}
                      lazy
                      onResponse={(_, data) => {
                        let { success } = data

                        if (success) {
                          window.trackAnalyticsEvent(
                            "AppAware Miscategorization Reports",
                            {
                              report_type: "appaware_miscategorization",
                              domain: this.state.domain,
                              categories: categories
                                .filter((category) => !category.security)
                                .map((category) => ({
                                  id: category.id,
                                  name: category.name,
                                })),
                              note: this.state.reportAppAwareDescription ?? "",
                            },
                          )
                        }
                      }}
                    >
                      {({ isFetching, data, error, dispatch }) => {
                        if (isFetching) {
                          return <i className="fa fa-spinner fa-spin" />
                        }

                        if (data) {
                          return (
                            <small
                              style={{
                                color: colors.silver,
                              }}
                            >
                              Thank you for your submission, we will review{" "}
                              {this.state.domain} and inform you of our
                              findings.
                            </small>
                          )
                        }

                        if (error) {
                          return (
                            <small
                              style={{
                                color: colors.silver,
                              }}
                            >
                              Sorry, submission failed. Please{" "}
                              <a
                                href="#try-again"
                                onClick={(event) => {
                                  event.preventDefault()
                                  dispatch()
                                }}
                              >
                                try again
                              </a>{" "}
                              or contact support.
                            </small>
                          )
                        }

                        if (this.state.showReportAppAwareForm) {
                          return (
                            <div>
                              <FormGroup
                                error={
                                  this.state.errorNotesAppAware &&
                                  "Notes are required."
                                }
                              >
                                <Input
                                  type="textarea"
                                  data-test-id="descriptionAppAware"
                                  rows={3}
                                  value={this.state.reportAppAwareDescription}
                                  onChange={(e) =>
                                    this.setState({
                                      reportAppAwareDescription: e.target.value,
                                      errorNotesAppAware: false,
                                    })
                                  }
                                  placeholder="Add any additional context or notes to this report"
                                  autoFocus
                                />
                              </FormGroup>
                              <div
                                style={{
                                  marginTop: -5,
                                }}
                              >
                                <Button
                                  intent="primary"
                                  data-test-id="domain-lookup"
                                  leftIcon="tick"
                                  small
                                  onClick={() => {
                                    if (!this.state.reportAppAwareDescription) {
                                      this.setState({
                                        errorNotesAppAware: true,
                                      })

                                      return
                                    }

                                    dispatch()
                                  }}
                                >
                                  Submit
                                </Button>{" "}
                                <Button
                                  data-test-id="domain-lookup-cancel-AppAware"
                                  leftIcon="cross"
                                  onClick={this.handleToggleAppAwareForm}
                                  small
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          )
                        }

                        if (!this.state.showReportAppAwareForm) {
                          return (
                            <small
                              style={{
                                marginLeft: 10,
                              }}
                            >
                              <a
                                href="#try-again"
                                style={{
                                  color: colors.easternBlue,
                                }}
                                onClick={(event) => {
                                  event.preventDefault()
                                  this.handleToggleAppAwareForm()
                                }}
                              >
                                <i
                                  className="fa fa-bullhorn"
                                  style={{
                                    color: "red",
                                    marginRight: 5,
                                  }}
                                />
                                Report Inaccurate Domain Association
                              </a>
                            </small>
                          )
                        }
                      }}
                    </Fetch>

                    <hr
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  </>
                )}
                {/* Security Threats */}
                <table
                  className="table-bordered table-striped table"
                  style={{
                    marginTop: 15,
                    marginBottom: 10,
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: colors.gallery,
                        }}
                      >
                        Security Threats
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCategorizationResults(
                      categories.filter((category) => category.security),
                      "None",
                    )}
                  </tbody>
                </table>
                <Fetch
                  method="post"
                  url={`${process.env.REACT_APP_API_URL}/support/email`}
                  body={{
                    organization_id: localStorage.getItem("orgId"),
                    body_text: `A customer has submitted the following threat report. Details:<br /><br />
                    domain:    ${this.state.domain}<br /><br />
                    user:      ${session?.user?.fullName ?? " - "}<br />
                    email:     ${session?.user?.email ?? " - "}<br />
                    org name:  ${localStorage.getItem("orgName")}<br />
                    org ID:    ${localStorage.getItem("orgId")}<br /><br />
                    Notes:<br />${
                      this.state.reportThreatDescription
                    }<br /><br />`,
                    subject: `Domain threat report: ${this.state.domain}`,
                    support: "false",
                  }}
                  headers={{
                    Authorization: `Bearer ${localStorage.access_token}`,
                  }}
                  lazy
                  onData={() => {
                    this.handleToggleReportThreatForm()
                  }}
                  onResponse={(_, data) => {
                    let { success } = data

                    if (success) {
                      window.trackAnalyticsEvent("Threat Reports", {
                        report_type: "threat",
                        domain: this.state.domain,
                        threat_categories: categories
                          .filter((category) => category.security)
                          .map((category) => ({
                            id: category.id,
                            name: category.name,
                          })),
                        note: this.state.reportThreatDescription ?? "",
                      })
                    }
                  }}
                >
                  {({ isFetching, data, error, dispatch }) => {
                    if (isFetching) {
                      return <i className="fa fa-spinner fa-spin" />
                    }

                    if (data) {
                      return (
                        <small
                          style={{
                            color: colors.silver,
                          }}
                        >
                          Thank you for your submission, we will review{" "}
                          {this.state.domain} and inform you of our findings.
                        </small>
                      )
                    }

                    if (error) {
                      return (
                        <small
                          style={{
                            color: colors.silver,
                          }}
                        >
                          Sorry, submission failed. Please{" "}
                          <a
                            href="#try-again"
                            onClick={(event) => {
                              event.preventDefault()
                              dispatch()
                            }}
                          >
                            try again
                          </a>{" "}
                          or contact support.
                        </small>
                      )
                    }

                    if (this.state.showReportThreatForm) {
                      return (
                        <div>
                          <FormGroup
                            error={
                              this.state.errorNotes && "Notes are required."
                            }
                          >
                            <Input
                              type="textarea"
                              data-test-id="description"
                              rows={3}
                              value={this.state.reportThreatDescription}
                              onChange={(e) =>
                                this.setState({
                                  reportThreatDescription: e.target.value,
                                  errorNotes: false,
                                })
                              }
                              placeholder="Add any additional context or notes to this report"
                              autoFocus
                            />
                          </FormGroup>
                          <div
                            style={{
                              marginTop: -5,
                            }}
                          >
                            <Button
                              intent="primary"
                              data-test-id="domain-lookup"
                              leftIcon="tick"
                              small
                              onClick={() => {
                                if (!this.state.reportThreatDescription) {
                                  this.setState({
                                    errorNotes: true,
                                  })

                                  return
                                }

                                dispatch()
                              }}
                            >
                              Submit
                            </Button>{" "}
                            <Button
                              data-test-id="domain-lookup-cancel"
                              leftIcon="cross"
                              onClick={this.handleToggleReportThreatForm}
                              small
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )
                    }

                    if (!this.state.showReportThreatForm) {
                      return (
                        <small
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          <a
                            href="#try-again"
                            style={{
                              color: colors.easternBlue,
                            }}
                            onClick={(event) => {
                              event.preventDefault()
                              this.handleToggleReportThreatForm()
                            }}
                          >
                            <i
                              className="fa fa-exclamation-triangle"
                              style={{
                                color: "red",
                                marginRight: 5,
                              }}
                            />
                            Report Threat
                          </a>
                        </small>
                      )
                    }
                  }}
                </Fetch>
                <hr
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />

                <table
                  className="table-bordered table-striped table"
                  style={{
                    marginTop: 15,
                    marginBottom: 10,
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: colors.gallery,
                        }}
                      >
                        Allow List &amp; Block List
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            Allow List:{" "}
                            {!whitelistPolicies.length
                              ? !this.state.showWhitelist && "None"
                              : whitelistPolicies.map(
                                  (policy) =>
                                    !this.state.showWhitelist && (
                                      <Link
                                        key={v4()}
                                        style={{
                                          marginRight: 5,
                                        }}
                                        to={createUrl(
                                          `/policies/filtering/${policy.id}/edit/allowlist`,
                                        )}
                                        onClick={this.props.onClose}
                                      >
                                        {policy.name}
                                      </Link>
                                    ),
                                )}
                          </div>
                          {policies.data.length && (
                            <div>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    showWhitelist: !prevState.showWhitelist,
                                  }))
                                }
                                data-test-id="add-whitelist"
                                disabled={isReadOnly && !isPoliciesOnly}
                              >
                                {!this.state.showWhitelist ? (
                                  <i className="fa fa-plus" />
                                ) : (
                                  <i className="fa fa-minus" />
                                )}{" "}
                                Add
                              </button>
                            </div>
                          )}
                        </div>
                        {this.state.showWhitelist && policies.data.length && (
                          <div
                            className="row"
                            style={{
                              marginTop: 10,
                            }}
                          >
                            {policies.data.map((policy, i) => {
                              const found = whitelistPolicies.find(
                                (p) => p.id === policy.id,
                              )
                              const foundBlacklistPolicies =
                                blacklistPolicies.find(
                                  (p) => p.id === policy.id,
                                )

                              return (
                                <div key={policy.id} className="col-xs-4">
                                  <WhitelistBlacklistButton
                                    tooltipDisabled={
                                      !policy.canEdit
                                        ? "This can only be changed by the network administrator"
                                        : "Domain already added"
                                    }
                                    isLoading={!foundBlacklistPolicies}
                                    disabled={
                                      !!foundBlacklistPolicies ||
                                      !policy.canEdit
                                    }
                                    active={!!found}
                                    onClick={() => {
                                      this.handleUpdatePolicy(
                                        "whitelist",
                                        found ? "delete" : "add",
                                        policy,
                                      )
                                    }}
                                  >
                                    {policy.name}
                                  </WhitelistBlacklistButton>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            Block List:{" "}
                            {!blacklistPolicies.length
                              ? !this.state.showBlacklist && "None"
                              : blacklistPolicies.map(
                                  (policy) =>
                                    !this.state.showBlacklist && (
                                      <span
                                        key={policy.id}
                                        style={{
                                          marginRight: 5,
                                        }}
                                      >
                                        <Link
                                          key={v4()}
                                          to={createUrl(
                                            `/policies/filtering/${policy.id}/edit/blocklist`,
                                          )}
                                          onClick={this.props.onClose}
                                        >
                                          {policy.name}
                                        </Link>
                                      </span>
                                    ),
                                )}
                          </div>
                          {policies.data.length && (
                            <div>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    showBlacklist: !prevState.showBlacklist,
                                  }))
                                }
                                data-test-id="add-blacklist"
                                disabled={isReadOnly && !isPoliciesOnly}
                              >
                                {!this.state.showBlacklist ? (
                                  <i className="fa fa-plus" />
                                ) : (
                                  <i className="fa fa-minus" />
                                )}{" "}
                                Add
                              </button>
                            </div>
                          )}
                        </div>
                        {this.state.showBlacklist && policies.data.length && (
                          <div
                            className="row"
                            style={{
                              marginTop: 10,
                            }}
                          >
                            {policies.data.map((policy) => {
                              const found = blacklistPolicies.find(
                                (p) => p.id === policy.id,
                              )
                              const foundWhitelistPolicies =
                                whitelistPolicies.find(
                                  (p) => p.id === policy.id,
                                )

                              return (
                                <div key={v4()} className="col-xs-4">
                                  <WhitelistBlacklistButton
                                    tooltipDisabled={
                                      !policy.canEdit
                                        ? "This can only be changed by the network administrator"
                                        : "Domain already added"
                                    }
                                    isLoading={!foundWhitelistPolicies}
                                    disabled={
                                      !!foundWhitelistPolicies ||
                                      !policy.canEdit
                                    }
                                    active={!!found}
                                    onClick={() => {
                                      this.handleUpdatePolicy(
                                        "blacklist",
                                        found ? "delete" : "add",
                                        policy,
                                      )
                                    }}
                                  >
                                    {policy.name}
                                  </WhitelistBlacklistButton>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                {/* CNAMEs */}
                <table
                  className="table-bordered table-striped table"
                  style={{
                    marginTop: 15,
                    marginBottom: 10,
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: colors.gallery,
                        }}
                      >
                        CNAMEs
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cnames.length === 0 && (
                      <tr>
                        <td>None</td>
                      </tr>
                    )}
                    {cnames.map((cname) => (
                      <tr key={cname}>
                        <td>
                          <DomainLookupCname cname={cname} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ allCategories, policies }) => {
  const filteredPolicies = {
    ...policies,
    data: policies?.data.map((policy) => {
      /**
       only allow editing if 
        1. the policy is not global
        2. the policy belongs to the localStorage organization (if the policy is global)
      */

      const canEdit = policy?.is_global_policy
        ? Number(policy?.organization_id) ===
          Number(localStorage.getItem("orgId"))
        : true

      return {
        ...policy,
        canEdit,
      }
    }),
  }

  return {
    categories: allCategories,
    policies: filteredPolicies,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePolicy: (shouldShowTakeEffectDelayMessage, data) =>
    dispatch(updatePolicy(shouldShowTakeEffectDelayMessage, data)),
})

const DomainLookupFormContainer = (props) => {
  let session = useContext(SessionContext)

  const platform = useDistributorPlatform()

  const hideAppAware = hideAppAwarePage({
    showAppAwarePlatform: platform.showAppAware,
    session,
  })

  return (
    <DomainLookupForm
      {...props}
      session={session}
      hideAppAware={hideAppAware}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DomainLookupFormContainer)
