import get from "lodash/get"
import orderBy from "lodash/orderBy"
import {
  LOAD_USER_AGENTS_LOCAL,
  SELECT_AGENT_LOCAL,
  CHANGE_ALL_USER_AGENTS_LOCAL,
  TOGGLE_EDIT_MULTIPLE_USER_AGENTS_LOCAL_FORM,
  UPDATE_MULTIPLE_USER_AGENTS_LOCAL_REQUEST,
  CHANGE_MULTIPLE_USER_AGENTS_LOCAL,
  CHANGE_USER_AGENT_LOCAL,
  CHANGE_USER_AGENTS_LOCAL_SEARCH_TEXT_FILTER,
  SORT_USER_AGENTS_LOCAL,
} from "action-types/userAgentsLocal"

const initialState = {
  status: null,
  data: [],
  searchText: "",
  selectedUserAgentsLocalIds: [],
  iterateesToSort: ["name"],
  sortOrders: ["asc"],
}
export const userAgentsLocal = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_AGENTS_LOCAL:
      return {
        ...state,
        status: action.payload.status,
        data: get(action.payload, "data", state.data),
      }
    case SELECT_AGENT_LOCAL:
      const selectedUserAgentLocal = state.selectedUserAgentsLocalIds.find(
        (agentId) => agentId === action.payload,
      )
      const newSelectedUserAgentsLocal = selectedUserAgentLocal
        ? state.selectedUserAgentsLocalIds.filter(
            (agentId) => agentId !== action.payload,
          )
        : [...state.selectedUserAgentsLocalIds, action.payload]

      return {
        ...state,
        selectedUserAgentsLocalIds: newSelectedUserAgentsLocal,
        allUserAgentsSelected:
          newSelectedUserAgentsLocal.length === state.data.length,
      }
    case CHANGE_ALL_USER_AGENTS_LOCAL:
      return {
        ...state,
        allUserAgentsLocalSelected: action.payload.allChecked,
        selectedUserAgentsLocalIds: action.payload.allChecked
          ? action.payload.visibleIds
          : [],
      }
    case TOGGLE_EDIT_MULTIPLE_USER_AGENTS_LOCAL_FORM:
      return {
        ...state,
        editMultipleUserAgentsLocal: action.payload,
        editMultipleUserAgentsLocalStatus: null,
      }
    case UPDATE_MULTIPLE_USER_AGENTS_LOCAL_REQUEST:
      return {
        ...state,
        editMultipleUserAgentsLocalStatus: action.payload,
        selectedUserAgentsLocalIds:
          action.payload === "success" ? [] : state.selectedUserAgentsLocalIds,
        allUserAgentsLocalSelected:
          action.payload === "success"
            ? false
            : state.allUserAgentsLocalSelected,
      }
    case CHANGE_MULTIPLE_USER_AGENTS_LOCAL:
      return {
        ...state,
        data: state.data.map((agent) => {
          const updatedAgentLocal = action.payload.find(
            (updatedAgentLocal) => updatedAgentLocal.id === agent.id,
          )

          return updatedAgentLocal || agent
        }),
      }
    case CHANGE_USER_AGENT_LOCAL:
      return {
        ...state,
        data: state.data.map((agent) =>
          agent.id === action.payload.id ? action.payload : agent,
        ),
      }
    case CHANGE_USER_AGENTS_LOCAL_SEARCH_TEXT_FILTER:
      return {
        ...state,
        searchText: action.payload,
      }

    case SORT_USER_AGENTS_LOCAL:
      const { sortOrders, iterateesToSort } = action.payload

      return {
        ...state,
        iterateesToSort: iterateesToSort,
        sortOrders: sortOrders,
        data: orderBy(state.data, iterateesToSort, sortOrders),
      }

    default:
      return state
  }
}

export const getVisibleUserAgentsLocal = (state) => {
  const {
    userAgentsLocal: { selectedUserAgentsLocalIds, data, searchText },
  } = state

  const lowerCaseSearchText = searchText.toLowerCase()

  return data
    .map((userAgentLocal) => ({
      ...userAgentLocal,
      selected: selectedUserAgentsLocalIds.includes(userAgentLocal.id),
      policy: state.policies.data.find(
        (policy) => policy.id === userAgentLocal.policy_id,
      ),
      filteringSchedule: state.filteringSchedules.data.find(
        (filteringSchedule) =>
          filteringSchedule.id === userAgentLocal.scheduled_policy_id,
      ),
      blockPage: state.blockPages.data.find(
        (blockPage) => blockPage.id === userAgentLocal.block_page_id,
      ),
    }))
    .filter((agent) =>
      (agent.name || "").toLowerCase().includes(lowerCaseSearchText),
    )
}

export const selectVisibleUserAgentsLocalIds = (state) =>
  state.userAgentsLocal.data
    .filter(
      (agent) =>
        state.userAgentsLocal.allUserAgentsLocalSelected ||
        state.userAgentsLocal.selectedUserAgentsLocalIds.includes(agent.id),
    )
    .map((agent) => agent.id)

export default userAgentsLocal
