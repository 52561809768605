import React from "react"
import get from "lodash/get"
import { showNotificationWithTTL } from "actions/notifications"
import addPolicyCall from "api/filtering/addPolicyCall"
import removePolicyCall from "api/filtering/removePolicyCall"
import loadPoliciesCall from "api/filtering/loadPoliciesCall"
import updatePolicyCall from "api/filtering/updatePolicyCall"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"
import {
  LOAD_POLICIES,
  UPDATE_POLICY_SEARCH_TEXT,
  UPDATE_POLICY_STATUS_FILTER,
  UPDATE_POLICY_FORM_CATEGORIES_SEARCH_TEXT,
  STORE_POLICY_CHANGES,
  CLEAN_POLICY_CHANGES,
  UPDATE_POLICY,
} from "action-types/filtering"

export const loadPoliciesRequest = actionCreatorFetch(LOAD_POLICIES)

export const loadPoliciesSuccess = actionCreatorSuccess(LOAD_POLICIES)

export const loadPoliciesFailure = actionCreatorFailure(LOAD_POLICIES)

export const loadPolicies =
  (dispatchRequest = true) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(
        loadPoliciesRequest({
          data: [],
        }),
      )
    }

    return loadPoliciesCall()
      .then((data) =>
        dispatch(
          loadPoliciesSuccess({
            data,
          }),
        ),
      )
      .catch(() => {
        dispatch(loadPoliciesFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "The was a problem while loading policies",
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const updatePolicySearchText = (searchText) => ({
  type: UPDATE_POLICY_SEARCH_TEXT,
  payload: searchText,
})

export const updatePolicyStatusFilter = (status) => ({
  type: UPDATE_POLICY_STATUS_FILTER,
  payload: status,
})

export const updatePolicyFormCategoriesSearchText = (searchText) => ({
  type: UPDATE_POLICY_FORM_CATEGORIES_SEARCH_TEXT,
  payload: searchText,
})

export const storePolicyChanges = (data) => ({
  type: STORE_POLICY_CHANGES,
  payload: data,
})

export const cleanPolicyChanges = () => ({
  type: CLEAN_POLICY_CHANGES,
})

export const updatePolicyChanges = (data) => ({
  type: UPDATE_POLICY,
  payload: data,
})

export const addPolicy = (data, afterSaveCallback) => (dispatch, getState) =>
  addPolicyCall(
    Object.assign({}, data, {
      organization: getState().auth.data.organizationId,
    }),
  ).then(
    ({ data: { id } }) => {
      dispatch(loadPolicies(false)).then(() => {
        dispatch(
          showNotificationWithTTL({
            type: "success",
            title: data.name,
            message: `${data.name} policy saved!`,
            icon: "check",
          }),
        )

        if (afterSaveCallback) {
          afterSaveCallback(id)
        }
      })
    },
    (error) => {
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: data.name,
          message: `Failed to save "${data.name}"`,
        }),
      )

      throw error
    },
  )

export const updatePolicy =
  (
    showDelayedMessage,
    policy,
    afterSaveCallback,
    hideSuccessMessage,
    platform,
  ) =>
  (dispatch, getState) =>
    updatePolicyCall({
      ...policy,
      organization: getState().auth.data.organizationId,
    }).then(
      ({ data }) => {
        dispatch(updatePolicyChanges(policy))

        const isUpdateModalActive = getState().policies.showUpdatePolicyModal
        if (isUpdateModalActive) {
          dispatch(cleanPolicyChanges())
        }

        if (!hideSuccessMessage) {
          dispatch(
            showNotificationWithTTL(
              {
                type: "success",
                title: `${policy.name} policy updated!`,
                message: (
                  <span>
                    {showDelayedMessage && (
                      <span className="green-alert">
                        <strong>
                          Changes may take a few minutes to take effect.
                        </strong>
                        {platform?.showLearnMore &&
                          (!localStorage.getItem("whitelabel.name") ||
                            localStorage.getItem("organization.isMSP")) && (
                            <span>
                              <br />
                              <a href="https://help.dnsfilter.com/hc/en-us/articles/1500008111261-caching">
                                Learn More
                              </a>
                            </span>
                          )}
                      </span>
                    )}
                  </span>
                ),
                icon: "check",
              },
              6,
            ),
          )
        }

        dispatch(loadPolicies(false))

        if (afterSaveCallback) {
          const nextPolicyVersion = data.attributes.lock_version

          afterSaveCallback({
            ...policy,
            lockVersion: nextPolicyVersion,
          })
        }
      },
      (error) => {
        if (
          error?.message?.includes("Policy being used by a MSP customer") &&
          error.organizations
        ) {
          dispatch(
            showNotificationWithTTL({
              type: "danger",
              title: policy.name,
              message: (
                <div>
                  You can't delete the {policy.name} policy because it is
                  currently in use by the following organization(s):
                  <br />
                  <br />
                  <ul>
                    {error.organizations.map((org) => (
                      <li key={org.id}>{org.name}</li>
                    ))}
                  </ul>
                </div>
              ),
            }),
          )
        } else {
          const isBaseKey = get(error, "base")
          const isPolicyChanged =
            isBaseKey && get(error, "base[0]").includes("record changed")
          if (isPolicyChanged) {
            dispatch(storePolicyChanges(policy))
          } else {
            const isNatIpError =
              get(error, "policy_ip_id[0]") === "has already been taken"
            const message = isNatIpError
              ? "The selected NAT IP has already been taken"
              : `Failed to update "${policy.name}"`

            dispatch(
              showNotificationWithTTL({
                type: "danger",
                title: policy.name,
                message,
              }),
            )

            if (isNatIpError) {
              const policyToUpdate = getState().policies.data.find(
                (item) => item.id === policy.id,
              )
              const policyData = {
                ...policy,
                policy_ip_id: policyToUpdate
                  ? policyToUpdate.policy_ip_id
                  : null,
              }
              afterSaveCallback(policyData)
            }
          }

          throw error
        }
      },
    )

export const removePolicy = (data) => (dispatch) =>
  removePolicyCall(data.id).then(
    () => {
      dispatch(
        showNotificationWithTTL({
          type: "success",
          title: data.name,
          message: `Policy "${data.name}" successfully removed!`,
          icon: "check",
        }),
      )

      dispatch(loadPolicies(false))
    },
    (error) => {
      if (error.organizations) {
        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: data.name,
            message: (
              <div>
                You can't delete the {data.name} policy because it is currently
                in use by the following organization(s):
                <br />
                <br />
                <ul>
                  {error.organizations.map((org) => (
                    <li key={org.id}>{org.name}</li>
                  ))}
                </ul>
              </div>
            ),
          }),
        )
      } else {
        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: data.name,
            message: `Failed to remove "${data.name}"`,
          }),
        )
      }

      throw error
    },
  )
