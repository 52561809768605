import React from "react"
import PropTypes from "prop-types"
import { Spinner as BlueprintSpinner } from "@blueprintjs/core"

const Spinner = (props) => (
  <BlueprintSpinner
    size={props.small ? BlueprintSpinner.SIZE_SMALL : undefined}
  />
)

Spinner.propTypes = {
  small: PropTypes.bool,
}

Spinner.defaultProps = {
  small: true,
}

export default Spinner
