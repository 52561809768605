import { useRef, useEffect } from "react"
import { useMountedState, useUnmount } from "react-use"
import { useHistory } from "react-router-dom"
import { Toaster, Position } from "@blueprintjs/core"
import { parseLocation } from "_Infinity/Core/Utils/parseLocation"

let toaster = Toaster.create({
  position: Position.TOP_RIGHT,
})

export function Toast(props) {
  let isMounted = useMountedState()
  let toastKeyRef = useRef(null)
  let history = useHistory()

  useUnmount(() => {
    toaster.dismiss(toastKeyRef.current)
  })

  // If the toast was opened, and then closed we need to reset the key so
  // we can open it again
  useEffect(() => {
    if (props.open) {
      return
    }

    toastKeyRef.current = null
  }, [props.open])

  useEffect(() => {
    if (toastKeyRef.current === undefined) {
      return
    }

    if (!props.open) {
      toaster.dismiss(toastKeyRef.current)

      return
    }

    toastKeyRef.current = toaster.show(
      {
        intent: props.intent,
        icon: props.icon,
        message: props.children,
        action: props.action
          ? {
              text: props.action.label,
              onClick: () => {
                if (props.action.to) {
                  history.push(parseLocation(props.action.to, history))
                }

                // eslint-disable-next-line
                props.action.onClick?.()
              },
            }
          : undefined,
        timeout: props.timeout,
        onDismiss() {
          if (!isMounted()) {
            return
          }

          toastKeyRef.current = undefined

          // eslint-disable-next-line
          props.onClose?.()
        },
      },
      // Blueprint interprets null as a valid key so we need to enforce
      // undefined
      toastKeyRef.current ?? undefined,
    )
  })

  return null
}
