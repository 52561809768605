import React from "react"
import { Spinner as BaseSpinner } from "@blueprintjs/core"
import { ICON_SIZES } from "_Infinity/Core/Constants/ICON_SIZES"

export function Spinner(props) {
  return (
    <BaseSpinner
      intent={props.intent}
      value={props.value}
      // As Spinner could be used as a icon in some cases, sometimes we want to
      // pass null explicitely so Blueprint can apply the size based on a
      // specific context
      size={props.size === null ? undefined : ICON_SIZES[props.size ?? 2]}
    />
  )
}
