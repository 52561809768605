import invariant from "invariant"
import axios from "axios"

let httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30_000,
})

httpClient.interceptors.request.use((config) => {
  let access_token = localStorage.getItem("access_token")

  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`
  }

  return config
})

httpClient.interceptors.response.use(undefined, (error) => {
  if (error.response?.data) {
    const _error = new Error(
      error.response.data.error ??
        error.response.data.message ??
        Object.values(error.response.data)[0],
    )
    _error.data = error.response.data
    throw _error
  }

  throw error
})

export async function sendRequest(params) {
  invariant(
    params.method === undefined ||
      params.method.match(/^get|post|put|patch|delete$/),
    `'${params.method}' is not a valid 'method'`,
  )

  invariant(params.path, "'path' is required")

  let response = await httpClient({
    method: params.method,
    url: params.path,
    [params.method === undefined || params.method === "get"
      ? "params"
      : "data"]: params.payload,
  })

  return {
    data: params.transformData?.(response.data) ?? response.data,
    meta: params.extractMetaFromData?.(response.data) ?? {},
  }
}

sendRequest.resolve = (getData, getMeta) =>
  new Promise((resolve) =>
    setTimeout(
      () =>
        import("faker").then((module) =>
          resolve({
            data: getData?.(module.default) ?? null,
            meta: getMeta?.(module.default) ?? {},
          }),
        ),
      1000,
    ),
  )

sendRequest.reject = () =>
  new Promise((_, reject) =>
    setTimeout(
      () =>
        reject(
          new Error(
            `Something went wrong (${new Date().toLocaleTimeString()})`,
          ),
        ),
      1000,
    ),
  )

let rejected = false

sendRequest.rejectThenResolve = async (...args) => {
  if (rejected) {
    let response = await sendRequest(...args)

    rejected = false

    return response
  }

  try {
    await sendRequest.reject()
  } catch (error) {
    rejected = true

    throw error
  }
}
