import React from "react"
import { Block, Row } from "jsxstyle"
import FormGroup from "components/FormGroup"
import Input from "components/Input"
import InputGroup from "components/InputGroup"
import PhoneInput from "components/PhoneInput"
import { industryOptions } from "../constants"

const SignupSecondStep = ({ form, submitting, success }) => (
  <div>
    <Row flexWrap="wrap" marginRight={-15}>
      <Block flex="1 0 230px" paddingRight={15}>
        <FormGroup
          label="First Name"
          error={form.isSubmitted && form.getError("firstName")}
          isRequired
        >
          <Input
            value={form.getValue("firstName")}
            data-test-id="first-name"
            autoFocus
            onChange={(e) => form.setValue("firstName", e.target.value)}
            disabled={submitting || success}
          />
        </FormGroup>
      </Block>
      <Block flex="1 0 230px" paddingRight={15}>
        <FormGroup
          label="Last Name"
          error={form.isSubmitted && form.getError("lastName")}
          isRequired
        >
          <Input
            value={form.getValue("lastName")}
            data-test-id="last-name"
            onChange={(e) => form.setValue("lastName", e.target.value)}
            disabled={submitting || success}
          />
        </FormGroup>
      </Block>
    </Row>
    <FormGroup
      label="Organization"
      isRequired
      error={form.isSubmitted && form.getError("organization")}
    >
      <InputGroup
        leftIcon="office"
        data-test-id="organization"
        value={form.getValue("organization")}
        onChange={(e) => form.setValue("organization", e.target.value)}
        disabled={submitting || success}
      />
    </FormGroup>
    <Row flexWrap="wrap" marginRight={-15}>
      <Block flex="1 0 230px" paddingRight={15}>
        <FormGroup
          label="Phone Number"
          error={form.isSubmitted && form.getError("phone")}
        >
          <PhoneInput
            value={form.getValue("phone")}
            onChange={(value) => form.setValue("phone", value)}
            disabled={submitting || success}
          />
        </FormGroup>
      </Block>
      <Block flex="1 0 230px" paddingRight={15}>
        <FormGroup
          label="Industry"
          isRequired
          error={form.isSubmitted && form.getError("industry")}
        >
          <Input
            type="select"
            data-test-id="industry"
            options={industryOptions}
            value={form.getValue("industry")}
            onChange={(e) => {
              form.setValue("industry", e.target.value)
            }}
            disabled={submitting || success}
          />
        </FormGroup>
      </Block>
    </Row>
    <FormGroup
      label="Briefly, please describe your use case:"
      marginBottom={20}
      error={form.isSubmitted && form.getError("useCase")}
    >
      <Input
        type="textarea"
        data-test-id="use-case"
        rows={3}
        value={form.getValue("useCase")}
        onChange={(e) => form.setValue("useCase", e.target.value)}
        disabled={submitting || success}
      />
    </FormGroup>
  </div>
)

export default SignupSecondStep
