import React, { useRef, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Button as BaseButton } from "@blueprintjs/core"
import { parseLocation } from "_Infinity/Core/Utils/parseLocation"

export function Button(props) {
  let buttonRef = useRef()
  let history = useHistory()

  useEffect(() => {
    if (!props.autoFocus) {
      return
    }

    buttonRef.current.buttonRef.focus()
  }, [props.autoFocus])

  return (
    <BaseButton
      ref={buttonRef}
      // type defaults to button so we can avoid unwanted submissions
      type={props.type ?? "button"}
      intent={props.intent}
      icon={props.icon}
      rightIcon={props.rightIcon}
      alignText={props.textAlign}
      outlined={props.outlined}
      minimal={props.minimal}
      large={props.size === 3}
      small={props.size === 1}
      fill={props.fill}
      loading={props.loading}
      active={props.active}
      disabled={props.readOnly || props.disabled}
      style={{
        cursor: props.readOnly ? "auto" : undefined,
      }}
      onClick={() => {
        if (props.to) {
          history.push(parseLocation(props.to, history))
        }

        if (props.onClick) {
          props.onClick()
        }
      }}
    >
      {props.children}
    </BaseButton>
  )
}
