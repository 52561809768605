import { createMachine, assign } from "_v2/Modules/Core"

import authenticate from "../Services/authenticate"

let sessionMachine = createMachine(
  {
    id: "session",
    context: {
      user: null,
      organizations: [],
      activeOrganization: null,
      featureFlags: {},
      subscriptions: [],
      activeSubscription: null,
      subscriptionByBeneficiary: null,
      activeOrganizationId: null,
      subscriptionIssuesAlertDismissed: false,
      legacyEnterpriseAlertDismissed: false,
      billingDetailsAlertDismissed: false,
      msp: null,
    },
    initial: "awaiting",
    states: {
      awaiting: {
        on: {
          READY: {
            target: "loading",
            actions: "setActiveOrganizationId",
          },
        },
      },
      loading: {
        invoke: {
          src: "authenticate",
          onDone: "success",
          onError: "failure",
        },
      },
      reloading: {
        invoke: {
          src: "authenticate",
          onDone: "success",
          onError: "failure",
        },
      },
      success: {
        entry: ["setData"],
        on: {
          RELOAD: "reloading",
        },
      },
      failure: {
        on: {
          RETRY: "loading",
        },
      },
    },
    on: {
      LOGOUT: {
        target: ".awaiting",
      },
      CHANGE_ORGANIZATION: {
        actions: "setActiveOrganizationId",
      },
      DISMISS_SUBSCRIPTION_ISSUES_ALERT: {
        actions: ["dismissSubscriptionIssuesAlert", "persist"],
      },
      DISMISS_LEGACY_ENTERPRISE_ALERT: {
        actions: ["dismissLegacyEnterpriseAlert", "persistLegacyEnterprise"],
      },
      DISMISS_BILLING_DETAILS_ALERT: {
        actions: ["dismissBillingDetailsAlert", "persistBillingDetailsAlert"],
      },
    },
  },
  {
    actions: {
      setData: assign({
        user: (_, e) => e.data.user,
        organizations: (_, e) => e.data.organizations,
        activeOrganization: (_, e) => e.data.activeOrganization,
        featureFlags: (_, e) => e.data.featureFlags,
        subscriptions: (_, e) => e.data.subscriptions,
        activeSubscription: (_, e) => e.data.activeSubscription,
        subscriptionByBeneficiary: (_, e) => e.data.subscriptionByBeneficiary,
        msp: (_, e) => e.data.msp,
      }),
      setActiveOrganizationId: assign({
        activeOrganizationId: (_, e) => e.payload,
      }),
      dismissSubscriptionIssuesAlert: assign({
        subscriptionIssuesAlertDismissed: true,
      }),
      dismissLegacyEnterpriseAlert: assign({
        legacyEnterpriseAlertDismissed: true,
      }),
      dismissBillingDetailsAlert: assign({
        billingDetailsAlertDismissed: true,
      }),
    },
    services: {
      authenticate: (ctx) => authenticate(ctx.activeOrganizationId),
    },
  },
)

export default sessionMachine
