import callAPI from "callAPI"

export default (networkId, enable) =>
  callAPI({
    method: "POST",
    path: `/networks/${networkId}/set_agent_auto_register`,
    body: {
      enable,
    },
  })
