import React from "react"
import join from "lodash/join"
import uniqBy from "lodash/uniqBy"
import isCidr from "is-cidr"
import { ip } from "utils/ip"
import { v4 } from "uuid"
import { loadPolicies } from "actions/filtering"
import { loadFilteringSchedules } from "actions/filteringSchedules"
import { loadBlockPages } from "actions/blockPages"
import { showNotificationWithTTL } from "actions/notifications"
import addNetworkCall from "api/networks/addNetworkCall"
import checkTrafficCall from "api/networks/checkTrafficCall"
import createDefaultPolicyCall from "api/networks/createDefaultPolicyCall"
import loadNetworkByIdCall from "api/networks/loadNetworkByIdCall"
import loadNetworksStatusCall from "api/networks/loadNetworksStatusCall"
import updateNetworkBlockPageCall from "api/networks/updateNetworkBlockPageCall"
import updateNetworkForLocalDomainsCall from "api/networks/updateNetworkForLocalDomainsCall"
import updateNetworkCall from "api/networks/updateNetworkCall"
import removeNetworkCall from "api/networks/removeNetworkCall"
import enableUserAgentAutoRegisterCall from "api/networks/enableUserAgentAutoRegisterCall"
import secretKeyCall from "api/networks/secretKeyCall"
import toggleBackgroundColor from "utils/toggleBackgroundColor"
import { isIp4InCidr, isIp4InCidrs } from "utils/networks"
import {
  LOAD_NETWORKS,
  LOAD_NETWORK_BY_ID,
  UPDATE_NETWORK_SEARCH_TEXT,
  UPDATE_NETWORK_STATUS_FILTER,
  UPDATE_NETWORK_IP,
  CHANGE_NETWORK,
  UPDATE_SECRET_KEY,
  LOAD_NETWORK_STATUS,
  SUBMITTING_NETWORK_FORM,
  TOGGLE_EDIT_MULTIPLE_NETWORKS_FORM,
  CHANGE_ALL_NETWORKS,
  SELECT_NETWORK,
  UPDATE_MULTIPLE_NETWORKS_REQUEST,
  CHANGE_MULTIPLE_NETWORKS,
  SHOW_CONFIRM_NETWORK_DELETE_MODAL,
  HIDE_CONFIRM_NETWORK_DELETE_MODAL,
  CLEAR_SELECTED_NETWORKS,
  TOGGLE_ADD_FORM,
  ENABLE_USER_AGENT_AUTO_REGISTER,
} from "action-types/networks"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"
import { colors } from "utils/colorPalette"
import callAPI from "callAPI"
import checkSitesCall from "api/networks/checkSitesCall"
import { showDNSResolvers } from "utils/dnsResolvers"

export const loadNetworksRequest = actionCreatorFetch(LOAD_NETWORKS)

export const loadNetworksSuccess = actionCreatorSuccess(LOAD_NETWORKS)

export const loadNetworksFailure = actionCreatorFailure(LOAD_NETWORKS)

export const loadNetworkByIdRequest = actionCreatorFetch(LOAD_NETWORK_BY_ID)

export const loadNetworkByIdSuccess = actionCreatorSuccess(LOAD_NETWORK_BY_ID)

export const loadNetworkByIdFailure = actionCreatorFailure(LOAD_NETWORK_BY_ID)

export const updateSecretKeyRequest = actionCreatorFetch(UPDATE_SECRET_KEY)

export const updateSecretKeySuccess = actionCreatorSuccess(UPDATE_SECRET_KEY)

export const updateSecretKeyFailure = actionCreatorFailure(UPDATE_SECRET_KEY)

export const updateSelectAllNetworks = ({ allChecked, visibleIds }) => ({
  type: CHANGE_ALL_NETWORKS,
  payload: {
    allChecked,
    visibleIds,
  },
})

export const toggleEditMultipleNetworksForm = (showForm) => ({
  type: TOGGLE_EDIT_MULTIPLE_NETWORKS_FORM,
  payload: showForm,
})

export const selectNetwork = (networkId) => ({
  type: SELECT_NETWORK,
  payload: networkId,
})

export const showNetworkModal = (networkId) => ({
  type: SHOW_CONFIRM_NETWORK_DELETE_MODAL,
  payload: networkId,
})

export const hideNetworkModal = () => ({
  type: HIDE_CONFIRM_NETWORK_DELETE_MODAL,
})

export const loadNetworks = () => async (dispatch) => {
  dispatch(
    loadNetworksRequest({
      data: [],
    }),
  )

  try {
    dispatch(
      loadNetworksSuccess({
        data: [],
        hasAtLeastOneSite: await checkSitesCall(),
        haveTraffic: await checkTrafficCall(),
      }),
    )
  } catch {
    dispatch(
      loadNetworksSuccess({
        data: [],
      }),
    )
  }
}

export const loadNetworkById =
  ({ dispatchRequest = true, networkId }) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(
        loadNetworkByIdRequest({
          data: [],
        }),
      )
    }

    return loadNetworkByIdCall(networkId)
      .then(async (data) => {
        dispatch(
          loadNetworkByIdSuccess({
            data,
          }),
        )
      })
      .catch(() => {
        dispatch(loadNetworkByIdFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "There was a problem while loading network",
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const updateNetworkSearchText = (searchText) => ({
  type: UPDATE_NETWORK_SEARCH_TEXT,
  payload: searchText,
})

export const updateNetworkStatusFilter = (status) => ({
  type: UPDATE_NETWORK_STATUS_FILTER,
  payload: status,
})

export const updateNetworkUpdateIp = (network) => ({
  type: UPDATE_NETWORK_IP,
  payload: network,
})

export const changeNetwork = (network) => ({
  type: CHANGE_NETWORK,
  payload: network,
})

export const updateSecretKey = (selectedId) => (dispatch, getState) => {
  dispatch(
    updateSecretKeyRequest({
      isSecretKeyFetching: true,
      secretKeyError: false,
    }),
  )

  return secretKeyCall(selectedId)
    .then(() => {
      dispatch(
        updateSecretKeySuccess({
          data: [],
          isSecretKeyFetching: false,
        }),
      )
    })
    .catch(() =>
      dispatch(
        updateSecretKeyFailure({
          isSecretKeyFetching: false,
          secretKeyError: true,
        }),
      ),
    )
}

export const loadNetworksStatusRequest = actionCreatorFetch(LOAD_NETWORK_STATUS)

export const loadNetworksStatusSuccess =
  actionCreatorSuccess(LOAD_NETWORK_STATUS)

export const loadNetworksStatusFailure =
  actionCreatorFailure(LOAD_NETWORK_STATUS)

export const submittingNetworkForm = (isSubmitting) => ({
  type: SUBMITTING_NETWORK_FORM,
  isSubmitting,
})

export const loadNetworksStatus = () => (dispatch) => {
  dispatch(
    loadNetworksStatusRequest({
      activeNetworkIds: [],
    }),
  )

  return loadNetworksStatusCall()
    .then((activeNetworkIds) =>
      dispatch(
        loadNetworksStatusSuccess({
          activeNetworkIds,
        }),
      ),
    )
    .catch(() => {
      dispatch(loadNetworksStatusFailure())

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "There was a problem while loading active networks",
          message: "",
          icon: "exclamation-circle",
        }),
      )
    })
}

const createIPAddressesOrFromCidr = (ipAddresses) => {
  let networkAddresses = [...ipAddresses]
  let generatedIpAddresses = []

  const ipAddressesToAnalyze = []

  networkAddresses.forEach((ipAddress) => {
    if (isCidr(ipAddress.address)) {
      ipAddressesToAnalyze.push(ipAddress.address)

      const newCidr = generateIpAddressesFromNewCidr(ipAddress.address)

      generatedIpAddresses = [...generatedIpAddresses, ...newCidr]
    }
  })

  if (ipAddressesToAnalyze.length && generatedIpAddresses.length) {
    networkAddresses = [...ipAddresses, ...generatedIpAddresses].filter(
      (ipAddress) => !ipAddressesToAnalyze.includes(ipAddress.address),
    )
  }

  return networkAddresses.filter((ipAddress) => !!ipAddress.address)
}

export function generateIpAddressesFromNewCidr(ipAddress) {
  const generatedIpAddresses = []
  const subnetData = ip.cidrSubnet(ipAddress)
  const { networkAddress, broadcastAddress } = subnetData
  const splittedNetwork = networkAddress.split(".")
  const fromAddress = Number(splittedNetwork[3])
  const toAddress = Number(broadcastAddress.split(".")[3])
  for (let newIp = fromAddress; newIp <= toAddress; newIp++) {
    generatedIpAddresses.push({
      address: [
        splittedNetwork[0],
        splittedNetwork[1],
        splittedNetwork[2],
        newIp,
      ].join("."),
      key: v4(),
    })
  }

  return generatedIpAddresses
}

export const toggleAddForm = (payload) => ({
  type: TOGGLE_ADD_FORM,
  payload,
})

export const addNetwork = (data, afterAddCallback) => {
  data.uiIpAddresses = data.ipAddresses
  data.ipAddresses = createIPAddressesOrFromCidr(data.ipAddresses)

  return async (dispatch, getState) => {
    const state = getState()
    let policyId

    let shouldCreateDefaultPolicy = false
    try {
      const hasAtLeastOneSite = await checkSitesCall()
      shouldCreateDefaultPolicy = !hasAtLeastOneSite
    } catch {}

    if (shouldCreateDefaultPolicy) {
      if (!state.policies.data || !state.policies.data.length) {
        const policy = await createDefaultPolicyCall(
          state.auth.data.organizationId,
        )
        policyId = policy.data.id
      } else {
        policyId = state.policies.data[0].id
      }
    }
    if (data.policyId && data.overrideWithPolicy) {
      policyId = data.policyId
    }
    return addNetworkCall({
      ...data,
      organization: state.auth.data.organizationId,
      policyIds: [Number(policyId)],
    }).then(
      ({ data }) => {
        dispatch(loadNetworks()).then(() => {
          dispatch(loadPolicies(false))

          dispatch(
            showNotificationWithTTL(
              {
                type: "success",
                title: `Network "${data.attributes.name}" added successfully`,
                message: `Please point this network’s DNS to our servers at ${showDNSResolvers(
                  { asSentence: true },
                )}`,
                icon: "check",
              },
              15,
            ),
          )
        })

        if (afterAddCallback) {
          afterAddCallback(data.id)
        }

        dispatch(toggleAddForm(false))
      },
      (error) => {
        const handled_errors = handleSaveNetworkError(data, error, dispatch)
        throw handled_errors
      },
    )
  }
}

export const updateIpAddressesOrCidr = (ipAddresses, originalIpAddresses) => {
  let receivedData = ipAddresses.slice()

  const originalData = [...originalIpAddresses]
  const ipAddressesToAnalyze = []
  const generatedIpAddresses = []

  receivedData.forEach((ipAddress, index) => {
    if (isCidr(ipAddress.address)) {
      ipAddressesToAnalyze.push(ipAddress.address)

      const subnetData = ip.cidrSubnet(ipAddress.address)
      const { networkAddress, broadcastAddress } = subnetData
      const splittedNetwork = networkAddress.split(".")
      const fromAddress = Number(splittedNetwork[3])
      const toAddress = Number(broadcastAddress.split(".")[3])
      for (let newIp = fromAddress; newIp <= toAddress; newIp++) {
        const generatedAddress = [
          splittedNetwork[0],
          splittedNetwork[1],
          splittedNetwork[2],
          newIp,
        ].join(".")
        const foundAddresses = originalData.find(
          (addressObj) => addressObj.address === generatedAddress,
        )

        if (foundAddresses) {
          if (ipAddress._destroy) {
            foundAddresses._destroy = true
          }

          generatedIpAddresses.push({
            ...foundAddresses,
          })
        }

        if (!foundAddresses) {
          generatedIpAddresses.push({
            address: generatedAddress,
            key: v4(),
          })
        }
      }
    } else {
      const currentAddress = receivedData[index]
      const isAlreadyExist = originalData.find(
        (address) =>
          address.id === currentAddress.id &&
          address.address !== currentAddress.address,
      )
      const isExist = originalData.length && isAlreadyExist
      if (isExist && !ipAddress._destroy) {
        isAlreadyExist._destroy = true
        delete isAlreadyExist.id
      }
    }
  })

  if (ipAddressesToAnalyze.length && generatedIpAddresses.length) {
    receivedData = [...receivedData, ...generatedIpAddresses]

    receivedData = receivedData.filter(
      (el) => !ipAddressesToAnalyze.includes(el.address),
    )
  }

  receivedData = receivedData.filter((el) => !isCidr(el.address))

  let addressesToDestroy = originalData.filter(
    (originalItem) =>
      receivedData.filter((current) => current.address === originalItem.address)
        .length === 0,
  )

  if (addressesToDestroy.length) {
    addressesToDestroy = addressesToDestroy.map((address) => ({
      ...address,
      _destroy: true,
    }))
  }

  receivedData = [...receivedData, ...addressesToDestroy]

  const updatedAddresses = receivedData
    .filter((el) => !!el.address)
    .map((address) =>
      Object.keys(address).reduce((res, key) => {
        if (key !== "ip") {
          res[key] = address[key]
        }

        return res
      }, {}),
    )

  return updatedAddresses
}

export const updateNetworkForLocalDomains = (
  data,
  afterSaveCallback,
  isPolicyUpdated,
) => {
  data.uiIpAddresses = data.ipAddresses

  if (!isPolicyUpdated && data.ipAddresses) {
    data.ipAddresses = updateIpAddressesOrCidr(
      data.ipAddresses,
      data.originalIpAddresses,
    )

    delete data.originalIpAddresses
  }

  return (dispatch, getState) =>
    updateNetworkForLocalDomainsCall({
      ...data,
      organization: getState().auth.data.organizationId,
    }).then(
      () => {
        dispatch(loadNetworks()).then(() => {
          dispatch(
            showNotificationWithTTL(
              {
                type: "success",
                title: data?.successMessage
                  ? data.successMessage
                  : `${data.name} network updated!`,
                message: (
                  <span>
                    {data.updatedFromNetworkPolicyDropdown && (
                      <span>
                        <strong>
                          Changes may take a few minutes to take effect.
                        </strong>
                        {(!localStorage.getItem("whitelabel.name") ||
                          localStorage.getItem("organization.isMSP")) && (
                          <span>
                            <br />
                            <a
                              href="https://help.dnsfilter.com/hc/en-us/articles/1500008111261-caching"
                              style={{
                                color: colors.navyBlue,
                              }}
                            >
                              Learn More
                            </a>
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                ),
                icon: "check",
              },
              6,
            ),
          )

          if (afterSaveCallback) {
            afterSaveCallback(data.id)
          }

          dispatch(loadPolicies(false))
          dispatch(loadFilteringSchedules(false))
        })
      },
      (error) => {
        const handled_errors = handleSaveNetworkError(data, error, dispatch)
        throw handled_errors
      },
    )
}

export const updateNetwork = (data, afterSaveCallback, isPolicyUpdated) => {
  data.uiIpAddresses = data.ipAddresses

  if (!isPolicyUpdated && data.ipAddresses) {
    data.ipAddresses = updateIpAddressesOrCidr(
      data.ipAddresses,
      data.originalIpAddresses,
    )

    delete data.originalIpAddresses
  }

  return (dispatch, getState) => {
    const updateNetworkResponse = updateNetworkCall({
      ...data,
      organization: getState().auth.data.organizationId,
    }).then(
      () => {
        dispatch(loadNetworks()).then(() => {
          dispatch(
            showNotificationWithTTL(
              {
                type: "success",
                title: data?.successMessage
                  ? data.successMessage
                  : `${data.name} network updated!`,
                message: (
                  <span>
                    {data.updatedFromNetworkPolicyDropdown && (
                      <span>
                        <strong>
                          Changes may take a few minutes to take effect.
                        </strong>
                        {(!localStorage.getItem("whitelabel.name") ||
                          localStorage.getItem("organization.isMSP")) && (
                          <span>
                            <br />
                            <a
                              href="https://help.dnsfilter.com/hc/en-us/articles/1500008111261-caching"
                              style={{
                                color: colors.navyBlue,
                              }}
                            >
                              Learn More
                            </a>
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                ),
                icon: "check",
              },
              6,
            ),
          )

          if (afterSaveCallback) {
            afterSaveCallback(data.id)
          }

          dispatch(loadPolicies(false))
          dispatch(loadFilteringSchedules(false))
        })
      },
      (error) => {
        const handled_errors = handleSaveNetworkError(data, error, dispatch)
        throw handled_errors
      },
    )

    if (isPolicyUpdated) {
      return updateNetworkResponse.catch((e) => {
        const message = e?.ipAddresses
          ?.map((address) => address?.message)
          ?.join("\n")
        dispatch(
          showNotificationWithTTL(
            {
              type: "danger",
              title: "Cannot update Policy/Schedule",
              message,
            },
            60,
          ),
        )
      })
    }

    return updateNetworkResponse
  }
}

function handleSaveNetworkError(network, error, dispatch) {
  let errors = {}

  if (error.ip_addresses_attributes) {
    const ipAddressesErrors = []

    Object.keys(error).forEach((key) => {
      const keySplitted = key.split(".")
      const errorPosition = keySplitted[1]
      if (errorPosition) {
        ipAddressesErrors.push({
          address: network.ipAddresses[errorPosition].address,
          message: error[key][0],
        })
      }
    })

    const cidrErrors = uniqBy(
      ipAddressesErrors.reduce((acc, ipError) => {
        let cidrError

        network.uiIpAddresses.forEach(({ address }, index) => {
          if (isCidr(address)) {
            if (isIp4InCidr(ipError.address)(address)) {
              cidrError = {
                address,
                message: "An IP in this range already exists in another site",
              }
            }
          }
        })

        if (cidrError) {
          return [...acc, cidrError]
        }

        return acc
      }, []),
      "address",
    )
    const nonSubnetIpsErrors = ipAddressesErrors.filter(
      (ipError) =>
        ipError &&
        !isIp4InCidrs(
          ipError.address,
          network.uiIpAddresses
            .map(({ address }) => address)
            .filter((address) => isCidr(address)),
        ),
    )

    errors.ipAddresses = [...cidrErrors, ...nonSubnetIpsErrors]

    if (!errors.ipAddresses.length) {
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: `Failed to save "${network.name}"`,
          message: error.ip_addresses_attributes[0],
        }),
      )
    }
  } else {
    let message = ""

    try {
      errors = Object.keys(error).reduce(
        (acc, key) => [...acc, ...error[key]],
        [],
      )

      message = join(errors, "; ")
    } catch (err) {
      message = "Error saving network"
    }

    dispatch(
      showNotificationWithTTL({
        type: "danger",
        title: `Failed to save "${network.name}"`,
        message,
      }),
    )
  }

  return errors
}

export const saveNetwork = (network, afterSaveCallback) => {
  if (network.id) {
    return updateNetwork(network, afterSaveCallback)
  }

  return addNetwork(network, afterSaveCallback)
}

export const saveNetworkForLocalDomains = (network, afterSaveCallback) => {
  if (network.id) {
    return updateNetworkForLocalDomains(network, afterSaveCallback)
  }

  return addNetwork(network, afterSaveCallback)
}

export const updateBlockPage =
  (networkId, networkName, block_page_id) => (dispatch, getState) =>
    updateNetworkBlockPageCall(networkId, block_page_id).then(
      () => {
        dispatch(
          showNotificationWithTTL(
            {
              type: "success",
              title: `${networkName} network updated!`,
              icon: "check",
            },
            6,
          ),
        )

        dispatch(loadNetworks()).then(() => {
          dispatch(loadPolicies(false))
          dispatch(loadFilteringSchedules(false))
          dispatch(loadBlockPages(false))
        })
      },
      (error) => {
        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: networkName,
            message: `Failed to update "${networkName}"`,
          }),
        )

        throw error
      },
    )

export const removeNetwork = (data) => (dispatch, getState) => {
  const state = getState()
  const selectedNetwork = state.networks.selectedNetworksIds.includes(data.id)

  return removeNetworkCall(data.id).then(
    () => {
      dispatch(
        showNotificationWithTTL({
          type: "success",
          title: data.name,
          message: `Network "${data.name}" successfully removed!`,
          icon: "check",
        }),
      )

      dispatch(hideNetworkModal())

      if (selectedNetwork) {
        dispatch(selectNetwork(data.id))
      }

      dispatch(loadNetworks()).then(() => dispatch(loadPolicies(false)))
    },
    (error) => {
      let message = ""

      switch (error?.error) {
        case "active_roaming_clients":
          message = `${data.name} has one or more roaming clients assigned to it. In order to remove this site, please remove roaming clients on the manage page.`
          break
        case "active_traffic":
          message = `${data.name} has received traffic within the last 15 minutes. In order to remove this site, verify that this site is no longer in use and try again.`
          break
        default:
          message = `Failed to remove "${data.name}"`
      }

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "Cannot remove site",
          message,
        }),
      )

      throw error
    },
  )
}

export const changeNetworks = (networks) => ({
  type: CHANGE_MULTIPLE_NETWORKS,
  payload: networks,
})

export const updateMultipleNetworksRequest = actionCreatorFetch(
  UPDATE_MULTIPLE_NETWORKS_REQUEST,
)

export const updateMultipleNetworksSuccess = actionCreatorSuccess(
  UPDATE_MULTIPLE_NETWORKS_REQUEST,
)

export const updateMultipleNetworksFailure = actionCreatorFailure(
  UPDATE_MULTIPLE_NETWORKS_REQUEST,
)

export const updateMultipleNetworks =
  ({
    selectedNetworksIds,
    policy_id,
    scheduled_policy_id,
    block_page_id,
    is_legacy_vpn_active,
  }) =>
  (dispatch) => {
    dispatch(updateMultipleNetworksRequest())

    const requests = selectedNetworksIds.map((networkId) => {
      const policy_ids =
        policy_id === "no-policy" ? [] : policy_id ? [policy_id] : undefined
      return callAPI({
        method: "PUT",
        path: `/networks/${networkId}`,
        body: {
          network: {
            policy_ids,
            block_page_id:
              block_page_id === "default-appearance" ? null : block_page_id,
            scheduled_policy_id:
              policy_ids === undefined
                ? scheduled_policy_id
                  ? Number(scheduled_policy_id)
                  : undefined
                : policy_id === "no-policy"
                ? null
                : undefined,
            is_legacy_vpn_active: is_legacy_vpn_active
              ? is_legacy_vpn_active === "true"
              : undefined,
          },
        },
      })
    })

    Promise.all(requests)
      .then(() => {
        dispatch(loadNetworks()).then(() => {
          dispatch(
            showNotificationWithTTL(
              {
                type: "success",
                title: "Sites updated!",
                message: null,
                icon: "check",
              },
              6,
            ),
          )
        })

        dispatch(updateMultipleNetworksSuccess())
        dispatch(toggleEditMultipleNetworksForm(false))

        selectedNetworksIds.forEach((id) => {
          toggleBackgroundColor(`#${id}`, "success")
        })
      })
      .catch(() => {
        dispatch(updateMultipleNetworksFailure())

        dispatch(
          showNotificationWithTTL(
            {
              type: "danger",
              title: "Some of the sites cannot be updated successfully",
              message: null,
              icon: "exclamation-circle",
            },
            6,
          ),
        )
      })
  }

export const clearSelectedNetworks = () => ({
  type: CLEAR_SELECTED_NETWORKS,
})

export const deleteMultipleNetworks = () => (dispatch, getState) => {
  const state = getState()
  const networkIds = state.networks.selectedNetworksIds
  const requests = networkIds.map((networkId) => removeNetworkCall(networkId))

  Promise.all(requests)
    .then(() => {
      dispatch(loadNetworks()).then(() => {
        dispatch(
          showNotificationWithTTL(
            {
              type: "success",
              title: "Sites deleted!",
              message: null,
              icon: "check",
            },
            6,
          ),
        )
      })

      dispatch(clearSelectedNetworks())
      dispatch(hideNetworkModal())
      dispatch(toggleEditMultipleNetworksForm(false))
    })
    .catch(() => {
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "Error deleting sites",
          message: "Failed to delete.",
          icon: "exclamation-circle",
        }),
      )
    })
}

export const enableUserAgentAutoRegister = (networkId, enable) => ({
  type: ENABLE_USER_AGENT_AUTO_REGISTER,
  payload: {
    networkId,
    enable,
  },
})

export const enableUserAgentsAutoRegisterRequest = actionCreatorFetch(
  ENABLE_USER_AGENT_AUTO_REGISTER,
)

export const enableUserAgentsAutoRegisterSuccess = actionCreatorSuccess(
  ENABLE_USER_AGENT_AUTO_REGISTER,
)

export const enableUserAgentsAutoRegisterFailure = actionCreatorFailure(
  ENABLE_USER_AGENT_AUTO_REGISTER,
)

export const enableUserAgentsAutoRegister =
  (networkId, enable) => (dispatch) => {
    dispatch(enableUserAgentsAutoRegisterRequest())

    return enableUserAgentAutoRegisterCall(networkId, enable)
      .then((response) => {
        dispatch(enableUserAgentsAutoRegisterSuccess(response.data))

        dispatch(
          showNotificationWithTTL({
            type: "success",
            title: `Roaming clients auto register ${
              enable ? "enabled" : "disabled"
            } successfully!`,
            message: "",
            icon: "check",
          }),
        )
      })
      .catch(() => {
        dispatch(enableUserAgentsAutoRegisterFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: `There was a problem ${
              enable ? "enabling" : "disabling"
            } roaming clients auto register`,
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }
