import { LOAD_MSP } from "action-types/msp"

export const msp = (
  state = {
    data: [],
    status: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_MSP: {
      const { data, status } = action.payload

      return {
        ...state,
        status,
        data,
      }
    }

    default:
      return state
  }
}

export default msp
