import callAPI from "callAPI"

export default ({
  organization: organization_id,
  name,
  categoryIds: blacklist_categories,
  whitelist,
  blacklist,
  block_unknown_domains,
  ...rest
}) =>
  callAPI({
    method: "POST",
    path: "/policies",
    body: {
      policy: {
        ...rest,
        organization_id,
        name,
        blacklist_categories,
        whitelist_domains: whitelist && whitelist.map(({ domain }) => domain),
        blacklist_domains: blacklist && blacklist.map(({ domain }) => domain),
        allow_unknown_domains: block_unknown_domains
          ? !block_unknown_domains
          : true,
      },
    },
  })
