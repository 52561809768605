export default function retry(fn, left = 10, delay = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (left === 1) {
            reject(error)
          } else {
            retry(fn, left - 1, delay).then(resolve, reject)
          }
        }, delay)
      })
  })
}
