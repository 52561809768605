export default (policies) =>
  policies.sort(({ name: previousName }, { name: nextName }) => {
    if (previousName < nextName) {
      return -1
    }

    if (previousName > nextName) {
      return 1
    }

    return 0
  })
