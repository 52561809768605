import {
  LOAD_FILTERING_SCHEDULES,
  SUBMITTING_FILTERING_SCHEDULE,
  AFTER_CREATE_FILTERING_SCHEDULE,
  AFTER_UPDATE_FILTERING_SCHEDULE,
  AFTER_REMOVE_FILTERING_SCHEDULE,
  UPDATE_ADD_NEW_SCHEDULE,
  UPDATE_EDIT_EXISTING_SCHEDULE,
  UPDATE_DELETE_EXISTING_SCHEDULE,
  UPDATE_SELECTED_ID,
} from "action-types/filteringSchedules"

const initialState = {
  data: [],
  status: null,
  submitting: false,
  addNewSchedule: false,
  editExistingSchedule: false,
  deleteExistingSchedule: false,
  selectedId: null,
}
export const filteringSchedules = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FILTERING_SCHEDULES:
      return {
        ...state,
        ...action.payload,
      }
    case AFTER_CREATE_FILTERING_SCHEDULE:
      return {
        ...state,
        data: [...state.data, action.payload],
        submitting: false,
      }
    case AFTER_UPDATE_FILTERING_SCHEDULE:
      return {
        ...state,
        submitting: false,
        data: state.data.map((filteringSchedule) =>
          filteringSchedule.id === action.payload.id
            ? {
                ...action.payload,
                networkCount: filteringSchedule
                  ? filteringSchedule.networkCount
                  : null,
                agentCount: filteringSchedule
                  ? filteringSchedule.agentCount
                  : null,
              }
            : filteringSchedule,
        ),
      }
    case AFTER_REMOVE_FILTERING_SCHEDULE:
      return {
        ...state,
        submitting: false,
        data: state.data.filter(
          (filteringSchedule) => filteringSchedule.id !== action.payload.id,
        ),
      }
    case SUBMITTING_FILTERING_SCHEDULE:
      return {
        ...state,
        submitting: true,
      }
    case UPDATE_ADD_NEW_SCHEDULE:
      return {
        ...state,
        addNewSchedule: action.payload,
      }
    case UPDATE_EDIT_EXISTING_SCHEDULE:
      return {
        ...state,
        editExistingSchedule: action.payload,
      }
    case UPDATE_DELETE_EXISTING_SCHEDULE:
      return {
        ...state,
        deleteExistingSchedule: action.payload,
      }
    case UPDATE_SELECTED_ID:
      return {
        ...state,
        selectedId: action.payload,
      }
    default:
      return state
  }
}

export default filteringSchedules
