import callAPI from "callAPI"
import sortPolicies from "api/filtering/transform/sortPolicies"
import { filterDistributorPolicies } from "utils/distributor"

export default async (allPolicies, include_global_policies = "false") => {
  const URL = allPolicies ? "/policies/all" : "/policies"
  let query = {
    organization_id: localStorage.getItem("orgId"),
    include_global_policies,
  }

  if (!allPolicies) {
    query = {
      ...query,
      include_global_policies: true,
    }
  }

  const policies = await callAPI({
    path: URL,
    query,
  })

  return sortPolicies(
    filterDistributorPolicies({
      key: "filtering",
      data: policies.data.map((value) => ({
        id: Number(value.id),
        name: value.attributes.name,
        categoryIds: value.attributes.blacklist_categories,
        whitelist: value.attributes.whitelist_domains || [],
        blacklist: value.attributes.blacklist_domains || [],
        policy_ip_id: value.attributes.policy_ip_id,
        bingSearch: value.attributes.bing_safe_search,
        googleSearch: value.attributes.google_safesearch,
        yandexSearch: value.attributes.yandex_safe_search,
        duckDuckGoSearch: value.attributes.duck_duck_go_safe_search,
        interstitial: value.attributes.interstitial,
        youtube_restricted: value.attributes.youtube_restricted,
        youtube_restricted_level: value.attributes.youtube_restricted_level,
        block_unknown_domains: !value.attributes.allow_unknown_domains,
        disable_iwf_blocking: value.attributes.disable_iwf_blocking,
        networkCount: (value.relationships.networks?.data ?? []).length,
        collectionCount: (value.relationships.collections?.data ?? []).length,
        agentCount: (value.relationships.user_agents?.data ?? []).length,
        agentLocalUsers: (value.relationships.agent_local_users?.data ?? [])
          .length,
        lockVersion: value.attributes.lock_version,
        is_global_policy: value.attributes.is_global_policy,
        organization_id: value.relationships.organization.data.id,
        allow_applications: value.attributes.allow_applications,
        block_applications: value.attributes.block_applications,
        allowNotes: value.attributes.allow_notes,
        blockNotes: value.attributes.block_notes,
        isSearchEnginesEnabled:
          value.attributes.bing_safe_search &&
          value.attributes.google_safesearch &&
          value.attributes.yandex_safe_search &&
          value.attributes.duck_duck_go_safe_search,
      })),
    }),
  )
}
