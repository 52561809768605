import React from "react"
import { Popover as BlueprintPopover, Position } from "@blueprintjs/core"
import PropTypes from "prop-types"

const Popover = (props) => (
  <BlueprintPopover
    position={Position[props.position.toUpperCase().replace("-", "_")]}
    content={props.content}
    isOpen={props.isOpen}
    fill={props.fill}
    interactionKind={props.interactionKind}
  >
    <div>{props.children}</div>
  </BlueprintPopover>
)

Popover.propTypes = {
  position: PropTypes.oneOf(
    Object.keys(Position).map((position) =>
      String(position).toLowerCase().replace("_", "-"),
    ),
  ).isRequired,
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
}

Popover.defaultProps = {
  position: "top",
  isOpen: null,
}

export default Popover
