import React, { useEffect } from "react"

import { useDistributorPlatform } from "_Infinity/DistributorPlatform/Hooks/useDistributorPlatform"

export default function Page(props) {
  const platform = useDistributorPlatform()

  useEffect(() => {
    if (platform.hideChatSupport) {
      localStorage.setItem("hideChatSupport", true)
    } else {
      localStorage.removeItem("hideChatSupport")
    }
  }, [platform])

  if (props.isInvoice) {
    return <div>{props.children}</div>
  }

  return (
    <div
      className={platform.showNavigation && "page"}
      style={{
        paddingTop: platform.showNavbar && 66,
      }}
    >
      {props.children}
    </div>
  )
}
