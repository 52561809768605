import moment from "moment"
import qpsCall from "api/reports/qpsCall"

export default async () => {
  const queriesPerSecond = await qpsCall({
    showIndividualNetworks: true,
    from: moment().subtract(15, "minutes").startOf("minute").format(),
    to: moment().format(),
  })

  return queriesPerSecond.data.network_ids
}
