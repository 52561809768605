import React from "react"
import PropTypes from "prop-types"

const RocketIcon = ({ width, height, color, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1885 1.75911C24.1394 0.819828 23.3904 0.0769939 22.4572 0.0278809C20.5664 -0.0764843 16.8707 0.0278809 13.3346 1.8512C11.5297 2.78435 9.62655 4.3621 8.10405 6.18542C8.08564 6.20998 8.06722 6.2284 8.0488 6.25295L4.59863 6.51694C4.0277 6.55991 3.52429 6.86073 3.2112 7.33344L0.853789 10.8941C0.614365 11.2563 0.57753 11.7045 0.749425 12.1035C0.921319 12.4964 1.28353 12.7727 1.71326 12.8402L4.62319 13.2884C4.61091 13.3436 4.60477 13.3989 4.5925 13.4603C4.49427 14.0374 4.68458 14.6329 5.10818 15.0503L9.15998 19.1021C9.50991 19.4521 9.97648 19.6424 10.4553 19.6424C10.5536 19.6424 10.6518 19.6362 10.75 19.6178C10.8053 19.6056 10.8605 19.5994 10.9219 19.5871L11.3701 22.4971C11.4376 22.9268 11.7138 23.2829 12.1067 23.4609C12.2725 23.5346 12.4444 23.5714 12.6163 23.5714C12.8619 23.5714 13.1013 23.4978 13.31 23.3627L16.8707 21.0053C17.3434 20.6922 17.6442 20.1826 17.6872 19.6178L17.9512 16.1676C17.9757 16.1492 17.9941 16.1308 18.0187 16.1124C19.842 14.5899 21.4259 12.6868 22.3529 10.8819C24.1885 7.34572 24.2867 3.64383 24.1885 1.75911ZM16.1094 19.8511L12.7022 22.1042L12.2602 19.2679C13.7029 18.8443 15.1272 18.169 16.4839 17.2604L16.312 19.5073C16.2997 19.6485 16.2261 19.7774 16.1094 19.8511ZM10.13 18.1322L6.07816 14.0803C5.97379 13.976 5.92468 13.8286 5.94923 13.6936C6.07202 12.9753 6.25619 12.2877 6.48947 11.6431L12.5672 17.7208C11.7936 17.9971 11.0938 18.1629 10.5167 18.2611C10.3755 18.2856 10.2343 18.2365 10.13 18.1322ZM4.69686 7.89824L6.94377 7.72634C6.04132 9.08309 5.35988 10.5074 4.93628 11.9501L2.10002 11.508L4.35921 8.10083C4.43288 7.98419 4.5618 7.91052 4.69686 7.89824ZM17.1408 15.0503C16.0235 15.9835 14.943 16.6526 13.9423 17.1438L7.07269 10.2679C7.71115 8.96645 8.49082 7.87982 9.16612 7.06946C10.572 5.3812 12.3277 3.92623 13.973 3.07289C17.2206 1.39691 20.6339 1.30482 22.3836 1.39691C22.6169 1.40918 22.801 1.59336 22.8133 1.82664C22.9115 3.5763 22.8195 6.99579 21.1373 10.2372C20.284 11.8887 18.8229 13.6383 17.1408 15.0503Z"
      fill={color}
    />
    <path
      d="M16.8451 10.8037C17.7251 10.8037 18.6051 10.4677 19.2773 9.7956C19.9251 9.14797 20.2856 8.28038 20.2856 7.36392C20.2856 6.44746 19.9251 5.58598 19.2773 4.93224C17.939 3.5942 15.7573 3.5942 14.419 4.93224C13.7712 5.57987 13.4106 6.44746 13.4106 7.36392C13.4106 8.28038 13.7712 9.14186 14.419 9.7956C15.0851 10.4677 15.9651 10.8037 16.8451 10.8037ZM15.3906 5.9098C15.794 5.50655 16.3195 5.30493 16.8512 5.30493C17.3768 5.30493 17.9084 5.50655 18.3118 5.9098C18.7029 6.30082 18.9168 6.81404 18.9168 7.37003C18.9168 7.91991 18.7029 8.43924 18.3118 8.83026C17.5051 9.63675 16.1973 9.63675 15.3968 8.83026C15.0056 8.43924 14.7918 7.92602 14.7918 7.37003C14.7856 6.81404 14.9995 6.30082 15.3906 5.9098Z"
      fill={color}
    />
    <path
      d="M1.39144 19.6429C1.58356 19.6429 1.77568 19.5699 1.92143 19.4239L4.35277 16.9885C4.64427 16.6965 4.64427 16.2253 4.35277 15.9333C4.06128 15.6414 3.59091 15.6414 3.29941 15.9333L0.861444 18.3688C0.569948 18.6608 0.569948 19.1319 0.861444 19.4239C1.00719 19.5699 1.19931 19.6429 1.39144 19.6429Z"
      fill={color}
    />
    <path
      d="M6.32603 17.8876C6.04757 17.6091 5.59823 17.6091 5.31976 17.8876L0.851671 22.3563C0.573206 22.6348 0.573206 23.0842 0.851671 23.3627C0.990903 23.5019 1.17444 23.5716 1.35797 23.5716C1.5415 23.5716 1.72504 23.5019 1.86427 23.3627L6.33236 18.894C6.6045 18.6155 6.6045 18.1661 6.32603 17.8876Z"
      fill={color}
    />
    <path
      d="M7.22125 19.8621L4.78991 22.2975C4.49842 22.5895 4.49842 23.0607 4.78991 23.3526C4.93566 23.4986 5.12778 23.5716 5.3199 23.5716C5.51203 23.5716 5.70415 23.4986 5.8499 23.3526L8.28124 20.9172C8.57273 20.6252 8.57273 20.154 8.28124 19.8621C7.98312 19.5701 7.51275 19.5701 7.22125 19.8621Z"
      fill={color}
    />
  </svg>
)

RocketIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}

RocketIcon.defaultProps = {
  width: "20",
  height: "20",
  color: "#1597F2",
}

export default RocketIcon
