import React from "react"

export function Row(props) {
  return (
    <div
      className="Row"
      style={{
        display: "flex",
        // The next line is necessary to fix a conflict with our custom CSS
        width: "100%",
        justifyContent: props.justifyContent,
        alignItems: props.alignItems ?? "center",
      }}
    >
      {props.children}
    </div>
  )
}
