import React from "react"

import ReactPhoneInput from "react-phone-input-2"

import "react-phone-input-2/lib/style.css"

function PhoneInput(props) {
  return (
    <ReactPhoneInput
      country={"us"}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      countryCodeEditable={false}
    />
  )
}

export default PhoneInput
