import callAPI from "callAPI"
import { formatRequestData } from "./helpers"
import queryString from "query-string"

export default (networkId, text, status, type, page, size, sortBy, tags) => {
  let query = {
    organization_ids: localStorage.getItem("orgId"),
    type: type || "agent",
    search: `${text || ""}`,
    sort: type === "agent" ? sortBy : null,
    status: status === "inactive" ? "disabled" : status === "all" ? "" : status,
  }

  if (type === "agent") {
    query = {
      ...query,
      tags,
      "page[number]": page,
      "page[size]": 150,
    }
  }

  return callAPI({
    path: "/user_agents",
    query,
  }).then((agents) => {
    const formattedData = agents.data ? formatRequestData(agents.data) : []

    if (type === "agent" && agents.links) {
      let totalPages = 1
      let searchText = ""
      let searchTag = ""
      let searchStatus = ""

      const last = queryString.parse(agents.links.last)

      totalPages = last["page[number]"] ?? 1
      searchText = last["search"] ?? ""
      searchTag = last["tags"] ?? ""
      searchStatus = last["status"] ?? ""

      return {
        data: formattedData,
        totalPages: Number(totalPages),
        searchText,
        searchTag,
        searchStatus,
      }
    }

    return {
      data: formattedData,
    }
  })
}
