import qs from "query-string"
import moment from "moment"
import filterPeriods from "consts/filterPeriods"
import {
  SELECT_TRAFFIC_TYPE,
  SELECT_QUERY_TYPE,
  SELECT_NETWORK_FILTER,
  SELECT_USER_AGENT,
  SELECT_LAN_PROXY,
  SELECT_QUERY_LOG_DEPLOYMENT,
  CHANGE_SEARCH_TEXT,
  CHANGE_PERIOD,
  SELECT_ORGANIZATION,
} from "action-types/filters"

const query = qs.parse(window.location.search)
const initialPeriod = query.period
const dates = query.from
  ? [moment(query.from).format(), moment(query.to).format()]
  : filterPeriods(initialPeriod)

const initialState = {
  lanProxies: [],
  loadLanProxyStatus: null,
  selectedOrganizationId: query.organizations || null,
  selectedNetworkId: query.networks || null,
  selectedUserAgentId: query.agents || null,
  selectedLanProxyId: query.proxies || null,
  selectedLocalUser: query.users || null,
  selectedTrafficType: query.traffic_type || "all",
  selectedQueryType: query.query_type || "all",
  searchText: query.q || "",
  period: initialPeriod || "last30Minutes",
  showCustomDateRange: false,
  from:
    initialPeriod === "custom"
      ? moment(query.from).format()
      : dates
      ? dates[0]
      : moment().startOf("day").format(),
  to:
    initialPeriod === "custom"
      ? moment(query.to).format()
      : dates
      ? dates[1]
      : moment().format(),
  dateRangeError: "",
}

export const filters = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_USER_AGENT:
      return {
        ...state,
        selectedUserAgentId: action.payload,
      }

    case SELECT_NETWORK_FILTER:
      return {
        ...state,
        selectedNetworkId: action.payload,
        selectedUserAgentId: null,
      }
    case SELECT_ORGANIZATION:
      return {
        ...state,
        selectedOrganizationId: action.payload,
      }
    case SELECT_LAN_PROXY:
      return {
        ...state,
        selectedLanProxyId: action.payload,
      }
    case SELECT_QUERY_LOG_DEPLOYMENT:
      return {
        ...state,
        selectedUserAgentId: action.payload.agents || null,
        selectedLocalUser: action.payload.users || null,
      }
    case SELECT_TRAFFIC_TYPE:
      return {
        ...state,
        selectedTrafficType: action.payload,
      }
    case SELECT_QUERY_TYPE:
      return {
        ...state,
        selectedQueryType: action.payload,
      }
    case CHANGE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      }
    case CHANGE_PERIOD:
      const { from, to, period, showCustomDateRange, dateRangeError } =
        action.payload

      return {
        ...state,
        period: period !== undefined ? period : state.period,
        from: from !== undefined ? from : state.from,
        to: to !== undefined ? to : state.to,
        showCustomDateRange:
          showCustomDateRange !== undefined
            ? showCustomDateRange
            : state.showCustomDateRange,
        dateRangeError: dateRangeError || state.dateRangeError,
      }
    default:
      return state
  }
}

export default filters
