import { UPDATE_POLICY_FORM_CATEGORIES_SEARCH_TEXT } from "action-types/filtering"

export const policyFormCategoriesSearchText = (state = "", action) => {
  switch (action.type) {
    case UPDATE_POLICY_FORM_CATEGORIES_SEARCH_TEXT:
      return action.payload
    default:
      return state
  }
}

export default policyFormCategoriesSearchText
