import React from "react"
import Card from "components/Card"
import Logo from "components/Logo"

const IEBlockedPage = () => (
  <div
    style={{
      height: "100vh",
      width: "100vw",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "60px 30px",
    }}
  >
    <div
      style={{
        textAlign: "center",
        width: 460,
      }}
    >
      <div
        style={{
          marginBottom: 30,
        }}
      >
        <Logo />
      </div>
      <Card maxWidth={700}>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <h1>Internet Explorer Detected</h1>
          <p>At this time, our Dashboard doesn't support Internet Explorer.</p>
          <p>
            Please use an alternate browser, such as{" "}
            <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>,{" "}
            <a href="https://www.google.com/chrome/browser/desktop/index.html">
              Chrome
            </a>
            , Edge or Safari.
          </p>
          {!localStorage.getItem("whitelabel.name") && (
            <p>
              If you're not using Internet Explorer, please{" "}
              <a href="mailto:support@dnsfilter.com">contact support</a>.
            </p>
          )}
        </div>
      </Card>
    </div>
  </div>
)

export default IEBlockedPage
