import { sendRequest } from "_Infinity/Core/Utils/sendRequest"

export function fetchSupport({
  body_text = "",
  subject = "",
  support = "true",
}) {
  return sendRequest({
    method: "post",
    path: `/support/email`,
    payload: {
      organization_id: localStorage.getItem("orgId"),
      body_text,
      subject,
      support,
    },
  })
}
