import React from "react"
import PropTypes from "prop-types"

const Card = ({
  title,
  titleElement,
  maxWidth,
  minWidth,
  marginBottom,
  paddingBottom,
  children,
  fullWidth,
  titleClass,
}) => (
  <div
    className="bp3-card"
    style={{
      maxWidth,
      minWidth,
      marginTop: 0,
      marginBottom,
      marginLeft: "auto",
      marginRight: "auto",
      padding: `15px 15px ${paddingBottom}px 15px`,
      width: fullWidth && "100%",
    }}
  >
    {title && <h4 className={titleClass}>{title}</h4>}
    {titleElement}
    {children}
  </div>
)

Card.propTypes = {
  title: PropTypes.node,
  titleElement: PropTypes.node,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  marginBottom: PropTypes.number,
  paddingBottom: PropTypes.number,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  titleClass: PropTypes.string,
}

Card.defaultProps = {
  paddingBottom: 15,
  title: null,
  children: null,
  titleElement: null,
  titleClass: null,
  fullWidth: false,
  maxWidth: null,
  minWidth: null,
  marginBottom: null,
}

export default Card
