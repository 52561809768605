import {
  LOAD_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS,
} from "action-types/subscriptions"

const subscriptions = (
  state = {
    data: [],
    type: null,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_SUBSCRIPTIONS:
      return {
        ...state,
        ...action.payload,
        type: LOAD_SUBSCRIPTIONS,
      }
    case UPDATE_SUBSCRIPTIONS:
      return {
        ...state,
        data: action.payload,
        type: UPDATE_SUBSCRIPTIONS,
      }
    default:
      return state
  }
}

export default subscriptions
