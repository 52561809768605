import {
  LOAD_ORGANIZATIONS,
  LOAD_MSP_OWNER_REQUEST,
  LOAD_MSP_OWNER_SUCCESS,
  LOAD_MSP_OWNER_FAILURE,
  UPDATE_ORGANIZATION,
  ORGANIZATION_REMOVE_UPDATE_PAYMENT_MESSAGE,
} from "action-types/organization"
import { showNotificationWithTTL } from "actions/notifications"
import loadMspOwnerCall from "api/msp/loadMspOwnerCall"
import loadOrganizationsCall from "api/msp/loadOrganizationsCall"
import updateOrganizationGdprStatus from "api/msp/updateOrganizationGdprStatus"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"

export const loadOrganizationsRequest = actionCreatorFetch(LOAD_ORGANIZATIONS)

export const loadOrganizationsSuccess = actionCreatorSuccess(LOAD_ORGANIZATIONS)

export const loadOrganizationsFailure = actionCreatorFailure(LOAD_ORGANIZATIONS)

export const updateOrganizationRequest = actionCreatorFetch(UPDATE_ORGANIZATION)

export const updateOrganizationSuccess =
  actionCreatorSuccess(UPDATE_ORGANIZATION)

export const updateOrganizationFailure =
  actionCreatorFailure(UPDATE_ORGANIZATION)

export const loadOrganizations =
  (dispatchRequest = true) =>
  (dispatch) => {
    if (dispatchRequest) {
      dispatch(
        loadOrganizationsRequest({
          data: [],
        }),
      )
    }

    return loadOrganizationsCall()
      .then((data) =>
        dispatch(
          loadOrganizationsSuccess({
            data,
          }),
        ),
      )
      .catch(() => {
        dispatch(loadOrganizationsFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "The was a problem while loading organizations",
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const loadMspOwnerRequest = () => ({
  type: LOAD_MSP_OWNER_REQUEST,
})

export const loadMspOwnerSuccess = () => ({
  type: LOAD_MSP_OWNER_SUCCESS,
})

export const loadMspOwnerFailure = () => ({
  type: LOAD_MSP_OWNER_FAILURE,
})

export const removeUpdatePaymentMessage = (organizationId) => ({
  type: ORGANIZATION_REMOVE_UPDATE_PAYMENT_MESSAGE,
  payload: {
    organizationId,
  },
})

export const loadMspOwner = () => (dispatch, getState) => {
  const currentOrg = getState().organizations.data.find(
    (org) => Number(org.id) === Number(localStorage.getItem("orgId")),
  )

  if (!currentOrg.owned_msp_id) {
    return
  }

  dispatch(loadMspOwnerRequest())

  return loadMspOwnerCall(currentOrg.owned_msp_id)
    .then(() => dispatch(loadMspOwnerSuccess()))
    .catch(() => {
      dispatch(loadMspOwnerFailure())

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "There was a problem while loading msp owner organization",
          message: "",
          icon: "exclamation-circle",
        }),
      )
    })
}

export const updateOrganizationGdpr = (org) => (dispatch, getState) => {
  dispatch(updateOrganizationRequest())

  return updateOrganizationGdprStatus(org)
    .then(({ data }) => {
      const organizations = getState().organizations.data
      const otherOrgs = organizations.filter(
        (item) => item.id !== Number(data.id),
      )
      let updatedOrg = organizations.find((item) => item.id === Number(data.id))

      updatedOrg = {
        ...updatedOrg,
        gdpr: data.attributes.gdpr,
      }

      if (data.attributes.gdpr) {
        localStorage.setItem("gdpr", data.attributes.gdpr)
        window.trackAnalyticsEvent("GDPR Enabled", {
          user_id: localStorage.getItem("userId"),
          organization_id: localStorage.getItem("orgId"),
        })
      } else {
        localStorage.removeItem("gdpr")
        window.trackAnalyticsEvent("GDPR Disabled", {
          user_id: localStorage.getItem("userId"),
          organization_id: localStorage.getItem("orgId"),
        })
      }

      return dispatch(
        updateOrganizationSuccess({
          data: [...otherOrgs, updatedOrg],
        }),
      )
    })
    .catch(() => {
      dispatch(updateOrganizationFailure())
    })
}
