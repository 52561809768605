import React from "react"
import { InputGroup } from "@blueprintjs/core"

export function TextInput(props) {
  return (
    <InputGroup
      type={props.type}
      intent={props.intent}
      leftIcon={props.icon}
      rightElement={props.rightAddon}
      value={props.value}
      placeholder={props.placeholder}
      round={props.round}
      large={props.size === 3}
      small={props.size === 1}
      fill={props.fill ?? true}
      autoFocus={props.autoFocus}
      readOnly={props.readOnly}
      disabled={props.disabled}
      data-test-id={props.testId ? props.testId : "input"}
      style={{
        // Fixes a Blueprint issue
        width: props.fill === false ? "auto" : undefined,
      }}
      onKeyUp={(e) => {
        if (props.onKeyUp === undefined) {
          return
        }

        props.onKeyUp(e.key)
      }}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
    />
  )
}
