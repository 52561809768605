import {
  USER_SIGN_UP_REQUEST,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_FAILURE,
  CLEAR_SIGN_UP,
} from "action-types/signup"

const initialState = {
  success: false,
  error: "",
  isSubmitting: false,
}

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGN_UP_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: "",
      }
    case USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        success: true,
        error: "",
      }
    case USER_SIGN_UP_FAILURE:
      return {
        ...state,
        success: false,
        isSubmitting: false,
        error: action.payload.error,
      }
    case CLEAR_SIGN_UP:
      return {
        ...state,
        success: false,
        error: "",
        isSubmitting: false,
      }
    default:
      return state
  }
}

export default auth
